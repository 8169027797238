import {AbortError} from '@/lib/CallChain';
import {persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import {EMPTY_LIST} from '@/lib/emptyList';
import {runLatest} from '@/lib/runLatest';
import {contextStore} from '@/stores/context';
import {MoStore, mutation} from '@/stores/lib/MoStore';
import {sprintStore} from '@/stores/sprint';
import {taskStore} from '@/stores/task';
import {Filters} from '@shared/filters/Filters';
import type {SprintFilter} from '@shared/filters/SprintFilter';
import {isStateEqual} from '@shared/lib/isStateEqual';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import {SprintState} from '@shared/models/Sprint';
import type {Task} from '@shared/models/Task';
import autobind from 'autobind-decorator';

export interface BacklogTaskListItem extends EntityListItem {
  sprintId: number | null;
  accountId?: number;
  projectId?: number;
}

interface BacklogState {
  currentTaskKey?: string | null;
  selectedTaskKeys: string[];
  selection: Set<number | string>;
  selected?: number;
  sprintList: EntityListItem[];
  taskSprintMap: Record<number, BacklogTaskListItem>;
}

const pageUrlRegex = /(?<context>\/:[^/]+)?\/(?<page>backlog)(?:\/(?<taskKey>\w+-\d+))?/;
export const DEFAULT_STATE: BacklogState = {
  currentTaskKey: undefined,
  selectedTaskKeys: [],
  selection: new Set(),
  selected: undefined,
  sprintList: [],
  taskSprintMap: {},
};

class BacklogStore extends MoStore<BacklogState> {
  constructor(state: BacklogState) {
    super(state);
    window.addEventListener('popstate', this.onPopState);
    window.addEventListener('pushState', this.onPushState);
    this.selectAndSubscribe((s) => [s.state.currentTaskKey, s.state.selectedTaskKeys], this.updateUrlFromState);
    this.selectAndSubscribe((s) => [s.state.selected, s.state.selection], this.saveSelectionIdsAsKeys);
  }

  @autobind
  saveSelectionIdsAsKeys() {
    const {selected, selection} = this.state;
    const selectionIds = [...selection]; // this set is not actually immutable
    runLatest('BacklogStore:saveSelectionIdsAsKeys', this.selectionIdsToKeys(selected, selectionIds)).then(
      ({key, keys}) => {
        backlogStore.setSelectionKeys(key, keys?.length > 1 ? keys : []);
      },
    );
  }

  @mutation
  setSelectionKeys(currentTaskKey?: string | null, selectedTaskKeys?: string[]) {
    this._state.currentTaskKey = currentTaskKey;
    if (selectedTaskKeys !== undefined && !isStateEqual(selectedTaskKeys, this._state.selectedTaskKeys)) {
      this._state.selectedTaskKeys = selectedTaskKeys;
    }
  }

  updateSelection(ids: Set<number | string>) {
    this.mutate((s) => {
      if (ids.size === 0) {
        s.selected = undefined;
        s.selection = ids;
        s.currentTaskKey = undefined;
        s.selectedTaskKeys = [];
      } else {
        if (ids.size > 1 && [...ids].some((id) => typeof id !== 'number')) {
          // if we have a mix of numbers and strings, we have selected the "new task" row, disallow the combination
          ids = new Set([[...ids].find((id) => typeof id !== 'number')!]); // select the first "new task" row
        }
        if (s.selected === undefined || !ids.has(s.selected)) {
          const first = ids.values().next().value;
          s.selected = typeof first === 'number' ? first : undefined;
        }
        if (![...ids].every((id) => s.selection.has(id)) || ![...s.selection].every((id) => ids.has(id))) {
          s.selection = ids;
        }
      }
    });
  }

  onMount() {
    this.updateStateFromUrl();

    // preload and watch backlog data
    const unsub = {
      sprintList: () => {},
      taskSprintMap: () => {},
    };
    this.selectAndSubscribe(
      (s) => s.state.sprintList,
      () => {
        unsub.taskSprintMap();
        unsub.taskSprintMap = taskStore.selectAndSubscribe(this.taskSprintMapSelector, (r) => {
          if (!r) return; // result is undefined if some data hasn't loaded yet
          this.set('taskSprintMap', r);
        });
      },
    );
    unsub.sprintList = sprintStore.selectAndSubscribe(
      (s) => this.sprintListSelector(s),
      (r) => this.set('sprintList', r),
    );
    return () => {
      unsub.sprintList();
      unsub.taskSprintMap();
    };
  }

  @autobind
  private onPopState() {
    this.updateStateFromUrl();
  }

  @autobind
  private onPushState() {
    this.updateStateFromUrl();
  }

  private async selectionIdsToKeys(selectedId?: number, selectionIds: (number | string)[] = EMPTY_LIST) {
    selectionIds = selectionIds.filter((id) => typeof id === 'number') as number[];
    if (selectedId === undefined || selectedId === 0) return {key: undefined, keys: []};

    const [key, keys] = (await taskStore.await((s) => [
      s.getById(selectedId)?.key,
      selectionIds.map((id) => s.getById(id as number)?.key),
    ])) ?? ['', []];
    if (!key) throw new AbortError(); // we failed to select
    return {key, keys: keys.filter((k) => k !== undefined)};
  }

  private getUrlComponents(): Partial<{context: string; page: string; taskKey: string; selectedTaskKeys: string[]}> {
    const components = window.location.pathname.match(pageUrlRegex)?.groups ?? {};
    const search = new URLSearchParams(window.location.search);
    const selectedTaskKeys = queryStringToTaskList(search.get('tasks') ?? '');
    return {...components, selectedTaskKeys};
  }

  private urlFromComponents(components: Partial<ReturnType<typeof this.getUrlComponents>>) {
    const {context, page, taskKey, selectedTaskKeys} = components;
    const basePath = context ? `${context}/` : '';
    return `${basePath}${page}/${taskKey ?? ''}${selectedTaskKeys && selectedTaskKeys.length > 0 ? `?tasks=${taskListToQueryString(selectedTaskKeys)}` : ''}`;
  }

  private updateStateFromUrl() {
    const {taskKey, selectedTaskKeys} = this.getUrlComponents();
    if (!taskKey) {
      this.updateSelection(new Set());
      return;
    }

    runLatest(
      'BacklogStore:updateStateFromUrl',
      taskStore.await((s) => s.listByKeys([taskKey, ...(selectedTaskKeys ?? [])].filter((s) => s !== undefined))),
    )
      .then((list) => list && taskStore.await((s) => list.map((t) => s.getById(t.id))))
      .then((taskList) =>
        taskList
          ?.filter((t) => !!t)
          .reduce(
            (acc, task) => {
              acc[task.key] = task;
              return acc;
            },
            {} as Record<string, Task>,
          ),
      )
      .then((taskMap) => {
        if (!taskMap || Object.keys(taskMap).length === 0) {
          this.updateSelection(new Set());
          throw new AbortError();
        }

        const selected = taskKey ? taskMap[taskKey]?.id : undefined;
        const selection = new Set(selectedTaskKeys?.map((key) => taskMap[key]?.id));
        return {selected, selection};
      })
      .then(async ({selected, selection}) => {
        const {key, keys} = await this.selectionIdsToKeys(selected, [...selection]);
        return {selected, selection, currentTaskKey: key, selectedTaskKeys: keys};
      })
      .then(({selected, selection, currentTaskKey, selectedTaskKeys}) => {
        if (selected) selection.add(selected);

        this.mutate((s) => {
          s.selected = selected;
          s.currentTaskKey = currentTaskKey;
          if (!isStateEqual(s.selection, selection)) {
            s.selection = selection;
            s.selectedTaskKeys = selectedTaskKeys;
          }
        });
      });
  }

  @autobind
  private updateUrlFromState() {
    const {currentTaskKey, selectedTaskKeys: currentSelection} = this.state;
    const {context, page, taskKey, selectedTaskKeys} = this.getUrlComponents();
    if (
      taskKey !== currentTaskKey ||
      taskListToQueryString(currentSelection) !== taskListToQueryString(selectedTaskKeys ?? [])
    ) {
      const newUrl = this.urlFromComponents({
        context,
        page,
        taskKey: currentTaskKey ?? undefined,
        selectedTaskKeys: currentSelection,
      });

      if (currentSelection.length > 1) {
        window.history.replaceState(null, '', newUrl);
      } else {
        window.history.pushState(null, '', newUrl);
      }
    }
  }

  @autobind
  private sprintListSelector(s: typeof sprintStore) {
    function getSorted(filter: SprintFilter) {
      return (s.getList(filter) ?? [])
        .map((sprint) => ({row: sprint, sprint: s.getById(sprint.id)}))
        .sort((a, b) => ((a.sprint?.startsAt ?? 'z') < (b.sprint?.startsAt ?? 'z') ? -1 : 1))
        .map(({row}) => row);
    }
    return [
      ...getSorted(Filters.sprintFilter({state: [SprintState.Active], projectId: contextStore.projectId})),
      ...getSorted(Filters.sprintFilter({state: [SprintState.Future], projectId: contextStore.projectId})),
    ];
  }

  @autobind
  private taskSprintMapSelector(s: typeof taskStore) {
    const results: Record<number, BacklogTaskListItem> = {};
    let anyNotLoaded = false;
    for (const sprint of this._state.sprintList ?? []) {
      const t = s.getList(Filters.taskFilter({sprintId: sprint.id}));
      anyNotLoaded ||= !t;
      if (t) {
        for (const item of t) {
          results[item.id] = {...item, sprintId: sprint.id};
        }
      }
    }

    const t = s.getList(Filters.taskFilter({sprintId: null}));
    anyNotLoaded ||= !t;

    if (anyNotLoaded) return undefined;
    return results;
  }

  public create() {
    backlogStore = new BacklogStore(DEFAULT_STATE);
  }
}

export let backlogStore = persistThroughHotReload('backlogStore', new BacklogStore(DEFAULT_STATE));
import.meta.hot?.accept(reloadOnHotUpdate);

export function queryStringToTaskList(str: string) {
  if (!str) return [];
  return str.split('_').flatMap((s) => {
    try {
      let [prefix, lowerStr, upperStr] = s.split('-');
      if (!upperStr) return [`${prefix}-${lowerStr}`];
      const lower = +lowerStr;
      const upper = +upperStr;
      return [...Array(upper - lower + 1).keys()].map((i) => `${prefix}-${lower + i}`);
    } catch (e) {
      console.error(e);
      return [];
    }
  });
}

export function taskListToQueryString(list: (string | undefined)[]) {
  const result = [];
  const sorted = list.filter(Boolean).sort((a, b) => +a!.split('-')[1] - +b!.split('-')[1]);
  if (sorted.length === 0) return '';
  let [prefix, lowerString] = sorted[0]?.split('-') ?? [];
  let lower = +lowerString;
  let upper = lower;
  for (let i = 1; i < sorted.length; i++) {
    const key = sorted[i];
    if (!key) continue;
    const [nextPrefix, nextString] = key.split('-');
    const nextValue = +nextString;
    if (nextPrefix !== prefix || nextValue !== upper + 1) {
      result.push(lower === upper ? `${prefix}-${lower}` : `${prefix}-${lower}-${upper}`);
      [prefix, lower, upper] = [nextPrefix, nextValue, nextValue];
    } else {
      upper = nextValue;
    }
  }
  result.push(lower === upper ? `${prefix}-${lower}` : `${prefix}-${lower}-${upper}`);
  return result.join('_');
}
