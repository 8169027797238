import {Worker} from '@/app-service-worker/Worker';
import {RightSide} from '@/components/backlog/RightSide';
import {MessageList} from '@/components/inbox/MessageList';
import {inboxMessageStore} from '@/stores/inboxMessage';
import {inboxStore} from '@/stores/ui/inboxStore';
import {EntityType} from '@shared/EntityType';
import React, {useCallback, useEffect} from 'react';

const Inbox: React.FC = () => {
  const {selectedId, taskId} = inboxStore.use((s) => s.state, []);
  const message = inboxMessageStore.use((s) => s.getById(selectedId), [selectedId]);
  const selectedTaskId = message?.taskId ?? taskId;

  useEffect(() => {
    inboxStore.onMount();
    return () => inboxStore.onDeselect();
  }, []);

  const onMarkUnread = useCallback((id: number) => {
    if (id) {
      Worker.patchEntities(EntityType.InboxMessage, [{id, readAt: null, updatedAt: new Date().toISOString()}]);
    }
  }, []);

  return (
    <div className="flex h-full flex-row">
      <div
        className="h-full w-1/4 min-w-64 border-r-2 border-[#B13982] border-opacity-65 bg-background-med"
        onClick={() => inboxStore.setSelectedId()}
      >
        <MessageList onClick={inboxStore.setSelectedId} onMarkUnread={onMarkUnread} selected={selectedId} />
      </div>
      <div className="flex h-full w-3/4 flex-col">
        <RightSide taskId={selectedTaskId} />
      </div>
    </div>
  );
};

export default Inbox;
