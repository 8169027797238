import {FormDataContext} from '@/stores/ui/formStore';
import {useContext} from 'react';
import type {TextFieldProps as AriaTextFieldProps} from 'react-aria-components';
import {TextField as AriaTextField} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';

export interface TextFieldProps extends AriaTextFieldProps {
  className?: string;
}

export function TextField({className, onChange, ...props}: TextFieldProps) {
  const {store} = useContext(FormDataContext);
  const {defaultValue, value} = store.use(
    (s) => (props.name ? {defaultValue: s.source[props.name], value: s.data[props.name]} : {}),
    [props.name],
  );
  const handleChange = (value: string) => {
    props.name && store.set(props.name, value);
    onChange?.(value);
  };

  return (
    <AriaTextField
      autoComplete="off"
      defaultValue={defaultValue}
      value={value}
      {...props}
      className={twMerge('flex flex-col gap-1', className)}
      onChange={handleChange}
    />
  );
}
