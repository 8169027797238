import {exposeInDev, persistThroughHotReload} from '@/lib/dev';
import type {EventedEvents} from '@/lib/Evented';
import {Evented} from '@/lib/Evented';
import autobind from 'autobind-decorator';

interface MouseEvents extends EventedEvents {
  move: (position: {x: number; y: number}) => void;
  drag: (position: {x: number; y: number}) => void;
}

class MouseMonitor extends Evented<MouseEvents> {
  public lastPosition?: {x: number; y: number};

  constructor() {
    super();
    window.addEventListener('mousemove', this.onMouseMove, {capture: true});
    window.addEventListener('drag', this.onMouseDrag, {capture: true});
  }

  @autobind
  private onMouseMove(event: MouseEvent) {
    this.lastPosition = {x: event.clientX, y: event.clientY};
    this.emit('move', this.lastPosition);
  }

  @autobind
  private onMouseDrag(event: DragEvent) {
    this.lastPosition = {x: event.clientX, y: event.clientY};
    this.emit('drag', this.lastPosition);
  }
}

export const mouseMonitor = persistThroughHotReload('mouseMonitor', new MouseMonitor());
exposeInDev({mouseMonitor});
