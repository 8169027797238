import {shallowEqual} from 'fast-equals';
import {useEffect, useRef} from 'react';

export function useAsyncEffect(effect: (isActive: () => boolean) => Promise<void>, deps: React.DependencyList) {
  const ref = useRef(deps);
  ref.current = deps;
  useEffect(() => {
    effect(() => shallowEqual(ref.current, deps)).catch((e) => {
      console.error(e);
    });
    return () => {};
  }, deps);
}
