import {createRef, useEffect, useRef} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  animation: string;
  className?: string;
  children: React.ReactNode;
}

export const AnimateIn: React.FC<Props> = ({animation, className, children}) => {
  const ref = createRef<HTMLDivElement>();
  const shouldIncludeAnimation = useRef(true);

  useEffect(() => {
    const duration = animation.match(/duration-\d+/)?.[0]?.replace('duration-', '');
    const timer = setTimeout(
      () => {
        shouldIncludeAnimation.current = false;
        if (!ref.current) return;
        ref.current.classList.remove('animate-in', ...animation.split(' '));
      },
      duration ? parseInt(duration) : 250,
    );
    return () => {
      clearTimeout(timer);
      shouldIncludeAnimation.current = true;
    };
  }, []);

  const classes = twMerge(className, shouldIncludeAnimation.current ? `animate-in ${animation}` : '');
  return (
    <div ref={ref} className={classes}>
      {children}
    </div>
  );
};
