import type {InputHTMLAttributes} from 'react';
import {forwardRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  initialValue?: string;
  value?: string;
  onCommit?: (value: string) => void;
  onCancel?: () => void;
  onValueChange?: (value: string) => void;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({className, initialValue, onCommit, onCancel, onValueChange, ...props}, ref) => {
    const [value, setValue] = useState(initialValue ?? '');

    const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
      // if the window still has focus, the user tabbed out or clicked elsewhere, commit the value
      if (document.hasFocus()) {
        const value = event.currentTarget.value.trim();
        if (value !== initialValue && value !== '') {
          onCommit?.(value);
        }
      }
      props.onBlur?.(event);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value.trim();
      if ((event.key === 'Enter' || event.key === 'Tab') && value !== initialValue && value !== '') {
        onCommit?.(value);
      } else if (event.key === 'Escape') {
        setValue(initialValue ?? '');
        onCancel?.();
      }
      props.onKeyDown?.(event);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const trimmedValue = event.currentTarget.value.trim();
      setValue(event.currentTarget.value);
      props.onChange?.(event);
      if (value.trim() !== trimmedValue) {
        onValueChange?.(trimmedValue);
      }
    };

    return (
      <input
        value={props.value ?? value}
        {...props}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={ref}
        className={twMerge(
          'transition-color-opacity placeholder-transparent hover:placeholder-gray-400/80 focus:placeholder-gray-400/80 disabled:bg-gray-50 disabled:text-gray-400',
          className,
        )}
        onBlur={onBlur}
      />
    );
  },
);
