import {SprintLabel} from '@/components/task/SprintLabel';
import {FilterableList, SelectableItem} from '@/design-system';
import {EMPTY_LIST} from '@/lib/emptyList';
import {contextStore} from '@/stores/context';
import {sprintStore} from '@/stores/sprint';
import {Filters} from '@shared/filters/Filters';
import type {ISprintFilter} from '@shared/filters/SprintFilter';
import {SprintState} from '@shared/models/Sprint';
import {useMemo} from 'react';

interface Props {
  onAction?: (sprintId: number) => void;
  excludeIds?: number[];
  includeNewSprintWithName?: string;
  filter?: Omit<ISprintFilter, 'type'>;
}

export const SprintLabelList: React.FC<Props> = ({onAction, excludeIds, includeNewSprintWithName, filter}) => {
  const sprints = sprintStore.use(
    (s) => {
      const list = s.getList(
        Filters.sprintFilter(
          filter ?? {
            projectId: contextStore.projectId,
            state: [SprintState.Active, SprintState.Future],
          },
        ),
      );
      return [
        {id: -1, text: 'Backlog', rank: ''},
        ...(list ?? EMPTY_LIST),
        ...(includeNewSprintWithName ? [{id: -2, text: includeNewSprintWithName, rank: ''}] : []),
      ];
    },
    [includeNewSprintWithName],
  );

  const items = useMemo(() => {
    if (excludeIds) {
      return sprints.filter((s) => !excludeIds.includes(s.id));
    }
    return sprints;
  }, [excludeIds, sprints]);

  return (
    <FilterableList items={items} aria-label="Sprint list" onAction={onAction}>
      {(item) => (
        <SelectableItem textValue={item.text!} key={item.id} className="flex items-center gap-2">
          <SprintLabel id={item.id} text={item.text} className="text-sm" />
        </SelectableItem>
      )}
    </FilterableList>
  );
};
