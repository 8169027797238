import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {Label} from '@shared/models/Label';
import type {DB} from 'src-sw/db/db';

export class LabelFilter extends EntityFilter<ILabelFilter> {
  readonly type = EntityType.Label;
  readonly projectId?: number;
  readonly startsWith?: string;

  constructor(filter: Omit<ILabelFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, entity: Label): Promise<[boolean, string, RankComparator]> {
    if (this.projectId !== undefined && entity.projectId !== this.projectId) {
      return [false, entity.name, RANK_COMPARATOR_ASC];
    }

    if (this.startsWith !== undefined && !entity.name.startsWith(this.startsWith)) {
      return [false, entity.name, RANK_COMPARATOR_ASC];
    }

    return [true, entity.name, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.projectId ?? ''}|${this.startsWith ?? ''}`;
  }
}

export type ILabelFilter = PublicPropertiesOf<LabelFilter>;
export function isLabelFilter(filter: IEntityFilter): filter is LabelFilter {
  return filter.type === EntityType.Label;
}
