import {Image} from '@/components/Image';
import {inboxMessageStore} from '@/stores/inboxMessage';
import React from 'react';
import {tv} from 'tailwind-variants';

interface Props {
  id: number;
  selected?: boolean;
  onClick: (id: number) => void;
  onMarkUnread: (id: number) => void;
}

const styles = tv({
  base: 'flex cursor-pointer flex-col justify-between overflow-hidden rounded-md bg-white bg-opacity-90 p-2 shadow-sm transition-all duration-200 hover:bg-opacity-100 hover:opacity-100 hover:shadow-md',
  variants: {
    isRead: {
      true: 'bg-opacity-30 opacity-70 outline outline-1 outline-white/60 hover:bg-opacity-60 hover:opacity-100 hover:outline-white/80',
    },
    isSelected: {
      true: 'bg-opacity-100 opacity-100 shadow-md outline outline-2 outline-purple-400 hover:bg-opacity-100 hover:opacity-100 hover:outline-2 hover:outline-purple-400',
    },
  },
  compoundVariants: [
    {
      isSelected: true,
      isRead: true,
      className: 'opacity-90',
    },
  ],
});

export const MessageListItem: React.FC<Props> = ({id, selected, onClick, onMarkUnread}) => {
  const item = inboxMessageStore.use((s) => s.getById(id), [id]);
  if (!item) return null;

  return (
    <div
      className={styles({isSelected: selected, isRead: !!item?.readAt})}
      style={{height: '80px'}}
      onClick={(e) => {
        e.stopPropagation();
        if (e.altKey) {
          onMarkUnread(id);
        } else {
          onClick(id);
        }
      }}
    >
      <div className="mb-1 flex flex-row items-start justify-start">
        <div className="mr-1 h-4 w-4 flex-shrink-0">{item?.titleIconId && <Image id={item.titleIconId} />}</div>
        <div className="line-clamp-2 overflow-hidden text-xs font-semibold text-gray-600">{item?.title}</div>
      </div>
      <div className="flex flex-col">
        <div className="line-clamp-2 text-xs text-gray-500">{item?.content}</div>
      </div>
    </div>
  );
};
