import {SuspenseLoader} from '@/design-system/SuspenseLoader';
import {twMerge} from 'tailwind-merge';

export function ModalBody({children, className}: {children: React.ReactNode; className?: string}) {
  return (
    <SuspenseLoader>
      <div className={twMerge('mb-4 flex flex-col gap-4', className)}>{children}</div>
    </SuspenseLoader>
  );
}
