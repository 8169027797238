import {Image} from '@/components/Image';
import {FilterableList, SelectableItem} from '@/design-system';
import {contextStore} from '@/stores/context';
import {taskTypeStore} from '@/stores/taskType';
import {Filters} from '@shared/filters/Filters';
import type {TaskTypeFilter} from '@shared/filters/TaskTypeFilter';
import React from 'react';

interface Props {
  onAction?: (statusId: number) => void;
  filter?: TaskTypeFilter;
}

export const TaskTypeList: React.FC<Props> = ({onAction, filter}) => {
  const items = taskTypeStore.use(
    (s) => s.getList(filter ?? Filters.taskTypeFilter({projectId: contextStore.projectId})) ?? [],
    [filter],
  );

  return (
    <FilterableList items={items} aria-label="Task type list" onAction={onAction}>
      {(item) => (
        <SelectableItem textValue={item.text} key={item.id} className="flex items-center gap-2">
          <Image id={item.icon} className="max-h-4 max-w-4" />
          <div className="line-clamp-1 text-sm">{item.text}</div>
        </SelectableItem>
      )}
    </FilterableList>
  );
};
