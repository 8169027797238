import {Spinner} from '@/design-system/Spinner';
import {Suspense} from 'react';

export function withSuspenseLoader<T>(Component: React.ComponentType<T>, Skeleton?: React.FC) {
  return (props: T & JSX.IntrinsicAttributes) => {
    return (
      <Suspense fallback={<>{Skeleton ? <Skeleton /> : <Spinner />}</>}>
        <Component {...props} />
      </Suspense>
    );
  };
}
