import {Worker} from '@/app-service-worker/Worker';
import {taskStore} from '@/stores/task';
import {momentumToAdf} from '@/text-editor/AdfToMomentum';
import type {EditorState} from '@/text-editor/MoEditor';
import {emptyDocString, MoEditor} from '@/text-editor/MoEditor';
import {EntityType} from '@shared/EntityType';
import {defaultFieldId} from '@shared/models/FieldSchema';
import {useCallback, useRef, useState} from 'react';
import {Button} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface Props {
  id: number;
}
const buttonStyles = tv({
  base: 'my-3 rounded-sm bg-pink-600 px-2 py-1 text-sm text-white',
  variants: {
    isCancel: {
      true: 'bg-gray-300 text-gray-700',
    },
    disabled: {
      true: 'bg-gray-200 text-gray-400',
    },
  },
});

export const TaskDescriptionEditor: React.FC<Props> = ({id}) => {
  const description = taskStore.use(
    (s) => {
      const t = s.getById(id);
      if (t) return t.description || emptyDocString;
      return undefined;
    },
    [id],
  );
  const editorRef = useRef<EditorState | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [editorKey, setEditorKey] = useState(0);

  const handleSave = useCallback(async () => {
    const doc = editorRef.current?.editor?.getJSON();
    if (!doc) throw new Error('Unable to get JSON from document editor');
    Worker.mutateEntity({
      entity: EntityType.Task,
      id: id.toString(),
      operations: {
        replace: {
          [defaultFieldId.Description.toString()]: {value: JSON.stringify(await momentumToAdf(doc, id))},
        },
      },
    });
  }, [editorRef, id]);

  const handleCancel = useCallback(() => {
    setIsDirty(false);
    // TODO: support resetting the editor without recreating it
    setEditorKey((prev) => prev + 1);
  }, []);

  if (description || description == null) {
    return (
      <>
        <MoEditor
          ref={editorRef}
          doc={description}
          taskId={id}
          onSave={handleSave}
          onDirtyChange={setIsDirty}
          editorClassName="min-h-36"
          key={editorKey}
        />
        <div className="flex flex-row justify-start gap-2 p-2">
          <Button
            className={buttonStyles({disabled: !isDirty, isCancel: true})}
            onPress={handleCancel}
            isDisabled={!isDirty}
          >
            Cancel
          </Button>
          <Button className={buttonStyles({disabled: !isDirty})} onPress={handleSave} isDisabled={!isDirty}>
            Save
          </Button>
        </div>
      </>
    );
  }
  return '';
};
