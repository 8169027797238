import RemoveIcon from '@/assets/icon-remove.svg?react';
import {Button} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface TagProps {
  strikethrough?: boolean;
  children: React.ReactNode;
  readonly?: boolean;
  onRemove?: () => void;
  isComponent?: boolean;
  isActive?: boolean;
}

const styles = tv({
  base: 'flex cursor-default items-center gap-1 break-all rounded-full bg-gray-500/15 px-2 text-sm leading-5 text-gray-800 transition-colors',
  variants: {
    readonly: {
      true: 'opacity-65',
    },
    strikethrough: {
      true: 'text-opacity-50 line-through',
    },
    isComponent: {
      true: 'bg-purple-200/50 text-purple-600 shadow-[inset_0_0_0_1px_rgba(var(--color-purple-800),0.5)]',
    },
    isActive: {
      false: 'opacity-50',
    },
  },
});

export const Tag: React.FC<TagProps> = ({
  children,
  strikethrough,
  isComponent,
  onRemove,
  readonly = false,
  isActive = true,
}) => {
  const style = styles({readonly, strikethrough, isComponent, isActive});
  return (
    <div className={style}>
      <span className="line-clamp-1">{children}</span>
      {!readonly && (
        <Button
          onPress={onRemove}
          className="-m-0.5 -mr-1.5 rounded-full p-0.5 opacity-30 transition-all hover:text-red-500 hover:opacity-100"
        >
          <RemoveIcon className="h-3.5 w-3.5" />
        </Button>
      )}
    </div>
  );
};
