import type {Entity} from '@shared/EntityType';

export enum FieldType {
  CreatedAt = 'CreatedAt',
  // UpdatedAt = 'UpdatedAt',
  Comment = 'Comment',
  Project = 'Project',
  Parent = 'Parent',
  TaskType = 'TaskType',
  Title = 'Title',
  Description = 'Description',
  Status = 'Status',
  Points = 'Points',
  Assignee = 'Assignee',
  Creator = 'Creator',
  Reporter = 'Reporter',
  Rank = 'Rank',
  Priority = 'Priority',
  Subtasks = 'Subtasks',
  Sprints = 'Sprints',
  Labels = 'Labels',
  Components = 'Components',
  LinkedTasks = 'LinkedTasks',
  Custom = 'Custom',
}

export enum FieldDataType {
  None = 'None',
  Array = 'Array',
  Boolean = 'Boolean',
  Number = 'Number',
  Date = 'Date',
  DateTime = 'DateTime',
  String = 'String',
  Text = 'Text',
  Enum = 'Enum',
  Image = 'Image',
  Project = 'Project',
  Board = 'Board',
  Sprint = 'Sprint',
  Status = 'Status',
  Actor = 'Actor',
  Attachment = 'Attachment',
  TaskType = 'TaskType',
  Priority = 'Priority',
  TaskLink = 'TaskLink',
  Components = 'Component',
}

export const nonNumericFieldDataTypes = new Set<FieldDataType>([
  FieldDataType.None,
  FieldDataType.Array,
  FieldDataType.Boolean,
  FieldDataType.Date,
  FieldDataType.DateTime,
  FieldDataType.String,
  FieldDataType.Text,
  FieldDataType.Enum,
]);

export interface FieldSchema extends Entity {
  id: number;
  accountId: number;
  identifier: string;
  name: string;
  description?: string;
  isDefault: boolean;
  type: FieldType;
  dataType: FieldDataType;
  itemsDataType: FieldDataType;
  required?: boolean;
  defaultValue?: string;
  options?: string[];
}

export const defaultFieldId: {[key in FieldType]: number} = {
  [FieldType.Comment]: -1,
  [FieldType.Project]: -2,
  [FieldType.Parent]: -3,
  [FieldType.TaskType]: -4,
  [FieldType.Title]: -5,
  [FieldType.Description]: -6,
  [FieldType.Status]: -7,
  [FieldType.Points]: -8,
  [FieldType.Assignee]: -9,
  [FieldType.Creator]: -10,
  [FieldType.Reporter]: -11,
  [FieldType.Rank]: -12,
  [FieldType.Priority]: -13,
  [FieldType.Subtasks]: -14,
  [FieldType.Sprints]: -15,
  [FieldType.Labels]: -16,
  [FieldType.LinkedTasks]: -17,
  [FieldType.CreatedAt]: -18,
  [FieldType.Components]: -19,
  [FieldType.Custom]: 0, // TODO
};

export const fieldTypeToModelKey: {[key in FieldType]: string} = {
  [FieldType.Comment]: '', // not used
  [FieldType.Project]: 'projectId',
  [FieldType.Parent]: 'parentId',
  [FieldType.TaskType]: 'typeId',
  [FieldType.Title]: 'title',
  [FieldType.Description]: 'description',
  [FieldType.Status]: 'statusId',
  [FieldType.Points]: 'points',
  [FieldType.Assignee]: 'assigneeId',
  [FieldType.Creator]: 'creatorId',
  [FieldType.Reporter]: 'reporterId',
  [FieldType.Rank]: 'rank',
  [FieldType.Priority]: 'priorityId',
  [FieldType.Subtasks]: '', // not used
  [FieldType.Sprints]: 'sprintId',
  [FieldType.Labels]: 'labels',
  [FieldType.Components]: 'components',
  [FieldType.LinkedTasks]: '', // not used
  [FieldType.CreatedAt]: 'createdAt',
  [FieldType.Custom]: '', // not used
};
