import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import type {EntityStoreState} from '@/stores/lib/EntityStore';
import {EntityStore} from '@/stores/lib/EntityStore';
import {EntityType} from '@shared/EntityType';
import type {TaskLinkType} from '@shared/models/TaskLinkType';

export const taskLinkTypeStore = persistThroughHotReload(
  'taskLinkTypeStore',
  new EntityStore<EntityStoreState<TaskLinkType>, TaskLinkType>(EntityType.TaskLinkType, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({taskLinkTypeStore});
import.meta.hot?.accept(reloadOnHotUpdate);
