import {mutateTask} from '@/app-service-worker/mutateTask';
import {Worker} from '@/app-service-worker/Worker';
import {EditableActor} from '@/components/actor/ActorSelector';
import {EditableEpicLabel} from '@/components/task/EditableEpicLabel';
import {EditableSprintLabel} from '@/components/task/EditableSprintLabel';
import {EditableTaskPoints} from '@/components/task/EditableTaskPoints';
import {EditableTaskTitle} from '@/components/task/EditableTaskTitle';
import {EditableTaskType} from '@/components/task/EditableTaskType';
import {StatusLabelSelector} from '@/components/task/StatusLabelSelector';
import {TagsField} from '@/components/task/TagsField';
import {Spacer} from '@/design-system';
import {useAsyncEffect} from '@/hooks/useAsyncEffect';
import {EMPTY_LIST} from '@/lib/emptyList';
import {taskStore} from '@/stores/task';
import {taskTypeStore} from '@/stores/taskType';
import {adfToMomentum} from '@/text-editor/AdfToMomentum';
import {MOMENTUM_TIPTAP_EXTENSIONS} from '@/text-editor/useMoEditor';
import {FieldType} from '@shared/models/FieldSchema';
import type {Mutation} from '@shared/models/Mutation';
import {generateHTML} from '@tiptap/core';
import React, {useCallback, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  id: number;
  expanded?: boolean;
  className?: string;
}

const styles = tv({
  slots: {
    container: 'flex shrink-0 flex-col justify-between gap-2 p-5 pb-1 pt-6 transition-all duration-500',
    summary: 'flex-grow overflow-hidden transition-all duration-300',
  },
  variants: {
    expanded: {
      true: {
        container: 'h-24 pb-4',
        summary: 'animate-out fade-out-0 fill-mode-forwards',
      },
      false: {
        container: 'h-36',
        summary: 'animate-in fade-in',
      },
    },
  },
});

export const TaskHeader: React.FC<Props> = ({id, expanded, className}) => {
  const task = taskStore.use((s) => s.getById(id), [id]);
  const taskType = taskTypeStore.use((s) => s.getById(task?.typeId), [task?.typeId]);
  const epicId = taskStore.use(
    (s) =>
      taskType?.hierarchyLevel === -1
        ? s.getById(task?.parentId)?.parentId
        : taskType?.hierarchyLevel === 0
          ? task?.parentId
          : null,
    [taskType?.hierarchyLevel, task?.parentId],
  );
  const {container, summary} = styles({expanded});

  const handleChange = useCallback(
    (_value: unknown, operation: Mutation['operations']) => {
      mutateTask(id, operation);
    },
    [id],
  );

  const handleTaskStatusChange = (statusId: number) => {
    Worker.moveTasks({taskIds: [id], statusId});
  };

  return (
    <div className={twMerge(container(), className)}>
      <div className="flex flex-row items-start justify-between gap-2 pb-2">
        <div className="relative flex max-h-8 flex-grow flex-row items-start gap-1">
          <div className="flex h-5 w-5 flex-shrink-0 items-center">
            {task?.typeId && <EditableTaskType id={task.typeId} onCommit={handleChange} />}
          </div>
          <div className="absolute -top-4 text-xs text-gray-500/50">{task?.key}</div>
          <EditableTaskTitle
            className="line-clamp-2 w-full overflow-hidden text-left font-semibold leading-tight text-gray-950"
            taskId={id}
            title={task?.title}
          />
        </div>
        <div className="flex flex-row items-center">
          <EditableActor type={FieldType.Assignee} actorId={task?.assigneeId} onCommit={handleChange} />
        </div>
      </div>
      <div className={summary()}>
        <div className="line-clamp-2 text-sm leading-tight text-gray-500">
          <TagsField
            className="inline-flex"
            labelIds={task?.labels ?? EMPTY_LIST}
            componentIds={task?.components ?? EMPTY_LIST}
            readonly
          />
          {!!(task?.labels?.length || task?.components?.length) && <Spacer />}
          <Description description={task?.description} taskId={id} />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row">
          <StatusLabelSelector id={task?.statusId} onCommit={handleTaskStatusChange} />
          <Spacer />
          {epicId && (
            <EditableEpicLabel id={epicId} onCommit={handleChange} editable={taskType?.hierarchyLevel === 0} />
          )}
        </div>
        <Spacer />
        <div className="flex max-w-[50%] gap-2">
          <EditableSprintLabel sprintId={task?.sprintId} taskId={id} />
          <EditableTaskPoints points={task?.points} onCommit={handleChange} />
        </div>
      </div>
    </div>
  );
};

const Description: React.FC<{description: string | undefined; taskId: number}> = ({description, taskId}) => {
  const [text, setText] = useState<string | null>(null);

  useAsyncEffect(
    async (isActive) => {
      if (!description) {
        setText('');
      } else {
        const doc = await adfToMomentum(description, taskId);
        if (!isActive()) return;
        const html = generateHTML(doc, MOMENTUM_TIPTAP_EXTENSIONS).replace(
          /<\/(p|li|div|pre|blockquote|h[1-6])>/g,
          '</$1> ',
        );
        setText(new DOMParser().parseFromString(html, 'text/html').documentElement.textContent);
      }
    },
    [description],
  );

  return <>{text}</>;
};
