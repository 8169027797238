import {Worker} from '@/app-service-worker/Worker';
import {TaskLabel} from '@/components/task/TaskLabel';
import {TaskTypeahead} from '@/components/task/TaskTypeahead';
import type {Suggestion} from '@/design-system';
import {InputWell} from '@/design-system/InputWell';
import type {SelectItem} from '@/design-system/Select';
import {Select} from '@/design-system/Select';
import {authStore} from '@/stores/auth';
import {taskLinkStore} from '@/stores/taskLink';
import {taskLinkTypeStore} from '@/stores/taskLinkType';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import {defaultFieldId} from '@shared/models/FieldSchema';
import type {TaskLink} from '@shared/models/TaskLink';
import type {TaskLinkType} from '@shared/models/TaskLinkType';
import {ChevronDownIcon} from 'lucide-react';
import {useCallback, useRef, useState} from 'react';

interface Props {
  taskId: number;
}

const inputWellStyles =
  'min-h-8 flex flex-row items-center gap-1 focus-within:ring-2 focus-within:ring-pink-500 flex-wrap';

export function TaskLinksInput({taskId}: Props) {
  const linkList = taskLinkStore.use((s) => s.getList(Filters.taskLinkFilter({taskId})), [taskId]) ?? [];
  const links =
    taskLinkStore.use((s) => linkList?.map((l) => s.getById(l.id)).filter((l) => l != null), [linkList]) ?? [];
  const linkTypes =
    taskLinkTypeStore.use(
      (s) =>
        links?.reduce(
          (acc, l) => {
            const v = s.getById(l?.taskLinkTypeId);
            if (v) acc[v.id] = v;
            return acc;
          },
          {} as Record<number, TaskLinkType>,
        ),
      [links],
    ) ?? {};

  const [addLinkKey, setAddLinkKey] = useState(0);

  const byLabel = links.reduce(
    (acc, link) => {
      const type = linkTypes[link.taskLinkTypeId] ?? null;
      if (!type) return acc;

      const label = link.srcTaskId === taskId ? type.dstLabel : type.srcLabel;
      const id = `${type.id}|${label}`;
      acc[id] ??= [];
      acc[id].push(link);
      return acc;
    },
    {} as Record<string, TaskLink[]>,
  );

  const onAdd = useCallback(
    async (typeId: string, targetTaskId: number) => {
      const [id, label] = typeId.split('|');
      const type = await taskLinkTypeStore.await((s) => s.getById(+id));
      if (type == null) return;
      const srcTaskId = type.srcLabel === label ? targetTaskId : taskId;
      const dstTaskId = type.srcLabel === label ? taskId : targetTaskId;

      Worker.mutateEntity({
        entity: EntityType.Task,
        id: taskId.toString(),
        operations: {
          append: {
            [defaultFieldId.LinkedTasks.toString()]: {
              value: JSON.stringify({
                taskLinkTypeId: +id,
                accountId: authStore.accountId,
                srcTaskId,
                dstTaskId,
              }),
            },
          },
        },
      });

      setAddLinkKey((prev) => prev + 1);
    },
    [taskId],
  );

  const onRemove = useCallback(
    (id: number) => {
      Worker.mutateEntity({
        entity: EntityType.Task,
        id: taskId.toString(),
        operations: {
          remove: {
            [defaultFieldId.LinkedTasks.toString()]: {
              value: id.toString(),
            },
          },
        },
      });
    },
    [taskId],
  );

  return (
    <div className="flex flex-col gap-1">
      {Object.entries(byLabel)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([idWithLabel, links]) => (
          <InputWell key={idWithLabel} className={inputWellStyles}>
            <h3 className="w-24 flex-shrink-0 text-xs font-medium">
              {idWithLabel.split('|')[1].replace(/^\w/, (c) => c.toUpperCase())}
            </h3>
            {links.map((link) => (
              <TaskLabel
                key={link.id}
                id={link.srcTaskId == taskId ? link.dstTaskId : link.srcTaskId}
                onRemove={() => onRemove(link.id)}
              />
            ))}
            <TaskTypeahead
              placeholder="Add..."
              onAction={(suggestion: Suggestion) => onAdd(idWithLabel, suggestion.id)}
              inputClassName="w-16"
              excludeTaskIds={[taskId]}
            />
          </InputWell>
        ))}
      <AddLinkRow key={`link-${addLinkKey}`} onAction={onAdd} taskId={taskId} currentLinks={links} />
    </div>
  );
}

const AddLinkRow = ({
  onAction,
  taskId,
  currentLinks,
}: {
  onAction: (typeId: string, taskId: number) => void;
  taskId: number;
  currentLinks: TaskLink[];
}) => {
  const allLinkTypes =
    taskLinkTypeStore
      .use(
        (s) =>
          s
            .getList(Filters.taskLinkTypeFilter({}))
            ?.map((linkType) => s.getById(linkType.id))
            .filter((t) => t != null)
            .flatMap((t) => [
              {id: `${t.id}|${t.srcLabel}`, textValue: t.srcLabel},
              t.srcLabel === t.dstLabel ? undefined : {id: `${t.id}|${t.dstLabel}`, textValue: t.dstLabel},
            ]),
        [],
      )
      ?.filter((t) => t != null) ?? [];

  const [selected, setSelected] = useState<SelectItem>();
  const onTypeSelected = useCallback((item: SelectItem) => {
    setSelected(item);
    inputRef.current?.focus();
  }, []);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <InputWell className={inputWellStyles}>
      <Select items={allLinkTypes} onChange={onTypeSelected} className="text-left !ring-0" ariaLabel="Task link type">
        <h3
          className={`-m-1.5 flex w-28 items-center gap-1 p-1.5 text-xs font-medium ${!selected ? 'text-gray-500' : ''}`}
        >
          {selected?.textValue ?? 'Add'}
          <ChevronDownIcon className="h-3 w-3" />
        </h3>
      </Select>
      {selected && (
        <TaskTypeahead
          ref={inputRef}
          autoFocus
          placeholder="Add..."
          onAction={(s) => onAction(selected.id as string, s.id)}
          excludeTaskIds={[
            taskId,
            ...currentLinks.filter((l) => l.taskLinkTypeId === selected.id).map((l) => l.dstTaskId),
          ]}
        />
      )}
    </InputWell>
  );
};
