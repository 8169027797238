import {deepEqual} from 'fast-equals';
import type {ModuleNamespace} from 'vite/types/hot.js';

export const TODO = (...args: unknown[]): unknown => {
  console.error('Not yet implemented. Provided args: ', args);
  return {};
};

// Caution: not tree-shakeable
export const whenInDev = (fn: () => void) => {
  if (DEV) {
    fn();
  }
};

export const exposeInDev = (obj: Record<string, unknown>) => {
  if (DEV) {
    Object.entries(obj).forEach(([key, value]) => {
      Object.defineProperty(window, key, {
        value: value,
        writable: true,
        enumerable: false,
        configurable: true,
      });
    });
  }
};

export const reloadOnHotUpdate = (newModule: ModuleNamespace | undefined) => {
  if (newModule) {
    window.location.reload();
  }
};

export const persistThroughHotReload = <T>(key: string, value: T): T => {
  if (!DEV) return value;
  exposeInDev({[key]: value});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((globalThis as any)['__hot__' + key] ??= value);
};

const map: Record<string, unknown> = {};
export const logHasChanged = <T>(key: string, value: T) => {
  if (!DEV) return;

  const info = Array.isArray(value)
    ? `[${value.length}]`
    : typeof value === 'object'
      ? `{${Object.keys(value as never).length}}`
      : '';

  const prev = map[key];
  if (prev !== value) {
    if (deepEqual(prev, value)) {
      console.log(`[DEV] ${key} is deepEqual but not identical to last`, info);
    } else {
      console.log(`[DEV] ${key} changed since last`, info);
    }
  } else {
    console.warn(`[DEV] ${key} is identical to last`, info);
  }
  map[key] = value;
};
