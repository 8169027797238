import {Worker} from '@/app-service-worker/Worker';
import {authStore} from '@/stores/auth';
import {EntityType} from '@shared/EntityType';
import type {TaskReadState} from '@shared/models/TaskReadState';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const taskReadStateStore = persistThroughHotReload(
  'taskReadStateStore',
  new EntityStore<EntityStoreState<TaskReadState>, TaskReadState>(EntityType.TaskReadState, {
    byId: new Map(),
    byList: new Map(),
  }).extend(function () {
    // const self = this;
    return {
      patchReadAt(taskId: number, readTo: string | null) {
        const {actorId = 0, accountId = 0} = authStore.state;
        Worker.putEntities(EntityType.TaskReadState, [{id: taskId, accountId, actorId, readTo}]);
      },
    };
  }),
);
exposeInDev({taskReadStateStore});
import.meta.hot?.accept(reloadOnHotUpdate);
