import {useEffect, useState} from 'react';
import {useSearch} from 'wouter';

type QueryParams = {
  [key: string]: string | undefined;
};

function applyDefaults<T extends QueryParams>(queryParams: URLSearchParams, defaults?: T): T {
  const params = Object.fromEntries(queryParams);
  if (!defaults) return params as T;
  for (const [key, defaultValue] of Object.entries(defaults)) {
    if (params[key] === undefined && defaultValue !== undefined) {
      params[key] = defaultValue;
    }
  }
  return params as T & {[key: string]: unknown};
}

const useQuery = <T extends QueryParams>(defaults?: T): T => {
  const search = useSearch();
  const queryParams = new URLSearchParams(search);
  const [result, setResult] = useState(applyDefaults(queryParams, defaults) as T);

  useEffect(() => {
    setResult(applyDefaults(queryParams, defaults) as T);
  }, [search]);

  return result;
};

export default useQuery;
