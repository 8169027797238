import {SuspenseLoader} from '@/design-system/SuspenseLoader';
import {ErrorBoundary} from '@/pages/ErrorBoundary';
import {enableMapSet} from 'immer';
import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './app-service-worker/Worker';
import './aria.css';
import './lib/pushStateEvent';
import './stores/auth';
import './styles.css';
import './tiptap.css';

enableMapSet();

export const startApp = () => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
      <ErrorBoundary>
        <SuspenseLoader>
          <App />
        </SuspenseLoader>
      </ErrorBoundary>
    </StrictMode>,
  );
};
