import {Spacer} from '@/design-system';
import type {ErrorInfo} from 'react';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state = {hasError: false, error: null} as State;

  static getDerivedStateFromError(error: unknown) {
    if (error instanceof Error) {
      return {hasError: true, error};
    }
    return {hasError: true, error: new Error(`An unknown error occurred: ${error}`)};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-full bg-gray-200 text-gray-300">
          <div
            className="flex h-screen w-full flex-col items-center justify-around text-5xl font-black"
            style={{
              textShadow: `0 0 .2rem #EEE7, 0 0 0 #0002`,
              color: 'transparent',
              backgroundColor: '#DDD',
              backgroundClip: 'text',
            }}
          >
            <Spacer />
            <div>Oops</div>
            <div className="rotate-45 text-8xl">;(</div>
            <div>Something went wrong</div>
            <div className="text-2xl underline hover:text-blue-300">
              <a href="/">Refresh to try again</a>
            </div>
            <Spacer />
          </div>
          <pre className="border-t-1 border-red-700 bg-red-100 p-10 text-sm text-red-700">
            {this.state.error?.message}
            {DEV && this.state.error?.stack}
          </pre>
        </div>
      );
    }

    return this.props.children;
  }
}
