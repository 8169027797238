import type {FieldErrorProps as AriaFieldErrorProps} from 'react-aria-components';
import {FieldError as AriaFieldError} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';

export interface FieldErrorProps extends AriaFieldErrorProps {
  className?: string;
}

export function FieldError({className, ...props}: FieldErrorProps) {
  return <AriaFieldError {...props} className={twMerge('min-h-4 text-xs leading-tight text-red-600', className)} />;
}
