interface AppLayoutProps {
  children: React.ReactNode;
  nav?: React.ReactNode;
}

export function AppLayout({children, nav}: AppLayoutProps) {
  return (
    <div className="h-screen max-h-screen overflow-hidden bg-[linear-gradient(64deg,#77476d,#eb378e)] animate-in fade-in">
      <header className="absolute flex h-10 w-full items-center justify-center"></header>
      <div className="absolute bottom-0 top-10 flex w-full overflow-visible">
        <nav className="flex w-14 flex-shrink-0 flex-col items-center gap-2">{nav}</nav>
        <main className="bg-background w-full overflow-y-auto rounded-tl-lg bg-background-light">{children}</main>
      </div>
    </div>
  );
}
