/**
 * Performs a general equality check on two values that may be used as state / part of state in a store.
 * This is a simplified deep equality check that only applies to primitives, arrays, sets, and plain objects.
 * It does NOT compare object prototypes, class instances, or handle circular references.
 */
export function isStateEqual(a: unknown, b: unknown): boolean {
  if (a === b) return true;

  if (typeof a === 'number' && typeof b === 'number') {
    return a === b || (Number.isNaN(a) && Number.isNaN(b));
  }

  if (a instanceof Set || b instanceof Set) {
    if (a instanceof Set !== b instanceof Set) return false;
    const aSet = a as Set<unknown>;
    const bSet = b as Set<unknown>;
    if (aSet.size !== bSet.size) return false;
    for (const item of aSet) {
      if (!bSet.has(item)) return false;
    }
    return true;
  }

  if (a instanceof Array || b instanceof Array) {
    if (a instanceof Array !== b instanceof Array) return false;
    const aArray = a as unknown[];
    const bArray = b as unknown[];
    if (aArray.length !== bArray.length) return false;
    for (let i = 0; i < aArray.length; i++) {
      if (!isStateEqual(aArray[i], bArray[i])) return false;
    }
    return true;
  }

  if (a instanceof Object || b instanceof Object) {
    if (a instanceof Object !== b instanceof Object) return false;
    const aObj = a as Record<string, unknown>;
    const bObj = b as Record<string, unknown>;
    const aKeys = Object.keys(aObj);
    const bKeys = Object.keys(bObj);
    if (aKeys.length !== bKeys.length) return false;
    for (const key of aKeys) {
      if (!bKeys.includes(key)) return false;
      if (!isStateEqual(aObj[key], bObj[key])) return false;
    }
    return true;
  }

  return false;
}
