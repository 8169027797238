import type {Entity} from '@shared/EntityType';

export enum StatusCategory {
  Unknown = 'Unknown',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Done = 'Done',
}

export const NOT_DONE_STATUS_CATEGORIES = [
  StatusCategory.InProgress,
  StatusCategory.NotStarted,
  StatusCategory.Unknown,
];
export const DONE_STATUS_CATEGORIES = [StatusCategory.Done];

export interface Status extends Entity {
  id: number;
  accountId: number;
  projectId?: number;
  category: StatusCategory;
  name: string;
  color?: string;
}
