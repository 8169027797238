import {ActorAvatar} from '@/components/actor/ActorAvatar';
import {TaskListItem} from '@/components/task/SelectTaskList';
import {SelectableItem} from '@/design-system';
import {useDebouncedState} from '@/hooks/useDebouncedState';
import {EMPTY_LIST} from '@/lib/emptyList';
import {actorStore} from '@/stores/actor';
import {taskStore} from '@/stores/task';
import type {MentionNodeAttrs} from '@/text-editor/extensions/mentions/Mention';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import type {SuggestionProps} from '@tiptap/suggestion';
import {deepEqual} from 'fast-equals';
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import type {Selection} from 'react-aria-components';
import {Dialog, ListBox} from 'react-aria-components';

export interface MentionListInterface {
  onKeyDown: (arg: {event: KeyboardEvent}) => boolean;
}

export const MentionList = forwardRef<MentionListInterface, SuggestionProps>((props, ref) => {
  const [selectedId, setSelectedId] = useState(0);
  const rawQuery = props.query.toLocaleLowerCase().trim();
  const [query, debouncedSetValue] = useDebouncedState(rawQuery, 100);
  const newOptions = useMentionSearch(query);
  const [options, setOptions] = useState(newOptions);
  useEffect(() => {
    if (query != rawQuery) debouncedSetValue(rawQuery);
    if (newOptions && !deepEqual(newOptions, options)) setOptions(newOptions);
  }, [newOptions, query, rawQuery]);

  const filteredOptions = useMemo(
    () =>
      options
        ?.filter((o) => o?.text?.toLocaleLowerCase().includes(rawQuery.replace(/^@/, '')))
        ?.sort((a, b) => (a?.text ?? '').localeCompare(b?.text ?? ''))
        ?.slice(0, 10) ?? EMPTY_LIST,
    [options, rawQuery],
  );

  useEffect(() => {
    const index = filteredOptions.findIndex((o) => o.id === selectedId);
    if (index === -1 && filteredOptions.length > 0) setSelectedId(filteredOptions[0].id);
  }, [filteredOptions, selectedId]);

  const selectItem = (id: number | string) => {
    const option = filteredOptions.find((o) => o.id === id);
    if (option) {
      props.command({id: option.id.toString(), label: option.text, entity: option.type} as MentionNodeAttrs);
    }
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({event}: {event: KeyboardEvent}) => {
      if (event.key === 'ArrowUp') {
        const index = Math.max(
          0,
          filteredOptions.findIndex((o) => o.id === selectedId),
        );
        setSelectedId(filteredOptions[(index + filteredOptions.length - 1) % filteredOptions.length].id);
      } else if (event.key === 'ArrowDown') {
        const index = filteredOptions.findIndex((o) => o.id === selectedId);
        setSelectedId(filteredOptions[(index + 1) % filteredOptions.length].id);
      } else if (event.key === 'Enter' || event.key === 'Tab') {
        if (selectedId === 0 && filteredOptions.length === 1) {
          selectItem(filteredOptions[0].id);
        } else {
          selectItem(selectedId);
        }
      } else {
        return false;
      }

      return true;
    },
  }));

  return (
    <Dialog
      className="min-w-40 max-w-64 rounded-md border border-black/20 bg-white px-0 py-2 text-black/80 shadow-md"
      aria-label="Mention list"
    >
      {filteredOptions.length ? (
        <ListBox
          items={filteredOptions}
          aria-label="Mention list"
          onAction={selectItem}
          selectedKeys={[selectedId]}
          selectionMode="single"
          onSelectionChange={(keys: Selection) => selectItem([...keys][0] as number)}
        >
          {(item) => (
            <SelectableItem key={item.id} textValue={item.text}>
              {item.type == EntityType.Actor && <ActorAvatar actorId={item.id} showName size="sm" style="normal" />}
              {item.type == EntityType.Task && <TaskListItem item={item} />}
            </SelectableItem>
          )}
        </ListBox>
      ) : (
        <pre className="text-sm text-gray-500/30">¯\_(ツ)_/¯</pre>
      )}
    </Dialog>
  );
});

interface Suggestion extends EntityListItem {
  type: EntityType;
}

function useMentionSearch(query: string): Suggestion[] | undefined {
  const type = query.startsWith('@') ? EntityType.Task : EntityType.Actor;
  query = query.replace(/^@/, '');
  const actorOptions = actorStore.use(
    (s) =>
      type == EntityType.Actor
        ? s.getList(Filters.actorFilter({query}))?.map((item) => ({...item, type: EntityType.Actor}))
        : undefined,
    [type, query],
  );
  const taskOptions = taskStore.use(
    (s) =>
      type == EntityType.Task
        ? s.getList(Filters.taskFilter({query}))?.map((item) => ({...item, type: EntityType.Task}))
        : undefined,
    [type, query],
  );

  if (type == EntityType.Actor) {
    return actorOptions || undefined;
  } else if (type == EntityType.Task) {
    return taskOptions || undefined;
  }
}
