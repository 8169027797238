import {exposeInDev} from '@/lib/dev';
import {mergeAttributes, Node, nodeInputRule, nodePasteRule} from '@tiptap/core';
import emojiRegex from 'emoji-regex';

export interface EmojiNodeAttrs {
  id: string;
  text: string;
}

export interface EmojiOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Commands<ReturnType> {}
}

const emojiPasteRegex = emojiRegex(); // this is a global regex without ^ or $
const emojiFindRegex = new RegExp('(?:' + emojiPasteRegex.source + ')$');
exposeInDev({emojiFindRegex});

/**
 * This extension allows you to insert and manage emojis.
 */
export const Emoji = Node.create<EmojiOptions, EmojiNodeAttrs>({
  name: 'emoji',

  inline: true,
  group: 'inline',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-id'),
        renderHTML: (attributes: EmojiNodeAttrs) => (attributes.id ? {'data-id': attributes.id} : {}),
      },
      text: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-text'),
        renderHTML: (attributes: EmojiNodeAttrs) => (attributes.text ? {'data-text': attributes.text} : {}),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({HTMLAttributes, node}) {
    return [
      'span',
      mergeAttributes({'data-type': this.name}, this.options.HTMLAttributes, HTMLAttributes),
      node.attrs.text,
    ];
  },

  renderText({node}) {
    return node.attrs.text;
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: emojiFindRegex,
        type: this.type,
        getAttributes: (match) => ({id: emojiToId(match[0]), text: match[0]}),
      }),
    ];
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: emojiPasteRegex,
        type: this.type,
        getAttributes: (match) => ({id: emojiToId(match[0]), text: match[0]}),
      }),
    ];
  },
});

function emojiToId(emoji: string) {
  return Array.from(emoji)
    .map((c) => c.codePointAt(0)!.toString(16))
    .join('-');
}
