import {mergeAttributes, Node} from '@tiptap/core';

export interface StatusNodeAttributes {
  color: string;
  text: string;
  localId?: string;
  style?: string;
}

export interface StatusOptions {
  HTMLAttributes: Record<string, any>;
}

/**
 * This extension is for viewing the Jira status node type
 * We currently don't plan to use this or allow the user to create it inside Momentum
 */
export const Status = Node.create<StatusOptions, StatusNodeAttributes>({
  name: 'status',

  inline: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'status',
      },
    };
  },

  addAttributes() {
    return {
      color: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-color'),
        renderHTML: (attributes) => (attributes.color ? {'data-color': attributes.color} : {}),
      },
      text: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-text'),
        renderHTML: (attributes) => (attributes.text ? {'data-text': attributes.text} : {}),
      },
      localId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-local-id'),
        renderHTML: (attributes) => (attributes.localId ? {'data-local-id': attributes.localId} : {}),
      },
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-style'),
        renderHTML: (attributes) => (attributes.style ? {'data-style': attributes.style} : {}),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="status"]',
      },
    ];
  },

  renderHTML({HTMLAttributes, node}) {
    return [
      'span',
      mergeAttributes({'data-type': 'status'}, this.options.HTMLAttributes, HTMLAttributes),
      node.attrs.text,
    ];
  },
});
