import type {EntityType} from '@shared/EntityType';

export enum ExternalService {
  AtlassianJira = 'AtlassianJira',
}

export interface IdMapping {
  id: number;
  service: ExternalService;
  Entity: EntityType;
  accountId: number;
  externalId: string;
  internalId: number;
}
