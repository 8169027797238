import {Button} from '@/design-system/Button';
import {TimeUnit} from '@/lib/time';
import {timeMonitor} from '@/lib/TimeMonitor';
import {ChevronLeftIcon, ChevronRightIcon} from 'lucide-react';
import {useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  className?: string;
  onChange?: (date: Date) => void;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
}

export function DatePicker({className, onChange, value, minDate, maxDate}: Props) {
  const [currentMonth, setCurrentMonth] = useState(() => {
    const d = value ?? new Date();
    d.setHours(0, 0, 0, 0);
    d.setDate(1);
    return d;
  });

  useEffect(() => {
    setCurrentMonth(value ?? new Date());
  }, [value]);

  const handleChange = (date: Date) => {
    onChange?.(date);
  };

  const incrementMonth = () => {
    const nextMonth = new Date(currentMonth);
    nextMonth.setMonth(currentMonth.getMonth() + 1);
    setCurrentMonth(nextMonth);
  };

  const decrementMonth = () => {
    const prevMonth = new Date(currentMonth);
    prevMonth.setMonth(currentMonth.getMonth() - 1);
    setCurrentMonth(prevMonth);
  };

  return (
    <div className={twMerge('flex cursor-default select-none flex-col gap-2 px-2 pb-2', className)}>
      <div className="-mx-2 flex flex-row items-center justify-between gap-4 border-b border-b-gray-100 bg-gray-50 px-2.5 py-2">
        <Button rounded tabIndex={-1} className="h-6 w-6 bg-transparent p-0.5" onPress={decrementMonth}>
          <ChevronLeftIcon className="h-4 w-4" />
        </Button>
        <span className="flex-grow text-center">
          {currentMonth.toLocaleDateString('en-US', {month: 'long', year: 'numeric'})}
        </span>
        <Button rounded tabIndex={-1} className="h-6 w-6 bg-transparent p-0.5" onPress={incrementMonth}>
          <ChevronRightIcon className="h-4 w-4" />
        </Button>
      </div>
      <DayPicker month={currentMonth} value={value} onChange={handleChange} minDate={minDate} maxDate={maxDate} />
    </div>
  );
}

interface DayPickerProps {
  month: Date;
  value?: Date;
  onChange?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

const dayStyle = tv({
  base: 'h-7 w-8 rounded-md p-0.5 font-medium transition-none hover:bg-pink-100',
  variants: {
    isOverlappingMonth: {
      true: 'text-gray-500/60',
    },
    isToday: {
      true: 'border-2 border-pink-100 font-bold text-pink-500',
    },
    isWeekend: {
      true: 'text-gray-500/60',
      false: 'font-medium',
    },
    isSelected: {
      true: 'bg-pink-500 font-medium text-white hover:bg-pink-500/90',
    },
    isDisabled: {
      true: 'rounded-none bg-gray-200 font-medium text-gray-500/30',
    },
  },
});

function DayPicker({month, value, onChange, minDate, maxDate}: DayPickerProps) {
  const firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
  firstDay.setHours(0, 0, 0, 0);
  const weeks = 6;
  const [today, setToday] = useState(new Date().toISOString().split('T')[0]);
  useEffect(() => timeMonitor.on(TimeUnit.Days, () => setToday(new Date().toISOString().split('T')[0])), []);

  // offset the first day of the calendar to the first day of the week (localized)
  // @ts-expect-error .weekInfo is not supported in all browsers
  const weekStartingDay = new Intl.Locale(navigator.language)?.weekInfo?.firstDay ?? 7;
  const offset = firstDay.getDay() - (weekStartingDay % 7);
  if (offset < 3) {
    // subtract 1 week to buffer up to a week before the first day of the month
    firstDay.setDate(firstDay.getDate() - offset - 7);
  } else {
    firstDay.setDate(firstDay.getDate() - offset);
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between font-thin text-gray-500/50">
        {[
          ...new Array(7).fill(0).map((_, i) => (
            <span key={i} className="w-8 pb-2 text-center">
              {DAY_MAP[(i + weekStartingDay) % 7]}
            </span>
          )),
        ]}
      </div>

      {[...Array(weeks)].map((_, i) => (
        <div key={i} className="flex flex-row items-center justify-between">
          {[...Array(7)].map((_, j) => {
            const date = new Date(firstDay.getTime());
            date.setDate(date.getDate() + (i * 7 + j));
            const dateString = date.toISOString().split('T')[0];
            const isDisabled =
              (minDate && dateString < minDate.toISOString().split('T')[0]) ||
              (maxDate && dateString > maxDate.toISOString().split('T')[0]);
            return (
              <Button
                seamless
                className={dayStyle({
                  isToday: dateString === today,
                  isSelected: value?.toISOString().split('T')[0] === dateString,
                  isOverlappingMonth: date.getMonth() !== month.getMonth(),
                  isWeekend: date.getDay() === 0 || date.getDay() === 6,
                  isDisabled,
                })}
                onPress={() => onChange?.(date)}
                key={i * 7 + j}
                data-date={dateString}
                isDisabled={isDisabled}
                tabIndex={-1}
              >
                {date.getDate()}
              </Button>
            );
          })}
        </div>
      ))}
    </div>
  );
}

const DAY_MAP = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
