import {mergeAttributes, Node} from '@tiptap/core';

export interface MediaGroupNodeAttrs {
  layout: string;
  width?: number;
  passthrough: string;
}

export interface MediaGroupOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Commands<ReturnType> {}
}

export const MediaGroup = Node.create<MediaGroupOptions, MediaGroupNodeAttrs>({
  name: 'mediaGroup',

  inline: false,
  group: 'block',

  content: 'media+',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      layout: {
        default: undefined,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-layout'),
        renderHTML: (attributes: MediaGroupNodeAttrs) => (attributes.layout ? {'data-layout': attributes.layout} : {}),
      },
      width: {
        default: undefined,
        parseHTML: (element: HTMLElement) => parseInt(element.getAttribute('data-width') ?? '0') || undefined,
        renderHTML: (attributes: MediaGroupNodeAttrs) =>
          attributes.width ? {'data-width': attributes.width.toString()} : {},
      },
      passthrough: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-passthrough'),
        renderHTML: (attributes: MediaGroupNodeAttrs) =>
          attributes.passthrough ? {'data-passthrough': JSON.stringify(attributes.passthrough)} : {},
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({HTMLAttributes, node}) {
    const alignment =
      node.attrs.layout === 'align-center' ? 'center' : node.attrs.layout === 'align-end' ? 'end' : 'start';
    return [
      'div',
      mergeAttributes({'data-type': this.name}, this.options.HTMLAttributes, HTMLAttributes, {
        style: `display: flex; flex-direction: row; justify-content: ${alignment};`,
        contenteditable: false,
      }),
      ['div', mergeAttributes({style: `width: ${node.attrs.width || 33}%;`}), 0],
    ];
  },

  renderText() {
    return '[Attachment(s)]';
  },
});
