import {Tooltip} from '@/design-system/Tooltip';
import {FormDataContext} from '@/stores/ui/formStore';
import {useContext} from 'react';
import type {Placement} from 'react-aria';
import type {ButtonProps, PressEvent} from 'react-aria-components';
import {Button as AriaButton, TooltipTrigger} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

type AriaButtonProps = ButtonProps & React.RefAttributes<HTMLButtonElement>;
interface Props extends AriaButtonProps {
  className?: string;
  children: React.ReactNode;
  rounded?: boolean;
  primary?: boolean;
  outline?: boolean;
  seamless?: boolean;
  danger?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  tooltip?: React.ReactNode | (() => React.ReactNode);
  tooltipPlacement?: Placement;
  tabIndex?: number;
}

const styles = tv({
  base: 'transition-color-opacity inline-flex flex-shrink-0 items-center justify-around gap-2 overflow-hidden rounded-sm bg-gray-800/5 px-2 py-1 hover:bg-gray-800/10',
  variants: {
    isFocusWithin: {
      true: 'bg-gray-100',
    },
    rounded: {
      true: 'rounded-full',
    },
    outline: {
      true: 'border-1 border !border-gray-500/20 bg-transparent hover:bg-gray-800/5',
    },
    primary: {
      true: '',
    },
    isFocused: {
      true: '',
    },
    isDisabled: {
      true: 'cursor-default opacity-30',
    },
    danger: {
      true: 'bg-red-500 text-white hover:bg-red-600',
    },
    size: {
      xs: 'px-1 py-0.5 text-xs',
      sm: 'px-2 py-0.5 text-sm',
      md: 'px-2 py-1 text-base',
      lg: 'px-4 py-2 text-lg',
    },
    seamless: {
      true: 'bg-transparent p-0 hover:bg-gray-800/5',
    },
  },
  compoundVariants: [
    {
      primary: true,
      outline: false,
      className: 'bg-pink-500/90 text-white outline-pink-700 hover:bg-pink-500',
    },
    {
      primary: true,
      outline: true,
      className: '!border-pink-500 bg-transparent text-pink-600 hover:bg-pink-400/10',
    },
    {
      isFocused: true,
      seamless: false,
      className: 'ring-offset-1 ring-offset-white',
    },
    {
      primary: true,
      isDisabled: true,
      outline: false,
      className: 'bg-pink-950/50',
    },
    {
      primary: true,
      outline: true,
      isDisabled: true,
      className: '!border-pink-800/40 bg-pink-800/5 text-pink-700/50',
    },
    {
      danger: true,
      seamless: true,
      className: 'bg-transparent text-red-500 hover:bg-red-500/10 hover:text-red-600',
    },
    {
      rounded: true,
      size: 'xs',
      className: 'px-2',
    },
    {
      rounded: true,
      size: 'sm',
      className: 'px-3',
    },
    {
      rounded: true,
      size: 'md',
      className: 'px-3',
    },
  ],
});

export const Button: React.FC<Props> = ({
  className,
  children,
  rounded = false,
  primary = false,
  danger = false,
  outline = false,
  seamless = false,
  size = 'sm',
  tooltip,
  tooltipPlacement,
  tabIndex,
  ...props
}) => {
  const {store} = useContext(FormDataContext) ?? {};
  const isDisabled = store?.hasErrors && props.type === 'submit' ? true : props.isDisabled;
  const handlePress = (e: PressEvent) => {
    if (props.isDisabled || isDisabled) return;
    props.onPress?.(e);
  };

  const button = () => (
    <AriaButton
      {...props}
      onPress={handlePress}
      isDisabled={props.isDisabled || isDisabled}
      className={(p) =>
        twMerge(styles({...p, isFocusWithin: false, rounded, primary, outline, seamless, danger, size}), className)
      }
      excludeFromTabOrder={tabIndex === -1}
    >
      {children}
    </AriaButton>
  );

  // if we have a tooltip and the button is disabled, we need to wrap a fake button with the tooltip
  return tooltip ? (
    <TooltipTrigger delay={isDisabled ? 400 : undefined}>
      {isDisabled ? (
        <AriaButton
          aria-label="disabled button"
          className={twMerge('cursor-default overflow-hidden', rounded ? 'rounded-full' : 'rounded-sm', className)}
        >
          <span
            className={twMerge(
              styles({isFocusWithin: false, rounded, primary, outline, seamless, danger, size, isDisabled}),
              'pointer-events-none',
            )}
          >
            {children}
          </span>
        </AriaButton>
      ) : (
        button()
      )}
      <Tooltip placement={tooltipPlacement}>{tooltip}</Tooltip>
    </TooltipTrigger>
  ) : (
    button()
  );
};
