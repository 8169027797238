import {STATE, Tracer} from '@/stores/lib/TracerProxy';
import {useMemo, useState} from 'react';

/**
 * State manager for simple objects
 * @param initialState initial state or a function that returns the initial state (same as React.useState)
 * @param props a list of properties to generate setters for
 * @returns [state, mutate, ...setters]
 */
export function useMoState<T extends object>(initialState: T | (() => T), ...props: Array<keyof T>) {
  const [state, setState] = useState(initialState);

  const mutate = (mutator: (state: T) => void) => {
    const proxied = Tracer.create(state);
    mutator(proxied);
    if (proxied[STATE].written.size > 0) {
      const result = Tracer.renderWithoutTracing(proxied);
      setState(result);
      return result;
    }
    return state;
  };
  mutate.replace = (newState: T) => setState(newState);

  return [
    state,
    mutate,
    ...useMemo(
      () => props.map((prop) => (value: any) => mutate((s) => s[prop] !== value && (s[prop] = value))),
      [state, props],
    ),
  ] as [T, typeof mutate, ...Array<(v: T[(typeof props)[number]]) => T>];
}
