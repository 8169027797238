import {exposeInDev} from '@/lib/dev';
import autobind from 'autobind-decorator';

enum CallChainState {
  Pending,
  Fulfilled,
  Rejected,
  Aborted,
}

export class AbortError extends Error {}

export class CallChain<T = any> {
  private _state = CallChainState.Pending;
  private abortController = new AbortController();
  private chain: ((value?: T) => any)[] = [];

  private fulfilledValue: T | undefined;
  private nextIndex = 0;

  private rejectionHandlers: ((reason: any) => void)[] = [];
  private rejectionReason: any | undefined;

  private settledHandlers: ((value: T | PromiseLike<T>) => void)[] = [];
  private settledTimer: number | undefined;

  set state(value: CallChainState) {
    if (value !== CallChainState.Pending) {
      this.startSettledTimer();
    }
    this._state = value;
  }

  get state() {
    return this._state;
  }

  constructor(value?: Promise<T> | (() => Promise<T> | T) | T) {
    if (typeof value === 'function') {
      this.next((value as () => Promise<T> | T)());
    } else {
      this.next(value);
    }
  }

  public abort(reason?: string) {
    this.abortController.abort(reason);
  }

  public get isPending() {
    return this.state === CallChainState.Pending;
  }

  public get isFulfilled() {
    return this.state === CallChainState.Fulfilled;
  }

  public get isRejected() {
    return this.state === CallChainState.Rejected;
  }

  public get isAborted() {
    return this.abortController.signal.aborted;
  }

  public get abortReason() {
    return this.abortController.signal.reason;
  }

  public then<TResult = T>(onFulfilled: (value: T) => TResult) {
    this.chain.push(onFulfilled as never);
    if (this.state === CallChainState.Fulfilled) {
      this.state = CallChainState.Pending;
      this.next(this.fulfilledValue!);
    }
    return this as unknown as CallChain<TResult extends Promise<infer R> ? R : TResult>;
  }

  public onReject(onReject: (reason: any) => void) {
    this.rejectionHandlers.push(onReject);
    if (this.state === CallChainState.Rejected) {
      onReject(this.rejectionReason!);
    }
    return this;
  }

  public onSettled(onSettled: (value: T | PromiseLike<T> | undefined) => void) {
    this.settledHandlers.push(onSettled as never);
    return this;
  }

  private startSettledTimer() {
    clearTimeout(this.settledTimer);
    this.settledTimer = setTimeout(this.testIfSettled, 0) as never;
  }

  /**
   * Because we support either promises or non-promise values,
   * we need to wait a frame to be sure the chain didn't get longer after adding a settled handler
   * This way we can create a settled handler on an empty call chain, then add the call chain
   */
  @autobind
  private testIfSettled() {
    if (!this.isPending && !this.isAborted) {
      for (const handler of this.settledHandlers) {
        handler(this.fulfilledValue!);
      }
    }
  }

  @autobind
  private next(value?: T | Promise<T>) {
    if (this.isAborted) {
      this.state = CallChainState.Aborted;
      return;
    }

    if (value instanceof Promise) {
      value.then(this.next).catch(this.reject);
      return;
    }

    const nextCall = this.chain[this.nextIndex++];
    if (nextCall) {
      let result: unknown;
      try {
        result = nextCall(value);
      } catch (e) {
        this.reject(e);
        return;
      }
      this.next(result as never);
    } else {
      this.nextIndex--;
      this.state = CallChainState.Fulfilled;
      this.fulfilledValue = value;
    }
  }

  @autobind
  private reject(reason: any) {
    if (reason instanceof AbortError) {
      this.abort(reason.message);
      return;
    }

    this.state = CallChainState.Rejected;
    this.rejectionReason = reason;
    for (const handler of this.rejectionHandlers) {
      handler(reason);
    }

    if (this.rejectionHandlers.length === 0) {
      console.warn('Unhandled call chain rejection', reason);
    }
  }
}

exposeInDev({CallChain});
