import {ActorAvatar} from '@/components/actor/ActorAvatar';
import {exposeInDev} from '@/lib/dev';
import {actorStore} from '@/stores/actor';
import {BoardUiContext} from '@/stores/ui/boardUiStore';
import {useContext} from 'react';
import type {Selection} from 'react-aria-components';
import {ListBox, ListBoxItem} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

const assigneeStyles = tv({
  base: '-mr-2 cursor-pointer rounded-full border-2 border-gray-100 bg-gray-100 transition-transform hover:z-20',
  variants: {
    isAnySelected: {
      true: 'opacity-50',
    },
    isFocused: {
      true: 'border-pink-500',
    },
    isSelected: {
      true: 'z-10 -translate-y-1.5 opacity-100',
    },
    isHovered: {
      true: 'opacity-100',
    },
  },
});

export function AssigneeFilter({className}: {className?: string}) {
  const boardUiStore = useContext(BoardUiContext).store;

  exposeInDev({boardUiStore}); // TODO REMOVE

  const isAnySelected = boardUiStore.use((s) => !!s.state.assigneeIds?.length, []);

  // TODO: implement actor filter for board instead of fetching every task here
  const actorIds = boardUiStore.use((s) => new Set(s.tasks.map((t) => t?.assigneeId) ?? []), []);
  const actors = actorStore.use(
    (s) => [...actorIds].map((id) => s.getById(id)).sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? '')),
    [actorIds],
  );
  const items = (actors ?? []).filter(Boolean).map((actor) => ({id: actor?.id, text: actor?.name ?? ''}));
  items.unshift({id: -1, text: 'Unassigned'});

  const onSelectionChange = (keys: Selection) => {
    boardUiStore.set('assigneeIds', [...keys].sort() as number[]);
  };

  return (
    <ListBox
      items={items}
      layout="grid"
      selectionMode="multiple"
      aria-label="Assignee filter"
      className={twMerge(className, 'flex flex-row')}
      onSelectionChange={onSelectionChange}
    >
      {(item) => (
        <ListBoxItem textValue={item.text} key={item.id} className={(s) => assigneeStyles({...s, isAnySelected})}>
          <ActorAvatar actorId={item.id} size="md" />
        </ListBoxItem>
      )}
    </ListBox>
  );
}
