import {PointsLabel} from '@/components/task/PointsLabel';
import {Editor, InlineEdit} from '@/design-system/InlineEdit';
import {NumberField} from '@/design-system/NumberField';
import {FieldType, defaultFieldId} from '@shared/models/FieldSchema';
import type {Mutation} from '@shared/models/Mutation';
import {useCallback, useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  points?: number;
  className?: string;
  onCommit: (value: number | undefined, operation: Mutation['operations']) => void;
}

export function EditableTaskPoints({points, className, onCommit}: Props) {
  const [pointValue, setPointValue] = useState(points ?? NaN);
  const onChange = useCallback(
    (value: number) => {
      setPointValue(value);
    },
    [setPointValue],
  );

  const commitCallback = useCallback(() => {
    if (pointValue !== points) {
      onCommit(
        isNaN(pointValue) ? undefined : pointValue,
        isNaN(pointValue)
          ? {
              delete: [defaultFieldId[FieldType.Points].toString()],
            }
          : {
              replace: {
                [defaultFieldId[FieldType.Points].toString()]: {value: pointValue.toString()},
              },
            },
      );
    }
  }, [pointValue, points, onCommit]);

  const onCancel = useCallback(() => {
    setPointValue(points ?? NaN);
  }, [points]);

  useEffect(() => {
    setPointValue(points ?? NaN);
  }, [points]);

  return (
    <InlineEdit
      className={twMerge('group/button -m-0.5 rounded-full p-0.5', className)}
      onCommit={commitCallback}
      onCancel={onCancel}
    >
      <PointsLabel points={points} />
      <Editor>
        <NumberField aria-label="Points" defaultValue={points} onChange={onChange} autoFocus minValue={0} />
      </Editor>
    </InlineEdit>
  );
}
