import {Spacer} from '@/design-system';
import {Time, TimeUnit} from '@/lib/time';
import React, {useMemo} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  from: string;
  to: string;
  prefix?: string;
  suffix?: string;
  className?: string;
  precision?: TimeUnit;
}

export const DeltaTime: React.FC<Props> = ({
  from,
  to,
  prefix,
  suffix,
  precision = TimeUnit.Minutes,
  className = 'text-xs text-gray-500',
}) => {
  const text = useMemo(() => getDeltaTime(new Date(from), new Date(to), precision, true), [from, to]);

  return (
    <div className={twMerge('relative flex cursor-default', className)}>
      {prefix && (
        <>
          {prefix}
          <Spacer />
        </>
      )}
      {text}
      {suffix}
    </div>
  );
};

function getDeltaTime(date: Date, now: Date, precision: TimeUnit = TimeUnit.Seconds, isDelta?: boolean): string {
  let seconds = (Time.trunc(now.getTime(), precision) - Time.trunc(date.getTime(), precision)) / 1000;
  let prefix = '';
  let suffix = '';

  // negative deltas
  if (seconds < 0) {
    seconds = -seconds;
    prefix = '-';
    suffix = '';
  }

  if (seconds < 60 && precision <= TimeUnit.Seconds) {
    return `${prefix}${seconds}s${suffix}`;
  }

  const minutes = Math.round(seconds / 60);
  if (minutes < 60 && precision <= TimeUnit.Minutes) {
    return `${prefix}${minutes}m${suffix}`;
  }

  const hours = Math.round(minutes / 60);
  if (hours < 24 && precision <= TimeUnit.Hours) {
    return `${prefix}${hours}h${suffix}`;
  }

  const days = Math.round(hours / 24);
  if (
    (precision === TimeUnit.Weeks && days < 365) ||
    (days > 0 && isDelta && days < 365 && days % 7 === 0 && precision <= TimeUnit.Days)
  ) {
    return `${prefix}${days / 7}w${suffix}`;
  }

  if (days < 365 && precision <= TimeUnit.Days) {
    return `${prefix}${days}d${suffix}`;
  }

  const years = Math.round(days / 365);
  return `${prefix}${years}y${suffix}`;
}
