import {persistThroughHotReload} from '@/lib/dev';
import {MoStore, mutation} from '@/stores/lib/MoStore';
import {debounce} from '@shared/lib/debounce';
import autobind from 'autobind-decorator';

interface DragStoreState {
  isDragging: boolean;
  dragTypes: readonly string[];
  data: Record<string, string> | undefined;
  source?: string;
}

class DndStore extends MoStore<DragStoreState> {
  constructor() {
    super({
      isDragging: false,
      dragTypes: [],
      data: undefined,
    });

    window.addEventListener('dragover', this.onWindowDragOver, {capture: true});
  }

  @mutation
  startDrag(source: string | undefined, dragTypes: readonly string[], data?: Record<string, string>) {
    this._state.isDragging = true;
    this._state.dragTypes = dragTypes;
    this._state.source = source;
    this._state.data = data;
  }

  @mutation
  endDrag() {
    this._state.isDragging = false;
    this._state.dragTypes = [];
    this._state.source = undefined;
    this._state.data = undefined;
  }

  @autobind
  onWindowDragOver(e: DragEvent) {
    if (!e.dataTransfer || e.dataTransfer.types.length === 0) return;
    if (this._state.isDragging) return;

    const data: Record<string, string> = {};
    for (const type of e.dataTransfer.types) {
      data[type] = e.dataTransfer.getData(type);
    }

    this.startDrag(this._state.source, e.dataTransfer.types, undefined);
    this.onWindowDragEndDebounced(e);
  }

  onWindowDragEndDebounced = debounce((_e: DragEvent) => {
    this.endDrag();
  }, 250);
}

export const dragStore = persistThroughHotReload('dragStore', new DndStore());
