import {SuspenseLoader} from '@/design-system/SuspenseLoader';
import {useState} from 'react';
import type {DialogProps} from 'react-aria-components';
import {Modal as AriaModal, Dialog, ModalOverlay} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

export interface ModalProps extends DialogProps {
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  drawer?: boolean;
}

const overlayStyle = tv({
  base: 'fixed inset-0 z-40 flex items-center justify-center backdrop-brightness-75 backdrop-contrast-75 duration-300 [--tw-backdrop-blur:blur(1px)] [--tw-backdrop-grayscale:grayscale(50%)]',
  variants: {
    isEntering: {
      true: 'animate-in fade-in',
    },
    isExiting: {
      true: 'animate-out fade-out',
    },
    isDrawer: {
      true: 'justify-end',
    },
  },
});

const modalStyle = tv({
  base: 'duration-300',
  variants: {
    isEntering: {
      true: 'animate-in',
    },
    isExiting: {
      true: 'blur animate-out',
    },
    isDrawer: {
      true: '',
    },
  },
  compoundVariants: [
    {isDrawer: false, isEntering: true, className: 'slide-in-from-bottom-5'},
    {isDrawer: true, isEntering: true, className: 'slide-in-from-right-48'},
    {isDrawer: false, isExiting: true, className: 'zoom-out-105'},
    {isDrawer: true, isExiting: true, className: 'slide-out-to-right-24'},
  ],
});

const dialogStyle = tv({
  base: 'max-h-full max-w-[95vw] rounded-md bg-gray-100 bg-clip-padding p-5 text-sm text-gray-700 shadow-2xl',
  variants: {
    isDrawer: {
      true: 'rounded-none',
    },
  },
});

export function Modal({children, isOpen, onOpenChange, drawer: isDrawer, ...props}: ModalProps) {
  const [isOpenLocal, onOpenChangeLocal] = useState(isOpen);
  const handleOpenChange = (isOpen: boolean) => {
    onOpenChange?.(isOpen);
    onOpenChangeLocal(isOpen);
  };

  return (
    <ModalOverlay
      isOpen={isOpen ?? isOpenLocal}
      className={(s) => overlayStyle({...s, isDrawer})}
      isDismissable
      onOpenChange={handleOpenChange}
    >
      <AriaModal
        isDismissable
        isOpen={isOpen ?? isOpenLocal}
        onOpenChange={handleOpenChange}
        className={(s) => modalStyle({...s, isDrawer})}
      >
        <SuspenseLoader>
          <Dialog {...props} className={twMerge(dialogStyle({isDrawer}), props.className)}>
            {children}
          </Dialog>
        </SuspenseLoader>
      </AriaModal>
    </ModalOverlay>
  );
}
