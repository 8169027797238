import type {AnyEntity, EntityPatch, EntityType} from '@shared/EntityType';
import type {AnyEntityFilter} from '@shared/filters/EntityFilter';
import type {FilteredEntityList} from '@shared/models/FilteredEntityList';
import type {Mutation} from '@shared/models/Mutation';
import type {WebContext} from '@shared/models/WebContext';

export type WSRequest = WSFetchRequest | WSMutationRequest;

export type WSFetchRequest = WSGetEntitiesRequest | WSGetEntityListRequest;

export type WSMutationRequest =
  | WSMutateEntityRequest
  | WSPutEntitiesRequest
  | WSPatchEntitiesRequest
  | WSPostEntityRequest
  | WSTaskMoveRequest;

export enum WSMessageType {
  Ping = 'Ping',
  // fetches
  GetEntities = 'GetEntities',
  GetEntityList = 'GetEntityList',
  // mutations
  PutEntities = 'PutEntities',
  PatchEntities = 'PatchEntities',
  MutateEntity = 'MutateEntity',
  PostEntity = 'PostEntity',
  TaskMove = 'TaskMove',
}

export type WSBaseRequest = {
  type: WSMessageType;
  requestId?: string;
};

export function isWSRequest(request: unknown): request is WSRequest {
  return (
    request != null &&
    typeof request === 'object' &&
    'type' in request &&
    !!WSMessageType[request.type as keyof typeof WSMessageType]
  );
}

export function isWSMutationRequest(request: unknown): request is WSMutationRequest {
  return (
    isWSRequest(request) &&
    (request.type === WSMessageType.MutateEntity ||
      request.type === WSMessageType.PostEntity ||
      request.type === WSMessageType.TaskMove ||
      request.type === WSMessageType.PatchEntities ||
      request.type === WSMessageType.PutEntities)
  );
}

// Fetch Entities / Lists

export interface WSGetEntitiesRequest extends WSBaseRequest {
  type: WSMessageType.GetEntities;
  message: {
    type: EntityType;
    entities: number[];
  };
}
export function isGetEntitiesRequest(request: WSRequest): request is WSGetEntitiesRequest {
  return request.type === WSMessageType.GetEntities;
}

export interface WSGetEntityListRequest extends WSBaseRequest {
  type: WSMessageType.GetEntityList;
  message: {
    type: EntityType;
    filter: AnyEntityFilter;
  };
}

export function isGetEntityListRequest(request: WSRequest): request is WSGetEntityListRequest {
  return request.type === WSMessageType.GetEntityList;
}

////////////////////////////////////////////////////////////
// Mutate entities

export interface WSPutEntitiesRequest extends WSBaseRequest {
  type: WSMessageType.PutEntities;
  message: {
    type: EntityType;
    entities: Array<Omit<AnyEntity, 'id'>>;
  };
}

export function isPutEntityRequest(request: WSRequest): request is WSPutEntitiesRequest {
  return request.type === WSMessageType.PutEntities;
}

export interface WSPatchEntitiesRequest extends WSBaseRequest {
  type: WSMessageType.PatchEntities;
  message: {
    type: EntityType;
    entities: EntityPatch[];
  };
}

export function isPatchEntitiesRequest(request: WSRequest): request is WSPatchEntitiesRequest {
  return request.type === WSMessageType.PatchEntities;
}

export interface WSMutateEntityRequest extends WSBaseRequest {
  type: WSMessageType.MutateEntity;
  message: {
    mutation: Mutation;
  };
}

export function isMutateEntityRequest(request: WSRequest): request is WSMutateEntityRequest {
  return request.type === WSMessageType.MutateEntity;
}

////////////////////////////////////////////////////////////
// Move a task
// this is a multi-operation message. You can either move a task, change the sprint, or change the status

export interface WSTaskMoveRequest extends WSBaseRequest {
  type: WSMessageType.TaskMove;
  message: WSTaskMoveOperation;
}

export function isTaskMoveRequest(request: WSRequest): request is WSTaskMoveRequest {
  return request.type === WSMessageType.TaskMove;
}

export interface WSTaskMoveBase {
  taskIds: number[];
}

export interface WSTaskChangeRank extends WSTaskMoveBase {
  beforeTaskId?: number;
  afterTaskId?: number;
  // dragging between statuses on the board allows you to change the status and rank simultaneously
  statusId?: number;
  // similarly, dropping issues into a sprint can happen during rank changes
  sprintId?: number | null;
}

export function isWSTaskChangeRank(message: unknown): message is WSTaskChangeRank {
  return (
    !!(message as WSTaskMoveBase).taskIds?.length &&
    ((message as WSTaskChangeRank).beforeTaskId !== undefined ||
      (message as WSTaskChangeRank).afterTaskId !== undefined ||
      (message as WSTaskChangeRank).sprintId !== undefined)
  );
}

export interface WSTaskTransitionStatus extends WSTaskMoveBase {
  statusId: number;
}

export type WSTaskMoveOperation = WSTaskChangeRank | WSTaskTransitionStatus;

////////////////////////////////////////////////////////////
// Create an entity

export interface WSPostEntityRequest extends WSBaseRequest {
  type: WSMessageType.PostEntity;
  message: {
    type: EntityType;
    entity: Partial<AnyEntity>;
  };
}

export function isPostEntityRequest(request: WSRequest): request is WSPostEntityRequest {
  return request.type === WSMessageType.PostEntity;
}

////////////////////////////////////////////////////////////
// General result responses / updates

export type WSResponse = WSContextMessage | WSEntityUpdateResult;

export enum WSResponseType {
  Context = 'Context',
  Result = 'Result',
}

export interface WSResponseBase {
  type: WSResponseType;
  requestId?: string;
  status: number;
  success: boolean;
  error?: string;
  entityErrors?: Array<{type: EntityType; id: number; message: string}>;
}

// Result w/Context

export interface WSContextMessage extends WSResponseBase {
  type: WSResponseType.Context;
  context: WebContext;
}

export interface WSContextResult extends WSResponseBase {
  type: WSResponseType.Context;
  context: WebContext;
}

export function isWSContextResponse(response: WSResponse): response is WSContextResult {
  return response.type === WSResponseType.Context;
}

// Result w/Updates

export interface WSEntityUpdate {
  type: EntityType;
  entities: Array<AnyEntity>;
}

export interface WSEntityDelete {
  type: EntityType;
  ids: number[];
}

export type WSEntityListUpdate = FilteredEntityList;

export interface WSEntityUpdateResult extends WSResponseBase {
  type: Exclude<WSResponseType, WSResponseType.Context>;
  entities?: WSEntityUpdate[];
  lists?: WSEntityListUpdate[];
  deletes?: WSEntityDelete[];
}

export function isWSResultResponse(response: WSResponse): response is WSEntityUpdateResult {
  return response.type === WSResponseType.Result;
}

export function getEntitiesFromResponse(response: WSEntityUpdateResult, type: EntityType): AnyEntity[] {
  return response.entities?.find((e) => e.type === type)?.entities ?? [];
}
