import {Button} from '@/design-system/Button';
import {DatePicker} from '@/design-system/date-time/DatePicker';
import {TextInput} from '@/design-system/TextInput';
import {useOnFocusInOut} from '@/hooks/useIsFocusWithin';
import {keyboardMonitor, ModifierKey} from '@/lib/KeyboardMonitor';
import {Time, TimeUnit} from '@/lib/time';
import {offset, useFloating} from '@floating-ui/react';
import {XIcon} from 'lucide-react';
import {useEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value?: Date | string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  onChange?: (date?: Date) => void;
  onCommit?: (date?: Date) => void;
  className?: string;
}

const styles = tv({
  slots: {
    containerStyle:
      'relative flex flex-row items-center gap-2 rounded-sm focus-within:ring-2 focus-within:ring-pink-500',
    rightSideStyle: 'relative w-9 pr-2 text-center text-xs',
    dayStyle: 'cursor-default select-none opacity-30 transition-opacity group-hover:opacity-0',
    xStyle:
      'absolute right-2 top-0 flex h-full w-full items-center justify-end opacity-[0.1] transition-opacity group-hover:opacity-100',
  },
  variants: {
    isXFocused: {
      true: {
        dayStyle: 'opacity-0',
        xStyle: 'opacity-100',
        containerStyle: '!ring-0',
      },
    },
    disabled: {
      true: {
        containerStyle: 'bg-gray-50',
        dayStyle: 'opacity-30 group-hover:opacity-30',
        xStyle: 'hidden',
      },
    },
    empty: {
      true: {
        xStyle: 'hidden',
        dayStyle: 'hidden',
      },
    },
  },
});

export function DateInput({
  value: providedValue,
  minDate,
  maxDate,
  disabled,
  onChange,
  onCommit,
  className,
  ...props
}: Props) {
  const [value, setValue] = useState(() => coerceDate(providedValue));
  const [rawValue, setRawValue] = useState(() => formatDate(value));
  useEffect(() => {
    let v = value;
    if (value && minDate && value < minDate) v = minDate;
    if (value && maxDate && value > maxDate) v = maxDate;
    setRawValue(formatDate(v));
    if (v !== value) {
      setValue(v);
    }
  }, [value, minDate, maxDate]);

  useEffect(() => {
    providedValue = coerceDate(providedValue);
    if (providedValue?.toDateString() !== value?.toDateString()) {
      setValue(providedValue);
    }
  }, [providedValue]);

  const inputRef = useRef<HTMLInputElement>(null);

  function setDate(date: Date | undefined, commit = false) {
    if (date?.toDateString() === 'Invalid Date') {
      date = value;
    }
    if (date && minDate && date < minDate) date = minDate;
    if (date && maxDate && date > maxDate) date = maxDate;
    setValue(date);
    setRawValue(formatDate(date));
    onChange?.(date);
    if (commit) onCommit?.(date);
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const part = determineDatePartAt(e.currentTarget.selectionStart ?? 0);
    if (!part) return;
    const currentValue = parseDate(rawValue);
    if (!currentValue) return;

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const date = addDatePart(currentValue, part, 1);
      setDate(date);
      selectDatePart(inputRef, part);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      const date = addDatePart(currentValue, part, -1);
      setDate(date);
      selectDatePart(inputRef, part);
    } else if (e.key === 'PageUp') {
      e.preventDefault();
      const date = addDatePart(currentValue, part, part === DatePart.Day ? 7 : 1);
      setDate(date);
      selectDatePart(inputRef, part);
    } else if (e.key === 'PageDown') {
      e.preventDefault();
      const date = addDatePart(currentValue, part, part === DatePart.Day ? -7 : -1);
      setDate(date);
      selectDatePart(inputRef, part);
    } else if (e.key === 'ArrowLeft') {
      if (selectPrevPart(inputRef, e.currentTarget.selectionStart ?? 0)) {
        e.preventDefault();
      }
    } else if (e.key === 'ArrowRight') {
      if (selectNextPart(inputRef, e.currentTarget.selectionStart ?? 0)) {
        e.preventDefault();
      }
    } else if (e.key === 'Tab') {
      if (e.shiftKey && selectPrevPart(inputRef, e.currentTarget.selectionStart ?? 0)) {
        e.preventDefault();
        e.stopPropagation();
      } else if (!e.shiftKey && selectNextPart(inputRef, e.currentTarget.selectionStart ?? 0)) {
        e.preventDefault();
        e.stopPropagation();
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (coerceDate(providedValue)?.toDateString() !== value?.toDateString()) {
        onCommit?.(value);
      }
    }
  };

  const [pickerClosed, setPickerClosed] = useState(false);
  const onClick = (_e: React.MouseEvent<HTMLInputElement>) => {
    selectDatePart(inputRef, determineDatePartAt(inputRef.current?.selectionStart ?? 0));
    setPickerClosed(false);
  };

  const onLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    setRawValue(v);
    if (parseDate(v)) {
      onChange?.(parseDate(v));
    }
  };

  const onPickerChange = (date: Date) => {
    setDate(date);
    setPickerClosed(true);
    inputRef.current?.focus();
    selectDatePart(inputRef, DatePart.Day);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isWrapperFocused, setIsWrapperFocused] = useState(false);
  useOnFocusInOut(
    wrapperRef,
    () => {
      setPickerClosed(false);
      setIsWrapperFocused(true);
      if (keyboardMonitor.getCurrentKey() === 'Tab' && keyboardMonitor.isModifierPressed(ModifierKey.Shift)) {
        selectDatePart(inputRef, determineDatePartAt((inputRef.current?.value.length ?? 1) - 1));
      } else if (keyboardMonitor.getCurrentKey() === 'Tab') {
        selectDatePart(inputRef, determineDatePartAt(0));
      } else {
        selectDatePart(inputRef, determineDatePartAt(inputRef.current?.selectionStart ?? 0));
      }
    },
    () => {
      setIsWrapperFocused(false);
      const date = parseDate(rawValue, value);
      setDate(date);
      onChange?.(date);
      if (coerceDate(providedValue)?.toDateString() !== date?.toDateString()) {
        onCommit?.(date);
      }
    },
  );

  const [isXFocused, setIsXFocused] = useState(false);
  const {refs, floatingStyles} = useFloating({
    middleware: [offset(2)],
    open: isWrapperFocused && !pickerClosed,
  });

  const {dayStyle, xStyle, rightSideStyle, containerStyle} = styles({isXFocused, disabled, empty: !value});

  return (
    <div {...props} className={twMerge('group bg-white', className)} ref={wrapperRef}>
      <div ref={refs.setReference} className={containerStyle()}>
        <TextInput
          ref={inputRef}
          value={rawValue}
          onCommit={() => {}}
          onChange={onLocalChange}
          disabled={disabled}
          className="w-32 rounded-sm px-2 py-1 tabular-nums tracking-wider !ring-0"
          onKeyDown={onKeyDown}
          onClick={onClick}
        />
        {
          <div className={rightSideStyle()}>
            <div className={dayStyle()}>{parseDate(rawValue) ? dayOfWeek[parseDate(rawValue)!.getDay()] : ''}</div>
            <div className={xStyle()}>
              <Button
                rounded
                onPress={() => setDate(undefined, true)}
                className="p-0.5"
                onFocusChange={(isFocused) => setIsXFocused(isFocused)}
              >
                <XIcon className="h-3 w-3 text-gray-500" />
              </Button>
            </div>
          </div>
        }
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          tabIndex={-1}
          className={twMerge(
            'pointer-events-none z-10 overflow-hidden rounded bg-white bg-clip-padding opacity-0 shadow-xl',
            isWrapperFocused && !pickerClosed && 'pointer-events-auto opacity-100',
          )}
        >
          <DatePicker maxDate={maxDate} minDate={minDate} value={value} onChange={onPickerChange} />
        </div>
      </div>
    </div>
  );
}

function formatDate(date: Date | undefined) {
  return date?.toLocaleDateString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric'}) ?? '';
}

enum DatePart {
  Day = 1,
  Year = 3,
  Month = 2,
}

function d2(s: string | number) {
  return s.toString().padStart(2, '0');
}

function d4(s: string | number) {
  return s.toString().padStart(4, '0');
}

function parseDate(value: string, fallback?: Date) {
  const date = _parseDate(value, fallback);
  if (isNaN(date as unknown as number)) return fallback;
  return date;
}

function _parseDate(value: string, fallback?: Date) {
  const parts = value.match(/(\d+)\D+(\d+)(?:\D+(\d+))?/)?.map((s) => parseInt(s));
  if (!parts) return fallback;
  const formatted = formatDate(new Date('3333-11-22T00:00'));
  const y = formatted.indexOf('3333');
  const m = formatted.indexOf('11');
  const d = formatted.indexOf('22');

  if (isNaN(parts[3])) {
    // dm or md
    const year = new Date().getFullYear();
    let date: Date;
    if (d < m || parts[1] > 12) {
      date = new Date(`${year}-${d2(parts[2])}-${d2(parts[1])}T00:00`);
    } else {
      date = new Date(`${year}-${d2(parts[1])}-${d2(parts[2])}T00:00`);
    }
    if (Time.delta(Time.now(), date.getTime(), TimeUnit.Months) > 6) {
      date.setFullYear(date.getFullYear() + 1);
    }
    return date;
  }

  // ymd, ydm, mdy, myd, dmy, dym
  if (y < d) {
    if (y < m) {
      if (m < d) {
        // year month day
        return new Date(`${d4(parts[1])}-${d2(parts[2])}-${d2(parts[3])}T00:00`);
      } else {
        // year day month
        return new Date(`${d4(parts[1])}-${d2(parts[3])}-${d2(parts[2])}T00:00`);
      }
    } else {
      // y < d && y > m => month year day
      return new Date(`${d4(parts[2])}-${d2(parts[1])}-${d2(parts[3])}T00:00`);
    }
  } else {
    // y > d
    if (y > m) {
      if (d < m) {
        // day month year
        return new Date(`${d4(parts[3])}-${d2(parts[2])}-${d2(parts[1])}T00:00`);
      } else {
        // month day year
        return new Date(`${d4(parts[3])}-${d2(parts[1])}-${d2(parts[2])}T00:00`);
      }
    } else {
      // y > d && y < m => day year month
      return new Date(`${d4(parts[2])}-${d2(parts[3])}-${d2(parts[1])}T00:00`);
    }
  }
}

function determineDatePartAt(index: number) {
  const formatted = formatDate(new Date('3333-11-22T00:00'));
  for (let i = 0; i < 2; i === 0 ? i-- : (i += 2)) {
    const char = formatted[index + i];
    if (char === '3') {
      return DatePart.Year;
    } else if (char === '1') {
      return DatePart.Month;
    } else if (char === '2') {
      return DatePart.Day;
    }
  }
}

function selectDatePart(ref: React.RefObject<HTMLInputElement>, part?: DatePart) {
  if (!part) return;
  const formatted = formatDate(new Date('3333-11-22T00:00'));
  const start = formatted.indexOf(part === DatePart.Year ? '3333' : part === DatePart.Month ? '11' : '22');

  setTimeout(() => ref.current && ref.current.setSelectionRange(start, start + (part === DatePart.Year ? 4 : 2)));
}

function selectPrevPart(ref: React.RefObject<HTMLInputElement>, index: number) {
  const currentPart = determineDatePartAt(index);
  let part = currentPart;
  while (index >= 0 && part === currentPart) {
    index--;
    part = determineDatePartAt(index);
  }
  if (part === undefined || part === currentPart) return false;

  selectDatePart(ref, part);
  return true;
}

function selectNextPart(ref: React.RefObject<HTMLInputElement>, index: number) {
  const currentPart = determineDatePartAt(index);
  let part = currentPart;
  while (index < (ref.current?.value.length ?? 0) && part === currentPart) {
    index++;
    part = determineDatePartAt(index);
  }
  if (part === undefined || part === currentPart) return false;

  selectDatePart(ref, part);
  return true;
}

function addDatePart(date: Date, part: DatePart | undefined, amount: number) {
  switch (part) {
    case DatePart.Year:
      return new Date(date.getFullYear() + amount, date.getMonth(), date.getDate());
    case DatePart.Month:
      return new Date(date.getFullYear(), date.getMonth() + amount, date.getDate());
    case DatePart.Day:
      return new Date(date.getFullYear(), date.getMonth(), date.getDate() + amount);
    default:
      return date;
  }
}

function coerceDate(date: Date | string | undefined) {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (date instanceof Date) {
    return date;
  }
  return undefined;
}

const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
