import {ActorAvatar} from '@/components/actor/ActorAvatar';
import {NonNullable} from '@/components/lib/NonNullable';
import {RelativeTime} from '@/components/time/RelativeTime';
import {Skeleton, Spacer} from '@/design-system';
import {useAsyncEffect} from '@/hooks/useAsyncEffect';
import {commentStore} from '@/stores/comment';
import {adfToMomentum} from '@/text-editor/AdfToMomentum';
import {MOMENTUM_TIPTAP_EXTENSIONS} from '@/text-editor/useMoEditor';
import {isIdEphemeral} from '@shared/lib/isIdEphemeral';
import type {Comment} from '@shared/models/Comment';
import {generateHTML} from '@tiptap/core';
import React, {useState} from 'react';
import {tv} from 'tailwind-variants';

interface Props {
  id: number;
}

const styles = tv({
  slots: {
    container:
      'w-fill flex min-h-24 flex-col justify-between rounded-md bg-white p-5 shadow transition-opacity animate-in fade-in',
  },
  variants: {
    n: {
      true: {
        container: 'min-h-0',
      },
    },
    isEphemeral: {
      true: {
        container: 'opacity-50',
      },
      false: {
        container: 'opacity-100',
      },
    },
  },
});

export const CommentView: React.FC<Props> = ({id}) => {
  const comment = commentStore.use((s) => s.getById(id), [id]);
  const {container} = styles({n: comment === null, isEphemeral: isIdEphemeral(id)});

  return (
    <div className="mx-[-1.25rem] py-1">
      <div className={container()}>
        <NonNullable value={comment} fallback={<CommentSkeleton />} nullFallback={<CommentNotFound />}>
          {(comment) => (
            <>
              <CommentHeader comment={comment} />
              <CommentBody comment={comment} />
            </>
          )}
        </NonNullable>
      </div>
    </div>
  );
};

const CommentHeader: React.FC<{comment: Comment}> = ({comment}) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <ActorAvatar actorId={comment.authorId} size="sm" showName />
      <Spacer />
      <RelativeTime date={comment.createdAt} />
    </div>
  );
};

const CommentBody: React.FC<{comment: Comment}> = ({comment}) => {
  const [html, setHtml] = useState(comment.renderedBody ?? '');

  useAsyncEffect(
    async (isActive) => {
      // For now, render all comments via the text editor
      // if (comment.renderedBody) {
      //   setHtml(comment.renderedBody);
      // }
      const doc = await adfToMomentum(comment.body, comment.taskId);
      if (!isActive()) return;
      setHtml(generateHTML(doc, MOMENTUM_TIPTAP_EXTENSIONS));
    },
    [comment.renderedBody, comment.body],
  );

  return (
    <div className="flex flex-col">
      <div className="tiptap text-sm text-gray-700" dangerouslySetInnerHTML={{__html: html}} />
    </div>
  );
};

const CommentSkeleton: React.FC = () => {
  return (
    <>
      <div className="flex flex-row items-center justify-between pb-5">
        <div className="flex flex-row items-center justify-between">
          <Skeleton className="h-6 w-6" />
          <Spacer />
          <Skeleton className="h-5 w-28" />
        </div>
        <Spacer />
        <Skeleton className="h-4 w-24" />
      </div>
      <div className="flex flex-col justify-between">
        <Skeleton className="h-4 w-2/3" />
        <Spacer />
        <Skeleton className="h-4 w-36" />
      </div>
    </>
  );
};

const CommentNotFound: React.FC = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-gray-500/50">Comment not found</div>
  );
};
