import {exposeInDev, persistThroughHotReload} from '@/lib/dev';

export enum ModifierKey {
  Alt = 'alt',
  Ctrl = 'ctrl',
  Meta = 'meta',
  Shift = 'shift',
}

class KeyboardMonitor {
  private modifiers = new Set<string>();
  private currentKey: string | undefined = undefined;

  constructor() {
    window.addEventListener(
      'keydown',
      (e) => {
        switch (e.key) {
          case 'Alt':
            this.modifiers.add(ModifierKey.Alt);
            break;
          case 'Control':
            this.modifiers.add(ModifierKey.Ctrl);
            break;
          case 'Meta':
            this.modifiers.add(ModifierKey.Meta);
            break;
          case 'Shift':
            this.modifiers.add(ModifierKey.Shift);
            break;
          default:
            this.currentKey = e.key;
            break;
        }
      },
      {capture: true},
    );

    window.addEventListener(
      'keyup',
      (e) => {
        switch (e.key) {
          case 'Alt':
            this.modifiers.delete(ModifierKey.Alt);
            break;
          case 'Control':
            this.modifiers.delete(ModifierKey.Ctrl);
            break;
          case 'Meta':
            this.modifiers.delete(ModifierKey.Meta);
            break;
          case 'Shift':
            this.modifiers.delete(ModifierKey.Shift);
            break;
          default:
            this.currentKey = undefined;
            break;
        }
      },
      {capture: true},
    );

    window.addEventListener('blur', () => {
      this.modifiers.clear();
    });

    window.addEventListener(
      'mousedown',
      (e) => {
        this.modifiers.clear();
        if (e.altKey) {
          this.modifiers.add(ModifierKey.Alt);
        } else {
          this.modifiers.delete(ModifierKey.Alt);
        }
        if (e.ctrlKey) {
          this.modifiers.add(ModifierKey.Ctrl);
        } else {
          this.modifiers.delete(ModifierKey.Ctrl);
        }
        if (e.metaKey) {
          this.modifiers.add(ModifierKey.Meta);
        } else {
          this.modifiers.delete(ModifierKey.Meta);
        }
        if (e.shiftKey) {
          this.modifiers.add(ModifierKey.Shift);
        } else {
          this.modifiers.delete(ModifierKey.Shift);
        }
      },
      {capture: true},
    );
  }

  public isModifierPressed(modifier: ModifierKey) {
    return this.modifiers.has(modifier);
  }

  public isAnyModifierPressed() {
    return this.modifiers.size > 0;
  }

  public getCurrentKey() {
    return this.currentKey;
  }
}

export const keyboardMonitor = persistThroughHotReload('keyboardMonitor', new KeyboardMonitor());
exposeInDev({keyboardMonitor});
