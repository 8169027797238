import {EntityType} from '@shared/EntityType';
import type {Image} from '@shared/models/Image';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const imageStore = persistThroughHotReload(
  'imageStore',
  new EntityStore<EntityStoreState<Image>, Image>(EntityType.Image, {
    byId: new Map(),
    byList: new Map(),
  }),
);
exposeInDev({imageStore});
import.meta.hot?.accept(reloadOnHotUpdate);
