import React from 'react';

interface Props {
  id?: string;
  onVisible?: (id?: string) => void;
  minDurationMs?: number;
  scrollIntoView?: boolean | ScrollIntoViewOptions;
  children: React.ReactNode;
}

const DEFAULT_OPTIONS: ScrollIntoViewOptions = {behavior: 'smooth', block: 'start'};

export const EnsureVisible: React.FC<Props> = ({
  id,
  onVisible,
  minDurationMs = 400,
  scrollIntoView = false,
  children,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    let timer: number | undefined;
    const observer = new IntersectionObserver(
      (entries) => {
        let anyVisible = false;
        for (const entry of entries) {
          if (entry.isIntersecting) {
            anyVisible = true;
            clearTimeout(timer);
            timer = setTimeout(() => {
              setVisible(true);
              onVisible?.(id);
            }, minDurationMs) as never; // TODO: setTimeout return type is wrong
          }
        }
        if (!anyVisible) {
          clearTimeout(timer);
          setVisible(false);
        }
      },
      {threshold: 1},
    );
    observer.observe(ref.current!);

    return () => {
      clearTimeout(timer);
      observer.disconnect();
    };
  }, [visible, minDurationMs, onVisible]);

  React.useEffect(() => {
    if (scrollIntoView) {
      ref.current?.scrollIntoView({...DEFAULT_OPTIONS, ...(typeof scrollIntoView === 'object' ? scrollIntoView : {})});
    }
  }, [scrollIntoView]);

  return (
    <div ref={ref} className="relative">
      {children}
    </div>
  );
};
