import React, {useEffect, useState} from 'react';
import useQuery from '../hooks/useQuery';
import {authStore} from '../stores/auth';

const AuthTransfer: React.FC = () => {
  const {nonce} = useQuery({nonce: ''});
  const [existingToken] = useState(authStore.token);
  const token = authStore.use((s) => s.token, []);

  useEffect(() => {
    if (nonce) {
      authStore.transfer(nonce);
    }
  }, [nonce]);

  useEffect(() => {
    if (token && token !== existingToken) {
      window.location.href = '/';
    }
  }, [token]);

  return (
    <div>
      {nonce}:{authStore.token}
    </div>
  );
};

export default AuthTransfer;
