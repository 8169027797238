import CopyIcon from '@/assets/text-editor/copy.svg?react';
import LinkBreakIcon from '@/assets/text-editor/link-break.svg?react';
import ExternalLinkIcon from '@/assets/text-editor/link-open.svg?react';
import {Button, InputWell, Spacer} from '@/design-system';
import {FieldError} from '@/design-system/forms/FieldError';
import {Form} from '@/design-system/forms/Form';
import {Label} from '@/design-system/forms/Label';
import {TextField} from '@/design-system/forms/TextField';
import {Modal} from '@/design-system/modal/Modal';
import {ModalFooter} from '@/design-system/modal/ModalFooter';
import {EllipsisInMiddle} from '@/design-system/text/EllipsisInMiddle';
import {validateUrl} from '@/lib/validation/url';
import {formattingMenuStyles} from '@/text-editor/interfaces/FormattingMenu';
import type {Editor} from '@tiptap/core';
import Link from '@tiptap/extension-link';
import {useEffect, useMemo, useState} from 'react';
import {Button as AriaButton, DialogTrigger, Input} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';

interface Props {
  editor: Editor;
}

const linkTextPreview = 'text-xs';

export function LinkMenu({editor}: Props) {
  const {base, button, icon, divider} = formattingMenuStyles();
  const [isEditing, setIsEditing] = useState(false);

  const commands = useMemo(
    () => ({
      unsetLink: () => editor?.chain().focus().unsetLink().run(),
      openLink: () => window.open(editor.getAttributes('link').href, '_blank'),
      copyLink: () => navigator.clipboard.writeText(editor.getAttributes('link').href),
      editLink: () => setIsEditing(true),
    }),
    [editor],
  );

  return (
    <div className={twMerge(base(), isEditing ? 'hidden' : '')}>
      <DialogTrigger onOpenChange={setIsEditing}>
        <AriaButton className={twMerge(button(), 'h-7 max-w-72')} aria-label="Edit link">
          <EllipsisInMiddle text={editor.getAttributes('link').href} className={linkTextPreview} />
        </AriaButton>
        <Modal aria-label="Link editor" className="modal-md">
          {({close}) => <LinkEditor editor={editor} onClose={close} />}
        </Modal>
      </DialogTrigger>
      <AriaButton onPress={commands.unsetLink} className={button()}>
        <LinkBreakIcon className={icon()} />
      </AriaButton>
      <AriaButton onPress={commands.copyLink} className={button()}>
        <CopyIcon className={icon()} />
      </AriaButton>
      <Spacer className={divider()} />
      <AriaButton onPress={commands.openLink} className={button()}>
        <ExternalLinkIcon className={icon()} />
      </AriaButton>
    </div>
  );
}

const linkEditorValidators = {
  text: (t?: string) => (t?.length ?? 0) > 0 || 'Text is required',
  href: validateUrl,
};

interface LinkEditorProps extends Props {
  onClose: () => void;
}

function LinkEditor({editor, onClose}: LinkEditorProps) {
  const [initialData, setInitialData] = useState({text: '', href: ''});
  useEffect(() => {
    editor.chain().focus().extendMarkRange('link').run();
    const {to, from, empty} = editor.state.selection;
    if (!empty) {
      setInitialData({text: editor.state.doc.textBetween(from, to), href: editor.getAttributes('link').href});
    }
  }, []);

  const commands = useMemo(
    () => ({
      updateLink: (text: string, href: string) => {
        const marks = editor.state.selection.$from.marks().filter((mark) => mark.type.name !== Link.name);
        marks.push(editor.state.schema.mark('link', {href}));
        const newNode = editor.state.schema.text(text, marks).toJSON();
        editor.chain().focus().extendMarkRange('link').insertContent(newNode).run();
        onClose();
      },
    }),
    [editor, onClose],
  );

  return (
    <div className="flex min-w-96 flex-col gap-2">
      <Form
        initialData={initialData}
        validators={linkEditorValidators}
        onSubmit={(e, store) => {
          e.preventDefault();
          if (store.hasErrors) return;
          const {text, href} = store.data;
          commands.updateLink(text, href);
        }}
      >
        <TextField name="text" isRequired>
          <Label>Text</Label>
          <InputWell className="p-0">
            <Input className="w-full rounded-sm bg-transparent px-2 py-1" />
          </InputWell>
          <FieldError />
        </TextField>
        <TextField name="href" isRequired>
          <Label>URL</Label>
          <InputWell className="p-0">
            <Input className="w-full rounded-sm bg-transparent px-2 py-1" autoFocus />
          </InputWell>
          <FieldError />
        </TextField>
        <ModalFooter>
          <Button outline rounded onPress={onClose}>
            Cancel
          </Button>
          <Button rounded primary type="submit">
            Update
          </Button>
        </ModalFooter>
      </Form>
    </div>
  );
}
