import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {TaskLinkType} from '@shared/models/TaskLinkType';
import type {DB} from 'src-sw/db/db';

export class TaskLinkTypeFilter extends EntityFilter<ITaskLinkTypeFilter> {
  readonly type = EntityType.TaskLinkType;

  constructor(filter: Omit<ITaskLinkTypeFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, entity: TaskLinkType): Promise<[boolean, string, RankComparator]> {
    return [true, entity.name, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}`;
  }
}
export type ITaskLinkTypeFilter = PublicPropertiesOf<TaskLinkTypeFilter>;
export function isTaskLinkTypeFilter(filter: IEntityFilter): filter is TaskLinkTypeFilter {
  return filter.type === EntityType.TaskLinkType;
}
