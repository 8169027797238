import {Tag} from '@/components/task/Tag';
import {componentStore} from '@/stores/component';
import {labelStore} from '@/stores/label';
import type {EntityStore, EntityStoreState} from '@/stores/lib/EntityStore';
import type {Component} from '@shared/models/Component';
import type {Label} from '@shared/models/Label';
import React, {useMemo} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  className?: string;
  labelIds: number[];
  componentIds: number[];
  readonly?: boolean;
  onRemove?: (tag: Label | Component) => void;
  onAdd?: (tag: string) => void;
}

const getTags = (
  store: EntityStore<EntityStoreState<Label>, Label> | EntityStore<EntityStoreState<Component>, Component>,
  ids: number[],
) =>
  store.useWhenLoaded((s) => ids.map((id) => s.getById(id)).sort((a, b) => a!.name.localeCompare(b!.name)), [], [ids]);

export const TagsField: React.FC<Props> = ({labelIds, componentIds, onRemove, className = '', readonly = false}) => {
  const labels = (getTags(labelStore, labelIds) as Label[]).sort((a, b) => a.name.localeCompare(b.name));
  const components = (getTags(componentStore, componentIds) as Component[]).sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const Components = useMemo(
    () =>
      components.map((component) => (
        <Tag
          key={component.id}
          readonly={readonly}
          isComponent
          onRemove={() => onRemove?.(component)}
          isActive={component.active}
        >
          {component.name}
        </Tag>
      )),
    [components, readonly, onRemove],
  );

  const Labels = useMemo(
    () =>
      labels.map((label) => (
        <Tag key={label.id} readonly={readonly} onRemove={() => onRemove?.(label)}>
          {label.name}
        </Tag>
      )),
    [labels, readonly, onRemove],
  );

  return (
    <div className={twMerge('flex flex-row items-center gap-1', className)}>
      {Components}
      {Labels}
    </div>
  );
};
