import {Worker} from '@/app-service-worker/Worker';
import {authStore} from '@/stores/auth';
import {momentumToAdf} from '@/text-editor/AdfToMomentum';
import type {EditorState} from '@/text-editor/MoEditor';
import {emptyDocString, MoEditor} from '@/text-editor/MoEditor';
import {EntityType} from '@shared/EntityType';
import type {Comment} from '@shared/models/Comment';
import {SendIcon} from 'lucide-react';
import React, {useCallback, useRef, useState} from 'react';
import {Button} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface Props {
  taskId: number;
}

const styles = tv({
  slots: {
    container: 'relative flex min-h-14 shrink-0 items-end gap-4 bg-white p-3 shadow-xl',
    button:
      'absolute bottom-4 right-4 flex grow-0 flex-col items-center justify-center rounded-md p-1 px-2 text-xs font-semibold text-gray-500/30 transition-all',
  },
  variants: {
    isEditing: {
      true: {
        button: 'text-pink-500/30',
        container: 'min-h-32',
      },
      false: {
        container: 'transition-all',
      },
    },
    isDirty: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isEditing: true,
      isDirty: true,
      className: {button: 'bg-pink-500 text-white'},
    },
  ],
});

export const CreateCommentView: React.FC<Props> = ({taskId}) => {
  const [doc, setDoc] = useState<string>(emptyDocString);
  const [isEditing, setIsEditing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const {container, button} = styles({isDirty, isEditing});
  const editorRef = useRef<EditorState | null>(null);
  const onEscape = useCallback(() => {
    setDoc(JSON.stringify(editorRef.current?.editor?.getJSON() ?? emptyDocString));
    setIsEditing(false);
    setIsDirty(false);
  }, []);

  const onSend = useCallback(async () => {
    if (!isDirty) return;
    const body = JSON.stringify(await momentumToAdf(editorRef.current?.editor?.getJSON() ?? {}, taskId));
    const renderedBody = editorRef.current?.editor?.getHTML() ?? '';
    Worker.postEntity(EntityType.Comment, {
      taskId,
      accountId: authStore.accountId,
      authorId: authStore.actorId,
      body,
      renderedBody,
    } as Partial<Comment>);
    setDoc(emptyDocString);
    setIsEditing(false);
    setIsDirty(false);
  }, [taskId, isDirty]);

  return (
    <div className={container()}>
      <div className="h-full w-full rounded-md ring-1 ring-gray-500/30 focus-within:ring-2 focus-within:ring-pink-500/80">
        {isEditing ? (
          <MoEditor
            ref={editorRef}
            autofocus
            doc={doc}
            taskId={taskId}
            onDirtyChange={setIsDirty}
            onEscape={onEscape}
            onSubmit={onSend}
            className="h-full max-h-56"
            editorClassName="min-h-20 pr-9"
          />
        ) : (
          <Button
            className="flex h-full w-full cursor-text items-center rounded-md px-2 text-sm text-gray-500/30"
            onPress={() => {
              setIsEditing(true);
            }}
          >
            Leave a comment...
          </Button>
        )}
      </div>
      <Button className={button()} onPress={onSend} isDisabled={!isDirty}>
        <SendIcon className="h-4 w-4" />
      </Button>
    </div>
  );
};
