import {EnsureVisible} from '@/components/lib/EnsureVisible';
import {TaskHistoryItem} from '@/components/task/TastHistoryItem';
import {useCallbackOnce} from '@/hooks/useCallbackOnce';
import {EMPTY_LIST} from '@/lib/emptyList';
import {taskHistoryStore} from '@/stores/taskHistory';
import {taskReadStateStore} from '@/stores/taskReadState';
import {Filters} from '@shared/filters/Filters';
import type {TaskHistoryListItem} from '@shared/models/FilteredEntityList';
import React, {useCallback, useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  id: number;
  className?: string;
}

interface State {
  readTo?: string;
  prevReadTo?: string;
  manualReadTo?: string;
}

export const TaskHistoryView: React.FC<Props> = ({id: taskId, className}) => {
  const history = taskHistoryStore.use((s) => s.getList(Filters.taskHistoryFilter({taskId})), [taskId]) ?? EMPTY_LIST;
  const readState = taskReadStateStore.use((s) => s.getById(taskId), [taskId]);
  const [state, setState] = useState<State>({
    readTo: readState?.readTo ?? undefined,
    prevReadTo: undefined,
    manualReadTo: undefined,
  });

  useEffect(() => {
    setState({
      readTo: readState?.readTo ?? undefined,
      prevReadTo: undefined,
      manualReadTo: undefined,
    });
  }, []);

  useEffect(() => {
    if (readState?.readTo !== state.readTo) {
      setState({
        ...state,
        readTo: readState?.readTo ?? undefined,
        prevReadTo: state.readTo,
      });
    }
  }, [readState, state]);

  const onVisible = useCallbackOnce(
    (createdAt?: string) => {
      if (!state.manualReadTo && state.readTo !== createdAt) {
        taskReadStateStore.patchReadAt(taskId, createdAt!);
      }
    },
    [taskId, state, readState?.readTo],
  );

  const onOptionClick = useCallback(
    (item: TaskHistoryListItem) => {
      const index = history.findIndex((h) => h.id === item.id);
      const prev = history[index - 1];
      const readTo = prev?.createdAt ?? null;

      taskReadStateStore.patchReadAt(taskId, readTo);
      setState({
        ...state,
        manualReadTo: readTo,
      });
    },
    [taskId, history],
  );

  return (
    <div
      className={twMerge('flex h-full min-h-1 w-full flex-col overflow-y-auto px-10 py-5 text-sm leading-7', className)}
    >
      <div className="flex-grow" />
      {renderHistory(history, state, onVisible, onOptionClick)}
    </div>
  );
};

function renderHistory(
  history: TaskHistoryListItem[],
  state: State,
  onVisible: (id?: string) => void,
  onOptionClick: (item: TaskHistoryListItem) => void,
) {
  let foundUnread = false;
  const readTo = state.manualReadTo ?? state.prevReadTo ?? state.readTo;
  const faded = !state.manualReadTo && !!state.prevReadTo;
  return history?.map((h, index) => {
    const isFirstUnread = !foundUnread && readTo && h.createdAt > readTo;
    if (index === history.length - 1) {
      return (
        <EnsureVisible
          key={h.id}
          id={h.createdAt}
          onVisible={onVisible}
          scrollIntoView={!foundUnread && {behavior: 'instant'}}
        >
          {isFirstUnread && <UnreadIndicator faded={faded} />}
          <TaskHistoryItem key={index} item={h} onOptionClick={onOptionClick} />
        </EnsureVisible>
      );
    } else if (isFirstUnread) {
      foundUnread = true;
      return (
        <EnsureVisible key={h.id} id={h.createdAt} scrollIntoView>
          <UnreadIndicator faded={faded} />
          <TaskHistoryItem key={index} item={h} onOptionClick={onOptionClick} />
        </EnsureVisible>
      );
    }
    return <TaskHistoryItem key={index} item={h} onOptionClick={onOptionClick} />;
  });
}

const UnreadIndicator = ({faded}: {faded?: boolean}) => {
  const fadedClass = faded ? 'border-opacity-20' : 'border-opacity-80';
  return <div className={`absolute w-full border-b-2 border-pink-500 ${fadedClass} transition-all duration-500`} />;
};
