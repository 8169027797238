import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {Board} from '@shared/models/Board';
import type {DB} from 'src-sw/db/db';

export class BoardFilter extends EntityFilter<IBoardFilter> {
  readonly type = EntityType.Board;
  readonly projectId?: number;

  constructor(filter: Omit<IBoardFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, board: Board): Promise<[boolean, string, RankComparator]> {
    if (this.projectId !== undefined && board.projectId !== this.projectId) {
      return [false, board.name, RANK_COMPARATOR_ASC];
    }

    return [true, board.name, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.projectId ?? ''}`;
  }
}

export type IBoardFilter = PublicPropertiesOf<BoardFilter>;
export function isBoardFilter(filter: IEntityFilter): filter is BoardFilter {
  return filter.type === EntityType.Board;
}
