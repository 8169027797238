export interface EventedEvents {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [event: string]: (...args: any[]) => void | Promise<(...args: any[]) => void>;
}

export class Evented<E extends EventedEvents> {
  private _listeners = new Map<keyof E, Set<(...args: Parameters<E[keyof E]>) => void>>();

  on<T extends keyof E>(event: T, listener: (...args: Parameters<E[T]>) => void) {
    let listeners = this._listeners.get(event);
    if (!listeners) {
      listeners = new Set();
      this._listeners.set(event, listeners);
    }
    listeners.add(listener);
    return () => this.off(event, listener);
  }

  off<T extends keyof E>(event: T, listener: (...args: Parameters<E[T]>) => void) {
    const listeners = this._listeners.get(event);
    if (listeners) {
      listeners.delete(listener);
    }
  }

  emit<T extends keyof E>(event: T, ...args: Parameters<E[T]>) {
    const listeners = this._listeners.get(event);
    if (listeners) {
      for (const listener of listeners) {
        try {
          listener(...args);
        } catch (e) {
          console.error(`[${this.constructor.name}] Error in event handler (${event.toString()})`, e);
        }
      }
    }
  }
}
