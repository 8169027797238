import {useAsyncEffect} from '@/hooks/useAsyncEffect';
import {adfToMomentum, momentumToAdf} from '@/text-editor/AdfToMomentum';
import {ContentMenu} from '@/text-editor/interfaces/ContentMenu';
import {FormattingMenu} from '@/text-editor/interfaces/FormattingMenu';
import {LinkMenu} from '@/text-editor/interfaces/LinkMenu';
import {useMoEditor} from '@/text-editor/useMoEditor';
import {debounce} from '@shared/lib/debounce';
import {isStateEqual} from '@shared/lib/isStateEqual';
import type {Editor, FocusPosition} from '@tiptap/core';
import type {JSONContent} from '@tiptap/react';
import {BubbleMenu, EditorContent} from '@tiptap/react';
import type {ForwardedRef, KeyboardEvent} from 'react';
import {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import {twMerge} from 'tailwind-merge';

interface Props {
  doc?: string;
  taskId?: number;
  onSave?: (doc: string) => void;
  onDirtyChange?: (isDirty: boolean) => void;
  className?: string;
  editorClassName?: string;
  autofocus?: FocusPosition;
  onEscape?: () => void;
  onSubmit?: () => void;
}

export interface EditorState {
  editor: Editor | null;
  isDirty: boolean;
}

export const emptyDocString = JSON.stringify({type: 'doc', content: [{type: 'paragraph', content: []}]});
const popperOptions = {modifiers: [{name: 'preventOverflow', options: {rootBoundary: 'document'}}]};

export const MoEditor = forwardRef(
  (
    {doc, onSave, onDirtyChange, className, editorClassName, autofocus = false, onEscape, onSubmit, taskId}: Props,
    ref: ForwardedRef<EditorState>,
  ) => {
    const [providedDoc, setProvidedDoc] = useState<string | undefined>();
    const editor = useMoEditor({editable: providedDoc !== undefined, autofocus}, editorClassName);

    // Because we translate between ADF and Momentum, the content may change immediately after being set
    // initialJson will store the "initial" state of the editor that represents the provided doc
    const [initialJson, setInitialJson] = useState<JSONContent>();
    const [editorState, setEditorState] = useState<EditorState>(() => ({
      editor,
      isDirty: false,
    }));

    const handleUpdate = useCallback(() => {
      if (!editor || !initialJson) return;
      const json = editor.getJSON();
      const isDirty = !isStateEqual(initialJson, json);
      if (isDirty != editorState.isDirty) {
        setEditorState({editor, isDirty});
        onDirtyChange?.(isDirty);
      } else if (editorState.editor !== editor) {
        setEditorState({editor, isDirty});
      }
    }, [editor, initialJson, editorState, onDirtyChange]);

    if (typeof ref === 'function') ref(editorState);
    else if (ref) ref.current = editorState;

    useEffect(() => {
      const debouncedHandleUpdate = debounce(handleUpdate, 300);
      editor?.on('update', debouncedHandleUpdate);
      return () => {
        debouncedHandleUpdate.cancel();
        editor?.off('update', debouncedHandleUpdate);
      };
    }, [editor, handleUpdate]);

    useAsyncEffect(
      async (isActive) => {
        if (!doc || !editor) return;
        if (doc !== providedDoc) {
          // TODO: don't overwrite user's changes without prompting and/or merging
          const content = await adfToMomentum(JSON.parse(doc), taskId);
          if (!isActive()) return;
          editor.commands.setContent(content, false);
          editor.setEditable(true);
          if (providedDoc === undefined && autofocus) editor.commands.focus();
          setProvidedDoc(doc);
          setInitialJson(editor.getJSON());
          handleUpdate();
        } else {
          setInitialJson((prev) => prev ?? editor.getJSON());
        }
        if (editorState.editor !== editor) {
          setEditorState((prev) => ({editor, isDirty: prev.isDirty}));
        }
      },
      [doc, providedDoc, editor, editorState.editor],
    );

    const handleKeyDown = async (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        event.stopPropagation();
        if (!editor || !editorState.isDirty) return;
        onSave?.(JSON.stringify(await momentumToAdf(editor.getJSON(), taskId)));
      } else if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        onEscape?.();
      } else if ((event.key === 'Enter' || event.key === 'Return') && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        event.stopPropagation();
        if (!editor || !editorState.isDirty) return;
        onSubmit?.();
      }
    };
    const containerRef = useRef<HTMLDivElement>(null);

    if (doc && editor) {
      return (
        <div
          ref={containerRef}
          className={twMerge(
            'flex flex-col rounded-md p-0 ring-1 ring-gray-200 focus-within:ring-2 focus-within:ring-pink-500/80',
            className,
          )}
        >
          <ContentMenu editor={editor} />
          {/** put this portal in the root instead of inside the editor content? **/}
          <BubbleMenu
            editor={editor}
            tippyOptions={{popperOptions, appendTo: () => containerRef.current!, zIndex: 10}}
            shouldShow={(props) => !props.editor.isActive('link') && !props.state.selection.empty}
          >
            <FormattingMenu editor={editor} />
          </BubbleMenu>
          <BubbleMenu
            editor={editor}
            pluginKey="linkBubbleMenu"
            shouldShow={(props) => props.editor.isActive('link')}
            tippyOptions={{
              placement: 'bottom',
              maxWidth: 450,
              popperOptions,
              zIndex: 10,
              appendTo: () => containerRef.current!,
            }}
          >
            <LinkMenu editor={editor} />
          </BubbleMenu>
          <Scrollbars autoHide autoHeight autoHeightMax={99999}>
            <EditorContent editor={editor} onKeyDownCapture={handleKeyDown} />
          </Scrollbars>
        </div>
      );
    }
    return '';
  },
);
