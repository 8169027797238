import {TaskView} from '@/components/task/TaskView';
import {Suspense} from 'react';

interface Props {
  taskId?: number;
}

export function RightSide({taskId}: Props) {
  if (taskId) {
    return (
      <Suspense fallback={<div className="h-full w-full" />}>
        <TaskView id={taskId} />
      </Suspense>
    );
  }
  return <div className="h-full w-full" />;
}
