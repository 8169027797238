import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import type {EntityStoreState} from '@/stores/lib/EntityStore';
import {EntityStore} from '@/stores/lib/EntityStore';
import {EntityType} from '@shared/EntityType';
import type {Label} from '@shared/models/Label';

export const labelStore = persistThroughHotReload(
  'labelStore',
  new EntityStore<EntityStoreState<Label>, Label>(EntityType.Label, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({labelStore});
import.meta.hot?.accept(reloadOnHotUpdate);
