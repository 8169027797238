import {TaskView} from '@/components/task/TaskView';
import {Modal} from '@/design-system/modal/Modal';
import {BoardUiContext} from '@/stores/ui/boardUiStore';
import {useCallback, useContext, useEffect, useState} from 'react';

export function TaskOverlay() {
  const boardUiStore = useContext(BoardUiContext).store;
  const [isOpen, setIsOpen] = useState(false);
  const shouldBeOpen = !!boardUiStore.use((s) => s.state.isTaskOpen && s.state.selected, []);
  useEffect(() => {
    setIsOpen(shouldBeOpen);
  }, [shouldBeOpen]);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) boardUiStore.closeTask();
    },
    [boardUiStore],
  );

  return (
    <Modal
      drawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className="drawer-lg w-[50vw] p-0"
      aria-label="Task overlay"
    >
      {({close}) => <TaskOverlayContent close={close} />}
    </Modal>
  );
}

function TaskOverlayContent({close: _close}: {close: () => void}) {
  const boardUiStore = useContext(BoardUiContext).store;
  return boardUiStore.state.selected && <TaskView id={boardUiStore.state.selected} />;
}
