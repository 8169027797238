import React from 'react';
import {tv} from 'tailwind-variants';

interface Props {
  points?: number;
}

const styles = tv({
  base: 'transition-color-opacity flex h-4 min-w-7 flex-shrink-0 items-center justify-center rounded-full border border-gray-400/40 bg-gray-300/50 px-1 text-xs font-semibold text-gray-600',
  variants: {
    emptyStyle: {
      true: 'border-pink-700/30 bg-pink-500/10 opacity-60 group-hover/button:opacity-100',
    },
  },
});

export const PointsLabel: React.FC<Props> = ({points}) => {
  const pointsText = pointsToString(points);

  return <div className={styles({emptyStyle: (points ?? null) === null})}>{pointsText}</div>;
};

function pointsToString(points: number | undefined) {
  if (points === undefined) {
    return '';
  }

  if (points === 0) {
    return '0';
  }

  if (points == 0.75) {
    return '¾';
  }

  if (points == 0.5) {
    return '½';
  }

  if (points == 0.25) {
    return '¼';
  }

  return points.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });
}
