import {Time} from '@/lib/time';
import NumberFlow from '@number-flow/react';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  value: number;
  total: number;
  offset?: number;
  caption?: string;
  subCaption?: string;
  className?: string;
}

export function DonutVisualization({value, total, offset = 0, caption, subCaption, className}: Props) {
  const percent = total == 0 ? 0 : (value / total) * 100;
  const offsetPercent = total == 0 ? 0 : (offset / total) * 100;

  const strokeOffset = -offsetPercent + 25; // -25 to start at the top

  const [state, setState] = useState({
    strokeDashArray: '0 100',
    strokeDashOffset: `${strokeOffset}`,
    value: 0,
  });

  useEffect(() => {
    setState((s) => ({
      strokeDashArray: `${percent} ${100 - percent}`,
      strokeDashOffset: `${strokeOffset}`,
      value: s.value,
    }));
  }, [strokeOffset, percent]);

  // Animate the value (e.g., count up from 0)
  const frame = useRef({timer: 0, time: Time.now(), intermediateValue: 0});
  useLayoutEffect(() => {
    if (frame.current.intermediateValue !== value) {
      const speed = value - frame.current.intermediateValue / 0.6;
      function animate() {
        const t = Time.now();
        const dt = Math.min(0.125, (t - frame.current.time) / 1000);
        frame.current.intermediateValue = clamp(frame.current.intermediateValue + speed * dt, 0, value);
        const roundedValue = Math.round(frame.current.intermediateValue);
        if (roundedValue != state.value) {
          setState((s) => ({...s, value: roundedValue}));
        }

        if (Math.abs(frame.current.intermediateValue - value) < 0.1) {
          frame.current.intermediateValue = value;
        } else {
          frame.current.timer = requestAnimationFrame(animate);
        }

        frame.current.time = t;
      }
      frame.current.timer = requestAnimationFrame(animate);
    }
    return () => cancelAnimationFrame(frame.current.timer);
  }, [value]);

  return (
    <figure
      className={twMerge('flex items-center gap-4 animate-in fade-in', className)}
      aria-label={`Donut chart visualizing ${value} of ${total} ${caption}`}
    >
      <div className="relative">
        <svg className="h-14" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="21" cy="21" r="16" stroke="currentColor" strokeWidth="6" fill="none" style={{opacity: 0.25}} />
          <circle
            cx="21"
            cy="21"
            r="16"
            stroke="currentColor"
            strokeWidth="6"
            fill="none"
            strokeDasharray={state.strokeDashArray}
            strokeDashoffset={state.strokeDashOffset}
            className="transition-all delay-150 duration-1000 [animation-timing-function:cubic-bezier(0.15,0,0.85,1)]"
          />
        </svg>
        <NumberFlow
          className="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 font-bold"
          value={state.value}
          style={{fontSize: 17 - Math.max(0, value.toString().length - 2) * 2.25}}
          continuous
        />
      </div>
      <figcaption className="flex flex-col">
        <span className="text-base font-bold">{caption}</span>
        {subCaption && <span className="text-xs opacity-50">{subCaption}</span>}
      </figcaption>
    </figure>
  );
}

function clamp(value: number, min: number, max: number) {
  if (min > max) [min, max] = [max, min];
  return Math.min(Math.max(value, min), max);
}
