import {MoStore, mutation} from '@/stores/lib/MoStore';
import {Tracer} from '@/stores/lib/TracerProxy';
import {isStateEqual} from '@shared/lib/isStateEqual';
import autobind from 'autobind-decorator';
import {createContext} from 'react';

interface FormState {
  source: Record<string, any>; // the original source data
  current: Record<string, any>; // the current data
  isDirty: boolean;
  hasErrors: boolean;
  errors: Record<string, string>;
}

export type FieldValidator = (value: any) => string | undefined | boolean;

const DEFAULT_STATE: FormState = {
  source: {}, // the original source data
  current: {}, // the current data
  isDirty: false,
  hasErrors: false,
  errors: {},
};

export class FormStore extends MoStore<FormState> {
  static create(source: Record<string, any>, validators: Record<string, FieldValidator | FieldValidator[]> = {}) {
    return new FormStore({source}, validators);
  }

  constructor(
    state: Partial<FormState> & Pick<FormState, 'source'>,
    private validators: Record<string, FieldValidator | FieldValidator[]> = {},
  ) {
    const current = JSON.parse(JSON.stringify(state.source ?? {}));
    super({...DEFAULT_STATE, current, ...state});
    this.selectAndSubscribe((s) => [s.data, s.source], this.validate);
  }

  /**
   * Get the current data as a plain object
   * Note: any strings will be trimmed
   */
  public get data() {
    return Object.fromEntries(
      Object.entries(this.state.current).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value]),
    );
  }

  public get(key: string): any {
    return this.state.current[key];
  }

  @mutation
  public set(key: string, value: any) {
    this._state.current[key] = value;
  }

  @mutation
  public update(data: Record<string, any>) {
    Object.assign(this._state.current, data);
  }

  @mutation
  public setSource(data: Record<string, any>) {
    const existing = Tracer.renderWithoutTracing(this._state.source);
    const current = Tracer.renderWithoutTracing(this._state.current);
    const keys = new Set([...Object.keys(data), ...Object.keys(existing)]);
    for (const key of keys) {
      // if there have been no changes, update the current value with the new source value
      if (current[key] === existing[key]) {
        current[key] = data[key];
      }
    }

    this._state.source = data;
    this.update(current);
  }

  @mutation
  public reset() {
    this._state.current = JSON.parse(JSON.stringify(this._state.source ?? {}));
    this.validate();
    this._state.isDirty = false;
  }

  public get isDirty() {
    return this._state.isDirty;
  }

  public get hasErrors() {
    return this._state.hasErrors;
  }

  public get errors() {
    return this.state.errors;
  }

  public get source() {
    return this.state.source;
  }

  public getError(key: string): string | undefined {
    return this.state.errors[key];
  }

  public setValidators(validators: Record<string, FieldValidator | FieldValidator[]>) {
    this.validators = validators;
    this.validate();
  }

  @autobind
  @mutation
  private validate() {
    const errors: Record<string, string> = {};
    const data = this.data; // use the normalized data for validation (e.g., trim strings)
    Object.entries(this.validators).forEach(([key, validator]) => {
      const value = key === '*' ? data : data[key];
      const error = mapFirstNotUndefined(validator, (v) => v(value));
      if (typeof error === 'string' || error === false) {
        errors[key] = error || 'invalid value';
      }
    });

    this._state.hasErrors = Object.keys(errors).length > 0;
    this._state.errors = errors;
    this._state.isDirty = !isStateEqual(this._state.current, this._state.source);
  }
}

export const FormDataContext = createContext<{store: FormStore}>({
  store: new FormStore(
    {
      source: {},
      hasErrors: true,
      errors: {'*': 'Form not initialized'},
    },
    {'*': () => 'Form not initialized'},
  ),
});

function mapFirstNotUndefined<T, R>(values: T[] | T, fn: (value: T) => R | undefined): R | undefined {
  for (const value of Array.isArray(values) ? values : [values]) {
    const result = fn(value);
    if (result !== undefined) return result;
  }
  return undefined;
}
