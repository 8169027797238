import React from 'react';
import {twMerge} from 'tailwind-merge';

export const Spacer = React.forwardRef<HTMLSpanElement, React.HTMLAttributes<HTMLSpanElement>>(
  ({children, ...props}, ref) => {
    return (
      <span ref={ref} {...props} className={twMerge('inline-block min-w-1', props.className)}>
        {children}
      </span>
    );
  },
);
