import type React from 'react';

interface Props<T> {
  value: T;
  fallback: React.ReactNode;
  nullFallback?: React.ReactNode;
  children?: React.ReactNode | ((value: NonNullable<T>) => React.ReactNode);
}

export function NonNullable<T>({value, fallback, nullFallback, children}: Props<T>): React.ReactNode {
  if (value === null && nullFallback) {
    return nullFallback;
  }
  if (value == null) {
    return fallback;
  }
  if (typeof children === 'function') {
    return children(value);
  }
  return <>{children}</>;
}
