import {ReactRenderer} from '@tiptap/react';
import type {Instance} from 'tippy.js';
import tippy from 'tippy.js';

import type {SuggestionKeyDownProps, SuggestionOptions, SuggestionProps} from '@tiptap/suggestion';
import type {MentionListInterface} from './MentionList';
import {MentionList} from './MentionList';

export const suggestion: Omit<SuggestionOptions<any>, 'editor'> = {
  allowSpaces: true,
  allowedPrefixes: [' ', '@', ':', ';', '.', '?', '!', '-', '—'],

  render: () => {
    let component: ReactRenderer<MentionListInterface, SuggestionProps>;
    let popup: Instance[];

    return {
      onStart: (props: SuggestionProps) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as (() => DOMRect) | null,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props: SuggestionProps) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as (() => DOMRect) | null,
        });
      },

      onKeyDown(props: SuggestionKeyDownProps) {
        if (props.event.key === 'Escape' && popup[0].state.isVisible) {
          popup[0].hide();
          props.event.stopPropagation();

          return true;
        }

        return component.ref?.onKeyDown(props) ?? false;
      },

      onExit(_props: SuggestionProps) {
        if (!popup[0].state.isDestroyed) {
          popup[0].destroy();
        }
        component.destroy();
      },
    };
  },
};
