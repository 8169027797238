import {Image} from '@/components/Image';
import type {SelectableItemProps} from '@/design-system';
import {SelectableItem} from '@/design-system';
import type {Suggestion, TypeaheadProps} from '@/design-system/Typeahead';
import {Typeahead} from '@/design-system/Typeahead';
import {EMPTY_LIST} from '@/lib/emptyList';
import {contextStore} from '@/stores/context';
import {taskStore} from '@/stores/task';
import {taskTypeStore} from '@/stores/taskType';
import {Filters} from '@shared/filters/Filters';
import {NOT_DONE_STATUS_CATEGORIES} from '@shared/models/Status';
import {forwardRef, useCallback, useEffect, useMemo, useRef} from 'react';

type PartialTypeaheadProps = PartialOmit<TypeaheadProps<Suggestion>, 'getSuggestions' | 'itemRenderer'>;
interface Props extends PartialTypeaheadProps {
  excludeTaskIds?: number[];
}

export const TaskTypeahead = forwardRef<HTMLInputElement, Props>(function ({excludeTaskIds, ...props}, ref) {
  const unsubscribe = useRef<() => void>();
  const getSuggestions = useMemo(() => {
    return (_value: string, resolve: (suggestions: Suggestion[]) => void) => {
      unsubscribe.current?.();
      unsubscribe.current = taskStore.selectAndSubscribe(
        (s) =>
          s
            .getList(
              Filters.taskFilter({statusCategory: NOT_DONE_STATUS_CATEGORIES, projectId: contextStore.projectId}),
            )
            ?.map((t) => ({id: t.id, textValue: s.getById(t.id)?.title ?? ''}))
            ?.filter((item) => item.textValue && excludeTaskIds?.every((id) => id !== item.id)) ?? EMPTY_LIST,
        resolve,
      );
    };
  }, []);
  useEffect(() => () => unsubscribe.current?.(), []);

  return (
    <Typeahead
      ref={ref}
      getSuggestions={getSuggestions}
      itemRenderer={(item, onAction) => {
        return <SelectableTaskItem key={item.id} item={item} textValue={item.textValue} onAction={onAction} />;
      }}
      {...props}
      crossOffset={-36}
    />
  );
});

const SelectableTaskItem: React.FC<
  {item: Suggestion; onAction: (item: Suggestion) => void} & Omit<SelectableItemProps, 'onAction'>
> = ({item, onAction, ...props}) => {
  const task = taskStore.use((s) => s.getById(item.id), [item.id]);
  const taskType = taskTypeStore.use((s) => s.getById(task?.typeId), [task?.typeId]);
  const actionHandler = useCallback(() => {
    onAction(item);
  }, [onAction, item]);

  return (
    <SelectableItem textValue={item.textValue} {...props} onAction={actionHandler} className="w-72 text-sm">
      <div className="mr-0.5 h-4 w-4 flex-shrink-0">
        <Image id={taskType?.iconImageId} />
      </div>
      <div className="line-clamp-1 flex-grow">{task?.title}</div>
      <div className="flex-shrink-0 text-xs text-gray-500/50">{task?.key}</div>
    </SelectableItem>
  );
};
