import {dragStore} from '@/design-system/dnd/dragStore';
import type {DragEvent, HTMLAttributes} from 'react';
import {useMemo, useState} from 'react';
import {flushSync} from 'react-dom';
import {createRoot} from 'react-dom/client';

export interface DragStartResponse {
  data: Record<string, string>;
}

export interface UseDragOptions {
  source: string;
  onDragStart?: (e: DragEvent) => DragStartResponse | false;
  onDragEnd?: (e: DragEvent) => void;
  preview?: React.ReactNode | ((e: DragEvent) => React.ReactNode);
  previewOffset?: [number, number];
}

export interface UseDragResult {
  dragProps: Pick<HTMLAttributes<HTMLDivElement>, 'draggable' | 'onDragStart' | 'onDragEnd'>;
  renderProps: {
    isDragging: boolean;
  };
}

let dragElement: HTMLDivElement | null = null;

export function useDrag(
  {source, onDragStart, onDragEnd, preview, previewOffset}: UseDragOptions,
  deps?: React.DependencyList,
): UseDragResult {
  const [isDragging, setIsDragging] = useState(false);
  const dragProps = useMemo(
    () => {
      function localOnDragStart(e: DragEvent) {
        e.dataTransfer.effectAllowed = 'move';
        const start = onDragStart?.(e) ?? ({data: {}} as DragStartResponse);
        if (start === false) {
          e.preventDefault();
          return;
        }
        for (const [type, data] of Object.entries(start.data)) {
          e.dataTransfer?.setData(type, data);
        }
        renderDragPreview(e, preview, previewOffset);
        dragStore.startDrag(source, Object.keys(start.data), start.data);
        setIsDragging(true);
      }

      function localOnDragEnd(e: DragEvent) {
        dragElement?.remove();
        dragElement = null;
        onDragEnd?.(e);
        dragStore.endDrag();
        setIsDragging(false);
      }
      return {draggable: true, onDragStart: localOnDragStart, onDragEnd: localOnDragEnd};
    },
    deps ?? [source, onDragStart, onDragEnd, preview],
  );

  return {
    dragProps,
    renderProps: {
      isDragging: isDragging,
    },
  };
}

export interface UseDropOptions {
  onDragEnter?: (e: DragEvent) => DataTransfer['dropEffect'];
  onDragOver?: (e: DragEvent) => DataTransfer['dropEffect'];
  onDragLeave?: (e: DragEvent) => void;
  onDrop?: (e: DragEvent) => DataTransfer['dropEffect'];
}

export interface UseDropResult {
  dropProps: Pick<HTMLAttributes<HTMLDivElement>, 'onDragOver' | 'onDragLeave' | 'onDrop'>;
  renderProps: {
    isDropTarget: boolean;
  };
}

export function useDrop(
  {onDragEnter, onDragOver, onDragLeave, onDrop}: UseDropOptions,
  deps: React.DependencyList,
): UseDropResult {
  const [isDropTarget, setIsDropTarget] = useState(false);

  const dropProps: UseDropResult['dropProps'] = useMemo(() => {
    function localOnDragEnter(e: DragEvent) {
      const effect = onDragEnter?.(e) ?? 'none';
      e.dataTransfer.dropEffect = effect;
      setIsDropTarget(effect !== 'none');
      if (effect != 'none') {
        e.preventDefault();
        e.stopPropagation();
      }
    }
    function localOnDragOver(e: DragEvent) {
      const effect = onDragOver?.(e) ?? 'none';
      e.dataTransfer.dropEffect = effect;
      setIsDropTarget(effect !== 'none');
      if (effect != 'none') {
        e.preventDefault();
        e.stopPropagation();
      }
    }
    function localOnDragLeave(e: DragEvent) {
      onDragLeave?.(e);
      setIsDropTarget(false);
    }
    function localOnDrop(e: DragEvent) {
      const effect = onDrop?.(e) ?? 'none';
      e.dataTransfer.dropEffect = effect;
      setIsDropTarget(false);
      if (effect != 'none') {
        e.preventDefault();
        e.stopPropagation();
      }
    }
    return {
      onDragEnter: localOnDragEnter,
      onDragOver: localOnDragOver,
      onDragLeave: localOnDragLeave,
      onDrop: localOnDrop,
    };
  }, deps ?? []);

  return {
    dropProps,
    renderProps: {
      isDropTarget,
    },
  };
}

function renderDragPreview(
  e: DragEvent,
  getPreview?: ((e: DragEvent) => React.ReactNode) | React.ReactNode,
  [x, y]: [number, number] = [0, 0],
) {
  const preview = typeof getPreview === 'function' ? getPreview?.(e) : getPreview;
  if (!preview) return;

  dragElement?.remove();
  dragElement = document.createElement('div');
  dragElement.style.position = 'fixed';
  dragElement.style.top = '-1000px';
  const root = createRoot(dragElement);
  flushSync(() => {
    root.render(preview);
  });
  document.body.appendChild(dragElement);

  e.dataTransfer?.setDragImage(dragElement, x, y);
}
