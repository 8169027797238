import {EntityType} from '@shared/EntityType';
import type {InboxMessage} from '@shared/models/InboxMessage';
import type {DB} from 'src-sw/db/db';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from './EntityFilter';
import {EntityFilter, RANK_COMPARATOR_DESC} from './EntityFilter';

export class InboxMessageFilter extends EntityFilter<IInboxMessageFilter> {
  readonly type = EntityType.InboxMessage;
  readonly read?: boolean;
  readonly projectId?: number;
  readonly taskId?: number;

  public constructor(filter: Omit<IInboxMessageFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(db: DB, entity: InboxMessage): Promise<[boolean, string, RankComparator]> {
    let matches = true;
    if (this.projectId !== undefined && entity.taskId) {
      const task = await db.task.get(entity.taskId);
      if (!task || task.projectId !== this.projectId) {
        matches = false;
      }
    }

    if (this.taskId !== undefined && entity.taskId !== this.taskId) {
      matches = false;
    }

    matches &&= this.read === undefined || !!entity.readAt === this.read;

    return [matches, entity.readAt ?? entity.updatedAt, RANK_COMPARATOR_DESC];
  }

  public toString(): string {
    return `${this.type}|${this.read ?? ''}|${this.projectId ?? ''}|${this.taskId ?? ''}`;
  }
}
export type IInboxMessageFilter = PublicPropertiesOf<InboxMessageFilter>;
export function isInboxMessageFilter(filter: IEntityFilter): filter is InboxMessageFilter {
  return filter.type === EntityType.InboxMessage;
}
