import {ColumnDropInterface} from '@/components/board/ColumnDropInterface';
import {TaskCard} from '@/components/board/TaskCard';
import type {ScrollViewRenderProps} from '@/design-system/ScrollView';
import {dragContextStore} from '@/stores/dragContext';
import {taskStore} from '@/stores/task';
import {BoardUiContext} from '@/stores/ui/boardUiStore';
import type {BoardColumn as BoardColumnType} from '@shared/models/Board';
import {useContext, useEffect, useMemo, useState} from 'react';
import type {DragAndDropHooks, Key} from 'react-aria-components';
import {GridList} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  column: BoardColumnType;
  dragAndDropHooks?: DragAndDropHooks;
  onSelectionChange?: (keys: Iterable<Key>) => void;
  selection?: Iterable<Key>;
  scrollProps: ScrollViewRenderProps;
}

const styles = tv({
  slots: {
    base: 'group/column relative w-96 min-w-56 flex-col gap-2 overflow-clip rounded-md bg-gray-200 p-2',
    header:
      'sticky top-0 z-10 -mx-2 -mt-2 mb-2 line-clamp-1 shrink-0 overflow-visible bg-gray-200 pb-1.5 pt-2 text-center text-xs font-bold uppercase text-gray-400 transition-shadow',
    dropInterface: 'absolute left-2 right-2 top-10 h-0',
    gridList: 'opacity-100 transition-opacity duration-100',
  },
  variants: {
    showDropInterface: {
      true: {
        gridList: 'pointer-events-none opacity-0',
      },
    },
    hasMoreTop: {
      true: {
        header: 'bg-gray-200 shadow-md',
      },
    },
  },
});

export function BoardColumn({column, dragAndDropHooks, onSelectionChange, selection, scrollProps}: Props) {
  const dragState = dragContextStore.use((s) => s.state, []);
  const isDraggingTasks = dragState.isDragging && dragState.dragType === 'momentum/task';
  const draggingTasks = taskStore.use(
    (s) => (isDraggingTasks && dragState.items.map((i) => s.getById(JSON.parse(i['momentum/task']).id))) || [],
    [isDraggingTasks, dragState.items],
  );

  // this property has to be delayed or else it interrupts the drag start and cancels the drag if the drag starts over the drop zone
  const shouldShowDropInterface =
    isDraggingTasks && draggingTasks.some((t) => t && !column.statusIds.includes(t.statusId));
  const [showDropInterface, setShowDropInterface] = useState(false);
  useEffect(() => {
    if (showDropInterface === shouldShowDropInterface) return;
    setShowDropInterface(shouldShowDropInterface);
  }, [showDropInterface, shouldShowDropInterface]);

  const boardUiStore = useContext(BoardUiContext).store;
  const {tasks, assigneeIds} = boardUiStore.use((s) => ({tasks: s.tasks, assigneeIds: s.assigneeIds}), []);
  const statusIds = new Set(column.statusIds);

  const items = useMemo(
    () =>
      tasks
        .filter((t) => t != null)
        .filter((t) => statusIds.has(t.statusId ?? -1))
        .filter((t) => !assigneeIds || assigneeIds.length === 0 || assigneeIds.includes(t.assigneeId ?? -1))
        .map((t) => ({id: t.id!, text: t.title, task: t})),
    [tasks, statusIds, assigneeIds],
  );

  const {base, header, dropInterface, gridList} = styles({showDropInterface, ...scrollProps});

  return (
    <div className={base()}>
      <div className={header()}>
        {column.name}
        {showDropInterface && (
          <div className={dropInterface()}>
            <ColumnDropInterface column={column} sprintId={boardUiStore.sprintId} />
          </div>
        )}
      </div>
      <GridList
        key="grid-list"
        className={twMerge('relative flex flex-col gap-2', gridList())}
        aria-label={`tasks in ${column.name} column`}
        selectionMode="multiple"
        selectionBehavior="replace"
        items={items}
        dragAndDropHooks={dragAndDropHooks}
        onSelectionChange={onSelectionChange}
        selectedKeys={selection}
      >
        {(item) => <TaskCard key={item.id} id={item.id} text={item.text} />}
      </GridList>
    </div>
  );
}
