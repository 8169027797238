import IconStatusComplete from '@/assets/icon-status-complete.svg?react';
import IconStatusInProgress from '@/assets/icon-status-inProgress.svg?react';
import IconStatusNotStarted from '@/assets/icon-status-notStarted.svg?react';
import {statusStore} from '@/stores/status';
import {StatusCategory} from '@shared/models/Status';
import React from 'react';
import {tv} from 'tailwind-variants';

export interface StatusLabelProps {
  id?: number;
  style?: 'indicator' | 'label';
}

export const statusBgColor = {
  NotStarted: 'bg-gray-200',
  InProgress: 'bg-blue-100',
  Done: 'bg-green-100',
};

export const statusFgColor = {
  NotStarted: 'text-gray-700',
  InProgress: 'text-blue-700',
  Done: 'text-green-700',
};

const icons = {
  [StatusCategory.Unknown]: <IconStatusNotStarted />,
  [StatusCategory.NotStarted]: <IconStatusNotStarted />,
  [StatusCategory.InProgress]: <IconStatusInProgress />,
  [StatusCategory.Done]: <IconStatusComplete />,
};

const styles = tv({
  slots: {
    label:
      'transition-color-opacity line-clamp-1 w-fit max-w-56 break-all rounded-sm p-[2px] px-2 text-xs font-semibold',
    indicator: 'transition-color-opacity',
  },
  variants: {
    category: {
      [StatusCategory.Unknown]: {
        label: 'bg-pink-200 text-pink-700 opacity-60 group-hover/button:opacity-100',
        indicator: 'text-pink-600/50 opacity-50 group-hover/button:opacity-100',
      },
      [StatusCategory.NotStarted]: {
        label: 'bg-gray-200 text-gray-700',
        indicator: 'text-gray-600/30',
      },
      [StatusCategory.InProgress]: {
        label: 'bg-blue-100 text-blue-700',
        indicator: 'text-blue-500',
      },
      [StatusCategory.Done]: {
        label: 'bg-green-100 text-green-700',
        indicator: 'text-green-600/80',
      },
    },
  },
});

export const StatusLabel: React.FC<StatusLabelProps> = ({id, style}) => {
  const status = statusStore.use((s) => s.getById(id), [id]);
  const category = status?.category ?? StatusCategory.Unknown;
  const {label, indicator} = styles({category});

  if (style === 'indicator') {
    return <div className={indicator()}>{icons[category]}</div>;
  }
  return <div className={label()}>{status?.name}</div>;
};
