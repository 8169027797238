import {attachmentStore} from '@/stores/attachment';
import {authStore} from '@/stores/auth';
import {externalAccountStore} from '@/stores/externalAccount';
import type {ExternalIdRef, InternalIdRef} from '@/stores/idMapping';
import {idMappingStore} from '@/stores/idMapping';
import {taskStore} from '@/stores/task';
import type {MediaNodeAttrs} from '@/text-editor/extensions/media/Media';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import {ExternalService} from '@shared/models/IdMapping';
import type {JSONContent} from '@tiptap/core';

const JIRA_TO_MOMENTUM_MARKS: Record<string, string> = {
  // ADF -> Momentum
  strong: 'bold',
  textColor: 'textStyle',
  em: 'italic',
};

const MOMENTUM_TO_JIRA_MARKS: Record<string, string> = Object.fromEntries(
  Object.entries(JIRA_TO_MOMENTUM_MARKS).map(([k, v]) => [v, k]),
);

const SUPPORTED_MARKS = [
  'bold',
  'code',
  'italic',
  'strike',
  'superscript',
  'subscript',
  'textStyle',
  'underline',
  'link',
];
const SUPPORTED_NODES = [
  'paragraph',
  'blockquote',
  'bulletList',
  'doc',
  'hardBreak',
  'heading',
  'listItem',
  'orderedList',
  'text',
  'codeBlock',
  'mention',
  'inlineCard',
  'rule',
  'emoji',
  'mediaGroup',
  'mediaSingle',
  'media',
  'panel',
  'status',
];

const MOMENTUM_ONLY_MARKS: string[] = [];
const MOMENTUM_ONLY_NODES: string[] = [];

// TODO: define these in a central place
const momentumToJiraColors: Record<string, string> = {
  // Momentum -> Jira
  '#302C2E': '#172B4D', // black
  '#828081': '#97A0AF', // gray
  '#F7F4F5': '#FFFFFF', // white
  '#2142B1': '#0747A6', // dark blue
  '#3F7BEC': '#4C9AFF', // blue
  '#A1CDFD': '#B3D4FF', // light blue
  '#0078A0': '#008DA6', // dark teal
  '#15A5CD': '#00B8D9', // teal
  '#84CFE4': '#B3F5FF', // light teal
  '#037A23': '#006644', // dark green
  '#12AE60': '#36B37E', // green
  '#A7E4A4': '#ABF5D1', // light green
  '#C65300': '#FF991F', // dark orange
  '#FA8C5D': '#FFC400', // orange
  '#FFDA9E': '#FFF0B3', // light orange
  '#9D0032': '#BF2600', // dark red
  '#D22A54': '#FF5630', // red
  '#FFBDAD': '#FFBDAD', // light red
  '#5800BC': '#403294', // dark purple
  '#7C55D8': '#6554C0', // purple
  '#D6B8FF': '#EAE6FF', // light purple
};

const jiraToMomentumColors = Object.fromEntries(Object.entries(momentumToJiraColors).map(([k, v]) => [v, k]));

export async function adfToMomentum(adf?: JSONContent | string | null, taskId?: number): Promise<JSONContent> {
  if (adf && typeof adf === 'string' && adf !== 'null') {
    try {
      adf = JSON.parse(adf);
    } catch (e) {
      console.warn('Failed to parse ADF', e);
      return {type: 'doc', content: [{type: 'paragraph', content: [{type: 'text', text: adf as string}]}]};
    }
  }

  if (!adf || adf === 'null') {
    return {type: 'doc', content: [{type: 'paragraph', content: []}]};
  }

  const doc: JSONContent = JSON.parse(JSON.stringify(adf));
  delete doc.version;

  return await resolveTransformations(transformNodeToMomentum(doc, taskId));
}

function transformNodeToMomentum(node?: JSONContent, taskId?: number): JSONContent {
  if (!node?.type) return node ?? {};

  if (!SUPPORTED_NODES.includes(node.type)) {
    const originalNode = JSON.stringify(node);
    const text = `[${node.type}]`;
    node.type = 'unsupportedNode';
    node.attrs = {...node.attrs, 'data-unsupported-node': originalNode};
    node.content = [{type: 'text', text}];
  }

  if (node.type === 'mention') {
    node.attrs = {id: IdTransformer.external(EntityType.Actor, node.attrs?.id), label: node.attrs?.text?.slice(1)};
  }

  if (node.type === 'rule') {
    node.type = 'horizontalRule';
  }

  if (node.type === 'orderedList') {
    node.attrs = {start: node.attrs?.order ?? 1};
  }

  if (node.type === 'inlineCard') {
    if (node.attrs?.url.startsWith(externalAccountStore.get().baseUrl + '/browse/')) {
      node.type = 'mention';
      node.attrs = AsyncOperation.create(node.attrs, createTaskMention);
    } else {
      node.type = 'text';
      node.marks = [{type: 'link', attrs: {href: node.attrs?.url, target: '_blank'}}];
      node.text = `[${node.attrs?.url}]`;
    }
  }

  if (node.type === 'mediaGroup' || node.type === 'mediaSingle') {
    const content = node.content?.map((n) => ({
      type: 'media',
      attrs: {
        passthrough: JSON.stringify(n.attrs),
        id: AsyncOperation.create({id: n.attrs?.id as string, taskId}, getMediaId),
        aspect: n.attrs?.width && n.attrs?.width ? n.attrs?.width / n.attrs?.height : 1,
        externalId: n.attrs?.id as string,
      },
    }));
    node = {
      type: 'mediaGroup',
      attrs: {
        passthrough: JSON.stringify(node.attrs),
        layout: node.attrs?.layout,
        width: node.attrs?.width,
      },
      content,
    };
  }

  if (node.marks) {
    node.marks.forEach((mark) => {
      if (mark.type === 'textColor') {
        mark.type = 'textStyle';
        mark.attrs = {...mark.attrs, color: jiraToMomentumColors[mark.attrs?.color?.toUpperCase() ?? '#172B4D']};
      } else if (mark.type === 'subsup') {
        if (mark.attrs?.type === 'sub') {
          mark.type = 'subscript';
        } else if (mark.attrs?.type === 'sup') {
          mark.type = 'superscript';
        }
      } else if (JIRA_TO_MOMENTUM_MARKS[mark.type]) {
        mark.type = JIRA_TO_MOMENTUM_MARKS[mark.type];
      } else if (!SUPPORTED_MARKS.includes(mark.type)) {
        // unsupported marks get wrapped in an unsupportedMark so we can unwrap them when we convert back to ADF
        const originalMark = JSON.stringify(mark);
        mark.type = 'unsupportedMark';
        mark.attrs = {
          ...mark.attrs,
          'data-unsupported-mark': originalMark,
        };
      }
    });
  }

  if (node.content) {
    node.content = node.content.map((n) => transformNodeToMomentum(n, taskId));
  }

  Object.keys(node).forEach((key) => {
    // remove empty arrays
    if (Array.isArray(node[key]) && node[key].length === 0) {
      delete node[key];
    }
  });

  return node;
}

export async function momentumToAdf(node: JSONContent, taskId?: number): Promise<JSONContent> {
  const doc: JSONContent = JSON.parse(JSON.stringify(node));
  idMappingStore;

  doc.content = (doc.content?.map((n) => transformNodeToAdf(n, taskId)).filter(Boolean) as JSONContent[]) ?? [];
  doc.version = 1;

  return await resolveTransformations(doc);
}

function transformNodeToAdf(node?: JSONContent, taskId?: number): JSONContent | null {
  if (!node?.type) return node ?? {};

  if (MOMENTUM_ONLY_NODES.includes(node.type)) {
    return null;
  }

  if (node.type === 'unsupportedNode') {
    return JSON.parse(node.attrs?.['data-unsupported-node'] ?? 'null');
  }

  if (node.type === 'mention' && node.attrs?.entity === EntityType.Task) {
    const key = node.attrs?.label.split(':')[0];
    return {
      type: 'inlineCard',
      attrs: {
        url: externalAccountStore.get().baseUrl + '/browse/' + key,
      },
    };
  } else if (node.type === 'mention') {
    return {
      type: 'mention',
      attrs: {
        id: IdTransformer.internal(EntityType.Actor, node.attrs?.id),
        text: `@${node.attrs?.label}`,
      },
    };
  }

  if (node.type === 'emoji') {
    return {
      type: 'emoji',
      attrs: {
        shortName: '',
        id: node.attrs?.id,
        text: node.attrs?.text,
      },
    };
  }

  if (node.type === 'mediaGroup') {
    const isSingle = node.attrs?.layout || node.attrs?.width;
    const content = node.content?.map((n) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {aspect, id, externalId, ...attrs} = n.attrs as MediaNodeAttrs;
      return {
        type: 'media',
        attrs: {
          id: AsyncOperation.create({id: n.attrs?.id as number, taskId}, getMediaId),
          ...JSON.parse(attrs.passthrough || '{}'),
        },
      };
    });
    return {
      type: isSingle ? 'mediaSingle' : 'mediaGroup',
      ...(isSingle
        ? {
            attrs: {
              layout: node.attrs?.layout,
              width: node.attrs?.width || undefined,
              ...JSON.parse(node.attrs?.passthrough || '{}'),
            },
          }
        : {}),
      content,
    };
  }

  if (node.type === 'horizontalRule') {
    return {type: 'rule'};
  }

  if (node.type === 'orderedList') {
    node.attrs = {order: node.attrs?.start ?? 1};
  }

  if (node.marks) {
    node.marks = node.marks
      .map((mark) => {
        if (mark.type === 'textStyle') {
          return {
            type: 'textColor',
            attrs: {color: (momentumToJiraColors[mark.attrs?.color ?? '#302C2E'] ?? '#172B4D').toLowerCase()},
          };
        } else if (mark.type === 'subscript') {
          return {
            type: 'subsup',
            attrs: {type: 'sub'},
          };
        } else if (mark.type === 'superscript') {
          return {
            type: 'subsup',
            attrs: {type: 'sup'},
          };
        } else if (mark.type === 'link') {
          return {
            type: 'link',
            attrs: {
              href: mark.attrs?.href,
            },
          };
        } else if (MOMENTUM_TO_JIRA_MARKS[mark.type]) {
          mark.type = MOMENTUM_TO_JIRA_MARKS[mark.type];
        } else if (mark.type === 'unsupportedMark') {
          const originalMark = JSON.parse(mark.attrs?.['data-unsupported-mark'] ?? 'null');
          if (!originalMark) return null;
          return originalMark;
        } else if (MOMENTUM_ONLY_MARKS.includes(mark.type)) {
          return null;
        }
        return mark;
      })
      .filter(Boolean);
  }

  if (node.content) {
    node.content = node.content.map((n) => transformNodeToAdf(n, taskId)).filter(Boolean) as JSONContent[];
  }

  return node;
}

class AsyncTransformer {}

class AsyncOperation<I = never, O = never> extends AsyncTransformer {
  public static create<I = never, O = never>(input: I, operation: (input: I) => Promise<O>): AsyncOperation<I, O> {
    return new AsyncOperation<I, O>(input, operation);
  }

  private constructor(
    private input: I,
    private operation: (input: I) => Promise<O>,
  ) {
    super();
  }

  public async transform(): Promise<O> {
    return await this.operation(this.input);
  }
}

class IdTransformer extends AsyncTransformer {
  constructor(
    private internalIdRef: InternalIdRef | null,
    private externalIdRef: ExternalIdRef | null,
  ) {
    super();
  }

  public static create(ref: InternalIdRef | ExternalIdRef) {
    if ('internalId' in ref) {
      return new IdTransformer(ref, null);
    } else {
      return new IdTransformer(null, ref);
    }
  }

  public static internal(entity: EntityType, internalId: number) {
    return new IdTransformer(
      {service: ExternalService.AtlassianJira, accountId: authStore.accountId, entity, internalId},
      null,
    );
  }

  public static external(entity: EntityType, externalId: string) {
    return new IdTransformer(null, {
      service: ExternalService.AtlassianJira,
      accountId: authStore.accountId,
      entity,
      externalId,
    });
  }

  public get ref(): InternalIdRef | ExternalIdRef | null {
    return this.internalIdRef ?? this.externalIdRef;
  }
}

async function resolveTransformations(doc: JSONContent): Promise<JSONContent> {
  const transformations = Array.from(transformers(doc));
  const idTransformations = transformations.filter(([, , value]) => value instanceof IdTransformer) as [
    object,
    string,
    IdTransformer,
  ][];
  const asyncOperations = transformations.filter(([, , value]) => value instanceof AsyncOperation) as [
    object,
    string,
    AsyncOperation,
  ][];

  // kick off all async asyncOperations
  const promises = asyncOperations.map(
    ([obj, key, value]) =>
      new Promise<[object, string, any]>((resolve) => value.transform().then((r) => resolve([obj, key, r]))),
  );

  if (idTransformations.length) {
    try {
      const refMap = await idMappingStore.get(idTransformations.map(([, , value]) => value.ref!));
      for (const [node, key, value] of idTransformations) {
        const ref = value.ref!;
        (node as any)[key] = 'internalId' in ref ? refMap.getExternalId(ref) : refMap.getInternalId(ref);
      }
    } catch (e) {
      console.error(e);
      throw new Error('Failed to resolve necessary IDs to transform ADF to Momentum');
    }
  }

  // wait for all async asyncOperations to complete
  for (const promise of promises) {
    const [node, key, value] = await promise;
    (node as any)[key] = value;
  }

  return doc;
}

/**
 * Recursive generator to iterate all IdTransformers in the tree
 * @param node the node to iterate
 * @yields [node, key, value] tuples of each IdTransformer found in the tree
 */
function* transformers(node: JSONContent | any): Generator<[object, string | number, AsyncTransformer]> {
  if (node instanceof Array) {
    for (let i = 0; i < node.length; i++) {
      const child = node[i];
      if (child instanceof AsyncTransformer) {
        yield [node, i, child];
      } else if (child instanceof Object) {
        yield* transformers(child);
      }
    }
  } else if (node instanceof Object) {
    for (const [key, value] of Object.entries(node)) {
      if (value instanceof AsyncTransformer) {
        yield [node, key, value];
      } else if (value instanceof Object) {
        yield* transformers(value);
      }
    }
  }
}

async function createTaskMention(attrs: any) {
  const key = attrs?.url.slice(externalAccountStore.get().baseUrl.length + 8);
  const task = await taskStore.await(
    (s) =>
      s
        .getList(Filters.taskFilter({key}))
        ?.slice(0, 1)
        ?.map((r) => s.getById(r.id))?.[0],
  );

  return {entity: EntityType.Task, id: task?.id.toString(), label: `${task?.key}: ${task?.title}`};
}

async function getMediaId({
  id,
  taskId,
  fallback = undefined,
}: {
  id: string | number;
  taskId?: number;
  fallback?: string | number;
}) {
  let otherId: number | string = id;
  try {
    if (typeof id === 'string') {
      const attachment = await attachmentStore.await((s) => {
        if (taskId === undefined) return null;
        const list = s.getList(Filters.attachmentFilter({taskId}))?.map((r) => s.getById(r.id));
        if (!list || list.some((a) => a === undefined)) return undefined; // ensure all attachments are loaded
        return list.find((a) => a?.externalId === id) ?? null;
      });
      if (attachment) {
        otherId = attachment.id;
      } else if (fallback) {
        otherId = fallback;
      } else {
        throw new Error('Attachment not found by externalId');
      }
    } else {
      const attachment = await attachmentStore.await((s) => s.getById(id));
      if (attachment && attachment.externalId) {
        otherId = attachment.externalId;
      } else if (fallback) {
        otherId = fallback;
      } else {
        throw new Error('Attachment externalId not found');
      }
    }
  } catch (e) {
    console.error(e);
    return 0;
  }
  return otherId;
}
