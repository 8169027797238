import {post} from '@/lib/api';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import type {EntityType} from '@shared/EntityType';
import type {ExternalService} from '@shared/models/IdMapping';

export interface InternalIdRef {
  accountId: number;
  service: ExternalService;
  entity: EntityType;
  internalId: number;
}

export interface ExternalIdRef {
  accountId: number;
  service: ExternalService;
  entity: EntityType;
  externalId: string;
}

class RefMap {
  public constructor(private map: Record<string, string | number>) {}

  public getInternalId(ref: ExternalIdRef): number | undefined {
    return this.map[refToString(ref)] as number | undefined;
  }

  public getExternalId(ref: InternalIdRef): string | undefined {
    return this.map[refToString(ref)] as string | undefined;
  }
}

class IdMappingStore {
  public async get(refs: (InternalIdRef | ExternalIdRef)[]): Promise<RefMap> {
    const internalIdRefs = refs.filter((r) => 'internalId' in r);
    const externalIdRefs = refs.filter((r) => 'externalId' in r);

    const {internalIds, externalIds} = await post<{internalIds: number[]; externalIds: []}>('/id-mapping/list', {
      body: JSON.stringify({
        internalIdRefs,
        externalIdRefs,
      }),
    });

    DEV &&
      internalIds.length !== externalIdRefs.length &&
      console.error('internalIds.length !== internalIdRefs.length');
    DEV &&
      externalIds.length !== internalIdRefs.length &&
      console.error('externalIds.length !== externalIdRefs.length');

    const map: Record<string, string | number> = {};
    internalIdRefs.forEach((ref, i) => {
      map[refToString(ref)] = externalIds[i];
    });
    externalIdRefs.forEach((ref, i) => {
      map[refToString(ref)] = internalIds[i];
    });

    return new RefMap(map);
  }
}

export function refToString(ref: InternalIdRef | ExternalIdRef): string {
  if ('internalId' in ref) {
    return `I:${ref.accountId}:${ref.service}:${ref.entity}:${ref.internalId}`;
  } else {
    return `E:${ref.accountId}:${ref.service}:${ref.entity}:${ref.externalId}`;
  }
}

export const idMappingStore = persistThroughHotReload('idMappingStore', new IdMappingStore());
exposeInDev({idMappingStore});
import.meta.hot?.accept(reloadOnHotUpdate);
