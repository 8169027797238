import {useRef, useState} from 'react';
import {useFocusWithin} from 'react-aria';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isDisabled?: boolean;
}

const styles = tv({
  base: 'flex h-full cursor-pointer select-none flex-row items-center rounded-sm border border-gray-500/15 bg-white/80 transition-colors hover:bg-white',
  variants: {
    isFocusWithin: {
      true: 'bg-white',
    },
    isDisabled: {
      true: 'pointer-events-none select-none bg-gray-50 opacity-50',
    },
  },
});

export const SelectWrapper: React.FC<Props> = ({isDisabled, className, children, ...props}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isFocusWithin, setFocusWithin] = useState(false);
  const {focusWithinProps} = useFocusWithin({onFocusWithinChange: setFocusWithin});

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const input = e.currentTarget.querySelector('input');
    if (input) {
      input.focus();
    } else {
      const button = e.currentTarget.querySelector('button');
      if (button) {
        button.focus();
        button.click();
      }
    }
    props.onClick?.(e);
  };

  return (
    <div
      ref={ref}
      {...focusWithinProps}
      {...props}
      className={twMerge(styles({isFocusWithin, isDisabled}), className)}
      onClick={handleClick}
    >
      {children}
      <div className="relative h-[6px]">
        <svg
          className="absolute right-2 top-0"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.79289 0.5H1.20711C0.761654 0.5 0.53857 1.03857 0.853553 1.35355L4.64645 5.14645C4.84171 5.34171 5.15829 5.34171 5.35355 5.14645L9.14645 1.35355C9.46143 1.03857 9.23835 0.5 8.79289 0.5Z"
            fill="#333333"
          />
        </svg>
      </div>
    </div>
  );
};
