import BoldIcon from '@/assets/text-editor/bold.svg?react';
import InlineCodeIcon from '@/assets/text-editor/inline-code.svg?react';
import ItalicIcon from '@/assets/text-editor/italic.svg?react';
import StrikethroughIcon from '@/assets/text-editor/strikethrough.svg?react';
import SubscriptIcon from '@/assets/text-editor/subscript.svg?react';
import SuperscriptIcon from '@/assets/text-editor/superscript.svg?react';
import TextColorIcon from '@/assets/text-editor/text-color.svg?react';
import UnderlineIcon from '@/assets/text-editor/underline.svg?react';
import {Popover} from '@/design-system';
import type {Editor} from '@tiptap/react';
import {useMemo} from 'react';
import {Button, MenuTrigger} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface Props {
  editor: Editor;
}

export const formattingMenuStyles = tv({
  slots: {
    base: 'flex items-center gap-0.5 rounded-md border border-black/20 bg-white px-1 py-0.5 text-black/80 shadow-md',
    button: 'flex min-w-7 items-center justify-center rounded-sm p-1 hover:bg-gray-50',
    icon: 'h-5 w-5',
    divider: 'mx-0.5 min-h-5 border-l border-black/20',
  },
  variants: {
    active: {
      true: {
        button: 'bg-purple-200 hover:bg-purple-100',
      },
    },
  },
});

export const FormattingMenu: React.FC<Props> = ({editor}) => {
  const {base, button, icon} = formattingMenuStyles();

  const commands = useMemo(
    () => ({
      bold: () => editor.chain().focus().toggleBold().run(),
      italic: () => editor.chain().focus().toggleItalic().run(),
      underline: () => editor.chain().focus().toggleUnderline().run(),
      strike: () => editor.chain().focus().toggleStrike().run(),
      code: () => editor.chain().focus().toggleCode().run(),
      superscript: () => {
        if (editor.isActive('subscript')) {
          editor.chain().focus().toggleSubscript().run();
        }
        editor.chain().focus().toggleSuperscript().run();
      },
      subscript: () => {
        if (editor.isActive('superscript')) {
          editor.chain().focus().toggleSuperscript().run();
        }
        editor.chain().focus().toggleSubscript().run();
      },
      textColor: () => void 0,
    }),
    [editor],
  );

  return (
    <div className={base()}>
      <Button onPress={commands.bold} className={button({active: editor.isActive('bold')})}>
        <BoldIcon className={icon()} />
      </Button>
      <Button onPress={commands.italic} className={button({active: editor.isActive('italic')})}>
        <ItalicIcon className={icon()} />
      </Button>
      <Button onPress={commands.underline} className={button({active: editor.isActive('underline')})}>
        <UnderlineIcon className={icon()} />
      </Button>
      <Button onPress={commands.strike} className={button({active: editor.isActive('strike')})}>
        <StrikethroughIcon className={icon()} />
      </Button>
      <Button onPress={commands.code} className={button({active: editor.isActive('code')})}>
        <InlineCodeIcon className={icon()} />
      </Button>
      <Button onPress={commands.superscript} className={button({active: editor.isActive('superscript')})}>
        <SuperscriptIcon className={icon()} />
      </Button>
      <Button onPress={commands.subscript} className={button({active: editor.isActive('subscript')})}>
        <SubscriptIcon className={icon()} />
      </Button>
      <TextColorButton editor={editor} />
    </div>
  );
};

function TextColorButton({editor}: Props) {
  const {button, icon} = formattingMenuStyles({active: editor.isActive('textColor')});

  return (
    <MenuTrigger aria-label="Text color">
      <Button onPress={() => void 0} className={button()}>
        <TextColorIcon className={icon()} />
      </Button>
      <Popover placement="top right">
        <TextColorMenu editor={editor} />
      </Popover>
    </MenuTrigger>
  );
}

const colors = [
  ['#302C2E', '#828081', '#F7F4F5'], // grays
  ['#2142B1', '#3F7BEC', '#A1CDFD'], // blues
  ['#0078A0', '#15A5CD', '#84CFE4'], // teals
  ['#037A23', '#12AE60', '#A7E4A4'], // greens
  ['#C65300', '#FA8C5D', '#FFDA9E'], // oranges
  ['#9D0032', '#D22A54', '#FFABC6'], // reds
  ['#5800BC', '#7C55D8', '#D6B8FF'], // purples
];

function TextColorMenu({editor}: Props) {
  const {button, icon} = formattingMenuStyles();
  const currentColor = editor.getAttributes('textStyle')?.color ?? '#302C2E';

  return (
    <div className="flex flex-row p-2">
      {colors.map((trio, i) => (
        <div key={i} className="flex flex-col">
          {trio.map((color, j) => (
            <Button
              key={j}
              onPress={() => editor.chain().focus().setColor(color).run()}
              className={button({active: currentColor === color})}
              autoFocus={currentColor === color}
            >
              <ColorIcon className={icon()} color={color} />
            </Button>
          ))}
        </div>
      ))}
    </div>
  );
}

const ColorIcon: React.FC<{color: string; className?: string}> = ({color, className}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      className={className}
    >
      <rect x="0" y="0" width="24" height="24" rx="4" ry="4"></rect>
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="4"
        ry="4"
        fill="none"
        stroke={color}
        filter="brightness(85%)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></rect>
    </svg>
  );
};
