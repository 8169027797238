import {AutoSizer} from '@/components/AutoSizer';
import {MessageListItem} from '@/components/inbox/MessageListItem';
import {inboxMessageStore} from '@/stores/inboxMessage';
import {Filters} from '@shared/filters/Filters';
import React from 'react';
import {FixedSizeList} from 'react-window';

interface Props {
  projectId?: number;
  onClick: (id: number) => void;
  onMarkUnread: (id: number) => void;
  selected?: number;
}

export const MessageList: React.FC<Props> = ({projectId, onClick, onMarkUnread, selected}) => {
  const unreadIds =
    inboxMessageStore.use((s) => s.getList(Filters.inboxMessageFilter({projectId, read: false})), [projectId]) ?? [];
  const readIds =
    inboxMessageStore.use((s) => s.getList(Filters.inboxMessageFilter({projectId, read: true})), [projectId]) ?? [];
  const ids = unreadIds.concat([{id: 0, rank: ''}]).concat(readIds);
  const count = (unreadIds?.length ?? 0) + (readIds?.length ?? 0) + 1;

  if (count === 1) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center text-pink-900 text-opacity-25">
        No messages
      </div>
    );
  }

  return (
    <div className="h-full w-full overflow-hidden">
      <AutoSizer>
        {({width, height}) => (
          <FixedSizeList height={height} width={width} itemCount={count} itemData={ids} itemSize={90}>
            {({data, index, style}) => (
              <div style={style} key={index}>
                {index === unreadIds.length ? (
                  <div className="flex h-full items-end justify-center text-xs font-semibold text-pink-900 text-opacity-50">
                    — RECENTLY READ —
                  </div>
                ) : (
                  <div className="p-4">
                    <MessageListItem
                      id={data[index].id}
                      selected={selected === data[index].id}
                      onClick={onClick}
                      onMarkUnread={onMarkUnread}
                    />
                  </div>
                )}
              </div>
            )}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
};
