import type {TextInputProps} from '@/design-system';
import {Popover, TextInput} from '@/design-system';
import {useDebouncedState} from '@/hooks/useDebouncedState';
import type {ForwardedRef, RefAttributes} from 'react';
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useFocusWithin} from 'react-aria';
import {ListBox} from 'react-aria-components';

export interface Suggestion {
  id: number;
  textValue: string;
}

type InputProps = TextInputProps & RefAttributes<HTMLInputElement>;

export interface TypeaheadProps<T extends Suggestion> extends InputProps {
  getSuggestions: (value: string, resolve: (suggestions: T[]) => void) => void;
  className?: string;
  inputClassName?: string;
  crossOffset?: number;
  itemRenderer: (item: T, onSelect: (suggestion: T) => void) => React.ReactNode;
  onAction?: (suggestion: T) => void;
  liveFilter?: (value: string, suggestion: T) => boolean;
  postProcess?: (rawValue: string, suggestions: T[]) => T[];
  inputFilter?: (value: string) => string;
}

export const startsWithFilter = <T extends Suggestion>(value: string, suggestion: T): boolean => {
  return suggestion.textValue.toLowerCase().startsWith(value.trim().toLowerCase());
};

export const containsFilter = <T extends Suggestion>(value: string, suggestion: T): boolean => {
  return suggestion.textValue.toLowerCase().includes(value.trim().toLowerCase());
};

export const Typeahead = forwardRef<HTMLInputElement, TypeaheadProps<Suggestion>>(function Typeahead<
  T extends Suggestion,
>(
  {
    getSuggestions,
    itemRenderer,
    className,
    inputClassName,
    crossOffset,
    onAction,
    liveFilter,
    postProcess,
    inputFilter,
    ...inputProps
  }: TypeaheadProps<T>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [rawValue, setRawValue] = useState('');
  const [value, setValue] = useDebouncedState('', 200);
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  useImperativeHandle(ref, () => inputRef.current!);

  const onSelect = useCallback(
    (suggestion: T) => {
      setValue('');
      setSuggestions([]);
      setRawValue('');
      onAction?.(suggestion);
    },
    [onAction],
  );

  useEffect(() => {
    if (isInputFocused) {
      getSuggestions(value, setSuggestions);
    }
  }, [value, isInputFocused, getSuggestions]);

  const {focusWithinProps} = useFocusWithin({onFocusWithinChange: setIsInputFocused});

  const listRef = useRef<HTMLDivElement>(null);
  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setValue('');
      setRawValue('');
      setSuggestions([]);
    } else if (event.key === 'ArrowDown') {
      const firstOption = listRef.current?.querySelector('div[role="option"]:first-of-type') as HTMLElement | null;
      firstOption?.focus();
    } else if (event.key === 'ArrowUp') {
      const lastOption = listRef.current?.querySelector('div[role="option"]:last-of-type') as HTMLElement | null;
      lastOption?.focus();
    }
  }, []);

  const onKeyDownCapture = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        setValue('');
        setRawValue('');
        setSuggestions([]);
        inputRef.current?.focus();
      }
    },
    [suggestions],
  );

  const filteredSuggestions = (postProcess ?? ((_, r) => r))(
    rawValue,
    suggestions.filter((s) => (liveFilter ?? containsFilter)(rawValue, s)),
  );

  return (
    <div
      {...focusWithinProps}
      className={`flex flex-grow items-center ${className ?? ''}`}
      onKeyDownCapture={onKeyDownCapture}
    >
      <TextInput
        {...inputProps}
        ref={inputRef}
        className={`w-16 flex-grow bg-transparent px-1 !ring-0 ${inputClassName ?? ''}`}
        onAbort={() => setValue('')}
        onValueChange={setValue}
        onChange={(e) => setRawValue(inputFilter?.(e.currentTarget.value) ?? e.currentTarget.value)}
        value={rawValue}
        onKeyDown={onKeyDown}
      />
      <Popover
        placement="bottom left"
        crossOffset={crossOffset}
        isOpen={isInputFocused && filteredSuggestions.length > 0}
        triggerRef={inputRef}
        isNonModal
        noScroll={false}
      >
        <ListBox ref={listRef} className="border-none py-2" aria-label="Tag Suggestions" shouldFocusWrap>
          {filteredSuggestions.map((s) => itemRenderer(s, onSelect))}
        </ListBox>
      </Popover>
    </div>
  );
}) as <T extends Suggestion>(props: TypeaheadProps<T>) => React.ReactElement;
