import {ChevronRightIcon} from 'lucide-react';
import type {MenuItemProps as AriaMenuItemProps} from 'react-aria-components';
import {MenuItem as AriaMenuItem, composeRenderProps} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

export interface MenuItemProps extends AriaMenuItemProps {
  isCurrent?: boolean;
}

const itemStyles = tv({
  base: 'flex min-h-6 cursor-pointer items-center gap-1 px-3 py-1 text-sm text-gray-900/80',
  variants: {
    isCurrent: {
      true: 'bg-purple-500/20 font-medium text-purple-950/80',
    },
    isHovered: {
      true: 'bg-pink-500/5',
    },
    isFocused: {
      true: 'bg-pink-500/15 text-pink-900/80',
    },
    isSelected: {
      true: 'bg-pink-500/15',
    },
    hasSubMenu: {
      true: 'pr-10',
    },
    isDisabled: {
      true: 'opacity-50 grayscale filter',
    },
  },
});

export function MenuItem({children, className, isCurrent, ...props}: MenuItemProps) {
  return (
    <AriaMenuItem
      {...props}
      className={composeRenderProps(className, (className, p) =>
        twMerge(itemStyles({...p, hasSubMenu: false, isCurrent}), className),
      )}
    >
      {(renderProps) => {
        const {hasSubmenu} = renderProps;
        if (!hasSubmenu) return typeof children === 'function' ? children(renderProps) : children;
        return (
          <div className="flex w-full items-center justify-between gap-2">
            {typeof children === 'function' ? children(renderProps) : children}
            <ChevronRightIcon className={`-mr-1 h-4 w-4`} />
          </div>
        );
      }}
    </AriaMenuItem>
  );
}
