import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {InboxMessage} from '@shared/models/InboxMessage';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const inboxMessageStore = persistThroughHotReload(
  'inboxMessageStore',
  new EntityStore<EntityStoreState<InboxMessage>, InboxMessage>(EntityType.InboxMessage, {
    byId: new Map(),
    byList: new Map(),
  }).extend(function () {
    return {
      getIdByTaskId: (taskId: number) => {
        return this.getList(Filters.inboxMessageFilter({taskId}))?.[0]?.id;
      },
      mark: (messageId: number, read: boolean) => {
        this.mutate((store) => {
          const message = store.getById(messageId);
          if (message) {
            store.setById(messageId, {...message, readAt: read ? new Date().toISOString() : undefined});
          }
        });
      },
    };
  }),
);
exposeInDev({inboxMessageStore});
import.meta.hot?.accept(reloadOnHotUpdate);
