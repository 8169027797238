import iconActorUnknown from '@/assets/icon-actor-unknown.svg';
import IconUnassigned from '@/assets/icon-unassigned.svg?react';
import {Image} from '@/components/Image';
import {actorStore} from '@/stores/actor';
import React from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

export interface ActorAvatarProps {
  actorId?: number;
  size?: 'sm' | 'md' | 'lg';
  style?: 'normal' | 'semibold' | 'thin';
  showName?: boolean;
  emptyName?: string;
  // optionally provide these props to avoid loading from the store
  // DO NOT CHANGE THESE PROPS AFTER INITIAL RENDER
  name?: string;
  avatarId?: number;
  className?: string;
}

const styles = tv({
  slots: {
    base: 'flex items-center justify-start gap-1',
    imgContainer: 'flex shrink-0 items-center justify-center overflow-hidden rounded-full',
    img: 'transition-color-opacity h-full w-full ease-linear',
    nameClasses: 'transition-color-opacity ml-1 line-clamp-1 break-all text-gray-600',
  },
  variants: {
    size: {
      sm: {
        imgContainer: 'h-5 w-5',
        nameClasses: 'text-sm',
      },
      md: {
        imgContainer: 'h-6 w-6',
        nameClasses: 'text-base',
      },
      lg: {
        imgContainer: 'h-7 w-7',
        nameClasses: 'text-lg',
      },
    },
    style: {
      normal: {
        nameClasses: 'font-normal',
      },
      semibold: {
        nameClasses: 'font-semibold',
      },
      thin: {
        nameClasses: 'font-thin',
      },
    },
    unassigned: {
      true: {
        img: 'text-pink-700/60 opacity-60 group-hover/button:opacity-100',
        nameClasses: 'text-pink-700/60 opacity-60 group-hover/button:opacity-100',
      },
    },
  },
});

export const ActorAvatar: React.FC<ActorAvatarProps> = ({
  actorId,
  size = 'sm',
  showName,
  style = 'semibold',
  emptyName,
  name,
  avatarId,
  className,
}) => {
  const actor =
    (name && avatarId) || actorId === -1 ? {name, avatarId} : actorStore.use((s) => s.getById(actorId), [actorId]);
  const isNone = actorId === -1 || actorId === undefined;
  style ??= 'normal';
  const {base, imgContainer, img, nameClasses} = styles({size, style, unassigned: isNone});

  const displayName = isNone ? (emptyName ?? 'None') : (actor?.name ?? 'Unknown');

  return (
    <div className={twMerge(base(), className)}>
      <div className={imgContainer()}>
        {isNone && <IconUnassigned className={img()} />}
        {!isNone && actor?.avatarId && <Image id={actor.avatarId} alt={displayName} fallback={iconActorUnknown} />}
        {!isNone && !actor?.avatarId && <img className={img()} src={iconActorUnknown} alt={displayName} />}
      </div>
      {showName && displayName && <div className={nameClasses()}>{displayName}</div>}
    </div>
  );
};
