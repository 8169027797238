import {dragStore} from '@/design-system/dnd/dragStore';
import {mouseMonitor} from '@/lib/MouseMonitor';
import {isStateEqual} from '@shared/lib/isStateEqual';
import {useEffect, useRef, useState} from 'react';
import type {positionValues, ScrollbarProps} from 'react-custom-scrollbars-2';
import Scrollbars from 'react-custom-scrollbars-2';
import {twMerge} from 'tailwind-merge';

export interface ScrollViewRenderProps {
  hasMoreTop: boolean;
  hasMoreBottom: boolean;
  hasMoreLeft: boolean;
  hasMoreRight: boolean;
}

const DEFAULT_RENDER_PROPS: ScrollViewRenderProps = {
  hasMoreTop: false,
  hasMoreBottom: false,
  hasMoreLeft: false,
  hasMoreRight: false,
};

interface Props extends Omit<ScrollbarProps, 'children'> {
  children: React.ReactNode | ((props: ScrollViewRenderProps) => React.ReactNode);
  dropTypes?: string[]; // note: cannot change after mount
}

export function ScrollView({children, dropTypes = ['momentum/task'], ...props}: Props) {
  const [renderProps, setRenderProps] = useState<ScrollViewRenderProps>(DEFAULT_RENDER_PROPS);
  const onScrollFrame = (values: positionValues) => {
    const newRenderProps: ScrollViewRenderProps = {
      hasMoreTop: values.scrollTop > 5,
      hasMoreBottom: values.scrollTop + values.clientHeight + 5 < values.scrollHeight,
      hasMoreLeft: values.scrollLeft > 5,
      hasMoreRight: values.scrollLeft + values.clientWidth + 5 < values.scrollWidth,
    };
    if (isStateEqual(newRenderProps, renderProps)) return;
    setRenderProps(newRenderProps);
    props.onScrollFrame?.(values);
  };

  const scrollRef = useRef<Scrollbars>(null);
  useEffect(() => {
    const node: HTMLDivElement | null = scrollRef.current?.container.children[0] as HTMLDivElement | null;
    if (node) {
      node.classList.add('scroll-p-5');
    }
  }, [scrollRef.current?.container]);

  // ensure we update the render props when the dom changes / view resizes
  useEffect(() => {
    if (!scrollRef.current) return;
    const handler = () => scrollRef.current && onScrollFrame(scrollRef.current.getValues());

    const mutationObserver = new MutationObserver(handler);
    mutationObserver.observe(scrollRef.current.container, {subtree: true, childList: true});

    const resizeObserver = new ResizeObserver(handler);
    resizeObserver.observe(scrollRef.current.container);

    onScrollFrame(scrollRef.current.getValues());

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [scrollRef.current, onScrollFrame]);

  useEffect(() => autoScrollWhenDragging(scrollRef.current, dropTypes), []);

  return (
    <Scrollbars
      autoHide
      {...props}
      onScrollFrame={onScrollFrame} // overflow-clip fixes an issue with the content shifting over by the right margin
      className={twMerge('!overflow-clip', renderProps.hasMoreBottom && 'gradient-mask-b', props.className)}
      ref={scrollRef}
    >
      {typeof children === 'function' ? children(renderProps) : children}
    </Scrollbars>
  );
}

function autoScrollWhenDragging(scrollRef: Scrollbars | null, dropTypes: string[]) {
  let unsub: () => void = () => void 0;
  const handler = (isDragging: boolean) => {
    if (!isDragging || !dropTypes.some((t) => dragStore.state.dragTypes.includes(t))) {
      unsub();
      unsub = () => void 0;
      return;
    }
    let movement = {x: 0, y: 0};
    let lastTime = performance.now();
    function frame(t: DOMHighResTimeStamp) {
      const dt = (t - lastTime) / 1000;
      const speed = 2000;
      lastTime = t;
      if (movement.x != 0 || movement.y != 0) {
        const el = scrollRef?.container.children[0] as HTMLDivElement | null;
        if (el) {
          el.scrollBy({left: movement.x * dt * speed, top: movement.y * dt * speed});
        }
      }

      if (dragStore.state.isDragging) {
        requestAnimationFrame(frame);
      }
    }
    requestAnimationFrame(frame);
    unsub = mouseMonitor.on('drag', (position) => {
      const el = scrollRef?.container.children[0] as HTMLDivElement | null;

      if (scrollRef && el) {
        // convert absolute position to relative position
        const rect = el.getBoundingClientRect();
        const insideContainer = position.x >= rect.left && position.x <= rect.right && position.y >= rect.top;
        if (!insideContainer) {
          movement.x = 0;
          movement.y = 0;
          return;
        }

        const xInset = Math.min(175, rect.width / 4);
        const yInset = Math.min(175, rect.height / 4);

        if (position.x < rect.left + xInset) {
          movement.x = (position.x - (rect.left + xInset)) / xInset;
        } else if (position.x > rect.right - xInset) {
          movement.x = (position.x - (rect.right - xInset)) / xInset;
        } else {
          movement.x = 0;
        }

        if (position.y < rect.top + yInset) {
          movement.y = (position.y - (rect.top + yInset)) / yInset;
        } else if (position.y > rect.bottom - yInset) {
          movement.y = (position.y - (rect.bottom - yInset)) / yInset;
        } else {
          movement.y = 0;
        }

        movement.x = Math.sign(movement.x) * Math.pow(Math.abs(movement.x), 3);
        movement.y = Math.sign(movement.y) * Math.pow(Math.abs(movement.y), 3);
      }
    });
  };

  dragStore.selectAndSubscribe((s) => s.state.isDragging, handler);
  return () => {
    dragStore.unsubscribe(handler);
    unsub();
  };
}
