import {Emoji} from '@/text-editor/extensions/emoji/Emoji';
import {Media} from '@/text-editor/extensions/media/Media';
import {MediaGroup} from '@/text-editor/extensions/media/MediaGroup';
import {Mention} from '@/text-editor/extensions/mentions/Mention';
import {MentionRenderer} from '@/text-editor/extensions/mentions/MentionRenderer';
import {suggestion} from '@/text-editor/extensions/mentions/suggestion';
import {Panel} from '@/text-editor/extensions/panel/Panel';
import {Status} from '@/text-editor/extensions/status/Status';
import {UnsupportedMark} from '@/text-editor/extensions/UnsupportedMark';
import {UnsupportedNode} from '@/text-editor/extensions/UnsupportedNode';
import {EntityType} from '@shared/EntityType';
import type {EditorOptions} from '@tiptap/core';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Color from '@tiptap/extension-color';
import {Link} from '@tiptap/extension-link';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextStyle from '@tiptap/extension-text-style';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import {mergeAttributes, useEditor} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {common, createLowlight} from 'lowlight';
import {twMerge} from 'tailwind-merge';

export const MOMENTUM_TIPTAP_EXTENSIONS = [
  StarterKit.configure({
    codeBlock: false,
  }),
  TextStyle,
  Color,
  Underline,
  CodeBlockLowlight.configure({
    HTMLAttributes: {
      class: 'code',
    },
    defaultLanguage: 'javascript',
    lowlight: createLowlight(common),
  }),
  Typography,
  Superscript,
  Subscript,
  UnsupportedMark,
  UnsupportedNode,
  Mention.configure({
    deleteTriggerWithBackspace: true,
    renderText: ({node}) => {
      let prefix = node.attrs.entity === EntityType.Task ? '@@' : '@';
      return `${prefix}${node.attrs.label}`;
    },
    renderHTML: ({options, node}) => [
      'span',
      mergeAttributes(options.HTMLAttributes, {class: 'font-semibold text-pink-600'}),
      `${node.attrs.entity === EntityType.Task ? '@@' : '@'}${node.attrs.label}`,
    ],
    component: MentionRenderer,
    suggestion,
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: 'https',
  }),
  Emoji,
  MediaGroup,
  Media,
  Panel,
  Status,
];

export function useMoEditor(options?: Partial<EditorOptions>, className?: string) {
  return useEditor({
    autofocus: false,
    ...options,
    extensions: [...MOMENTUM_TIPTAP_EXTENSIONS, ...(options?.extensions ?? [])],
    editorProps: {
      attributes: {
        class: twMerge('min-h-10 h-full px-3 py-1 text-[#302C2E] !ring-0', className),
        ...(options?.editorProps?.attributes ?? {}),
      },
      ...(options?.editorProps ?? {}),
    },
  });
}
