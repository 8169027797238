import {mutateTask} from '@/app-service-worker/mutateTask';
import IconListDragHandle from '@/assets/icon-list-drag-handle.svg?react';
import {EditableActor} from '@/components/actor/ActorSelector';
import {EditableEpicLabel} from '@/components/task/EditableEpicLabel';
import {EditableTaskPoints} from '@/components/task/EditableTaskPoints';
import {EditableTaskType} from '@/components/task/EditableTaskType';
import {Spacer} from '@/design-system';
import {AnimateIn} from '@/design-system/AnimateIn';
import {Follow} from '@/design-system/Follow';
import {GridListItem} from '@/design-system/GridListItem';
import {taskStore} from '@/stores/task';
import {BoardUiContext} from '@/stores/ui/boardUiStore';
import {FieldType} from '@shared/models/FieldSchema';
import type {Mutation} from '@shared/models/Mutation';
import type {Task} from '@shared/models/Task';
import {useContext, useRef} from 'react';
import type {GridListItemRenderProps} from 'react-aria-components';
import {Button, type GridListItemProps} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface Props extends GridListItemProps {
  id: number;
  text?: string;
  task?: Task;
}

const styles = tv({
  base: 'flex h-[6.5rem] w-full flex-row rounded-md',
  variants: {
    isSelected: {
      true: 'bg-pink-50 ring-1 ring-pink-500/30',
    },
  },
});

export function TaskCard({id, text, ...props}: Props) {
  const boardUiStore = useContext(BoardUiContext).store;
  const ref = useRef<HTMLDivElement>(null);
  return (
    <GridListItem
      selectOnPressUp
      {...props}
      id={id}
      textValue={text ?? id.toString()}
      className={styles}
      onAction={() => boardUiStore.openTask(id)}
      ref={ref}
    >
      {(renderProps) => (
        <Follow follow={ref} fixedHeight={104}>
          <TaskCardContent id={id} text={text} renderProps={renderProps} />
        </Follow>
      )}
    </GridListItem>
  );
}

export function TaskCardDragPreview({id}: Props) {
  return <TaskCardContent id={id} text={id.toString()} />;
}

const cardStyles = tv({
  base: 'group/task flex h-[6.5rem] w-full cursor-default flex-col justify-between rounded-md bg-white p-2 shadow-sm',
  variants: {
    isSelected: {
      true: 'bg-pink-50 ring-1 ring-pink-500/30',
    },
  },
});

function TaskCardContent({id, text, task: providedTask, renderProps}: Props & {renderProps?: GridListItemRenderProps}) {
  const task = taskStore.use((s) => providedTask ?? s.getById(id), [providedTask, id]);

  const handleTaskUpdate = (_value: unknown, operation: Mutation['operations']) => {
    mutateTask(id, operation);
  };

  return (
    <AnimateIn className={cardStyles(renderProps)} animation="fade-in zoom-in-50">
      <div className="relative flex flex-row items-center gap-2">
        <EditableTaskType id={task?.typeId} onCommit={handleTaskUpdate} />
        <EditableTaskPoints points={task?.points} onCommit={handleTaskUpdate} />
        <Spacer className="w-full" />
        <EditableActor
          className="absolute -top-2.5 right-1 border border-white bg-white"
          size="md"
          type={FieldType.Assignee}
          actorId={task?.assigneeId}
          onCommit={handleTaskUpdate}
        />
      </div>
      <div className="line-clamp-2 h-[2.25rem] text-sm leading-tight text-gray-800">{task?.title ?? text}</div>
      <div className="flex flex-row justify-between gap-2">
        <EditableEpicLabel style="label" id={task?.parentId ?? null} onCommit={handleTaskUpdate} />
        <Button slot="drag" className="-my-1 hidden rounded-sm py-1">
          <IconListDragHandle />
        </Button>
      </div>
    </AnimateIn>
  );
}
