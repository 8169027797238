import type {Suggestion, TypeaheadProps} from '@/design-system';
import {SelectableItem, Spacer, Typeahead} from '@/design-system';
import {componentStore} from '@/stores/component';
import {contextStore} from '@/stores/context';
import {labelStore} from '@/stores/label';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import {forwardRef, memo, useEffect, useMemo, useRef} from 'react';

type PartialTypeaheadProps = PartialOmit<TypeaheadProps<TagSuggestion>, 'getSuggestions' | 'itemRenderer'>;
interface Props extends PartialTypeaheadProps {
  labelsToExclude?: number[];
  componentsToExclude?: number[];
}

export interface TagSuggestion extends Suggestion {
  type: EntityType;
}

export const TaskTagTypeahead = forwardRef<HTMLInputElement, Props>(function (
  {labelsToExclude, componentsToExclude, ...props},
  ref,
) {
  const unsubscribe = useRef<() => void>();
  const getSuggestions = useMemo(() => {
    return (value: string, resolve: (suggestions: TagSuggestion[]) => void) => {
      unsubscribe.current?.();

      // kinda ugly, but we need to wait for both labels and components to load before we combine them
      let labels: TagSuggestion[];
      let components: TagSuggestion[];
      const update = () => {
        if (!labels || !components) return;
        const result = [...labels, ...components].sort((a, b) => a.textValue.localeCompare(b.textValue));
        resolve(result);
      };
      const onLabelsUpdate = (result?: TagSuggestion[]) => {
        labels = result ?? labels;
        update();
      };
      const onComponentsUpdate = (result?: TagSuggestion[]) => {
        components = result ?? components;
        update();
      };

      const labelUnsubscribe = labelStore.selectAndSubscribe(
        (s) =>
          s
            .getList(Filters.labelFilter({projectId: contextStore.projectId, startsWith: value}))
            ?.map((l) => s.getById(l.id))
            ?.filter((l) => l != null)
            ?.map((l) => ({id: l.id, textValue: l.name, type: EntityType.Label})) as TagSuggestion[],
        onLabelsUpdate,
      );

      const componentUnsubscribe = componentStore.selectAndSubscribe(
        (s) =>
          s
            .getList(Filters.componentFilter({startsWith: value}))
            ?.filter((c) => !componentsToExclude?.includes(c.id))
            ?.map((c) => s.getById(c.id))
            ?.filter((c) => c != null)
            ?.map((c) => ({id: c.id, textValue: c.name, type: EntityType.Component})) as TagSuggestion[],
        onComponentsUpdate,
      );

      unsubscribe.current = () => {
        labelUnsubscribe();
        componentUnsubscribe();
      };
    };
  }, [componentsToExclude]);

  const postProcess = (rawValue: string, suggestions: TagSuggestion[]) => {
    if (
      rawValue.length > 0 &&
      suggestions.every(
        (s) => s.type === EntityType.Label && s.textValue.toLocaleLowerCase() !== rawValue.toLocaleLowerCase(),
      )
    ) {
      suggestions = [...suggestions, {type: EntityType.Label, id: 0, textValue: rawValue}];
    }

    // we have to exclude labels at the end to decide if we are going to allow the user to add a new tag
    return suggestions.filter((s) => s.type === EntityType.Label && !labelsToExclude?.includes(s.id));
  };

  const inputFilter = (value: string) => value.replace(/( +-* *|-* +)+/g, '-');

  useEffect(() => {
    return () => unsubscribe.current?.();
  }, []);

  return (
    <Typeahead<TagSuggestion>
      ref={ref}
      getSuggestions={getSuggestions}
      postProcess={postProcess}
      inputFilter={inputFilter}
      {...props}
      itemRenderer={(item, onSelect) => <SuggestionItem key={item.id} item={item} onSelect={onSelect} />}
    />
  );
});

interface SuggestionProps {
  item: TagSuggestion;
  onSelect: (value: TagSuggestion) => void;
}

const SuggestionItem = memo(({item, onSelect}: SuggestionProps) => {
  const pillClassNames =
    item.type === EntityType.Component
      ? 'bg-purple-200/50 shadow-[inset_0_0_0_1px_rgba(var(--color-purple-800),0.5)]'
      : 'bg-gray-500/15';
  return (
    <SelectableItem
      key={item.type + ':' + item.id}
      textValue={item.textValue}
      onAction={() => onSelect(item)}
      className="gap-2 text-sm"
    >
      <div className={`h-3 w-5 rounded-full bg-gray-500/15 ${pillClassNames}`} />
      {item.textValue}
      {item.id === 0 && (
        <>
          <Spacer className="flex-grow" />
          <span className="text-xs text-gray-800/50">(new)</span>
        </>
      )}
    </SelectableItem>
  );
});
