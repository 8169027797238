import {mutateTask} from '@/app-service-worker/mutateTask';
import {Worker} from '@/app-service-worker/Worker';
import {EditableActor} from '@/components/actor/ActorSelector';
import type {TaskListItemType} from '@/components/backlog/TaskList';
import {EditableEpicLabel} from '@/components/task/EditableEpicLabel';
import {EditableTaskPoints} from '@/components/task/EditableTaskPoints';
import {EditableTaskType} from '@/components/task/EditableTaskType';
import {StatusLabelSelector} from '@/components/task/StatusLabelSelector';
import {Spacer} from '@/design-system';
import {dragStore} from '@/design-system/dnd/dragStore';
import type {ListItemRenderProps} from '@/design-system/lists/VList';
import {taskStore} from '@/stores/task';
import {isIdEphemeral} from '@shared/lib/isIdEphemeral';
import {isStateEqual} from '@shared/lib/isStateEqual';
import {FieldType} from '@shared/models/FieldSchema';
import type {Mutation} from '@shared/models/Mutation';
import React, {memo} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props extends ListItemRenderProps<TaskListItemType, number | string> {}

const styles = tv({
  base: 'mo-task-item group/task flex cursor-default select-none flex-row items-center gap-2 border-y border-transparent px-2 py-1 transition-opacity', //  animate-in fade-in-20
  variants: {
    isHovered: {
      true: 'border-y border-pink-500/10 bg-pink-300/10',
    },
    isSelected: {
      true: 'border-y border-pink-500/10 bg-pink-500/15 hover:bg-pink-500/20',
      false: 'hover:border-y hover:border-pink-400/10 hover:bg-pink-300/10',
    },
    isDragging: {
      true: 'border-transparent bg-transparent opacity-20',
    },
    isDragPreview: {
      true: 'relative min-w-96 bg-white !opacity-100 fade-in-100',
    },
    isEphemeral: {
      true: 'pointer-events-none bg-gray-100 opacity-20',
    },
  },
});

export function TaskListItem({id, isSelected, isDragPreview}: Props) {
  const dragState = dragStore.use((s) => s.state, []);
  const isDragging =
    isSelected && dragState.isDragging && dragState.dragTypes.includes('momentum/task') && !isDragPreview;
  if (typeof id !== 'number') return null;
  const isEphemeral = isIdEphemeral(id);

  return (
    <div
      className={twMerge(styles({isSelected, isDragging, isDragPreview, isEphemeral}), 'h-[32px] pl-8')}
      aria-selected={isSelected}
    >
      <TaskRowContent id={id} />
    </div>
  );
}

const TaskRowContent = memo(({id}: {id: number}) => {
  return <TaskRow id={id} />;
}, isStateEqual);

type TaskRowProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'id'> & {id: number};
const TaskRow = ({id, ...props}: TaskRowProps) => {
  const [task, parent] = taskStore.use(
    (s) => {
      const task = s.getById(id);
      const parent = task && s.getById(task.parentId);
      return [task, parent];
    },
    [id],
  );

  if (!task) return <Spacer />;

  const handleTaskUpdate = (_value: unknown, operation: Mutation['operations']) => {
    mutateTask(id, operation);
  };

  const handleTaskStatusChange = (statusId: number) => {
    Worker.moveTasks({taskIds: [id], statusId});
  };

  return (
    <div {...props} className={twMerge('flex h-[32px] w-full flex-row items-center gap-3 pr-3', props.className)}>
      <div className="flex w-4 flex-shrink-0 items-center">
        <StatusLabelSelector id={task.statusId} style="indicator" onCommit={handleTaskStatusChange} />
      </div>
      <div className="flex w-4 flex-shrink-0 items-center">
        <EditableTaskType id={task.typeId} onCommit={handleTaskUpdate} />
      </div>
      <div className="line-clamp-1 flex-grow break-all text-left text-[0.925rem]">{task.title}</div>
      <div className="shrink-0">
        <EditableEpicLabel id={parent?.id ?? null} onCommit={handleTaskUpdate} />
      </div>
      <EditableTaskPoints className="min-w-8 flex-shrink-0" points={task.points} onCommit={handleTaskUpdate} />
      <div className="flex-shrink-0">
        <EditableActor type={FieldType.Assignee} size="sm" actorId={task.assigneeId} onCommit={handleTaskUpdate} />
      </div>
    </div>
  );
};
