import {authStore} from '@/stores/auth';
import {EntityType} from '@shared/EntityType';
import type {Account} from '@shared/models/Account';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

class AccountStore extends EntityStore<EntityStoreState<Account>, Account> {
  get() {
    // there is only one, and it should have been loaded with the app context and cached by the app
    if (!this.state.byId.size) {
      throw new Error('Account not found');
    }
    return this.state.byId.values().next().value;
  }
}

export const accountStore = persistThroughHotReload(
  'accountStore',
  new AccountStore(EntityType.Account, {
    byId: new Map(),
    byList: new Map(),
  }).extend(function () {
    const self = this;
    return {
      getList() {
        throw new Error('Not implemented');
      },
      get current() {
        return self.getById(authStore.accountId)!;
      },
    };
  }),
);

exposeInDev({accountStore});
import.meta.hot?.accept(reloadOnHotUpdate);
