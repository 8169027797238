import {EntityType} from '@shared/EntityType';
import type {FieldSchema} from '@shared/models/FieldSchema';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const fieldSchemaStore = persistThroughHotReload(
  'fieldSchemaStore',
  new EntityStore<EntityStoreState<FieldSchema>, FieldSchema>(EntityType.FieldSchema, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({fieldSchemaStore});
import.meta.hot?.accept(reloadOnHotUpdate);
