import {ChevronDown, ChevronUp} from 'lucide-react';
import {useEffect, useRef} from 'react';
import {useNumberField} from 'react-aria';
import type {NumberFieldProps as AriaNumberFieldProps} from 'react-aria-components';
import {Button, useLocale} from 'react-aria-components';
import {useNumberFieldState} from 'react-stately';

export interface NumberFieldProps extends AriaNumberFieldProps {
  className?: string;
}

export function NumberField({className, ...props}: NumberFieldProps) {
  let {locale} = useLocale();
  let state = useNumberFieldState({...props, locale});
  let inputRef = useRef<HTMLInputElement>(null);

  let {groupProps, inputProps, incrementButtonProps, decrementButtonProps} = useNumberField(props, state, inputRef);

  useEffect(() => {
    props.onChange?.(state.numberValue);
  }, [props.onChange, state.numberValue]);

  useEffect(() => {
    inputRef.current?.select();
  }, []);

  return (
    <div {...groupProps} className={className}>
      <input
        {...inputProps}
        ref={inputRef}
        className={'w-14 rounded-md bg-white py-0.5 pr-7 text-right text-sm ring-1 ring-gray-600/20'}
      />
      <div className="absolute bottom-0 right-0 top-0 flex w-5 flex-col items-center justify-center">
        <Button
          {...incrementButtonProps}
          className="flex h-1/2 w-full justify-center border-b border-l border-gray-600/20"
        >
          <ChevronUp className="h-3 w-3" />
        </Button>
        <Button {...decrementButtonProps} className="flex h-1/2 w-full justify-center border-l border-gray-600/20">
          <ChevronDown className="h-3 w-3" />
        </Button>
      </div>
    </div>
  );
}
