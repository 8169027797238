import {EntityType} from '@shared/EntityType';
import type {Status} from '@shared/models/Status';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const statusStore = persistThroughHotReload(
  'statusStore',
  new EntityStore<EntityStoreState<Status>, Status>(EntityType.Status, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({statusStore});
import.meta.hot?.accept(reloadOnHotUpdate);
