import {Image} from '@/components/Image';
import {TaskTypeList} from '@/components/task/TaskTypeList';
import {Popover} from '@/design-system';
import {taskTypeStore} from '@/stores/taskType';
import type {TaskTypeFilter} from '@shared/filters/TaskTypeFilter';
import {defaultFieldId, FieldType} from '@shared/models/FieldSchema';
import type {Mutation} from '@shared/models/Mutation';
import {forwardRef, useCallback, useState} from 'react';
import {Button, MenuTrigger} from 'react-aria-components';

interface Props {
  id: number | undefined;
  onCommit: (value: number, operation: Mutation['operations']) => void;
  filter?: TaskTypeFilter;
}

export const EditableTaskType = forwardRef<HTMLButtonElement, Props>(({onCommit, filter, ...props}, ref) => {
  const taskType = taskTypeStore.use((s) => (props.id ? s.getById(props.id) : null), [props.id]);
  const [isOpen, onOpenChange] = useState(false);

  const onAction = useCallback(
    (taskTypeId: number) => {
      onOpenChange(false);
      onCommit(taskTypeId, {
        replace: {
          [defaultFieldId[FieldType.TaskType].toString()]: {value: taskTypeId.toString()},
        },
      });
    },
    [onCommit, onOpenChange],
  );

  return (
    <MenuTrigger aria-label={`Change task type`} isOpen={isOpen} onOpenChange={onOpenChange}>
      <Button
        className="group/button -m-0.5 flex h-5 w-5 shrink-0 items-center justify-center rounded-sm p-0.5"
        ref={ref}
      >
        {taskType?.iconImageId && <Image id={taskType.iconImageId} />}
        {!taskType && (
          <div className="transition-color-opacity h-3.5 w-3.5 rounded-sm border border-pink-700/40 bg-pink-500/10 opacity-50 group-hover/button:opacity-100" />
        )}
      </Button>
      <Popover showArrow placement="bottom" className="p-2">
        <TaskTypeList onAction={onAction} filter={filter} />
      </Popover>
    </MenuTrigger>
  );
});
