import {authStore} from '../stores/auth';

class HttpError extends Error {
  status: number;
  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export const request = async <T>(path: string, options: RequestInit = {}) => {
  // check if path is relative
  if (path.startsWith('/')) {
    path = `${VITE_API_BASE_URL}${path}`;
  }

  return await fetch(path, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authStore.token}`,
      ...options.headers,
    },
  })
    .catch((e) => {
      throw new HttpError(e.status ?? 500, e.message);
    })
    .then(async (response) => {
      if (!response.ok) {
        throw new HttpError(response.status, (await response.text()) || response.statusText);
      }

      if (response.headers.get('Content-Type')?.includes('application/json')) {
        return (await response.json()) as T;
      } else {
        return response.text() as T;
      }
    });
};

export const get = async <T>(path: string, options: RequestInit = {}) => request<T>(path, {...options, method: 'GET'});

export const post = async <T>(path: string, options: RequestInit = {}) =>
  request<T>(path, {...options, method: 'POST'});

export const put = async <T>(path: string, options: RequestInit = {}) => request<T>(path, {...options, method: 'PUT'});

export const del = async <T>(path: string, options: RequestInit = {}) =>
  request<T>(path, {...options, method: 'DELETE'});
