import {BoardHeader} from '@/components/board/BoardHeader';
import {ScrumBoard} from '@/components/board/ScrumBoard';
import {TaskOverlay} from '@/components/board/TaskOverlay';
import {Error404} from '@/components/errors/Error404';
import {contextStore} from '@/stores/context';
import {BoardUiContext, BoardUiStore} from '@/stores/ui/boardUiStore';
import React, {useEffect, useState} from 'react';
import {tv} from 'tailwind-variants';

const styles = tv({
  base: 'flex h-full flex-col gap-5 p-5 pb-0',
});

const Board: React.FC = () => {
  const board = contextStore.require((s) => s.state.board);
  const [uiStore, setUiStore] = useState<BoardUiStore | null>(null);

  useEffect(() => {
    setUiStore(BoardUiStore.create());
    () => {
      uiStore?.destroy();
    };
  }, []);

  if (!uiStore) {
    return <></>;
  }

  if (!board) {
    return <Error404 message="Board not found" />;
  }

  return (
    <BoardUiContext.Provider value={{store: uiStore}}>
      <div className={styles()}>
        <TaskOverlay />
        <BoardHeader />
        <ScrumBoard board={board} />
      </div>
    </BoardUiContext.Provider>
  );
};

export default Board;
