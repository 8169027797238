import React from 'react';

interface Props {
  initialWidth?: number;
  initialHeight?: number;
  children: (props: {width: number; height: number}) => React.ReactNode;
}

export const AutoSizer: React.FC<Props> = ({children, initialWidth, initialHeight}) => {
  const [[width, height], setSize] = React.useState<[number, number]>([initialWidth ?? 0, initialHeight ?? 0]);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];
        setSize([entry.contentRect.width, entry.contentRect.height]);
      });
      resizeObserver.observe(ref.current);
      return () => resizeObserver.disconnect();
    }
  }, [ref.current]);

  return (
    <div className="h-full w-full" ref={ref}>
      {children({width, height})}
    </div>
  );
};
