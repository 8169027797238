import {EntityType} from '@shared/EntityType';
import type {Actor} from '@shared/models/Actor';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const actorStore = persistThroughHotReload(
  'actorStore',
  new EntityStore<EntityStoreState<Actor>, Actor>(EntityType.Actor, {
    byId: new Map(),
    byList: new Map(),
  }).extend(function () {
    const originalGetById = this.getById;
    return {
      getById(id: number) {
        if (id === -1) {
          return null; // TBD
        }
        return originalGetById.call(this, id);
      },
    };
  }),
);

exposeInDev({actorStore});
import.meta.hot?.accept(reloadOnHotUpdate);
