import type {LabelProps as AriaLabelProps} from 'react-aria-components';
import {Label as AriaLabel} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';

export interface LabelProps extends AriaLabelProps {
  className?: string;
}

export function Label({className, ...props}: LabelProps) {
  return (
    <AriaLabel
      {...props}
      className={twMerge('flex flex-row gap-1 text-xs font-bold uppercase text-gray-700/50', className)}
    />
  );
}
