import {Worker} from '@/app-service-worker/Worker';
import {StatusLabel} from '@/components/task/StatusLabel';
import type {BoardColumn} from '@shared/models/Board';
import {useRef} from 'react';
import type {DropItem} from 'react-aria';
import {isTextDropItem, useDrop} from 'react-aria';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';
import type {Task} from 'vitest';

interface Props {
  column: BoardColumn;
  sprintId?: number;
  className?: string;
}

export function ColumnDropInterface({column, sprintId, className}: Props) {
  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      {column.statusIds.map((statusId) => (
        <StatusDropZone key={statusId} statusId={statusId} sprintId={sprintId} />
      ))}
    </div>
  );
}

const styles = tv({
  base: 'flex min-h-24 w-full items-center justify-center rounded-md border-2 border-pink-500/20 bg-pink-500/5 uppercase transition-colors',
  variants: {
    isDropTarget: {
      true: 'border-pink-500 bg-pink-500/20',
    },
  },
});

function StatusDropZone({statusId, sprintId}: {statusId: number; sprintId?: number}) {
  const ref = useRef<HTMLDivElement>(null);
  const {dropProps, isDropTarget} = useDrop({
    ref,
    async onDrop(operation) {
      const itemPromises = operation.items
        .map((i: DropItem) => (isTextDropItem(i) ? i.getText('momentum/task') : undefined))
        .filter((i) => !!i);
      const taskIds = await Promise.all(itemPromises).then((items) => {
        // TODO: only accept drops for the same account
        return (items.map((i) => JSON.parse(i!)) as Task[]).map((i) => +i.id);
      });
      Worker.moveTasks({taskIds, statusId, sprintId});
    },
    getDropOperation(types, allowedOperations) {
      if (types.has('momentum/task') && allowedOperations.includes('move')) {
        return 'move';
      }
      return 'cancel';
    },
  });

  return (
    <div ref={ref} {...dropProps} className={styles({isDropTarget})}>
      <StatusLabel id={statusId} />
    </div>
  );
}
