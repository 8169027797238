import {EntityType} from '@shared/EntityType';
import type {Comment} from '@shared/models/Comment';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const commentStore = persistThroughHotReload(
  'commentStore',
  new EntityStore<EntityStoreState<Comment>, Comment>(EntityType.Comment, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({commentStore});
import.meta.hot?.accept(reloadOnHotUpdate);
