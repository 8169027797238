import type {Actor} from '@shared/Actor';
import type {Account} from '@shared/models/Account';
import type {Attachment} from '@shared/models/Attachment';
import {Comment} from '@shared/models/Comment';
import type {Component} from '@shared/models/Component';
import type {ExternalAccount} from '@shared/models/ExternalAccount';
import type {FieldSchema} from '@shared/models/FieldSchema';
import type {TaskHistoryListItem} from '@shared/models/FilteredEntityList';
import type {Image} from '@shared/models/Image';
import type {InboxMessage} from '@shared/models/InboxMessage';
import type {Label} from '@shared/models/Label';
import type {Priority} from '@shared/models/Priority';
import type {Project} from '@shared/models/Project';
import type {Sprint} from '@shared/models/Sprint';
import type {Status} from '@shared/models/Status';
import type {TaskLink} from '@shared/models/TaskLink';
import type {TaskLinkType} from '@shared/models/TaskLinkType';
import type {TaskReadState} from '@shared/models/TaskReadState';
import type {TaskType} from '@shared/models/TaskType';
import type {Task} from './models/Task';

export enum EntityType {
  None = 'None',
  Task = 'Task',
  Actor = 'Actor',
  Project = 'Project',
  Board = 'Board',
  Account = 'Account',
  ExternalAccount = 'ExternalAccount',
  Priority = 'Priority',
  Status = 'Status',
  TaskType = 'TaskType',
  Field = 'Field',
  Sprint = 'Sprint',
  FieldSchema = 'FieldSchema',
  Image = 'Image',
  InboxMessage = 'InboxMessage',
  TaskHistory = 'TaskHistory', // Sudo Entity
  Comment = 'Comment',
  TaskReadState = 'TaskReadState',
  Component = 'Component',
  Label = 'Label',
  TaskLink = 'TaskLink',
  TaskLinkType = 'TaskLinkType',
  Attachment = 'Attachment',
}

export const isEntityType = (type: EntityType | string): type is EntityType => {
  return type in EntityType;
};

export type EntityId = number;

export interface Entity {
  id: EntityId;
}
export type AnyEntity =
  | Task
  | Actor
  | Project
  | Image
  | Account
  | ExternalAccount
  | Priority
  | Status
  | TaskType
  | Sprint
  | FieldSchema
  | InboxMessage
  | TaskReadState
  | TaskHistoryListItem
  | Component
  | Label
  | TaskLink
  | TaskLinkType
  | Attachment
  | Comment;

export type EntityPatch = Partial<AnyEntity> & {id: EntityId};
