import {persistThroughHotReload} from '@/lib/dev';
import {runLatest} from '@/lib/runLatest';
import {accountStore} from '@/stores/account';
import {authStore} from '@/stores/auth';
import {boardStore} from '@/stores/board';
import {MoSessionStore} from '@/stores/lib/MoSessionStore';
import {projectStore} from '@/stores/project';
import {Filters} from '@shared/filters/Filters';
import type {Board} from '@shared/models/Board';
import type {Project} from '@shared/models/Project';
import autobind from 'autobind-decorator';

interface AppContext {
  context?: string;
  projectId?: number;
  boardId?: number;
  project?: Project | null;
  board?: Board | null;
  isReady?: boolean;
}

// matches :account:project:boardId in a URL like https://app.mmntm.ai/:fooaccount:FA:1/board
export const pathContextRegex = /^\/(?<context>(?::[\w-_]+){1,3})?\/?/;

class ContextStore extends MoSessionStore<AppContext> {
  private unsubscribes: Array<() => void> = [];

  constructor(state: AppContext) {
    super(state, 'ContextStore');
    this.mutate((s) => (s.isReady = false));
    this.on('mutate', this.subscribeToEntities);
    window.addEventListener('popstate', this.onUrlStateChange);
    window.addEventListener('pushState', this.onUrlStateChange);
  }

  get context() {
    return this.state.context;
  }

  get projectId() {
    return this.state.projectId;
  }

  get project() {
    return this.state.project;
  }

  get boardId() {
    return this.state.boardId;
  }

  get board() {
    return this.state.board;
  }

  get isReady() {
    const {project, board, isReady} = this.state;
    return project !== undefined && board !== undefined && isReady;
  }

  async changeProject(projectId: number, boardId: number) {
    if (this.projectId === projectId && this.boardId === boardId) return;

    const account = (await accountStore.await((s) => s.getById(authStore.accountId)))!;
    const projectKey = await projectStore.await((s) => s.getById(projectId)?.key);
    // stay in the current view if there is one
    const view = window.location.pathname.split(/^\/(?::[\w-_]+)+\//)?.[1]?.split('/')?.[0] ?? '';

    // TODO: bug: if we reload the service worker on page reload (via dev tools), this change does not stick
    window.location.href = `/:${account.name}:${projectKey}:${boardId}/${view}`;
  }

  @autobind
  private onUrlStateChange() {
    const [, context] = window.location.pathname.split(pathContextRegex) ?? [];
    this.reconcileWithLocation(context);
  }

  async reconcileWithLocation(urlContext: string = '') {
    if (this.isReady && this.context === urlContext) {
      return;
    }

    this.set('isReady', false);

    runLatest('ContextStore:reconcileWithLocation', async () => {
      const [, account, projectKey, boardId] = urlContext.split(':');
      const storedAccount = (await accountStore.await((s) => s.getById(authStore.accountId)))!;

      if (account && account !== storedAccount.name) throw new Error('TODO: Handle account switching');

      // load project given projectKey, existing project, or first project
      const project = await projectStore.await((s) => {
        if (projectKey) {
          if (this.project?.key === projectKey) return this.project;
          return s.getByKey(projectKey);
        } else {
          if (this.project) return this.project;
          if (this.projectId) return s.getById(this.projectId);
          return s.getById(s.getList(Filters.projectFilter({}))?.[0]?.id);
        }
      });
      if (!project) throw new Error('No project found'); // TODO: handle this better

      // load board given boardId, existing board, or first board
      let board = await boardStore.await((s) => {
        if (boardId) {
          if (this.board?.id === +boardId) return this.board;
          return s.getById(+boardId);
        }
        return null;
      });
      // ensure board is for the project, otherwise load the first board for this project
      if (!board || board.projectId !== project?.id) {
        board = await boardStore.await((s) => s.getFirstByProjectId(project.id));
      }
      if (!board) throw new Error('No board found'); // TODO: handle this better

      const context = ':' + [storedAccount.name, project.key, board.id].join(':');

      // update state and set isReady
      return {
        context,
        projectId: project.id,
        boardId: board.id,
        project,
        board,
        isReady: true,
      };
    }).then((state) => {
      // update url context if needed
      if (state.context !== urlContext) {
        this.updateRouteWithContext(state.context);
      }
      this.update(state);
    });
  }

  updateRouteWithContext(context: string) {
    const [, , path] = window.location.pathname.split(pathContextRegex) ?? [];
    window.history.replaceState(null, '', `/${context}/${path ?? ''}`);
  }

  @autobind
  private subscribeToEntities() {
    this.unsubscribes.forEach((unsubscribe) => unsubscribe());
    this.unsubscribes = [
      projectStore.selectAndSubscribe(
        (s) => s.getById(this.projectId),
        (p) => this.update({project: p}),
      ),
      boardStore.selectAndSubscribe(
        (s) => s.getById(this.boardId),
        (b) => this.update({board: b}),
      ),
    ];
  }
}

export const contextStore = persistThroughHotReload('contextStore', new ContextStore({}));
