import {Evented, type EventedEvents} from '@/lib/Evented';
import type {EntityType} from '@shared/EntityType';
import type {AnyEntityFilter} from '@shared/filters/EntityFilter';
import {Filters} from '@shared/filters/Filters';
import {DevLog, devLog} from '@shared/lib/devLog';
import type {FilteredEntityList} from '@shared/models/FilteredEntityList';
import {EMPTY_LIST} from '../src/lib/emptyList';
import type {Entity, EntityId} from './EntityType';

export enum EventType {
  OnCreate = 'OnCreate',
  OnDelete = 'OnDelete',
  OnFirst = 'OnFirst',
  AfterLast = 'AfterLast',
}

interface SubscriptionManagerEvents extends EventedEvents {
  [EventType.OnCreate]: (subscription: EntitySubscription) => void;
  [EventType.OnDelete]: (subscription: EntitySubscription) => void;
  [EventType.OnFirst]: (subscription: EntitySubscription) => void;
  [EventType.AfterLast]: (subscription: EntitySubscription) => void;
}

export interface EntitySubscription {
  type: EntityType;
  id?: EntityId;
  filter?: AnyEntityFilter;
  handler?: SubscriptionUpdateHandler;
}

export interface EntityIdSubscription extends EntitySubscription {
  id: EntityId;
  handler?: SubscriptionUpdateHandler;
}

export function isEntityIdSubscription(subscription: EntitySubscription): subscription is EntityIdSubscription {
  return subscription.id !== undefined;
}

export interface EntityFilterSubscription extends EntitySubscription {
  filter: AnyEntityFilter;
  handler?: SubscriptionUpdateHandler;
}

export function isEntityFilterSubscription(subscription: EntitySubscription): subscription is EntityFilterSubscription {
  return subscription.filter !== undefined;
}

export type SubscriptionEventHandler = (type: EventType, subscription: EntitySubscription) => void;

export interface SubscriptionUpdate {
  type: EntityType;
  entities: Entity[];
  lists: FilteredEntityList[];
  deletions: EntityId[];
}

export type SubscriptionUpdateHandler = (update: SubscriptionUpdate) => void;

interface EntitySubscriptions {
  byId: Map<EntityId, Set<SubscriptionUpdateHandler>>;
  byFilter: Map<AnyEntityFilter, Set<SubscriptionUpdateHandler>>;
}

export class SubscriptionManager extends Evented<SubscriptionManagerEvents> {
  private entitySubscriptions: Map<EntityType, EntitySubscriptions> = new Map();

  public getSubscriptions(entityType: EntityType): Readonly<EntitySubscriptions> | undefined {
    return this.entitySubscriptions.get(entityType);
  }

  public get allSubscriptions() {
    return this.entitySubscriptions;
  }

  public create(entityType: EntityType, idOrFilter: number | AnyEntityFilter, handler: SubscriptionUpdateHandler) {
    let entitySubscriptions = this.entitySubscriptions.get(entityType);
    if (!entitySubscriptions) {
      entitySubscriptions = {
        byId: new Map(),
        byFilter: new Map(),
      };
      this.entitySubscriptions.set(entityType, entitySubscriptions);
    }

    if (typeof idOrFilter === 'number') {
      let subscribers = entitySubscriptions.byId.get(idOrFilter);
      if (!subscribers) {
        subscribers = new Set();
        entitySubscriptions.byId.set(idOrFilter, subscribers);
        this.emit(EventType.OnFirst, {type: entityType, id: idOrFilter, handler});
      }
      subscribers.add(handler);
      this.emit(EventType.OnCreate, {type: entityType, id: idOrFilter, handler});
    } else {
      let subscribers = entitySubscriptions.byFilter.get(idOrFilter);
      if (!subscribers) {
        subscribers = new Set();
        entitySubscriptions.byFilter.set(idOrFilter, subscribers);
        this.emit(EventType.OnFirst, {type: entityType, filter: idOrFilter, handler});
      }
      subscribers.add(handler);
      this.emit(EventType.OnCreate, {type: entityType, filter: idOrFilter, handler});
    }
  }

  public remove(entityType: EntityType, idOrFilter: number | AnyEntityFilter, handler: SubscriptionUpdateHandler) {
    let entitySubscriptions = this.entitySubscriptions.get(entityType);
    if (!entitySubscriptions) {
      return;
    }

    if (typeof idOrFilter === 'number') {
      let subscribers = entitySubscriptions.byId.get(idOrFilter);
      if (!subscribers) {
        return;
      }
      subscribers.delete(handler);
      this.emit(EventType.OnDelete, {type: entityType, id: idOrFilter, handler});
      if (subscribers.size === 0) {
        entitySubscriptions.byId.delete(idOrFilter);
        this.emit(EventType.AfterLast, {type: entityType, id: idOrFilter});
      }
    } else {
      let subscribers = entitySubscriptions.byFilter.get(idOrFilter);
      if (!subscribers) {
        return;
      }
      this.emit(EventType.OnDelete, {type: entityType, filter: idOrFilter, handler});
      subscribers.delete(handler);
      if (subscribers.size === 0) {
        entitySubscriptions.byFilter.delete(idOrFilter);
        this.emit(EventType.AfterLast, {type: entityType, filter: idOrFilter});
      }
    }
  }

  public update(update: SubscriptionUpdate) {
    devLog(DevLog.SubscriptionManager, 'Update', update);
    const subs = this.entitySubscriptions.get(update.type);
    if (!subs) {
      return;
    }

    // Only call each handler once, even if it's subscribed to multiple affected entities/lists
    const handlers = new Set<SubscriptionUpdateHandler>();
    for (const entity of update.entities) {
      for (const handler of subs.byId.get(entity.id) ?? EMPTY_LIST) {
        handlers.add(handler);
      }
    }

    for (const entityList of update.lists) {
      const filter = Filters.fromObject(entityList.filter);
      for (const handler of subs.byFilter.get(filter) ?? EMPTY_LIST) {
        handlers.add(handler);
      }
    }

    for (const id of update.deletions) {
      for (const handler of subs.byId.get(id) ?? EMPTY_LIST) {
        handlers.add(handler);
      }
    }

    for (const handler of handlers) {
      handler(update);
    }
  }

  public removeAll(handler: SubscriptionUpdateHandler) {
    for (const [type, subs] of this.entitySubscriptions.entries()) {
      for (const [idOrFilter, subscribers] of subs.byFilter.entries()) {
        subscribers.delete(handler);
        if (subscribers.size === 0) {
          subs.byFilter.delete(idOrFilter);
          this.emit(EventType.AfterLast, {type, filter: idOrFilter});
        }
      }
      for (const [idOrFilter, subscribers] of subs.byId.entries()) {
        subscribers.delete(handler);
        if (subscribers.size === 0) {
          subs.byId.delete(idOrFilter);
          this.emit(EventType.AfterLast, {type, id: idOrFilter});
        }
      }
    }
  }
}
