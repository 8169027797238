import {Image} from '@/components/Image';
import {FilterableList, SelectableItem} from '@/design-system';
import {taskStore} from '@/stores/task';
import type {TaskFilter} from '@shared/filters/TaskFilter';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import React from 'react';

interface Props {
  onAction?: (taskId: number) => void;
  filter: TaskFilter;
}

export const SelectTaskList: React.FC<Props> = ({filter, onAction}) => {
  const items = taskStore.use((s) => s.getList(filter)?.filter((i) => i != null) ?? [], [filter]);

  return (
    <FilterableList items={items} aria-label="Task list" onAction={onAction}>
      {(item) => (
        <SelectableItem textValue={item.text} key={item.id} className="flex items-center gap-2">
          <TaskListItem item={item} />
        </SelectableItem>
      )}
    </FilterableList>
  );
};

export const TaskListItem = ({item}: {item: EntityListItem}) => {
  return (
    <div className="flex max-w-72 flex-row items-center break-all text-sm">
      <div className="mr-2 h-4 w-4 flex-shrink-0">
        <Image id={item.icon} />
      </div>
      <div className="line-clamp-1">{item.text}</div>
    </div>
  );
};
