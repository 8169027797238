import {useMemo} from 'react';

export interface Collection<K extends string | number, T extends {id: K}> {
  list: T[];
  idToIndex: Record<K, number>;
}

export function useCollection<K extends string | number, T extends {id: K}>(
  items: T[] | Collection<K, T>,
  deps?: React.DependencyList,
): Collection<K, T> {
  return useMemo(
    () => {
      if (isCollection(items)) return items;
      const map = {} as Record<K, number>;
      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        map[item.id] = index;
      }
      return {list: items, idToIndex: map};
    },
    deps ?? [items],
  );
}

export function isCollection<K extends string | number, T extends {id: K}>(
  collection: Collection<K, T> | unknown,
): collection is Collection<K, T> {
  return (
    (collection as Collection<K, T>).idToIndex !== undefined && (collection as Collection<K, T>).list !== undefined
  );
}

export function indexOf<K extends string | number, T extends {id: K}>(
  collection: Collection<K, T>,
  id: K | undefined,
): number {
  if (id === undefined) return -1;
  return collection.idToIndex[id];
}
