import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import type {EntityStoreState} from '@/stores/lib/EntityStore';
import {EntityStore} from '@/stores/lib/EntityStore';
import {EntityType} from '@shared/EntityType';
import type {AnyEntityFilter} from '@shared/filters/EntityFilter';
import type {TaskHistoryListItem} from '@shared/models/FilteredEntityList';

class TaskHistoryStore extends EntityStore<EntityStoreState<TaskHistoryListItem>, TaskHistoryListItem> {
  constructor(state: EntityStoreState<TaskHistoryListItem>) {
    super(EntityType.TaskHistory, state);
  }

  getList(filter: AnyEntityFilter): TaskHistoryListItem[] | undefined {
    return super.getList(filter) as TaskHistoryListItem[] | undefined;
  }
}

export const taskHistoryStore = persistThroughHotReload(
  'taskHistoryStore',
  new TaskHistoryStore({
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({taskHistoryStore});
import.meta.hot?.accept(reloadOnHotUpdate);
