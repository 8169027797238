import {isStateEqual} from '@shared/lib/isStateEqual';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const STATE: unique symbol = Symbol.for('MO_TRACER_STATE');

interface InternalState<T extends object> {
  keyPath: string;
  accessed: Set<string>;
  written: Set<string>;
  deleted: number[];
  data: T;
  updatedData?: T;
  proxies: {
    [key: string | number | symbol]: any & TracerProxy<any>;
  };
}

export interface TracerProxy<T extends object> {
  [STATE]: InternalState<T>;
}

const handler: ProxyHandler<InternalState<{[key: string | number | symbol]: any}>> = {
  has(target, prop) {
    if (prop === STATE) {
      return true;
    }
    const data = target.updatedData ?? target.data;
    target.accessed.add(Array.isArray(data) ? target.keyPath : `${target.keyPath}.${prop.toString()}`);
    return prop in data;
  },
  ownKeys(target) {
    return Reflect.ownKeys(target.updatedData ?? target.data);
  },
  getOwnPropertyDescriptor(target, prop) {
    const descriptor = Reflect.getOwnPropertyDescriptor(target.updatedData ?? target.data, prop);
    if (descriptor) {
      return {...descriptor, configurable: true};
    }
    return undefined;
  },
  get(target, prop, receiver) {
    const data = target.updatedData ?? target.data;
    if (prop === STATE) {
      return target;
    } else if (prop === 'toJSON') {
      const localKeysRegex = new RegExp(`^${target.keyPath.replace('.', '\\.')}\\.[^.]+$`);
      return () => ({
        ...data,
        ...Object.fromEntries(
          Object.entries(target.proxies)
            .filter(([key]) => localKeysRegex.test(key))
            .map(([key, proxy]) => [key.substring(target.keyPath.length + 1), proxy]),
        ),
      });
    }

    try {
      Reflect.get(data, prop, receiver);
    } catch (e) {
      console.error(e);
    }

    const value = Reflect.get(data, prop, receiver);
    let key = `${target.keyPath}.${prop.toString()}`;
    target.accessed.add(Array.isArray(data) ? target.keyPath : key);

    if (typeof value !== 'object' || value === null) {
      return value;
    }

    if (value instanceof Set) {
      return (target.proxies[key] ??= new SetProxy({...target, updatedData: undefined, keyPath: key, data: value}));
    }

    return (target.proxies[key] ??= new Proxy({...target, updatedData: undefined, keyPath: key, data: value}, handler));
  },
  set(target, p, value) {
    if (isStateEqual((target.updatedData ?? target.data)[p], value)) return true;
    const data = (target.updatedData ??= clone(target.data));

    let key = `${target.keyPath}.${p.toString()}`;
    delete target.proxies[key];

    if (Reflect.set(data, p, value)) {
      target.written.add(Array.isArray(data) ? target.keyPath : key);
      return true;
    }
    return false;
  },
  deleteProperty(target, p) {
    const data = (target.updatedData ??= clone(target.data));
    const key = `${target.keyPath}.${p.toString()}`;

    if (Reflect.deleteProperty(data, p)) {
      delete target.proxies[key];
      target.written.add(Array.isArray(data) ? target.keyPath : key);
      return true;
    }
    return false;
  },
  defineProperty(...args) {
    throw new Error('TracerProxy: defineProperty is not supported ' + JSON.stringify(args));
  },
  getPrototypeOf(target) {
    return Object.getPrototypeOf(target.updatedData ?? target.data);
  },
  setPrototypeOf() {
    throw new Error('TracerProxy: setPrototypeOf is not supported');
  },
};

class SetProxy<T> extends Set<T> {
  [STATE]: InternalState<Set<T>>;
  constructor(state: InternalState<Set<T>>) {
    super();
    this[STATE] = state;
  }
  get size() {
    return (this[STATE].updatedData ?? this[STATE].data).size;
  }
  has(value: T) {
    // similar to arrays, we don't track accesses to individual values
    // the fact that we are here, means we have already accessed the key path to the set
    return (this[STATE].updatedData ?? this[STATE].data).has(value);
  }
  add(value: T) {
    if ((this[STATE].updatedData ?? this[STATE].data).has(value)) return this;
    const data = (this[STATE].updatedData ??= new Set(this[STATE].data));
    this[STATE].written.add(this[STATE].keyPath);
    data.add(value);
    return this;
  }
  delete(value: T) {
    if (!(this[STATE].updatedData ?? this[STATE].data).has(value)) return false;
    const data = (this[STATE].updatedData ??= new Set(this[STATE].data));
    this[STATE].written.add(this[STATE].keyPath);
    return data.delete(value);
  }
  clear() {
    const data = (this[STATE].updatedData ??= new Set(this[STATE].data));
    this[STATE].written.add(this[STATE].keyPath);
    return data.clear();
  }
  values() {
    return (this[STATE].updatedData ?? this[STATE].data).values();
  }
  entries() {
    return (this[STATE].updatedData ?? this[STATE].data).entries();
  }
  [Symbol.iterator]() {
    return (this[STATE].updatedData ?? this[STATE].data)[Symbol.iterator]();
  }
  forEach(callbackfn: (value: T, value2: T, set: Set<T>) => void, thisArg?: any): void {
    (this[STATE].updatedData ?? this[STATE].data).forEach(callbackfn, thisArg);
  }
  toJSON() {
    return [...(this[STATE].updatedData ?? this[STATE].data)];
  }
}

function clone<T = unknown>(data: T): T {
  if (data instanceof Set) {
    return new Set(data) as T;
  }
  if (Array.isArray(data)) {
    return [...data] as T;
  } else if (typeof data === 'object' && data !== null) {
    return {...data};
  }
  throw new Error('TracerProxy: cannot clone unsupported data type');
}

function isProxied<T extends object>(data: T): data is T & TracerProxy<T> {
  return STATE in data;
}

function isObject(data: unknown): data is object {
  return typeof data === 'object' && data !== null;
}

const reactElementSymbol = Symbol.for('react.element');

export const Tracer = {
  create<T extends object>(data: T): T & TracerProxy<T> {
    if (isProxied(data)) {
      return data;
    }
    return new Proxy(
      {keyPath: '', data, accessed: new Set(), written: new Set(), deleted: [], proxies: {}} as InternalState<T>,
      handler,
    ) as never;
  },
  render<T = any>(data: T): T {
    if (!isObject(data)) {
      return data;
    }

    if (data instanceof Set) {
      if (data instanceof SetProxy) {
        // we don't need to trace inside sets
        return (data[STATE].updatedData ?? data[STATE].data) as T;
      } else {
        return data;
      }
    }

    if (isProxied(data)) {
      const state = data[STATE];
      const updatedData = state.updatedData ?? clone(state.data);
      for (const key of Object.keys(updatedData)) {
        updatedData[key as keyof T] = Tracer.render(data[key as keyof T]);
      }
      return updatedData;
    }

    data = clone(data);
    for (const key of Object.keys(data as object)) {
      const v = data[key as keyof T];
      if (isObject(v) && (v as any).$$typeof !== reactElementSymbol) {
        (data[key as keyof T] as unknown) = Tracer.render(v);
      }
    }

    return data;
  },
  renderWithoutTracing<T extends object>(data: T | (T & TracerProxy<T>)): T {
    if (!isProxied(data)) return data;
    if (data instanceof Set) {
      if (data instanceof SetProxy) {
        return (data[STATE].updatedData ?? data[STATE].data) as T;
      } else {
        return data;
      }
    }

    const state = data[STATE];
    const updatedData = state.updatedData ?? clone(state.data);
    for (const key of Object.keys(updatedData)) {
      const proxy = state.proxies[`${state.keyPath}.${key}`];
      if (proxy) {
        // it won't hurt to modify the internal updatedData as there are proxies in place with the source of truth for any modifications
        updatedData[key as keyof T] = Tracer.renderWithoutTracing(proxy as never);
      }
    }
    return updatedData;
  },
  mutate<T extends object, R = void>(data: T, mutator: (data: T) => R): [T, Set<string>, R] {
    if (isProxied(data)) {
      data = Tracer.render(data as T & TracerProxy<T>);
    }
    const monitored = Tracer.create(data);
    const result = mutator(monitored);
    return [Tracer.renderWithoutTracing(monitored), monitored[STATE].written, result];
  },
  hasChanges<T extends object>(data: T): boolean {
    if (isProxied(data)) {
      const state = data[STATE];
      if (state.updatedData) return true;
      for (const key of Object.keys(data as object)) {
        const proxy = state.proxies[`${state.keyPath}.${key}`];
        if (proxy && Tracer.hasChanges(proxy as never)) return true;
      }
    }
    return false;
  },
  revert<T extends object>(data: T): T {
    if (isProxied(data)) {
      const state = data[STATE];
      state.updatedData = undefined;
      for (const proxy of Object.values(state.proxies)) {
        proxy[STATE].updatedData = undefined;
      }
    }
    return data;
  },
  select<T extends object, S = never>(data: T, selector: (data: T) => S): [S, Set<string>] {
    const monitored = Tracer.create(data);
    monitored[STATE].accessed.clear();
    const selection = Tracer.render(selector(monitored));
    return [selection, monitored[STATE].accessed];
  },
};
