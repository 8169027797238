import {Worker} from '@/app-service-worker/Worker';
import {EditableActor} from '@/components/actor/ActorSelector';
import type {TaskListItemType} from '@/components/backlog/TaskList';
import {EditableEpicLabel} from '@/components/task/EditableEpicLabel';
import {EditableTaskPoints} from '@/components/task/EditableTaskPoints';
import {EditableTaskType} from '@/components/task/EditableTaskType';
import {StatusLabelSelector} from '@/components/task/StatusLabelSelector';
import {Editor, InlineEdit, TextInput} from '@/design-system';
import {useDrop} from '@/design-system/dnd/dragHooks';
import {dragStore} from '@/design-system/dnd/dragStore';
import type {ListItemRenderProps} from '@/design-system/lists/VList';
import {useMoState} from '@/hooks/useMoState';
import {onDragOverAllowTaskMove, tasksFromDragData} from '@/lib/dragHelpers';
import {authStore} from '@/stores/auth';
import {contextStore} from '@/stores/context';
import {sprintStore} from '@/stores/sprint';
import {taskStore} from '@/stores/task';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import {FieldType} from '@shared/models/FieldSchema';
import type {Task} from '@shared/models/Task';
import {PlusIcon} from 'lucide-react';
import {useMemo, useRef, useState} from 'react';
import {tv} from 'tailwind-variants';

const styles = tv({
  base:
    'transition-color-opacity group/task w-full border-y border-transparent text-xs text-pink-700/40 opacity-0 group-hover/sprint:opacity-50 focus-visible:opacity-100' +
    ' hover:border-pink-500/10 hover:bg-pink-300/10 hover:!opacity-100' +
    ' focus-within:!opacity-100',
  variants: {
    visible: {true: '!opacity-100'},
  },
});

const newTaskStyle = tv({
  base: 'transition-color-opacity line-clamp-1 flex-grow break-all text-left text-pink-700/80 opacity-60 group-hover/button:opacity-100',
  variants: {
    placeholder: {
      false: 'text-base',
    },
  },
});

interface Props extends ListItemRenderProps<TaskListItemType, number | string> {
  sprintId: number | null;
}

export function NewTaskRow({sprintId, isSelected, isFocused}: Props) {
  const [task, mutate, setTitle, setTaskType, setEpic, setPoints, setAssignee] = useMoState<Partial<Task>>(
    {},
    'title',
    'typeId',
    'parentId',
    'points',
    'assigneeId',
  );
  const sprint = sprintId ? sprintStore.require((s) => s.getById(sprintId)) : null;
  const isSprintEmpty = taskStore.use((s) => s.getList(Filters.taskFilter({sprintId}))?.length === 0, [sprintId]);
  const parent = taskStore.use((s) => (task.parentId ? s.getById(task.parentId) : null), [task.parentId]);
  const isNotEmpty = useMemo(() => !!task.title || !!task.typeId || !!task.parentId || !!task.points, [task]);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const createTask = async (task: Partial<Task>) => {
    const newTask: Partial<Task> = {
      ...task,
      accountId: authStore.accountId,
      projectId: sprint?.projectId ?? contextStore.projectId,
      sprintId: sprint?.id,
    };
    // We could use the rank of the prev task, but it gets complicated when a sprint is empty
    // To guarantee it goes to the bottom, we can just use a symbol beyond jira rank alphabet, before the new task rank of Ω
    newTask.rank = 'Ψ';
    Worker.postEntity(EntityType.Task, newTask);
    mutate.replace({});
  };

  const taskTypeButtonRef = useRef<HTMLButtonElement>(null);
  const onCommitTitle = () => {
    if (!task.title) return;
    if (task.typeId === undefined) {
      taskTypeButtonRef.current?.click();
    } else {
      createTask(task);
    }
  };

  const onCancelTitle = () => {
    mutate.replace({});
  };

  const onCommitType = (value: number) => {
    const newTask = setTaskType(value);
    if (newTask.title) {
      createTask(newTask);
    }
  };

  const {
    dropProps,
    renderProps: {isDropTarget},
  } = useDrop(
    {
      onDrop(e) {
        const tasks = tasksFromDragData(e.dataTransfer);
        if (!tasks) return 'none';

        const taskIds = tasks.map((t) => t.id);
        Worker.moveTasks({taskIds, sprintId});
        return 'move';
      },
      onDragOver: onDragOverAllowTaskMove,
    },
    [],
  );
  const {isDragging, dragTypes} = dragStore.use((s) => s.state, []);
  const canDrop = isDragging && dragTypes.includes('momentum/task') && isSprintEmpty;

  return (
    <div className={styles({visible: isNotEmpty || isEditingTitle || canDrop || isSelected || isFocused})}>
      <div {...dropProps} className="flex h-8 items-center gap-3 pl-8 pr-5">
        {canDrop && <DropHighlight sprintId={sprint?.id} isDropTarget={isDropTarget} />}
        {!isDropTarget && !isDragging && (
          <>
            <div className="flex w-4 flex-shrink-0 items-center">
              <StatusLabelSelector
                id={task.statusId}
                filter={Filters.statusFilter({projectId: sprint?.projectId})}
                style="indicator"
                onCommit={() => {}}
              />
            </div>
            <div className="flex w-4 flex-shrink-0 items-center">
              <EditableTaskType
                id={task.typeId}
                onCommit={onCommitType}
                filter={Filters.taskTypeFilter({projectId: sprint?.projectId ?? contextStore.projectId})}
                ref={taskTypeButtonRef}
              />
            </div>
            <div className="flex flex-grow">
              <InlineEdit
                className="group/button relative flex h-5 flex-grow items-center rounded-sm"
                placement="center"
                onCommit={onCommitTitle}
                onCancel={onCancelTitle}
                onOpenChange={setIsEditingTitle}
              >
                <div className={newTaskStyle({placeholder: !task.title})}>
                  {task.title || (
                    <div className="flex items-center">
                      <PlusIcon className="h-3 w-3" />
                      &nbsp;New task
                    </div>
                  )}
                </div>
                <Editor className="-mx-1">
                  <TextInput
                    className="w-full rounded-sm px-1"
                    initialValue={task.title}
                    placeholder=""
                    onValueChange={setTitle}
                  />
                </Editor>
              </InlineEdit>
            </div>
            <div>{<EditableEpicLabel id={parent?.id ?? null} onCommit={setEpic} />}</div>
            <EditableTaskPoints className="min-w-8 flex-shrink-0" points={task.points} onCommit={setPoints} />
            <div className="flex-shrink-0">
              <EditableActor type={FieldType.Assignee} size="sm" actorId={task.assigneeId} onCommit={setAssignee} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const dropStyles = tv({
  base: 'transition-color-opacity flex h-full w-full items-center justify-center rounded-sm border-2 border-pink-500/60 bg-pink-500/10 text-sm text-pink-700/80 opacity-30',
  variants: {
    isDropTarget: {
      true: 'opacity-100',
    },
  },
});

function DropHighlight({sprintId, isDropTarget}: {sprintId?: number | null; isDropTarget?: boolean}) {
  return <div className={dropStyles({isDropTarget})}>Drop to add to {sprintId ? 'sprint' : 'backlog'}</div>;
}
