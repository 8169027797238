import React from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const Skeleton: React.FC<Props> = ({children, className}) => {
  return (
    <span className={`animate-pulse rounded-sm bg-slate-400/50 ${children ? '' : 'h-4'} ${className ?? ''}`}>
      {children ?? <div className="invisible">{children}</div>}
    </span>
  );
};
