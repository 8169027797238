/* eslint-disable @typescript-eslint/no-explicit-any */
import {useIsMounted} from '@/hooks/useIsMounted';
import type {ExcludesUndefined} from '@/lib/containsUndefined';
import {excludesUndefined} from '@/lib/containsUndefined';
import {EMPTY_LIST} from '@/lib/emptyList';
import type {EventedEvents} from '@/lib/Evented';
import {Evented} from '@/lib/Evented';
import {ManyToMany} from '@/lib/ManyToMany';
import type {TracerProxy} from '@/stores/lib/TracerProxy';
import {STATE, Tracer} from '@/stores/lib/TracerProxy';
import {batch} from '@shared/batcher';
import {isStateEqual} from '@shared/lib/isStateEqual';
import autobind from 'autobind-decorator';
import type {DependencyList} from 'react';
import {useEffect, useMemo, useRef, useState} from 'react';

type Subscriber<R = any> = (result: R) => void;
interface Subscription<Store extends MoStore<S>, S extends object, R = any> {
  subscriber: Subscriber<R>;
  selector?: (store: Store) => R;
  /**
   * because of how hooks work, we need to subscribe before we want updates to be delivered
   * Therefore, subscriptions need to be enabled.
   * Until they are enabled, they are only marked as needing updates or not
   */
  enabled: boolean;
  needsUpdate: boolean;
  referenceState?: S;
  result?: R;
}

interface MoStoreEvents<S extends object> extends EventedEvents {
  mutate: (state: S) => void;
}

interface MutationOptions {
  noEmit?: boolean;
}

export class MoStore<S extends object = any> extends Evented<MoStoreEvents<S>> {
  protected _state: S & TracerProxy<S>;
  protected _isMutating = false;
  private _subscribers = new Map<Subscriber, Subscription<this, S>>();
  private _keySubs = new ManyToMany<string, Subscription<this, S>>();

  constructor(state: S) {
    super();
    this._state = Tracer.create(state);
    setInterval(() => this.cleanup(), 10_000);
  }

  extend<T>(extension: (this: this) => T): this & T {
    return Object.assign(this, extension.call(this));
  }

  get state(): Readonly<S> {
    return this._state;
  }

  get<K extends keyof S>(key: K): Readonly<S[K]> {
    return this._state[key];
  }

  pick<K extends keyof S>(...keys: K[]): Pick<S, K> {
    return keys.reduce(
      (acc, key) => {
        acc[key] = this._state[key];
        return acc;
      },
      {} as Pick<S, K>,
    );
  }

  @mutation
  set<K extends keyof S>(key: K, value: S[K]) {
    (this._state as S)[key] = value;
  }

  @autobind
  @mutation
  update(update: Partial<S>) {
    Object.assign(this._state, update);
  }

  mutate(updater: (state: S) => void, options: MutationOptions = {}) {
    if (this._isMutating) {
      return updater(this.state);
    }

    this._isMutating = true;
    const result = updater(this.state);
    const updatedKeys = this._state[STATE].written;
    if (updatedKeys.size === 0) {
      this._isMutating = false;
      return result;
    }
    const newState = Tracer.renderWithoutTracing(this._state);
    this._state = Tracer.create(newState);
    this._isMutating = false;
    this.handleUpdates(updatedKeys);
    if (!options.noEmit) {
      this.emit('mutate', newState);
    }
    return result;
  }

  selectAndSubscribe<R>(selector: (store: this) => R, subscriber: Subscriber<R>): R {
    this.createSubscription(subscriber);
    return this.select(selector, subscriber);
  }

  subscribe<R>(selector: (store: this) => R, subscriber: Subscriber<R>): () => void {
    const unsubscribe = this.createSubscription(subscriber);
    this.select(selector, subscriber);
    return unsubscribe;
  }

  select<R>(selector: (store: this) => R, subscriber: Subscriber<R>): R {
    let subscription = this._subscribers.get(subscriber);

    if (subscription) {
      if (subscription.referenceState === this._state && subscription.selector === selector) {
        return subscription.result;
      }
      subscription.selector = selector;
    } else {
      subscription = {
        subscriber,
        selector,
        enabled: false,
        needsUpdate: false,
      };
      this._subscribers.set(subscriber, subscription);
    }

    const internalState = this._state[STATE];
    subscription.referenceState = internalState.data;
    internalState.accessed.clear();

    const selection = Tracer.render(selector(this));

    if (!isStateEqual(subscription.result, selection)) {
      subscription.result = selection;
    }

    // subscribe to any accessed keys
    this._keySubs.removeByTarget(subscription);
    for (const key of internalState.accessed) {
      this._keySubs.add(key, subscription);
    }

    return subscription.result;
  }

  createSubscription<R = never>(subscriber: Subscriber<R>) {
    const existing = this._subscribers.get(subscriber);
    if (existing) {
      existing.enabled = true;
      if (existing.needsUpdate) {
        existing.needsUpdate = false;
        subscriber(this.select(existing.selector!, subscriber));
      }
      return () => this.unsubscribe(subscriber);
    }
    this._subscribers.set(subscriber, {
      subscriber,
      selector: undefined,
      enabled: true,
      needsUpdate: false,
    });
    return () => this.unsubscribe(subscriber);
  }

  unsubscribe<R = never>(subscriber: Subscriber<R>) {
    const subscription = this._subscribers.get(subscriber);
    if (!subscription) {
      return;
    }
    this._keySubs.removeByTarget(subscription);
    this._subscribers.delete(subscriber);
  }

  getSnapshot(): S {
    return this._state[STATE].data;
  }

  use<T>(selector: (store: this) => T, deps: DependencyList = [selector]): T {
    const [memoedSelector, callback] = useMemo(() => {
      const callback = (v: T) => {
        updateState(v);
        (selector as any).result = v;
      };
      (selector as any).result = this.select(selector, callback);
      return [selector as typeof selector & {result: T}, callback];
    }, deps);

    const [, updateState] = useState(memoedSelector.result);

    useEffect(() => {
      const unsubscribe = this.createSubscription(callback);

      const reselection = this.select(memoedSelector, callback);
      if (!isStateEqual(memoedSelector.result, reselection)) {
        memoedSelector.result = reselection;
        updateState(reselection);
      }

      return unsubscribe;
    }, [callback]);

    return memoedSelector.result;
  }

  require<T>(selector: (store: this) => T, deps?: DependencyList): ExcludesUndefined<T> {
    const context = useRef({
      resolve: () => {},
      unsubscribe: () => {},
    });
    const isMounted = useIsMounted();
    const [memoedSelector, callback] = useMemo(
      () => [
        selector,
        (v: T) => {
          if (!isMounted) {
            context.current.resolve();
          } else {
            updateState(v);
          }
        },
      ],
      deps ?? [selector],
    );
    if (!isMounted) {
      context.current.unsubscribe?.();
      context.current.unsubscribe = this.createSubscription(callback);
    }

    const result = this.select(memoedSelector, callback);
    (memoedSelector as any).result = result;
    const [, updateState] = useState(result);

    useEffect(() => {
      const unsubscribe = this.createSubscription(callback);
      const reselection = this.select(memoedSelector, callback);
      if (!isStateEqual((memoedSelector as any).result, reselection)) {
        updateState(reselection);
      }

      return unsubscribe;
    }, [callback]);

    if (!excludesUndefined(result)) {
      throw new Promise<void>((resolve) => (context.current.resolve = resolve));
    }

    return result;
  }

  private handleUpdates = batch((updatedKeysArgs: Set<string>[][]) => {
    const updatedKeys = new Set<string>();
    for (const keys of updatedKeysArgs.flat()) {
      for (const key of keys) {
        updatedKeys.add(key);
      }
    }
    const relevantSubscriptions = new Set<Subscription<this, S>>();
    for (const key of updatedKeys) {
      // if if the root is an array (anti-pattern), and it is mutated, we need to re-run all selectors
      if (key === '') {
        for (const subscription of this._keySubs.targetKeys()) {
          relevantSubscriptions.add(subscription);
        }
        break;
      }
      for (const subscription of this._keySubs.get(key) ?? EMPTY_LIST) {
        relevantSubscriptions.add(subscription);
      }
    }

    for (const subscription of relevantSubscriptions) {
      if (subscription.enabled) {
        const existingResult = subscription.result;
        const newResult = this.select(subscription.selector!, subscription.subscriber);
        if (existingResult !== newResult) {
          subscription.subscriber(newResult);
        }
      } else {
        subscription.needsUpdate = true;
      }
    }
  }, 1);

  private cleanup() {
    // the useHook can create subscriptions that are never enabled, and therefore never unsubscribed
    // to mitigate this, we can grab a copy of the subscribers and delete any that are not enabled after a second
    const entries = this._subscribers.entries();
    setTimeout(() => {
      for (const [subscriber] of entries) {
        const subscription = this._subscribers.get(subscriber);
        if (subscription && !subscription.enabled) {
          this._subscribers.delete(subscriber);
        }
      }
    }, 1_000);
  }
}

// an annotation to mark a method as a mutation
export function mutation<S extends object, Store extends MoStore<S>>(
  _target: Store | MoStore<S>,
  _propertyKey: string,
  descriptor: PropertyDescriptor,
) {
  const original = descriptor.value;
  descriptor.value = function (this: Store, ...args: never[]) {
    if (!this._isMutating) {
      return this.mutate(() => original.apply(this, args));
    } else {
      return original.apply(this, args);
    }
  };
  return descriptor;
}
