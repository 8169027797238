import {useMeasureRef} from '@/hooks/useMeasureRef';
import type {ForwardedRef} from 'react';
import React, {useCallback, useEffect, useRef} from 'react';
import type {OverlayProps, Placement} from 'react-aria';
import {
  FocusScope,
  OverlayContainer,
  mergeProps,
  useDialog,
  useModal,
  useObjectRef,
  useOverlay,
  useOverlayPosition,
  useOverlayTrigger,
} from 'react-aria';
import type {ButtonProps} from 'react-aria-components';
import {Button} from 'react-aria-components';
import {useOverlayTriggerState} from 'react-stately';

interface Props extends ButtonProps {
  children: React.ReactNode[];
  onOpenChange?: (isOpen: boolean) => void;
  onCommit?: () => void;
  onCancel?: () => void;
  placement?: Placement | 'center';
}

export const InlineEdit = React.forwardRef<HTMLButtonElement, Props>(
  ({children, onCommit, onCancel, placement = 'center', onOpenChange, ...buttonProps}, forwardedRef) => {
    let editor: React.ReactElement | null = null;
    let display = children.filter((child) => {
      if (React.isValidElement(child) && (DEV ? child.type.toString() === Editor.toString() : child.type === Editor)) {
        editor = child;
        return false;
      }
      return true;
    });

    const targetRef = useObjectRef<HTMLButtonElement>(forwardedRef);
    const overlayRef = useRef<HTMLDivElement>(null);
    const targetBounds = useMeasureRef<HTMLButtonElement>(targetRef);
    const overlayBounds = useMeasureRef<HTMLDivElement>(overlayRef);
    const state = useOverlayTriggerState({});
    const {overlayProps: triggerOverlayProps, triggerProps} = useOverlayTrigger({type: 'dialog'}, state, targetRef);
    let {overlayProps: positionProps} = useOverlayPosition({
      targetRef,
      overlayRef,
      placement: placement === 'center' ? 'bottom' : placement,
      offset:
        placement === 'center' && state.isOpen && overlayBounds && targetBounds
          ? (-targetBounds.height - overlayBounds.height) / 2
          : undefined,
      isOpen: state.isOpen,
    });

    const onClose = useCallback(() => {
      if (state.isOpen) {
        state.close();
        onCommit?.();
      }
    }, [state, onCommit]);

    const onCancelEdit = useCallback(() => {
      if (state.isOpen) {
        state.close();
        onCancel?.();
      }
    }, [state, onCancel]);

    useEffect(() => {
      if (state.isOpen) {
        onOpenChange?.(true);
      } else {
        onOpenChange?.(false);
      }
    }, [state.isOpen, onOpenChange]);

    return (
      <>
        <Button ref={targetRef} {...buttonProps} {...triggerProps}>
          {display}
        </Button>
        {state.isOpen && (
          <OverlayContainer>
            <InlinePopover
              {...triggerOverlayProps}
              {...positionProps}
              ref={overlayRef}
              isOpen={state.isOpen}
              onClose={onClose}
              onCancel={onCancelEdit}
              className="rounded-sm shadow-xl animate-in fade-in-5"
            >
              <div style={{minWidth: targetBounds?.width}}>{editor}</div>
            </InlinePopover>
          </OverlayContainer>
        )}
      </>
    );
  },
);

export interface EditorProps {
  className?: string;
  children: React.ReactNode;
}

export const Editor = React.forwardRef(({className, children}: EditorProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
});

interface InlinePopoverProps extends OverlayProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  className?: string;
}

const InlinePopover = React.forwardRef(
  (
    {children, isOpen, onClose, onCancel, className, ...props}: InlinePopoverProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const {overlayProps} = useOverlay(
      {
        onClose,
        isOpen,
        shouldCloseOnBlur: true,
        isDismissable: true,
      },
      ref as React.RefObject<HTMLDivElement>,
    );
    const {modalProps} = useModal();
    const {dialogProps} = useDialog({}, ref as React.RefObject<HTMLDivElement>);

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape') {
          onCancel();
        } else if (event.key === 'Enter' || event.key === 'Return') {
          onClose();
        }
      },
      [onClose],
    );

    return (
      <>
        <FocusScope restoreFocus autoFocus contain>
          <div
            className={className}
            ref={ref}
            {...mergeProps(overlayProps, dialogProps, props, modalProps)}
            onKeyDownCapture={onKeyDown}
            onMouseDown={stopPropagation}
          >
            {children}
          </div>
        </FocusScope>
      </>
    );
  },
);

const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};
