import {Time, TimeFormat, TimeUnit} from '@/lib/time';

interface Props {
  from?: string | number;
  to?: string | number;
  includeDuration?: boolean;
}

export function DateRange({from, to, includeDuration = true}: Props) {
  if (!from && !to) return null;

  const fromString = Time.format(from, TimeFormat.AbbreviatedDate);
  const toString = Time.format(to, TimeFormat.AbbreviatedDate);
  const duration = includeDuration && from && to ? Time.delta(Time.from(from), Time.from(to), TimeUnit.Days) : null;

  return (
    <div className="flex flex-row items-center gap-1 text-xs">
      <span className="text-gray-500">{from ? fromString : '?'}</span>
      <span>-</span>
      <span className="text-gray-500">{to ? toString : '?'}</span>
      {duration && (
        <span className="text-gray-400">
          ({duration} {duration > 1 ? 'days' : 'day'})
        </span>
      )}
    </div>
  );
}
