import {twMerge} from 'tailwind-merge';

interface Props {
  /** 0-1 */
  progress: number;
  className?: string;
  label?: string;
  children?: React.ReactNode;
}

export const ProgressPill: React.FC<Props> = ({progress, className, label, children}) => {
  const progressPercent = Math.round((progress * 100) / 5) * 5;
  return (
    <div
      className={twMerge(
        'animate-in zoom-in-50',
        'bg-gradient-to-r from-pink-500/15 via-pink-500/15 to-pink-500/5',
        'rounded-full border border-pink-500/25 px-2 py-0.5 text-xs text-pink-700',
        className,
      )}
      style={
        {
          '--tw-gradient-stops': `var(--tw-gradient-from) 0% ${progressPercent}%, var(--tw-gradient-to) ${progressPercent}% 100%`,
        } as React.CSSProperties
      }
    >
      {children ?? label ?? Math.round(progress * 100) + '%'}
    </div>
  );
};
