import {Image} from '@/components/Image';
import {ProjectBoardMenu} from '@/components/project-selector/ProjectBoardMenu';
import {Button, Popover} from '@/design-system';
import {contextStore} from '@/stores/context';
import {useState} from 'react';
import {MenuTrigger} from 'react-aria-components';

export function ProjectSelector() {
  const project = contextStore.use((s) => s.project, []);
  const [isOpen, onOpenChange] = useState(false);

  const onAction = async (projectId: number, boardId: number) => {
    onOpenChange(false);
    contextStore.changeProject(projectId, boardId);
  };

  return (
    <MenuTrigger aria-label="Change project" isOpen={isOpen} onOpenChange={onOpenChange}>
      <Button className="h-8 w-8" seamless>
        <Image id={project?.imageId} />
      </Button>
      <Popover className="rounded-md">
        <ProjectBoardMenu onAction={onAction} />
      </Popover>
    </MenuTrigger>
  );
}
