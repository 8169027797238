import {MoStore} from '@/stores/lib/MoStore';
import {debounce} from '@shared/lib/debounce';

const SESSION_STORE_PREFIX = 'ss-';

export class MoSessionStore<S extends object> extends MoStore<S> {
  constructor(
    state: S,
    private _name: string | undefined = undefined,
  ) {
    super(state);
    const stored = sessionStorage.getItem(this.storageName);
    if (stored) {
      this.update(JSON.parse(stored));
    }
    this.on('mutate', this.persist);
    this.afterLoad();
  }

  protected get storageName() {
    return SESSION_STORE_PREFIX + (this._name ?? this.constructor.name);
  }

  protected afterLoad() {}

  public persistNow() {
    sessionStorage.setItem(this.storageName, JSON.stringify(this.state));
  }

  private persist = debounce(
    (state: S) => {
      sessionStorage.setItem(this.storageName, JSON.stringify(state));
    },
    250,
    1000,
  );
}
