interface Props {
  message: string;
}

export function Error404({message}: Props) {
  // TODO

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-gray-500/50 delay-300 animate-in fade-in">
      <div className="flex h-full w-full flex-col items-center justify-center text-xl font-black">
        <span className="text-pink-700">404</span>
      </div>
      <div className="flex h-full w-full flex-col items-center justify-center text-lg font-semibold">
        <span className="text-pink-700">{message}</span>
      </div>
    </div>
  );
}
