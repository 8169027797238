import {TaskDescriptionEditor} from '@/components/task/TaskDescriptionEditor';
import {TaskLinksInput} from '@/components/task/TaskLinksInput';
import {TaskTagsInput} from '@/components/task/TaskTagsInput';
import {ChevronUp, GithubIcon, LinkIcon, TagIcon} from 'lucide-react';
import {useRef} from 'react';
import {Button} from 'react-aria-components';
import type {positionValues} from 'react-custom-scrollbars-2';
import {tv} from 'tailwind-variants';

interface Props {
  id: number;
  onScrollChange?: (scroll: positionValues) => void;
}

const styles = tv({
  slots: {
    fieldsList: 'flex flex-col gap-5 p-5',
  },
});

export const TaskDetailsView: React.FC<Props> = ({id}) => {
  const detailsRef = useRef<HTMLDivElement>(null);
  const onDetailsPress = () => {
    detailsRef.current?.scrollIntoView({behavior: 'smooth'});
  };
  const {fieldsList} = styles();

  return (
    <div className="flex h-full flex-col gap-10 py-5">
      <div className="px-3 pb-2">
        <TaskDescriptionEditor id={id} />
      </div>
      <div ref={detailsRef} className="flex w-full flex-col">
        <Button
          className="sticky bottom-full top-0 flex h-8 shrink-0 items-center border-b border-gray-800/20 bg-gray-100 px-2 text-xs font-semibold text-gray-800/70 shadow"
          onPress={onDetailsPress}
        >
          Details
          <ChevronUp className="ml-auto h-5 w-5" />
        </Button>
        <div className={fieldsList()}>
          <FieldRow>
            <FieldHeader Icon={TagIcon}>Tags</FieldHeader>
            <FieldValue>
              <TaskTagsInput taskId={id} />
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldHeader Icon={LinkIcon}>Linked Tasks</FieldHeader>
            <FieldValue>
              <TaskLinksInput taskId={id} />
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldHeader Icon={GithubIcon}>GitHub </FieldHeader>
          </FieldRow>
        </div>
      </div>
    </div>
  );
};

const FieldRow = ({children}: {children: React.ReactNode}) => {
  return <div className="flex min-h-7 flex-row gap-2">{children}</div>;
};

const FieldHeader = ({Icon, children}: {Icon: React.FC<React.SVGProps<SVGSVGElement>>; children: React.ReactNode}) => {
  return (
    <div className="w-40">
      <div className="flex items-center gap-0.5 pt-1 text-xs font-semibold uppercase text-gray-800/50">
        <Icon className="h-5" />
        {children}
      </div>
    </div>
  );
};

const FieldValue = ({children}: {children: React.ReactNode}) => {
  return <div className="w-full text-sm text-gray-800">{children}</div>;
};
