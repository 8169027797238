import React from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  children: React.ReactNode;
  invisible?: boolean;
  content: React.ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

const styles = tv({
  base: 'absolute right-0 top-0 min-h-4 min-w-4 -translate-y-1/2 translate-x-1/2 rounded-full bg-red-500 px-1 text-center text-xs font-semibold leading-4 text-white shadow-sm shadow-black/50',
  variants: {
    invisible: {
      true: 'invisible',
    },
    size: {
      sm: 'scale-80',
      md: '',
      lg: 'scale-125',
    },
  },
});

export const Badge: React.FC<Props> = ({children, invisible, content, className, size = 'md'}) => {
  return (
    <div className="relative">
      {children}
      <div
        className={twMerge(styles({size, invisible: content == null || content == '' ? true : invisible}), className)}
      >
        <div className="mt-[-.03125rem]">{content}</div>
      </div>
    </div>
  );
};
