import {Worker} from '@/app-service-worker/Worker';
import {NewTaskRow} from '@/components/backlog/NewTaskRow';
import {SprintDropZone} from '@/components/backlog/SprintDropZone';
import {TaskListItem} from '@/components/backlog/TaskListItem';
import {dragStore} from '@/design-system/dnd/dragStore';
import {useCollection} from '@/design-system/lists/Collection';
import {DropLocation, type ListStore, type Selection} from '@/design-system/lists/ListStore';
import type {DndOptions, ListItemRenderProps} from '@/design-system/lists/VList';
import {VList} from '@/design-system/lists/VList';
import {tasksFromDragData, tasksToDragData} from '@/lib/dragHelpers';
import {authStore} from '@/stores/auth';
import {contextStore} from '@/stores/context';
import {taskStore} from '@/stores/task';
import {toastStore} from '@/stores/toast';
import type {BacklogTaskListItem} from '@/stores/ui/backlogStore';
import type {TaskFilter} from '@shared/filters/TaskFilter';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import type {Ref} from 'react';
import React, {forwardRef, useMemo} from 'react';

interface Props {
  filter: TaskFilter;
  onSelectionChange?: (selection: Selection<number | string>) => void;
  selection?: Set<number | string>;
  onUnprocessedKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export interface TaskListItemType extends Omit<EntityListItem, 'id'> {
  id: number | string;
}

export const TaskList = forwardRef(
  (
    {filter, onSelectionChange, selection, onUnprocessedKeyDown}: Props,
    ref: Ref<ListStore<TaskListItemType, number | string>>,
  ) => {
    let taskList = taskStore.use(
      (s) => {
        const list = s.getList(filter) as TaskListItemType[] | undefined;
        if (list) {
          return [...list, {id: filter.toString(), text: '+ New task', rank: 'Ω'}];
        }
        return list ? [...list] : undefined;
      },
      [filter],
    );
    const items = useCollection(taskList ?? []);
    const ItemRenderer = useMemo(
      () => (props: ListItemRenderProps<TaskListItemType, number | string>) =>
        typeof props.id === 'string' ? (
          <NewTaskRow key={props.id} sprintId={filter.sprintId ?? null} {...props} />
        ) : (
          <TaskListItem key={props.id} {...props} />
        ),
      [filter.sprintId],
    );

    const dnd: DndOptions<TaskListItemType, number | string> = useMemo(
      () => ({
        mode: items.list.filter((i) => typeof i.id === 'number').length === 0 ? 'none' : 'reorder/insert',
        source: `TaskList|${filter.sprintId ?? ''}`,
        getDragData: (keys) => {
          const ids = [...keys].filter((id) => typeof id !== 'string'); // exclude new task row
          if (ids.length === 0) return undefined;

          return tasksToDragData(
            ids
              .map((id) => items.list[items.idToIndex[id]])
              .sort((a, b) => a.rank.localeCompare(b.rank))
              .map(
                (i) =>
                  ({
                    ...i,
                    projectId: contextStore.projectId,
                    accountId: authStore.accountId,
                  }) as BacklogTaskListItem,
              ),
          );
        },
        allowedEffect: (index, _location, dataTypes) => {
          // cannot drop on new task row
          if (typeof items.list[index].id === 'string') return 'none';

          if (dataTypes.includes('momentum/task')) {
            const data = dragStore.state.data;
            if (!data) return 'move'; // dragging from outside the app, can't view data until drop

            const tasks = JSON.parse(data['momentum/task']);
            if (tasks.length === 0) return 'none';

            const projectId = tasks[0].projectId;
            const accountId = tasks[0].accountId;
            if (projectId !== contextStore.projectId || accountId !== authStore.accountId) return 'none';

            return 'move';
          }
          return 'none';
        },
        onDrop: (index, location, e) => {
          try {
            const tasks = tasksFromDragData(e.dataTransfer);
            if (!tasks) return 'none';

            const taskIds = tasks.map((i) => i.id);
            const targetId = items.list[index].id as number;
            const sprintId = filter.sprintId;
            const beforeTaskId = location === DropLocation.Above ? targetId : undefined;
            const afterTaskId = location === DropLocation.Below ? targetId : undefined;

            Worker.moveTasks({taskIds, sprintId, beforeTaskId, afterTaskId});
            return 'move';
          } catch (e) {
            console.error(e);
            toastStore.error({
              title: 'Failed to move tasks',
              message: 'There was a problem moving the selected tasks. See console for details.',
              ttl: 5000,
            });
          }
          return 'none';
        },
      }),
      [items, filter.sprintId],
    );

    if (!taskList) return <></>;
    if (taskList.length === 0) return <SprintDropZone sprintId={filter.sprintId ?? null} />;

    return (
      <VList<TaskListItemType, number | string>
        items={items}
        itemHeight={32}
        itemGap={1}
        onSelectionChange={(selection) => onSelectionChange?.(selection)}
        selection={selection}
        renderItem={ItemRenderer}
        onKeyDown={onUnprocessedKeyDown}
        className="group/taskList"
        ref={ref}
        dnd={dnd}
      />
    );
  },
);
