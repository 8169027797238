type OnEvict<K, T> = (key: K, value: T) => void;
const MIN_INTERVAL = 60 * 1000;

export class TtlCache<K, T> {
  private map = new Map<K, [T, number, OnEvict<K, T> | undefined]>();
  private nextEviction = Date.now();
  private evictionTimer: number | undefined;

  constructor(private defaultTtl: number = 10_000) {}

  public put(key: K, value: T, ttl?: number, onEvict?: OnEvict<K, T>) {
    const evictionTime = Date.now() + (ttl ?? this.defaultTtl);
    this.map.set(key, [value, evictionTime, onEvict]);
    if (this.nextEviction > evictionTime || this.evictionTimer === undefined) {
      this.scheduleEviction(evictionTime);
    }
  }

  public has(key: K): boolean {
    return this.map.has(key);
  }

  public get(key: K): T | undefined {
    if (!this.map.has(key)) return undefined;
    const [value] = this.map.get(key)!;
    return value;
  }

  public remove(key: K) {
    this.map.delete(key);
  }

  public getAndRemove(key: K): T | undefined {
    if (!this.map.has(key)) return undefined;
    const [value] = this.map.get(key)!;
    this.map.delete(key);
    return value;
  }

  private scheduleEviction(evictionTime: number) {
    clearTimeout(this.evictionTimer);
    this.evictionTimer = self.setTimeout(this.performEviction, evictionTime - Date.now());
    this.nextEviction = evictionTime;
  }

  public performEviction = () => {
    const now = Date.now();
    let minEvictionTime = now + MIN_INTERVAL;
    for (const [key, [value, evictionTime, onEvict]] of this.map.entries()) {
      if (evictionTime <= now) {
        this.map.delete(key);
        if (onEvict) {
          try {
            onEvict(key, value);
          } catch (e) {
            console.error(e);
          }
        }
      } else {
        if (minEvictionTime > evictionTime) {
          minEvictionTime = evictionTime;
        }
        break;
      }
    }
    this.scheduleEviction(minEvictionTime);
  };
}
