import {Worker} from '@/app-service-worker/Worker';
import {DeltaTime} from '@/components/time/DeltaTime';
import {Button, InputWell, TextInput} from '@/design-system';
import {DateInput} from '@/design-system/date-time/DateInput';
import {TextArea} from '@/design-system/TextArea';
import {useMoState} from '@/hooks/useMoState';
import {TimeUnit} from '@/lib/time';
import {timeMonitor} from '@/lib/TimeMonitor';
import {sprintStore} from '@/stores/sprint';
import {EntityType} from '@shared/EntityType';
import type {Operation} from '@shared/models/Mutation';
import type {Sprint} from '@shared/models/Sprint';
import {SprintState} from '@shared/models/Sprint';
import {deepEqual} from 'fast-equals';
import {useEffect} from 'react';

interface Props {
  close: () => void;
  sprintId?: number;
  projectId?: number;
  boardId?: number;
  templateId?: number;
}

function constrainDate(date: Date | string, min?: Date | string, max?: Date | string) {
  if (typeof date === 'string') date = new Date(date);
  if (typeof min === 'string') min = new Date(min);
  if (typeof max === 'string') max = new Date(max);

  // In momentum, we always set sprint times to noon when editing because we believe time isn't important here
  // especially since Jira will set the time when you start the sprint regardless of what you put in
  date.setHours(12, 0, 0, 0);
  if (min && date <= min) {
    date = new Date(min);
    date.setSeconds(60);
    return date;
  }
  if (max && date >= max) {
    date = new Date(max);
    date.setHours(12, 0, 0, 0);
    return date;
  }
  return date;
}

export const EditSprintModal: React.FC<Props> = ({sprintId, close, projectId, boardId, templateId}) => {
  const [storedSprint, template] = sprintStore.require((s) => {
    const template = templateId ? s.getById(templateId) : null;

    const sprint = sprintId ? s.getById(sprintId) : ({projectId, boardId} as Partial<Sprint>);

    return [sprint!, template];
  })!;

  const [sprint, updateSprint, setEnd, setName] = useMoState(
    () => (template ? sprintStore.deriveSprintFromTemplate(template) : storedSprint),
    'endsAt',
    'name',
  );
  const canEditStart = !sprint.state || sprint.state === SprintState.Future;
  const canEditEnd = !sprint.state || sprint.state !== SprintState.Closed;

  const onStartChange = (value: Date | undefined) => {
    if (!canEditStart) return;
    updateSprint((s) => {
      s.startsAt = value && constrainDate(value, new Date()).toISOString();
      if (s.endsAt && s.startsAt) {
        s.endsAt = constrainDate(new Date(s.endsAt), new Date(s.startsAt)).toISOString();
      } else if (!value) {
        s.endsAt = undefined;
      }
    });
  };

  const onEndChange = (value: Date | undefined) => {
    if (!canEditEnd) return;
    let min = new Date();
    if (sprint.startsAt) {
      const startsAt = new Date(sprint.startsAt);
      if (min < startsAt) min = startsAt;
    }

    setEnd(value && constrainDate(value, min).toISOString());
  };

  const onGoalChange = (value: string) => {
    updateSprint((s) => {
      // we need to differentiate between empty and undefined for canUpdate
      if (!value && !Object.keys(storedSprint).includes('goal')) {
        delete s.goal;
      } else {
        s.goal = value;
      }
    });
  };

  useEffect(() => {
    // apply validation constraints on load
    if (storedSprint.startsAt && canEditStart) {
      onStartChange(storedSprint.startsAt ? new Date(storedSprint.startsAt) : undefined);
    } else if (storedSprint.endsAt && canEditEnd) {
      onEndChange(storedSprint.endsAt ? new Date(storedSprint.endsAt) : undefined);
    }
  }, [storedSprint, canEditStart]);

  useEffect(() => {
    return timeMonitor.on(TimeUnit.Minutes, () => {
      if (sprint.startsAt && canEditStart) {
        onStartChange(sprint.startsAt ? new Date(sprint.startsAt) : undefined);
      } else if (sprint.endsAt && canEditEnd) {
        onEndChange(sprint.endsAt ? new Date(sprint.endsAt) : undefined);
      }
    });
  }, [sprint, canEditStart]);

  const onSave = () => {
    const s = {
      ...sprint,
      startsAt: sprint.startsAt && constrainDate(sprint.startsAt, new Date())?.toISOString(),
      endsAt: sprint.endsAt && constrainDate(sprint.endsAt, sprint.startsAt ?? new Date())?.toISOString(),
    };

    if (sprintId) {
      Worker.mutateEntity({
        entity: EntityType.Sprint,
        id: sprintId.toString(),
        operations: operationsFromDiff(storedSprint, s),
      });
    } else {
      Worker.postEntity(EntityType.Sprint, s);
    }
    close();
  };

  const canSave = sprint.name && (!deepEqual(sprint, storedSprint) || !sprint.id);

  return (
    <div className="flex flex-col gap-5">
      <InputWell className="p-0">
        <TextInput
          autoFocus
          initialValue={sprint.name}
          className="w-full rounded-sm bg-transparent px-2 py-1 font-semibold text-gray-600 placeholder:opacity-50"
          onValueChange={setName}
          placeholder="Sprint name"
        />
      </InputWell>
      <div className="flex flex-row items-start gap-2">
        <div>
          <Header>Start</Header>
          <InputWell className="p-0" disabled={!canEditStart}>
            <DateInput
              value={sprint.startsAt ? new Date(sprint.startsAt) : undefined}
              aria-label="Sprint start"
              onCommit={onStartChange}
              disabled={!canEditStart}
              minDate={canEditStart ? new Date() : undefined}
            />
          </InputWell>
        </div>
        <div className="relative flex h-9 min-w-16 flex-grow items-end justify-center border-b border-b-gray-300 px-4 before:absolute before:-bottom-1.5 before:left-0 before:h-3 before:border-l before:border-gray-300 after:absolute after:-bottom-1.5 after:right-0 after:h-3 after:border-r after:border-gray-300">
          {sprint.startsAt && sprint.endsAt && (
            <DeltaTime
              from={sprint.startsAt}
              to={sprint.endsAt}
              className="text-xs font-thin text-gray-400"
              precision={TimeUnit.Days}
            />
          )}
        </div>
        <div>
          <div className="flex justify-between">
            <Header>End</Header>
          </div>
          <InputWell className="p-0">
            <DateInput
              value={sprint.endsAt}
              aria-label="Sprint end"
              onCommit={onEndChange}
              disabled={!canEditEnd}
              minDate={
                sprint.startsAt ? new Date(Math.max(new Date(sprint.startsAt).getTime(), Date.now())) : undefined
              }
            />
          </InputWell>
        </div>
      </div>
      <div>
        <Header>Sprint Goal</Header>
        <InputWell className="p-0">
          <TextArea
            className="w-full rounded-sm bg-transparent px-2 py-1 text-gray-600"
            onValueChange={onGoalChange}
            initialValue={sprint.goal}
          />
        </InputWell>
      </div>
      <div className="flex flex-row items-center justify-end gap-4">
        <Button rounded outline onPress={close}>
          Cancel
        </Button>
        <Button primary rounded isDisabled={!canSave} onPress={onSave}>
          {sprintId ? 'Update' : 'Create'}
        </Button>
      </div>
    </div>
  );
};

function Header({children}: {children: React.ReactNode}) {
  return <h3 className="mb-1.5 text-xs font-semibold uppercase text-gray-400">{children}</h3>;
}

function operationsFromDiff(before: Record<string, any>, after: Record<string, any>) {
  const operation: Operation = {
    replace: {},
    delete: [],
  };
  const keys = new Set([...Object.keys(before), ...Object.keys(after)]);
  for (const key of keys) {
    if (after[key] !== before[key]) {
      if (after[key] == null) {
        operation.delete!.push(key);
      } else {
        operation.replace![key] = {value: after[key]};
      }
    }
  }
  return operation;
}
