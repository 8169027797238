import clsx from 'clsx';
import React from 'react';
import {Link, useRoute} from 'wouter';

interface NavIconProps {
  icon: React.ReactNode;
  selected?: boolean;
  to?: string;
  match?: RegExp;
}

const NavIcon: React.FC<NavIconProps> = ({icon, to, match}) => {
  const [selected] = useRoute(match ?? to ?? '#');

  return (
    <Link
      href={to ?? '#'}
      className={clsx(
        'flex h-8 w-8 cursor-pointer items-center justify-center rounded-md bg-white text-white',
        'hover:bg-opacity-20',
        selected ? 'bg-opacity-20 opacity-100' : 'bg-opacity-0 opacity-50',
      )}
    >
      {icon}
    </Link>
  );
};

export default NavIcon;
