export enum TimeUnit {
  Seconds,
  Minutes,
  Hours,
  Days,
  Weeks,
  Months,
  Years,
  None,
}

export enum TimeFormat {
  AbbreviatedDate,
  AbbreviatedDateWithYear,
  ShortDate,
  LongDate,
  // for dev purposes
  PrecisionTime,
}

function truncate(value: number, divisor: number): number {
  return Math.trunc(value / divisor) * divisor;
}

function round(value: number, divisor: number): number {
  return Math.round(value / divisor) * divisor;
}

export const Time = {
  /** current time in milliseconds */
  now: () => Date.now(),
  from: (date: string | number) => (typeof date === 'string' ? new Date(date).getTime() : date),
  trunc(time: number, unit: TimeUnit = TimeUnit.Seconds) {
    switch (unit) {
      case TimeUnit.Seconds:
        return truncate(time, 1000);
      case TimeUnit.Minutes:
        return truncate(time, 60_000);
      case TimeUnit.Hours:
        return truncate(time, 3600_000);
      case TimeUnit.Days:
        return truncate(time, 3600_000 * 24);
      case TimeUnit.Weeks:
        return truncate(time, 3600_000 * 24 * 7);
      case TimeUnit.Months:
        throw new Error('Time.trunc: Month not implemented');
      case TimeUnit.Years:
        throw new Error('Time.trunc: Year not implemented');
      case TimeUnit.None:
        return time;
    }
  },
  round(time: number, unit: TimeUnit = TimeUnit.Seconds) {
    switch (unit) {
      case TimeUnit.Seconds:
        return round(time, 1000);
      case TimeUnit.Minutes:
        return round(time, 60_000);
      case TimeUnit.Hours:
        return round(time, 3600_000);
      case TimeUnit.Days:
        return round(time, 3600_000 * 24);
      case TimeUnit.Weeks:
        return round(time, 3600_000 * 24 * 7);
      case TimeUnit.Months:
        return round(time, 3600_000 * 24 * 30.4375);
      case TimeUnit.Years:
        return round(time, 3600_000 * 24 * 365.25);
      case TimeUnit.None:
        return time;
    }
  },
  divide(time: number, unit: TimeUnit): number {
    switch (unit) {
      case TimeUnit.Seconds:
        return time / 1_000;
      case TimeUnit.Minutes:
        return time / 60_000;
      case TimeUnit.Hours:
        return time / 3600_000;
      case TimeUnit.Days:
        return time / (3600_000 * 24);
      case TimeUnit.Weeks:
        return time / (3600_000 * 24 * 7);
      case TimeUnit.Months:
        return time / (3600_000 * 24 * 30.4375);
      case TimeUnit.Years:
        return time / (3600_000 * 24 * 365.25);
      case TimeUnit.None:
        return time;
    }
  },
  localDateTrunc<T extends number | string | Date>(dateTime: T, unit: TimeUnit = TimeUnit.Seconds): T {
    const date = new Date(dateTime);
    switch (unit) {
      case TimeUnit.Years:
        date.setMonth(0, 1);
        date.setHours(0, 0, 0, 0);
        break;
      case TimeUnit.Months:
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        break;
      case TimeUnit.Weeks:
        date.setDate(date.getDate() - date.getDay());
        date.setHours(0, 0, 0, 0);
        break;
      case TimeUnit.Days:
        date.setHours(0, 0, 0, 0);
        break;
      case TimeUnit.Hours:
        date.setMinutes(0, 0, 0);
        break;
      case TimeUnit.Minutes:
        date.setSeconds(0, 0);
        break;
      case TimeUnit.Seconds:
        date.setMilliseconds(0);
        break;
      case TimeUnit.None:
        return dateTime;
    }
    if (dateTime instanceof Date) {
      return date as T;
    } else if (typeof dateTime === 'string') {
      return date.toISOString() as T;
    }
    return date.getTime() as T;
  },
  add(dateTime: number, amount: number, unit: TimeUnit = TimeUnit.Seconds) {
    return new Date(dateTime).getTime() + this.millis(amount, unit);
  },
  millis(amount: number, unit: TimeUnit = TimeUnit.Seconds) {
    switch (unit) {
      case TimeUnit.Seconds:
        return amount * 1000;
      case TimeUnit.Minutes:
        return amount * 60_000;
      case TimeUnit.Hours:
        return amount * 3600_000;
      case TimeUnit.Days:
        return amount * 3600_000 * 24;
      case TimeUnit.Weeks:
        return amount * 3600_000 * 24 * 7;
      case TimeUnit.Months:
        throw new Error('Time.millis: Month not implemented');
      case TimeUnit.Years:
        throw new Error('Time.millis: Year not implemented');
      case TimeUnit.None:
        return amount;
    }
  },
  delta(from: number, to: number, unit: TimeUnit = TimeUnit.Seconds) {
    return Math.abs(Math.round(this.divide(to, unit) - this.divide(from, unit)));
  },
  format(date: Date | number | string | undefined, format: TimeFormat = TimeFormat.AbbreviatedDate) {
    if (!date) return '';
    const dateTime = new Date(date);
    const showYear = new Date().getFullYear() !== dateTime.getFullYear();
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: showYear ? 'numeric' : undefined,
    };

    switch (format) {
      case TimeFormat.AbbreviatedDate:
        return dateTime.toLocaleDateString(undefined, options);
      case TimeFormat.AbbreviatedDateWithYear:
        return dateTime.toLocaleDateString(undefined, {...options, year: 'numeric'});
      case TimeFormat.ShortDate:
        return dateTime.toLocaleDateString(undefined, {dateStyle: 'short'});
      case TimeFormat.LongDate:
        return dateTime.toLocaleDateString(undefined, {dateStyle: 'long'});
      case TimeFormat.PrecisionTime:
        return (
          dateTime.toLocaleTimeString(undefined, {hour12: false}) +
          `.${dateTime.getMilliseconds().toString().padEnd(3, '0')}`
        );
    }
  },
  today() {
    return;
  },
};
