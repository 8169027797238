import RemoveIcon from '@/assets/icon-remove.svg?react';
import {Image} from '@/components/Image';
import {taskStore} from '@/stores/task';
import {taskTypeStore} from '@/stores/taskType';
import React from 'react';
import {Button} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

export interface TaskLabelProps {
  id?: number;
  onRemove?: (id: number) => void;
  className?: string;
  fallbackText?: string | null;
  style?: 'full' | 'label';
}

const styles = tv({
  slots: {
    container:
      'flex max-w-56 flex-row break-all rounded-sm bg-gray-600/15 p-[2px] pl-1 pr-2 text-xs font-medium text-gray-600',
    image: 'mr-2 h-4 w-4 flex-shrink-0',
    button: '-m-0.5 -mr-1.5 ml-0.5 p-0.5 opacity-30 transition-all hover:text-red-500 hover:opacity-100',
  },
  variants: {
    style: {
      label: {
        container: 'bg-purple-300/30 px-1 py-0 font-medium text-purple-900/60',
        image: 'hidden',
      },
      full: {},
    },
  },
});

export const TaskLabel: React.FC<TaskLabelProps> = ({id, onRemove, className, fallbackText, style}) => {
  const task = taskStore.use((s) => s.getById(id), [id]);
  const taskType = taskTypeStore.use((s) => s.getById(task?.typeId), [task?.typeId]);
  const {container, image, button} = styles({style});

  return (
    <div className={twMerge(container(), className)}>
      {<Image className={image()} id={taskType?.iconImageId} />}
      <div className="line-clamp-1">{task?.title ?? fallbackText}</div>
      {onRemove && id && (
        <Button onPress={() => onRemove(id)} className={button()}>
          <RemoveIcon className="h-3.5 w-3.5" />
        </Button>
      )}
    </div>
  );
};
