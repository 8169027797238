export interface JWTPayload {
  sub: number;
  'context.accountId': number;
  exp: number;
}

export const parseToken = (token: string): JWTPayload | null => {
  if (!token) return null;
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload;
  } catch (e) {
    console.error('[JWT] Error parsing token', e);
    throw new Error('Invalid auth token');
  }
};

export const isTokenExpired = (token: string): boolean => {
  const payload = parseToken(token);
  if (!payload) return true;
  return Date.now() >= payload.exp * 1000;
};

export interface JWTContext {
  actorId: number;
  accountId: number;
  expiresAt: Date;
}

export const contextFromToken = (token: string): JWTContext | null => {
  const payload = parseToken(token);
  if (!payload) return null;
  return {
    actorId: payload.sub,
    accountId: payload['context.accountId'],
    expiresAt: new Date(payload.exp * 1000),
  };
};
