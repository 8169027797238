import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {Component} from '@shared/models/Component';
import type {DB} from 'src-sw/db/db';

export class ComponentFilter extends EntityFilter<IComponentFilter> {
  readonly type = EntityType.Component;
  readonly projectId?: number;
  readonly startsWith?: string;
  readonly active?: boolean;

  constructor(filter: Omit<IComponentFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, entity: Component): Promise<[boolean, string, RankComparator]> {
    if (entity.active !== (this.active ?? true)) {
      return [false, entity.name, RANK_COMPARATOR_ASC];
    }

    if (this.projectId !== undefined && entity.projectId !== this.projectId) {
      return [false, entity.name, RANK_COMPARATOR_ASC];
    }

    if (this.startsWith !== undefined && !entity.name.startsWith(this.startsWith)) {
      return [false, entity.name, RANK_COMPARATOR_ASC];
    }

    return [true, entity.name, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.projectId ?? ''}|${this.startsWith ?? ''}|${this.active ?? ''}`;
  }
}
export type IComponentFilter = PublicPropertiesOf<ComponentFilter>;
export function isComponentFilter(filter: IEntityFilter): filter is ComponentFilter {
  return filter.type === EntityType.Component;
}
