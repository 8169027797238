import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC, RANK_COMPARATOR_DESC} from '@shared/filters/EntityFilter';
import type {Sprint, SprintState} from '@shared/models/Sprint';
import type {DB} from 'src-sw/db/db';

export class SprintFilter extends EntityFilter<ISprintFilter> {
  readonly type = EntityType.Sprint;
  readonly projectId?: number;
  readonly state?: SprintState[];
  readonly rankByCompletedAt?: 'Asc' | 'Desc';
  readonly limit?: number;

  public constructor(filter: Omit<ISprintFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, sprint: Sprint): Promise<[boolean, string, RankComparator]> {
    const rank = this.rankByCompletedAt
      ? (sprint.completedAt ?? '')
      : [sprint.startsAt ?? 'null', sprint.name].join(':');
    const rankComparator = this.rankByCompletedAt === 'Asc' ? RANK_COMPARATOR_ASC : RANK_COMPARATOR_DESC;

    if (this.projectId !== undefined && sprint.projectId !== this.projectId) {
      return [false, rank, rankComparator];
    }

    if (this.state !== undefined && !this.state.includes(sprint.state)) {
      return [false, rank, rankComparator];
    }

    return [true, rank, rankComparator];
  }

  public toString(): string {
    return `${this.type}|${this.projectId ?? ''}|${this.state?.join(',') ?? ''}`;
  }
}
export type ISprintFilter = PublicPropertiesOf<SprintFilter>;
export function isSprintFilter(filter: IEntityFilter): filter is SprintFilter {
  return filter.type === EntityType.Sprint;
}
