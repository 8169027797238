import {EntityType} from '@shared/EntityType';
import type {AnyEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import {StatusCategory, type Status} from '@shared/models/Status';
import type {DB} from 'src-sw/db/db';

export class StatusFilter extends EntityFilter<IStatusFilter> {
  private static readonly categoryOrder = [
    StatusCategory.NotStarted,
    StatusCategory.InProgress,
    StatusCategory.Done,
    StatusCategory.Unknown,
  ];

  public readonly type = EntityType.Status;
  public readonly projectId?: number;

  constructor(filter: Omit<IStatusFilter, 'type'> = {}) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, status: Status): Promise<[boolean, string, RankComparator]> {
    if (this.projectId !== undefined && status.projectId !== this.projectId) {
      return [false, this.rankFromStatus(status), RANK_COMPARATOR_ASC];
    }

    return [true, this.rankFromStatus(status), RANK_COMPARATOR_ASC];
  }

  public rankFromStatus(status: Status): string {
    return `${StatusFilter.categoryOrder.indexOf(status.category)}|${status.name}`;
  }

  public toString(): string {
    return `${this.type}|${this.projectId ?? ''}`;
  }
}

export type IStatusFilter = PublicPropertiesOf<StatusFilter>;
export function isStatusFilter(filter: AnyEntityFilter): filter is StatusFilter {
  return filter.type === EntityType.Status;
}
