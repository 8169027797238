import {Worker} from '@/app-service-worker/Worker';
import {EditSprintModal} from '@/components/backlog/sprint/EditSprintModal';
import {useDrop} from '@/design-system/dnd/dragHooks';
import {dragStore} from '@/design-system/dnd/dragStore';
import {Modal} from '@/design-system/modal/Modal';
import {onDragOverAllowTaskMove, tasksFromDragData} from '@/lib/dragHelpers';
import {sprintStore} from '@/stores/sprint';
import {PlusIcon} from 'lucide-react';
import {Button, DialogTrigger} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface Props {
  templateId?: number;
}

const titleStyles = tv({
  base: 'transition-color-opacity cursor-default font-bold text-pink-700/20 opacity-0',
  variants: {
    isDragging: {
      true: 'opacity-100',
    },
  },
});

const dropStyles = tv({
  base: 'transition-color-opacity flex h-8 w-full items-center justify-center rounded-sm border-2 border-dashed border-pink-500/15 bg-pink-500/5 text-sm text-pink-700/30 opacity-0 group-hover/sprint:opacity-100',
  variants: {
    isDragging: {
      true: 'border-pink-500/30 bg-pink-500/5 opacity-70',
    },
    isDropTarget: {
      true: 'border-solid border-pink-500/60 bg-pink-500/10 text-pink-700/80 opacity-100',
    },
  },
});

export function NewSprintGroup({templateId}: Props) {
  // TODO: TEMPORARY--use template until we track the current project/board
  const template = sprintStore.use((s) => (templateId ? s.getById(templateId) : undefined), [templateId]);

  const isDragging = dragStore.use((s) => s.state.isDragging && s.state.dragTypes.includes('momentum/task'), []);

  const {
    dropProps,
    renderProps: {isDropTarget},
  } = useDrop(
    {
      onDrop(e) {
        const tasks = tasksFromDragData(e.dataTransfer);
        if (!tasks) return 'none';

        if (!template) {
          console.error('No sprint template to create from');
          return 'none';
        }

        sprintStore.createFromTemplate(template).then((newSprintId) => {
          const taskIds = tasks.map((i) => i.id);
          Worker.moveTasks({taskIds, sprintId: newSprintId});
        });
        return 'move';
      },
      onDragOver: onDragOverAllowTaskMove,
    },
    [template],
  );

  return (
    <div className="group/sprint mx-5 mb-4">
      <div className={titleStyles({isDragging})}>New sprint</div>
      <div {...dropProps} className={dropStyles({isDragging, isDropTarget})}>
        {isDragging ? (
          'Drop to create a new sprint'
        ) : (
          <DialogTrigger>
            <Button className="transition-color-opacity flex flex-row items-center gap-1 rounded-sm px-1 text-xs text-pink-700/40 hover:text-pink-700/80 group-hover/sprint:opacity-100 focus-visible:text-pink-700/80 focus-visible:opacity-100">
              <PlusIcon className="h-4 w-4" /> Create new sprint
            </Button>
            <Modal className="modal-sm">
              {({close}) => {
                return (
                  <EditSprintModal
                    close={close}
                    projectId={template?.projectId}
                    boardId={template?.boardId}
                    templateId={templateId}
                  />
                );
              }}
            </Modal>
          </DialogTrigger>
        )}
      </div>
    </div>
  );
}
