import type {TooltipProps} from 'react-aria-components';
import {Tooltip as AriaTooltip, OverlayArrow} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface Props extends Omit<TooltipProps, 'children'> {
  children: React.ReactNode | (() => React.ReactNode);
}

// Note: positioning and animation in aria.css
const tooltipStyles = tv({
  base: 'react-aria-Tooltip box-shadow max-w-96 rounded-sm bg-gray-800 px-1 py-0.5 text-xs text-gray-100 opacity-80 outline-none',
});

const arrowStyles = tv({
  base: 'block fill-gray-800',
});

export function Tooltip({children, ...props}: Props) {
  return (
    <AriaTooltip {...props} className={tooltipStyles}>
      <OverlayArrow>
        <svg className={arrowStyles()} width={8} height={8} viewBox="0 0 8 8">
          <path d="M0 0 L4 4 L8 0" />
        </svg>
      </OverlayArrow>
      {typeof children === 'function' ? children() : children}
    </AriaTooltip>
  );
}
