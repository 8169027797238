import {debounce} from '@shared/lib/debounce';
import {useCallback, useEffect, useState} from 'react';

/**
 * @param initialValue initial value (not debounced)
 * @param delay delay in ms (MUST BE FIXED / CANNOT BE CHANGED)
 * @param limit (optional) maximum delay between calls
 * @returns [value, setValue] where setValue is debounced by delay
 */
export function useDebouncedState<T>(initialValue: T, delay: number, limit?: number): [T, (value: T) => void] {
  const [value, setValue] = useState(initialValue);

  const debouncedSetValue = useCallback(
    debounce(
      (value: T) => {
        setValue(value);
      },
      delay,
      limit,
    ),
    [],
  );

  useEffect(() => () => debouncedSetValue.cancel(), []);

  return [value, debouncedSetValue];
}
