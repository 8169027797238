export type ExcludesUndefined<T> = T extends object
  ? {[K in keyof T]: Exclude<T[K], undefined>}
  : Exclude<T, undefined>;

/**
 * Tests if the object/array/primitive is NOT undefined/does NOT contains undefined values
 * @param obj to test
 * @returns true if the provided obj is not undefined and does not contain undefined values
 */
export function excludesUndefined<T>(obj: T): obj is ExcludesUndefined<T> {
  if (obj === undefined) return false;

  if (typeof obj !== 'object') return true;

  if (Array.isArray(obj)) {
    for (const item of obj) {
      if (item === undefined) return false;
    }
    return true;
  }

  for (const key in obj) {
    if (obj[key as keyof typeof obj] === undefined) return false;
  }

  return true;
}

/**
 * Tests if the object shallowly contains undefined values or is itself undefined
 * @param obj to test
 * @param elseValue to return if the object is undefined or contains undefined values
 * @returns obj or elseValue if the object is undefined or contains undefined values
 */
export function excludesUndefinedOrElse<T, R>(obj: T, elseValue: R = undefined as R): ExcludesUndefined<T> | R {
  if (!excludesUndefined(obj)) return elseValue;
  return obj as ExcludesUndefined<T>;
}
