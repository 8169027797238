import {persistThroughHotReload} from '@/lib/dev';
import {MoStore} from '@/stores/lib/MoStore';
import type {DragItem} from 'react-aria';

const DEFAULT_STATE = {isDragging: false, dragType: '', items: [] as DragItem[]};
type DragContextState = typeof DEFAULT_STATE;

class DragContextStore extends MoStore<DragContextState> {
  beginDrag(type: string, items: DragItem[]) {
    this.mutate((state) => {
      state.isDragging = true;
      state.dragType = type;
      state.items = items;
    });
  }

  endDrag() {
    this.mutate((state) => {
      state.isDragging = false;
    });
  }
}

export const dragContextStore = persistThroughHotReload('dragContextStore', new DragContextStore(DEFAULT_STATE));
