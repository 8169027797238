/**
 * Utility to ensure something happens within a certain time limit
 * If the logic takes longer than the limit, the elseFn will be called as a fallback
 * UseCase: To avoid flicker when fetching changing data
 */
export function withinLimitOrElse<T>(
  callback: (trigger: () => void, itHappened: () => void, orElse: (fn: () => void) => void) => T,
  limit = 400,
) {
  let timer: number | undefined;
  let elseFn: () => void;

  const trigger = () => {
    if (timer === undefined) {
      timer = self.setTimeout(() => {
        timer = undefined;
        elseFn?.();
      }, limit);
    }
  };

  const itHappened = () => {
    if (timer !== undefined) {
      self.clearTimeout(timer);
      timer = undefined;
    }
  };

  const orElse = (fn: () => void) => (elseFn = fn);

  return callback(trigger, itHappened, orElse);
}
