import {Worker} from '@/app-service-worker/Worker';
import {SprintLabelSelector} from '@/components/task/SprintLabelSelector';

interface Props {
  sprintId?: number | null;
  taskId: number;
}

export function EditableSprintLabel({sprintId, taskId}: Props) {
  const onSprintChange = (sprintId: number) => {
    Worker.moveTasks({taskIds: [taskId], sprintId: sprintId === -1 ? null : sprintId});
  };

  if (!taskId) return null;
  return <SprintLabelSelector id={sprintId} excludeIds={[sprintId ?? -1]} onChange={onSprintChange} />;
}
