import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import type {EntityStoreState} from '@/stores/lib/EntityStore';
import {EntityStore} from '@/stores/lib/EntityStore';
import {EntityType} from '@shared/EntityType';
import type {Attachment} from '@shared/models/Attachment';

export const attachmentStore = persistThroughHotReload(
  'attachmentStore',
  new EntityStore<EntityStoreState<Attachment>, Attachment>(EntityType.Attachment, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({attachmentStore});
import.meta.hot?.accept(reloadOnHotUpdate);
