/**
 * Like debounce, but produces a batch of all arguments passed to the function
 * in the last frame of the event loop, or after the provided delay.
 * Guarantees if the handler is async, batches are processed in order
 * @param fn the function to call with the last batch of arguments, can be async
 * @returns the wrapper function
 */
export const batch = <T extends unknown[]>(
  fn: (batch: T[]) => void | Promise<void>,
  delay = 0,
): ((...args: T) => void) => {
  let batches: T[] = [];
  let timer = 0;
  return (...args: T) => {
    batches.push(args);
    if (timer === 0) {
      const processBatch = async () => {
        const prev = batches;
        batches = [];
        await fn(prev);
        timer = 0;
        if (batches.length > 0) timer = self.setTimeout(processBatch, delay);
      };
      timer = self.setTimeout(processBatch, delay);
    }
  };
};

/**
 * Like batch, but combines batches by a provided key
 * in this case the args are provided as an array, rather than spread into the params
 * If the handler is async, guarantees batches are processed in order with the exception
 * of the grouping by key, which may be processed in parallel per batch
 */
export const batchByKey = <T, K>(
  fn: (key: K, batch: T[]) => void | Promise<void>,
  delay = 0,
): ((key: K, args: T) => void) => {
  let batches = new Map<K, T[]>();
  let timer = 0;
  return (key: K, args: T) => {
    if (!batches.has(key)) batches.set(key, []);
    batches.get(key)!.push(args);
    if (timer === 0) {
      const processBatch = async () => {
        const prev = batches;
        batches = new Map<K, T[]>();
        const promises = [...prev.entries()].map(([key, batch]) => fn(key, batch));
        await Promise.allSettled(promises);
        timer = 0;
        if (batches.size > 0) timer = self.setTimeout(processBatch, delay);
      };
      timer = self.setTimeout(processBatch, delay);
    }
  };
};

/**
 * Like batchByKey, but by two keys
 */
export const batchByTuple = <T, K1, K2>(
  fn: (k1: K1, k2: K2, batch: T[]) => void | Promise<void>,
  delay = 0,
): ((k1: K1, k2: K2, args: T) => void) => {
  let batches = new Map<K1, Map<K2, T[]>>();
  let timer = 0;
  return (k1: K1, k2: K2, args: T) => {
    if (!batches.has(k1)) batches.set(k1, new Map());
    const map = batches.get(k1)!;
    if (!map.has(k2)) map.set(k2, []);
    map.get(k2)!.push(args);
    if (timer === 0) {
      const processBatch = async () => {
        const prev = batches;
        batches = new Map<K1, Map<K2, T[]>>();
        const promises = [...prev.entries()].map(([k1, batchMap]) =>
          [...batchMap.entries()].map(([k2, batch]) => fn(k1, k2, batch)),
        );
        await Promise.allSettled(promises.flat());
        timer = 0;
        if (batches.size > 0) timer = self.setTimeout(processBatch, delay);
      };
      timer = self.setTimeout(processBatch, delay);
    }
  };
};
