import {Worker} from '@/app-service-worker/Worker';
import {dragContextStore} from '@/stores/dragContext';
import type {BacklogTaskListItem} from '@/stores/ui/backlogStore';
import type {DropEvent, DropItem} from 'react-aria';
import type {DragTypes, DropOperation} from 'react-aria-components';
import {DropZone, isTextDropItem} from 'react-aria-components';
import {tv} from 'tailwind-variants';

function getDropOperation(types: DragTypes, allowedOperations: DropOperation[]): DropOperation {
  if (types.has('momentum/task') && allowedOperations.includes('move')) {
    return 'move';
  }
  return 'cancel';
}

const styles = tv({
  base: 'flex w-full flex-col items-center justify-center p-3 text-sm text-pink-700/40 opacity-50 outline-dashed outline-2 -outline-offset-8 outline-pink-700/20 transition-opacity',
  variants: {
    isDragging: {
      true: 'bg-pink-500/15 text-pink-700/60 outline-pink-500/60',
    },
    isDropTarget: {
      true: 'bg-pink-500/20 font-semibold opacity-100',
    },
  },
});

export function SprintDropZone({sprintId}: {sprintId: number | null}) {
  const dragState = dragContextStore.use((s) => s.state, []);
  const isDragging = dragState.isDragging && dragState.dragType === 'momentum/task';

  const onDrop = async (operation: DropEvent) => {
    const itemPromises = operation.items
      .map((i: DropItem) => (isTextDropItem(i) ? i.getText('momentum/task') : undefined))
      .filter((i) => !!i);
    const taskIds = await Promise.all(itemPromises).then((items) => {
      // TODO: only accept drops for the same account
      return (items.map((i) => JSON.parse(i!)) as BacklogTaskListItem[]).map((i) => i.id);
    });
    Worker.moveTasks({taskIds, sprintId});
  };

  return (
    <DropZone getDropOperation={getDropOperation} onDrop={onDrop} className={(s) => styles({...s, isDragging})}>
      {(e) => (e.isDropTarget ? 'Drop to add tasks' : 'Drag and drop to add tasks')}
    </DropZone>
  );
}
