import {EditSprintModal} from '@/components/backlog/sprint/EditSprintModal';
import {SprintActionButton} from '@/components/backlog/sprint/SprintActionButton';
import {Spacer} from '@/design-system';
import {AnimateIn} from '@/design-system/AnimateIn';
import {Follow} from '@/design-system/Follow';
import {Modal} from '@/design-system/modal/Modal';
import {ProgressPill} from '@/design-system/ProgressPill';
import {Time} from '@/lib/time';
import {sprintStore} from '@/stores/sprint';
import {SprintState} from '@shared/models/Sprint';
import {PencilIcon} from 'lucide-react';
import React from 'react';
import {Button, DialogTrigger} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface Props {
  sprintId: number;
  isFirstInList?: boolean;
  taskList: React.ReactNode;
}

const styles = tv({
  slots: {
    container: 'group/sprint mt-2 origin-top-left', //  animate-in fade-in zoom-in-75
    header: 'group flex flex-row items-center gap-2 px-5',
    name: 'line-clamp-1 flex-shrink-0 break-all font-bold text-gray-500 transition-colors',
  },
  variants: {
    isActive: {
      true: {name: 'text-pink-500'},
    },
  },
});

export const SprintTaskGroup: React.FC<Props> = ({sprintId, isFirstInList, taskList}) => {
  const sprint = sprintStore.require((s) => s.getById(sprintId))!;
  const isActive = sprint.state === SprintState.Active;
  const progress =
    isActive && sprint.startsAt && sprint.endsAt
      ? (Time.now() - Time.from(sprint.startsAt)) /
        (Math.max(Time.now(), Time.from(sprint.endsAt)) - Time.from(sprint.startsAt))
      : 0;
  const {container, header, name} = styles({isActive});

  return (
    <div className={container()}>
      <Follow>
        <AnimateIn animation="fade-in zoom-in-75">
          <div className={header()}>
            <h3 className={name()}>{sprint.name}</h3>
            {isActive && <ProgressPill progress={progress}>Active</ProgressPill>}
            <DialogTrigger>
              <Button className="transition-color-opacity flex flex-row items-center gap-1 rounded-sm px-1 text-xs text-pink-700/40 opacity-0 hover:text-pink-700/80 group-hover:opacity-100 focus-visible:text-pink-700/80 focus-visible:opacity-100">
                <PencilIcon className="h-4 w-4" /> Edit
              </Button>
              <Modal className="modal-sm">
                {({close}) => {
                  return <EditSprintModal sprintId={sprintId} close={close} />;
                }}
              </Modal>
            </DialogTrigger>
            {/* <div className="line-clamp-1 flex flex-grow break-all text-xs text-pink-800/30">{sprint.goal}</div> */}
            <Spacer className="flex-grow" />
            <SprintActionButton sprintId={sprint.id} isFirstInList={isFirstInList} />
          </div>
          {taskList}
        </AnimateIn>
      </Follow>
    </div>
  );
};
