import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import {EntityType} from '@shared/EntityType';
import type {Component} from '@shared/models/Component';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const componentStore = persistThroughHotReload(
  'componentStore',
  new EntityStore<EntityStoreState<Component>, Component>(EntityType.Component, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({componentStore});
import.meta.hot?.accept(reloadOnHotUpdate);
