import {useState} from 'react';
import type {TextAreaProps as AriaTextAreaProps} from 'react-aria-components';
import {TextArea as AriaTextArea} from 'react-aria-components';

export interface TextAreaProps extends AriaTextAreaProps {
  className?: string;
  initialValue?: string;
  value?: string;
  onValueChange?: (value: string) => void;
  onCommit?: (value: string) => void;
}

export function TextArea({className, initialValue, onValueChange, onCommit, value, ...props}: TextAreaProps) {
  const [content, setContent] = useState(initialValue ?? '');

  const onBlur = (event: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    // if the window still has focus, the user tabbed out or clicked elsewhere, commit the value
    if (document.hasFocus()) {
      const value = event.currentTarget.value.trim();
      if (value !== initialValue && value !== '') {
        onCommit?.(value);
      }
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const trimmedValue = event.currentTarget.value.trim();
    setContent(event.currentTarget.value);
    props.onChange?.(event);
    if (trimmedValue !== content) {
      onValueChange?.(trimmedValue);
    }
  };

  return <AriaTextArea {...props} className={className} value={value ?? content} onChange={onChange} onBlur={onBlur} />;
}
