import {Worker} from '@/app-service-worker/Worker';
import {Tag} from '@/components/task/Tag';
import type {TagSuggestion} from '@/components/task/TaskTagTypeahead';
import {TaskTagTypeahead} from '@/components/task/TaskTagTypeahead';
import {InputWell} from '@/design-system/InputWell';
import {EMPTY_LIST} from '@/lib/emptyList';
import {componentStore} from '@/stores/component';
import {labelStore} from '@/stores/label';
import type {EntityStore, EntityStoreState} from '@/stores/lib/EntityStore';
import {taskStore} from '@/stores/task';
import {EntityType} from '@shared/EntityType';
import type {Component} from '@shared/models/Component';
import {defaultFieldId, FieldType} from '@shared/models/FieldSchema';
import type {Label} from '@shared/models/Label';
import {memo, useCallback, useMemo, useRef, useState} from 'react';
import {useFocusWithin} from 'react-aria';
import {tv} from 'tailwind-variants';

interface Props {
  taskId?: number;
}

const styles = tv({
  base: 'cursor-text flex-row flex-wrap gap-1',
  variants: {
    isInputFocused: {
      true: 'ring-2 ring-pink-500',
    },
  },
});

const getTags = (
  store: EntityStore<EntityStoreState<Label>, Label> | EntityStore<EntityStoreState<Component>, Component>,
  ids: number[],
) =>
  store.use(
    (s) =>
      ids
        .map((id) => s.getById(id))
        .filter((c) => !!c)
        .sort((a, b) => a!.name.localeCompare(b!.name)),
    [ids],
  );

export const TaskTagsInput: React.FC<Props> = ({taskId}) => {
  const [labelIds, componentIds] = taskStore.use(
    (s) => {
      const task = s.getById(taskId);
      return [task?.labels ?? EMPTY_LIST, task?.components ?? EMPTY_LIST];
    },
    [taskId],
  );
  const components = getTags(componentStore, componentIds) as Component[];
  const labels = getTags(labelStore, labelIds) as Label[];
  const [isInputFocused, setIsInputFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const {focusWithinProps} = useFocusWithin({onFocusWithinChange: setIsInputFocused});

  const onRemove = useCallback((type: EntityType.Component | EntityType.Label, id: number) => {
    if (!taskId) return;
    const field = type === EntityType.Component ? FieldType.Components : FieldType.Labels;
    Worker.mutateEntity({
      entity: EntityType.Task,
      id: taskId.toString(),
      operations: {
        remove: {
          [defaultFieldId[field].toString()]: {value: id.toString()},
        },
      },
    });
  }, []);

  const onAdd = useCallback((suggestion: TagSuggestion) => {
    if (!taskId) return;
    const field = suggestion.type === EntityType.Component ? FieldType.Components : FieldType.Labels;
    const value = suggestion.type === EntityType.Component ? suggestion.id.toString() : suggestion.textValue;
    Worker.mutateEntity({
      entity: EntityType.Task,
      id: taskId.toString(),
      operations: {
        append: {
          [defaultFieldId[field].toString()]: {value},
        },
      },
    });
  }, []);

  return (
    <InputWell {...focusWithinProps} className={styles({isInputFocused})}>
      <Tags labels={labels} components={components} onRemove={onRemove} />
      <TaskTagTypeahead
        ref={ref}
        className="w-16 flex-grow bg-transparent px-1 !ring-0"
        placeholder="Add tag"
        onAction={onAdd}
        aria-label="Tag"
        labelsToExclude={labels.map((l) => l.id)}
        componentsToExclude={components.map((c) => c.id)}
      />
    </InputWell>
  );
};

const Tags = memo(
  ({
    labels,
    components,
    onRemove,
  }: {
    labels: Label[];
    components: Component[];
    onRemove?: (type: EntityType.Component | EntityType.Label, id: number) => void;
  }) => {
    const Labels = useMemo(
      () =>
        labels.map((label) => (
          <Tag key={label.id} onRemove={() => onRemove?.(EntityType.Label, label.id)}>
            {label.name}
          </Tag>
        )),
      [labels, onRemove],
    );
    const Components = useMemo(
      () =>
        components.map((component) => (
          <Tag
            key={component.id}
            onRemove={() => onRemove?.(EntityType.Component, component.id)}
            isComponent
            isActive={component.active}
          >
            {component.name}
          </Tag>
        )),
      [components, onRemove],
    );
    return (
      <>
        {Components}
        {Labels}
      </>
    );
  },
);
