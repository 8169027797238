import {useIsMounted} from '@/hooks/useIsMounted';
import React, {useEffect} from 'react';
import {twMerge} from 'tailwind-merge';

export function EllipsisInMiddle({text, className}: {text: string; className?: string}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const [isMounted] = useIsMounted();
  useEffect(() => {
    setIsOverflowing(ref.current!.offsetWidth < ref.current!.scrollWidth);
    const observer = new ResizeObserver(() => setIsOverflowing(ref.current!.offsetWidth < ref.current!.scrollWidth));
    observer.observe(ref.current!);
    return () => observer.disconnect();
  }, [text]);

  return (
    <div className={twMerge('relative max-w-full overflow-hidden', className)}>
      <div className={`absolute left-0 top-0 flex h-full w-full flex-row ${isOverflowing ? '' : 'hidden'}`}>
        <div className="overflow-hidden text-ellipsis text-nowrap break-all gradient-mask-r-80">{text}</div>
        <div
          className="-ml-3.5 overflow-hidden text-ellipsis text-nowrap break-all gradient-mask-l-80"
          style={{direction: 'rtl'}}
        >
          {text}
        </div>
      </div>
      <div className={'text-nowrap'} style={{visibility: !isMounted || isOverflowing ? 'hidden' : 'visible'}} ref={ref}>
        {text}
      </div>
    </div>
  );
}
