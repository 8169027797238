import type {ExcludesUndefined} from '@/lib/containsUndefined';
import {excludesUndefined} from '@/lib/containsUndefined';
import type {Entity, EntityId, EntityType} from '@shared/EntityType';
import type {SubscriptionUpdate, SubscriptionUpdateHandler} from '@shared/SubscriptionManager';
import {batch} from '@shared/batcher';
import type {AnyEntityFilter} from '@shared/filters/EntityFilter';
import {Filters} from '@shared/filters/Filters';
import {DevLog, devLog} from '@shared/lib/devLog';
import {isStateEqual} from '@shared/lib/isStateEqual';
import type {EntityListItem, FilteredEntityListItem} from '@shared/models/FilteredEntityList';
import {produce} from 'immer';
import {useCallback, useEffect, useRef, useState} from 'react';
import {debounce} from '../../../shared/lib/debounce';
import {ManyToMany} from '../../lib/ManyToMany';
import {reloadOnHotUpdate, whenInDev} from '../../lib/dev';
import {EMPTY_LIST} from '../../lib/emptyList';
import {subscriptions} from './subscriptions';

export interface EntityStoreState<E> {
  byId: Map<number, E | null>;
  byList: Map<AnyEntityFilter, FilteredEntityListItem[]>;
}

type Subscriber<T> = (state: T, prevState: T) => void;
let nextId = 0;
const cleanupInterval = DEV ? 1000 : 5 * 60 * 1000;
const cleanupIntervalLimit = DEV ? 10_000 : 15 * 60 * 1000;

export class EntityStore<T extends EntityStoreState<E>, E extends Entity> {
  private subscribers: Record<EntityId, Subscriber<T>> = {};
  private idToSubscriber = new ManyToMany<EntityId, number>();
  private listToSubscriber = new ManyToMany<AnyEntityFilter, number>();

  private tracking = {
    accessed: new Set<EntityId>(),
    missing: new Set<EntityId>(),
    updated: new Set<EntityId>(),
    listed: new Set<AnyEntityFilter>(),
    missingLists: new Set<AnyEntityFilter>(),
    updatedLists: new Set<AnyEntityFilter>(),
  };

  constructor(
    protected entityType: EntityType,
    protected state: T,
  ) {}

  public extend<S>(extension: (this: this) => S): this & S {
    return Object.assign(this, extension.call(this));
  }

  private updateHandler: SubscriptionUpdateHandler = batch((update: SubscriptionUpdate[][]) => {
    const updates = update.flat();

    this.mutate((store) => {
      for (const update of updates) {
        whenInDev(() => {
          if (update.type !== this.entityType) {
            console.error('[EntityStore] Update for wrong entity type', update);
            return;
          }
        });

        for (const entity of update.entities) {
          store.setById(entity.id, entity as E);
        }

        for (const {filter, list} of update.lists) {
          const entityFilter = Filters.fromObject(filter);
          store.setList(entityFilter, list);
        }

        for (const id of update.deletions) {
          store.deleteById(id);
        }
      }
    });
  }, 1);

  getById(id?: EntityId): E | undefined | null {
    if (id === undefined) return null;

    this.tracking.accessed.add(id);
    const value = this.state.byId.get(id);
    if (value === undefined) {
      this.tracking.missing.add(id);
    }
    return value;
  }

  setById(id: EntityId, value: E | null) {
    this.tracking.updated.add(id);
    this.state.byId.set(id, value);
  }

  deleteById(id: EntityId) {
    this.tracking.updated.add(id);
    this.state.byId.delete(id);
  }

  getList(filter: AnyEntityFilter): EntityListItem[] | undefined {
    this.tracking.listed.add(filter);
    const value = this.state.byList.get(filter);
    if (value === undefined) {
      this.tracking.missingLists.add(filter);
    }
    return value;
  }

  setList(filter: AnyEntityFilter, value: EntityListItem[]) {
    this.tracking.updatedLists.add(filter);
    this.state.byList.set(filter, value);
  }

  select<T>(subscriberId: number | undefined, selector: (store: this) => T): T {
    this.tracking.accessed.clear();
    this.tracking.missing.clear();
    this.tracking.listed.clear();
    this.tracking.missingLists.clear();

    let result: T = undefined as T;
    try {
      result = selector(this);
    } catch (e) {
      if (!(e instanceof SelectorNotReadyError)) {
        console.error(e);
      }
    }
    devLog(DevLog.EntityStore, `Select ${this.entityType}`, subscriberId, result);

    if (!subscriberId) {
      return result;
    }

    for (const id of this.tracking.accessed) {
      this.idToSubscriber.add(id, subscriberId);
    }
    for (const id of this.tracking.listed) {
      this.listToSubscriber.add(id, subscriberId);
    }

    // TODO: track missing entities and fetch them
    for (const id of this.tracking.missing) {
      subscriptions.create(this.entityType, id, this.updateHandler);
    }
    for (const id of this.tracking.missingLists) {
      subscriptions.create(this.entityType, id, this.updateHandler);
    }

    return result;
  }

  mutate<T>(mutator: (store: this) => T): void {
    this.tracking.updated.clear();
    this.tracking.updatedLists.clear();

    const oldState = this.state;
    this.state = produce(this.state, (draft) => {
      // TODO: this is ugly
      this.state = draft as typeof this.state;
      mutator(this);
    });

    for (const id of this.tracking.updated) {
      for (const subscriber of this.idToSubscriber.get(id) ?? EMPTY_LIST) {
        // TODO: remove ? check... fix bug (may only happen in dev after hot reloads)
        this.subscribers[subscriber]?.call(this, this.state, oldState);
      }
    }

    for (const id of this.tracking.updatedLists) {
      for (const subscriber of this.listToSubscriber.get(id) ?? EMPTY_LIST) {
        this.subscribers[subscriber]?.call(this, this.state, oldState);
      }
    }
  }

  protected subscribe(subscriberId: number, callback: (state: T, prevState: T) => void): () => void {
    if (this.subscribers[subscriberId] && this.subscribers[subscriberId] !== callback) {
      throw new Error(`Subscriber ${subscriberId} already exists`);
    }
    this.subscribers[subscriberId] = callback;
    devLog(DevLog.EntityStore, `(${this.constructor.name}) Subscribed`, subscriberId);
    return () => {
      this.idToSubscriber.removeByTarget(subscriberId);
      this.listToSubscriber.removeByTarget(subscriberId);
      delete this.subscribers[subscriberId];
      devLog(DevLog.EntityStore, 'Unsubscribed', subscriberId);

      this.cleanup();
    };
  }

  selectAndSubscribe<T>(selector: (store: this) => T, callback: (selection: T) => void): () => void {
    const subscriberId = ++nextId;
    const unsubscribe = this.subscribe(subscriberId, () => {
      callback(this.select(subscriberId, selector));
    });
    callback(this.select(subscriberId, selector));
    return unsubscribe;
  }

  /**
   * Fetches data from the store and waits for it to be available (by testing the selection for ANY undefined values)
   * - This means the selector MUST exclude keys that are undefined to succeed
   * Note: this does not subscribe to future updates, so it may select stale data even if fresh data is being fetched
   * @param selector MUST return undefined ONLY if the data is not available yet but will be fetched from the selection
   * @param timeoutMs the maximum time to wait for the data to be available
   * @returns the result of the selection after all requested data is fetched
   */
  await<T>(
    selector: (store: this) => T | undefined,
    timeoutMs: number = 15_000,
  ): Promise<ExcludesUndefined<T> | undefined> {
    return new Promise((resolve) => {
      const timer = setTimeout(() => {
        console.error(`[EntityStore] Timeout waiting for ${this.entityType} data`);
        resolve(undefined);
        unsubscribe();
      }, timeoutMs);

      const subscriberId = ++nextId;
      const attemptSelection = () => {
        const result = this.select(subscriberId, selector);
        if (excludesUndefined(result)) {
          resolve(result);
          unsubscribe();
          clearTimeout(timer);
        }
      };

      const unsubscribe = this.subscribe(subscriberId, attemptSelection);

      attemptSelection();
    });
  }

  use<T>(selector: (store: this) => T, deps: React.DependencyList = [selector]): T {
    const subscriberId = useRef<number>();
    const handler = useRef(() => {});

    const [state, setState] = useState<T>(() => this.select(subscriberId.current, selector));

    handler.current = () => {
      const newSelection = this.select(subscriberId.current, selector);
      if (!isStateEqual(newSelection, state)) {
        setState(newSelection);
      }
    };

    useEffect(() => {
      subscriberId.current ??= ++nextId;
      const unsubscribe = this.subscribe(subscriberId.current, () => handler.current());
      handler.current(); // re-select now that we're subscribed
      return () => {
        unsubscribe();
        subscriberId.current = undefined;
      };
    }, deps);

    return state;
  }

  useWhenLoaded<T>(selector: (store: this) => T, initialFallback: T, deps: React.DependencyList = [selector]): T {
    const subscriberId = useRef<number>();
    const handler = useRef(() => {});

    const [state, setState] = useState<T>(() => {
      const result = this.select(subscriberId.current, selector);
      if (excludesUndefined(result)) {
        return result;
      }
      return initialFallback;
    });

    handler.current = () => {
      const newSelection = this.select(subscriberId.current, selector);
      if (excludesUndefined(newSelection) && !isStateEqual(newSelection, state)) {
        setState(newSelection);
      }
    };

    useEffect(() => {
      subscriberId.current ??= ++nextId;
      const unsubscribe = this.subscribe(subscriberId.current, () => handler.current());
      handler.current(); // re-select now that we're subscribed
      return () => {
        unsubscribe();
        subscriberId.current = undefined;
      };
    }, deps);

    return state;
  }

  require<T>(selector: (store: this) => T): ExcludesUndefined<T> {
    const [subscriberId] = useState(() => ++nextId);
    const context = useRef({
      handler: () => {},
      unsubscribe: () => {},
      resolve: () => {},
      isMounted: false,
    });

    if (!this.subscribers[subscriberId]) {
      context.current.unsubscribe = this.subscribe(subscriberId, () => context.current.handler());
    }

    useEffect(() => {
      context.current.isMounted = true;
      // in cases where we unmount and then remount, we need to resubscribe
      if (!this.subscribers[subscriberId]) {
        context.current.unsubscribe = this.subscribe(subscriberId, () => context.current.handler());
        const selection = this.select(subscriberId, selector);
        if (!isStateEqual(selection, state)) {
          setState(selection);
        }
      }
      return () => {
        context.current.isMounted = false;
        context.current.unsubscribe();
      };
    }, []);

    const selection = this.select(subscriberId, selector);
    const [state, setState] = useState<T>(selection);

    context.current.handler = useCallback(() => {
      const newSelection = this.select(subscriberId, selector);
      if (!isStateEqual(newSelection, state)) {
        if (!context.current.isMounted) {
          context.current.resolve();
        } else {
          setState(newSelection);
        }
      }
    }, [state, selector]);

    if (!excludesUndefined(selection)) {
      throw new Promise<void>((resolve) => {
        context.current.resolve = resolve;
      });
    }

    if (!isStateEqual(selection, state)) {
      setState(selection);
      return selection as ExcludesUndefined<T>;
    }

    return state as ExcludesUndefined<T>;
  }

  cleanup = debounce(
    () => {
      devLog(DevLog.EntityStore, `Cleanup ${this.entityType}`);
      // clean up unreferenced entities
      this.state = produce(this.state, (draft) => {
        const entitiesToRemove = [];
        for (const id of this.idToSubscriber.keys()) {
          const ids = this.idToSubscriber.get(id);
          if (ids === undefined || ids.size === 0) {
            entitiesToRemove.push(id);
          }
        }
        for (const id of entitiesToRemove) {
          draft.byId.delete(id);
          subscriptions.remove(this.entityType, id, this.updateHandler);
        }

        // clean up unreferenced lists
        const listsToRemove = [];
        for (const id of this.listToSubscriber.keys()) {
          const ids = this.listToSubscriber.get(id);
          if (ids === undefined || ids.size === 0) {
            listsToRemove.push(id);
          }
        }
        for (const id of listsToRemove) {
          draft.byList.delete(id);
          subscriptions.remove(this.entityType, id, this.updateHandler);
        }
      });
    },
    cleanupInterval,
    cleanupIntervalLimit,
  );
}

export class SelectorNotReadyError extends Error {}

import.meta.hot?.accept(reloadOnHotUpdate);
