import {get, post} from '@/lib/api';
import {persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import {MoSessionStore} from '@/stores/lib/MoSessionStore';
import {parseToken} from '@shared/lib/jwt';

interface AuthStoreState {
  loading?: boolean;
  token?: string;
  actorId?: number;
  accountId?: number;
}

class AuthStore extends MoSessionStore<AuthStoreState> {
  constructor(state: AuthStoreState) {
    super(state, 'AuthStore');
  }

  get isAuthorized() {
    return !!this.state.token;
  }

  get token() {
    return this.state.token ?? '';
  }

  get actorId() {
    return this.state.actorId ?? 0;
  }

  get accountId() {
    return this.state.accountId ?? 0;
  }

  async transfer(nonce: string) {
    this.set('loading', true);

    const {token} = await post<{token: string}>('/auth/accept', {body: nonce, headers: {'Content-type': 'text/plain'}});
    this.setToken(token);

    this.persistNow();
  }

  protected afterLoad() {
    if (this.state.loading) {
      this.set('loading', false);
    }
    if (!this.state.token) {
      const existing = sessionStorage.getItem(this.storageName);
      if (existing) {
        if (JSON.parse(existing).token) {
          console.error('[AuthStore] was not initialized correctly.', this.state, JSON.stringify(this.state), existing);
          this.setToken((existing as any).token);
        }
      }
      const token = localStorage.getItem('token');
      if (token) {
        this.setToken(token);
      }
    } else {
      setTimeout(() => get('/auth/cookie', {credentials: 'include'}), 0); // asynchronously fetch the secure auth cookie after init finishes
    }
  }

  private setToken(token: string) {
    this.mutate((state) => {
      state.loading = false;
      state.token = token;
      if (token) {
        // TODO: handle error
        const payload = parseToken(token);
        state.actorId = payload ? +payload?.sub : undefined;
        state.accountId = payload ? +payload?.['context.accountId'] : undefined;
        localStorage.setItem('token', token);
      }
    });
    setTimeout(() => get('/auth/cookie', {credentials: 'include'}), 0); // asynchronously fetch the secure auth cookie after init
    this.persistNow();
  }
}

export const authStore = persistThroughHotReload('authStore', new AuthStore({}));

window.addEventListener('focus', () => {
  if (authStore.token) {
    localStorage.setItem('token', authStore.token);
  }
});

import.meta.hot?.accept(reloadOnHotUpdate);
