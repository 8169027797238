import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {Project} from '@shared/models/Project';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const projectStore = persistThroughHotReload(
  'projectStore',
  new EntityStore<EntityStoreState<Project>, Project>(EntityType.Project, {
    byId: new Map(),
    byList: new Map(),
  }).extend(function () {
    const self = this;
    return {
      async getByKey(key: string) {
        return self.await((s) => s.getList(Filters.projectFilter({key}))?.map((item) => s.getById(item.id))?.[0]);
      },
    };
  }),
);

exposeInDev({projectStore});
import.meta.hot?.accept(reloadOnHotUpdate);
