import {authStore} from '@/stores/auth';
import {contextStore} from '@/stores/context';
import type {BacklogTaskListItem} from '@/stores/ui/backlogStore';
import type {DragEvent} from 'react';

export function taskToDragData(taskId: number) {
  return {'momentum/task': JSON.stringify([{id: taskId}])};
}

export function tasksToDragData(tasks: BacklogTaskListItem[]) {
  return {'momentum/task': JSON.stringify(tasks)};
}

export function tasksFromDragData(data: string | DataTransfer): BacklogTaskListItem[] | undefined {
  const taskData = typeof data === 'string' ? data : data.getData('momentum/task');
  if (!taskData) return undefined;
  try {
    const tasks = JSON.parse(taskData) as BacklogTaskListItem[];

    return tasks.filter((t) => t.accountId === authStore.accountId && t.projectId === contextStore.projectId);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export function onDragOverAllowTaskMove(e: DragEvent) {
  return e.dataTransfer.types.includes('momentum/task') && e.dataTransfer.effectAllowed.toLowerCase().includes('move')
    ? 'move'
    : 'none';
}
