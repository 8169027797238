import {CallChain} from '@/lib/CallChain';
import {exposeInDev} from '@/lib/dev';

const current = new Map<symbol | string, CallChain<unknown>>();
export function generateRunLatestId(): symbol {
  return Symbol('runLatest');
}

/**
 * Similar to useLatest, but can run outside a react component
 * @param instanceId the instance ID to use for deduplication (aborting the previous call chain)
 * @param value the initial value for the call chain
 * @returns the call chain to build on (must be built out in a single event loop / cannot add to the chain async)
 */
export function runLatest<T = unknown>(
  instanceId: symbol | string,
  value?: (() => Promise<T>) | Promise<T> | T,
): T extends undefined ? CallChain<undefined> : CallChain<T> {
  const prev = current.get(instanceId);
  if (prev?.isPending) {
    prev.abort('not latest');
  }

  const chain = new CallChain(value).onSettled(() => current.delete(instanceId));
  current.set(instanceId, chain as CallChain<unknown>);
  return chain as T extends undefined ? CallChain<undefined> : CallChain<T>;
}

exposeInDev({runLatest});
