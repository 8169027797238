import type {Collection} from '@/design-system/lists/Collection';
import {reloadOnHotUpdate} from '@/lib/dev';
import {MoStore, mutation} from '@/stores/lib/MoStore';
import {debounce} from '@shared/lib/debounce';
import {createContext} from 'react';

export interface ListItem<K extends number | string = number | string> {
  id: K;
  text?: string;
  isDisabled?: boolean;
}

export enum SelectionMode {
  None,
  Single,
  Multiple,
}

export enum SelectMode {
  Set,
  Add,
  Toggle,
}

export interface Selection<K extends number | string> {
  selected?: K | undefined;
  keys: Set<K>;
  anchor?: K;
  focus?: K;
}

export enum DropLocation {
  None,
  Above,
  Below,
  On,
}

interface ListStoreState<T extends ListItem<K>, K extends number | string> {
  collection: Collection<K, T>;
  selectionMode: SelectionMode;
  selection: Selection<K>;
  itemHeight: number;
  itemGap: number;

  // virtualization
  firstRow: number;
  nRows: number;
  nRowsPerPage: number;

  // dnd
  dragStartIndex?: number;
  dragOver: {
    index?: number;
    location: DropLocation;
  };
}

export const DEFAULT_STATE: ListStoreState<any, any> = {
  selectionMode: SelectionMode.Single,
  selection: {keys: new Set()},
  collection: {list: [], idToIndex: {}},
  itemHeight: 0,
  itemGap: 0,
  firstRow: 0,
  nRows: 0,
  nRowsPerPage: 1,
  dragStartIndex: undefined,
  dragOver: {
    index: undefined,
    location: DropLocation.None,
  },
};

export class ListStore<T extends ListItem<K>, K extends number | string> extends MoStore<ListStoreState<T, K>> {
  constructor(state: ListStoreState<T, K>) {
    super(state);
  }

  get collection() {
    return this._state.collection;
  }

  get itemHeight() {
    return this._state.itemHeight;
  }

  get itemGap() {
    return this._state.itemGap;
  }

  get selection() {
    return this._state.selection;
  }

  @mutation
  selectAndFocus(id: K, mode: SelectMode = SelectMode.Set) {
    if (id === undefined) return;
    if (mode === SelectMode.Set) {
      this.selection.keys = new Set([id]);
    } else if (mode === SelectMode.Add) {
      this.selection.keys.add(id);
    } else if (mode === SelectMode.Toggle) {
      this.selection.keys.has(id) ? this.selection.keys.delete(id) : this.selection.keys.add(id);
    }
    this.selection.focus = id;
  }

  @mutation
  setSelection(selection: Selection<K>) {
    const firstValue = selection.keys.size > 0 ? selection.keys.values().next()?.value : undefined;
    const existing = this._state.selection;
    const newSet = selection.keys;

    this._state.selection = {
      keys: selection.keys,
      anchor: selection.anchor ?? (newSet.has(existing.anchor!) ? existing.anchor : firstValue),
      focus: selection.focus ?? existing.focus ?? firstValue, //(newSet.has(existing.focus!) ? existing.focus : firstValue),
      selected: selection.selected ?? (newSet.has(existing.selected!) ? existing.selected : firstValue),
    };
  }

  @mutation
  setDragOver(index?: number, location: DropLocation = DropLocation.Below) {
    this.updateDragState({index, location});
  }

  @mutation
  clearDragOver() {
    this.updateDragState({location: DropLocation.None});
  }

  updateDragState = debounce(
    (dragOver: Partial<ListStoreState<T, K>['dragOver']>) => {
      this.mutate((s) => {
        s.dragOver = {...s.dragOver, ...dragOver};
      });
    },
    5,
    1000 / 60,
  );
}

export const ListContext = createContext({listStore: new ListStore<any, any>(DEFAULT_STATE)});

import.meta.hot?.accept(reloadOnHotUpdate);
