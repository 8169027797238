import {useRef} from 'react';
import type {AriaTextFieldProps} from 'react-aria';
import {useTextField} from 'react-aria';

export interface TextFieldProps extends AriaTextFieldProps {
  className?: string;
}

export function TextField({className, ...props}: TextFieldProps) {
  const ref = useRef<HTMLInputElement>(null);
  const {inputProps, validationErrors} = useTextField(props, ref);

  // TODO: decide how to display validation errors
  return <input {...inputProps} ref={ref} className={className} aria-invalid={!!validationErrors} />;
}
