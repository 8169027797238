/* eslint-disable @typescript-eslint/no-explicit-any */
import type React from 'react';
import {useMemo} from 'react';

type Callback<T extends (...args: any[]) => unknown> = (...args: Parameters<T>) => ReturnType<T>;
export function useCallbackOnce<T extends (...args: any[]) => unknown>(
  callback: Callback<T>,
  deps: React.DependencyList,
): Callback<T> {
  return useMemo(() => {
    let called = false;
    let result: ReturnType<T>;
    return (...args: Parameters<T>) => {
      if (!called) {
        called = true;
        return (result = callback(...args));
      } else {
        return result;
      }
    };
  }, deps);
}
