export class ManyToMany<Src, Target> {
  private srcToTarget: Map<Src, Set<Target>> = new Map();
  private targetToSrc: Map<Target, Set<Src>> = new Map();

  keys() {
    return this.srcToTarget.keys();
  }

  targetKeys() {
    return this.targetToSrc.keys();
  }

  add(src: Src, target: Target) {
    if (!this.srcToTarget.has(src)) {
      this.srcToTarget.set(src, new Set());
    }
    this.srcToTarget.get(src)!.add(target);
    if (!this.targetToSrc.has(target)) {
      this.targetToSrc.set(target, new Set());
    }
    this.targetToSrc.get(target)!.add(src);
  }

  get(src: Src): Set<Target> | undefined {
    return this.srcToTarget.get(src);
  }

  getByTarget(target: Target): Set<Src> | undefined {
    return this.targetToSrc.get(target);
  }

  remove(src: Src, target: Target) {
    this.srcToTarget.get(src)?.delete(target);
    this.targetToSrc.get(target)?.delete(src);
  }

  removeByTarget(target: Target) {
    const srcList = this.targetToSrc.get(target);
    if (srcList === undefined) {
      return;
    }
    for (const src of srcList) {
      this.remove(src, target);
    }
    this.targetToSrc.delete(target);
  }
}
