import {StatusLabel, type StatusLabelProps} from '@/components/task/StatusLabel';
import {StatusLabelList} from '@/components/task/StatusLabelList';
import {Popover} from '@/design-system';
import type {StatusFilter} from '@shared/filters/StatusFilter';
import {useCallback, useState} from 'react';
import {Button, MenuTrigger} from 'react-aria-components';

interface Props extends StatusLabelProps {
  onCommit: (statusId: number) => void;
  filter?: StatusFilter;
}

export function StatusLabelSelector({onCommit, ...props}: Props) {
  const [isOpen, onOpenChange] = useState(false);
  const onAction = useCallback(
    (id: number) => {
      onOpenChange(false);
      onCommit(id);
    },
    [onOpenChange, onCommit],
  );

  return (
    <MenuTrigger aria-label={`Change Status`} isOpen={isOpen} onOpenChange={onOpenChange}>
      <Button className={`group/button -m-0.5 p-0.5 ${props.style === 'indicator' ? 'rounded-full' : 'rounded-sm'}`}>
        <StatusLabel {...props} />
      </Button>
      <Popover showArrow placement="bottom" className="p-2">
        <StatusLabelList onAction={onAction} filter={props.filter} />
      </Popover>
    </MenuTrigger>
  );
}
