import {MoStore} from '@/stores/lib/MoStore';
import {isStateEqual} from '@shared/lib/isStateEqual';
import autobind from 'autobind-decorator';

export type UrlState = Record<string, string | undefined | null>;

// TODO: this should be abstract, but vite/esbuild is generating invalid code
// Expected ")" but found "class"
// export let MoUrlStateStore = (_class = abstract class MoUrlStateStore<S extends object, U extends UrlState> extends MoStore<S> {
export class MoUrlStateStore<S extends object, U extends UrlState> extends MoStore<S> {
  constructor(state: S) {
    super(state);
    window.addEventListener('popstate', this.onPopState);
    window.addEventListener('pushState', this.onPushState);
    this.onPopState(); // load the state from the url first
    this.selectAndSubscribe((s) => s.getUrlState(), this.updateUrl);
  }

  protected getUrlState(): U {
    return {} as U;
  }
  protected updateFromUrl(_urlState: U): void {}
  protected matchesPage(_url: URL): boolean {
    return false;
  }

  @autobind
  private onPopState() {
    if (!this.matchesPage(new URL(window.location.href))) return;

    const urlParams = new URLSearchParams(window.location.search);
    const state = this.getUrlState(); // use the state to get the keys we care about
    Object.keys(state).forEach((key) => {
      (state as UrlState)[key] = urlParams.get(key) ?? undefined;
    });
    this.updateFromUrl(state);
  }

  @autobind
  private onPushState() {
    this.updateFromUrl(this.getUrlState());
  }

  @autobind
  protected updateUrl(state: U) {
    if (!this.matchesPage(new URL(window.location.href))) return;

    // parse the search params
    const urlParams = new URLSearchParams(window.location.search);

    const existingState = {} as UrlState;
    Object.keys(state).forEach((key) => {
      existingState[key] = urlParams.get(key);
    });

    if (isStateEqual(existingState, state)) return;

    Object.entries(state).forEach(([key, value]) => {
      if (value == null) {
        urlParams.delete(key);
      } else {
        urlParams.set(key, value.toString());
      }
    });

    // window.history.pushState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
  }
}
