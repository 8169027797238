import {EntityType} from '@shared/EntityType';
import type {Actor} from '@shared/models/Actor';
import type {DB} from 'src-sw/db/db';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from './EntityFilter';
import {EntityFilter, RANK_COMPARATOR_DESC} from './EntityFilter';

export class ActorFilter extends EntityFilter<IActorFilter> {
  readonly type = EntityType.Actor;
  readonly projectId?: number;
  readonly boardId?: number;
  readonly query?: string;

  public constructor(filter: Omit<IActorFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, actor: Actor): Promise<[boolean, string, RankComparator]> {
    // TODO: determine if the actor belongs to this project or board
    if (
      this.query &&
      // TODO: consolidate our search logic somewhere
      !actor.name.toLowerCase().includes(this.query.toLowerCase())
    ) {
      return [false, actor.name, RANK_COMPARATOR_DESC];
    }
    return [true, actor.name, RANK_COMPARATOR_DESC];
  }

  public toString(): string {
    return `${this.type}|${this.projectId ?? ''}|${this.boardId ?? ''}|${this.query ?? ''}`;
  }
}
export type IActorFilter = PublicPropertiesOf<ActorFilter>;
export function isActorFilter(filter: IEntityFilter): filter is ActorFilter {
  return filter.type === EntityType.Actor;
}
