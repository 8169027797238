import {AssigneeFilter} from '@/components/board/AssigneeSelector';
import {SprintSelector} from '@/components/board/SprintSelector';

export function BoardHeader() {
  return (
    <div className="flex flex-row justify-between gap-2">
      <AssigneeFilter className="w-full" />
      <div className="shrink-0">
        <SprintSelector />
      </div>
      <div className="w-full" />
    </div>
  );
}
