import {useLayoutEffect, useMemo, useState} from 'react';

export function useMeasureRef<T extends HTMLElement>(ref: React.RefObject<T>): DOMRectReadOnly | null {
  const [bounds, set] = useState<DOMRectReadOnly | null>(null);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        if (entries.length > 0) {
          set(entries[0].contentRect);
        }
      }),
    [],
  );

  useLayoutEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref.current]);

  return bounds ?? ref.current?.getBoundingClientRect() ?? null;
}
