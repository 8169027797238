import {Worker} from '@/app-service-worker/Worker';
import {EndSprintModal} from '@/components/backlog/sprint/EndSprintModal';
import {RelativeTime} from '@/components/time/RelativeTime';
import {Button} from '@/design-system';
import {Modal} from '@/design-system/modal/Modal';
import {useIsMounted} from '@/hooks/useIsMounted';
import {exposeInDev} from '@/lib/dev';
import {Time, TimeUnit} from '@/lib/time';
import {sprintStore} from '@/stores/sprint';
import {taskStore} from '@/stores/task';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {Operation} from '@shared/models/Mutation';
import type {Sprint} from '@shared/models/Sprint';
import {SprintState} from '@shared/models/Sprint';
import {useState} from 'react';
import {DialogTrigger} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  sprintId: number;
  className?: string;
  isFirstInList?: boolean;
}

interface RenderProps {
  happeningToday: boolean;
  anyActiveSprints: boolean;
  isFirstInList: boolean;
  anyTasksInSprint: boolean;
}

const buttonStyles = tv({
  base: 'transition-color-opacity opacity-0 group-focus-within/sprint:opacity-50 hover:!opacity-100 group-hover/sprint:opacity-50',
  variants: {
    happeningToday: {true: ''},
    anyTasksInSprint: {true: ''},
  },
  compoundVariants: [
    {
      happeningToday: true,
      anyTasksInSprint: true,
      className: '!opacity-100',
    },
  ],
});

const timeStyles = tv({
  base: 'flex flex-shrink-0 items-baseline justify-end text-xs text-gray-500 transition-opacity group-focus-within/sprint:opacity-0 group-hover/sprint:opacity-0',
  variants: {
    happeningToday: {true: ''},
    anyTasksInSprint: {true: ''},
  },
  compoundVariants: [
    {
      happeningToday: true,
      anyTasksInSprint: true,
      className: 'opacity-0',
    },
  ],
});

export function SprintActionButton({sprintId, className, isFirstInList = false}: Props) {
  const sprint = sprintStore.use((s) => s.getById(sprintId), [sprintId]);
  const anyActiveSprints = sprintStore.use(
    (s) =>
      (s.getList(Filters.sprintFilter({projectId: sprint?.projectId, state: [SprintState.Active]}))?.length ?? 0) > 0,
    [sprint?.projectId],
  );
  const anyTasksInSprint = taskStore.use(
    (s) => (s.getList(Filters.taskFilter({sprintId: sprint?.id}))?.length ?? 0) > 0,
    [sprint?.id],
  );

  const today = Time.localDateTrunc(Time.now(), TimeUnit.Days) + Time.millis(1, TimeUnit.Days);
  const startsToday = !!sprint?.startsAt && Time.from(sprint.startsAt) < today;
  const endsToday = !!sprint?.endsAt && Time.from(sprint.endsAt) <= today;
  const happeningToday =
    (sprint?.state === SprintState.Active && endsToday) ||
    (sprint?.state === SprintState.Future && (startsToday || (isFirstInList && !anyActiveSprints)));

  const renderProps = {happeningToday, anyActiveSprints, isFirstInList, anyTasksInSprint};

  return (
    <div className={twMerge('relative flex h-8 min-w-20 flex-row items-center justify-end', className)}>
      {sprint && <TimeHint sprint={sprint} renderProps={renderProps} />}
      <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-end">
        {sprint?.state === SprintState.Future && <StartButton sprint={sprint} renderProps={renderProps} />}
        {sprint?.state === SprintState.Active && <EndButton sprint={sprint} renderProps={renderProps} />}
      </div>
    </div>
  );
}

exposeInDev({Time});

interface ButtonProps {
  sprint: Sprint;
  className?: string;
  renderProps: RenderProps;
}

function StartButton({sprint, className, renderProps}: ButtonProps) {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [, mountedRef] = useIsMounted();
  const onPress = async () => {
    setButtonDisabled(true);

    const updates: Operation['replace'] = {};
    updates.startsAt = {value: new Date().toISOString()};
    if (!sprint.endsAt) {
      const duration = await determineSprintDuration(sprint);
      updates.endsAt = {value: new Date(Time.add(Time.now(), duration, TimeUnit.Days)).toISOString()};
    }
    setTimeout(() => mountedRef.current && setButtonDisabled(false), 1000);
    Worker.mutateEntity({
      entity: EntityType.Sprint,
      id: sprint.id.toString(),
      operations: {
        replace: {
          ...updates,
          state: {value: SprintState.Active},
        },
      },
    });
  };

  return (
    <Button
      size="xs"
      outline={
        !renderProps.anyTasksInSprint ||
        !renderProps.happeningToday ||
        !renderProps.isFirstInList ||
        renderProps.anyActiveSprints
      }
      primary
      isDisabled={buttonDisabled || !renderProps.anyTasksInSprint}
      className={twMerge(buttonStyles(renderProps), className)}
      onPress={onPress}
    >
      Start Sprint
    </Button>
  );
}

function EndButton({sprint, className, renderProps}: ButtonProps) {
  return (
    <DialogTrigger>
      <Button
        size="xs"
        primary
        outline={!renderProps.happeningToday}
        className={twMerge(buttonStyles(renderProps), className)}
      >
        End Sprint
      </Button>
      <Modal className="modal-md">{({close}) => <EndSprintModal sprint={sprint} close={close} />}</Modal>
    </DialogTrigger>
  );
}

function TimeHint({sprint, className, renderProps}: ButtonProps) {
  return (
    <div className={twMerge(timeStyles(renderProps), className)}>
      {sprint.state === SprintState.Future && sprint.startsAt ? (
        <RelativeTime prefix={'Starts'} date={sprint.startsAt} precision={TimeUnit.Days} />
      ) : (
        sprint.state !== SprintState.Future &&
        sprint.endsAt && (
          <RelativeTime
            prefix={new Date(sprint.endsAt).getTime() > Date.now() ? 'Ends' : 'Ended'}
            date={sprint.endsAt}
          />
        )
      )}
    </div>
  );
}

async function determineSprintDuration(targetSprint: Sprint) {
  const recentSprints = await sprintStore.await(
    (s) =>
      s
        .getList(Filters.sprintFilter({state: [SprintState.Closed], rankByCompletedAt: 'Desc', limit: 4}))
        ?.map((sp) => s.getById(sp.id)),
    500,
  );
  if (!recentSprints) return 14;

  const weeks: Record<number, number> = {};
  const n: Record<number, number> = {};

  for (const sprint of recentSprints) {
    const end = sprint?.completedAt ?? sprint?.endsAt;
    if (!sprint?.startsAt || !end) continue;
    const similarity = nameSimilarity(sprint.name, targetSprint.name);
    const durationWeeks = Time.delta(Time.from(sprint.startsAt), Time.from(end), TimeUnit.Weeks);
    weeks[durationWeeks] = (weeks[durationWeeks] ?? 0) + similarity;
    n[durationWeeks] = (n[durationWeeks] ?? 0) + 1;
  }

  // get the most similar number of weeks
  return +(Object.keys(weeks).sort((a, b) => weeks[+b] / n[+b] - weeks[+a] / n[+a])[0] ?? 2) * 7;
}

// simplified similarity test, does not have to be perfect
function nameSimilarity(a: string, b: string) {
  const charSetA = new Set(a.split(''));
  const charSetB = new Set(b.split(''));
  const charsA = Array.from(charSetA).join('');
  const charsB = Array.from(charSetB).join('');

  return (
    (Math.max(linearSimilarity(charsA, charsB), linearSimilarity(charsB, charsA)) + // do they have a similar character set
      Math.max(linearSimilarity(a, b), linearSimilarity(b, a)) * 2) / // do they have a similar sequence (prefix especially)
    3
  );
}

function linearSimilarity(a: string, b: string) {
  let similarity = 0;
  for (let i = 0, j = 0; i < a.length && j < b.length; i++, j++) {
    for (; j < b.length; j++) {
      if (a[i] === b[j]) {
        similarity++;
        break;
      } else if (a[i] > '0' && a[i] < '9' && b[j] > '0' && b[j] < '9') {
        similarity++;
        break;
      }
    }
  }
  return similarity / Math.max(a.length, b.length);
}
