import BoardIcon from '@/assets/icon-board.svg?react';
import {Image} from '@/components/Image';
import {Popover} from '@/design-system';
import {MenuItem} from '@/design-system/MenuItem';
import {boardStore} from '@/stores/board';
import {contextStore} from '@/stores/context';
import {SelectorNotReadyError} from '@/stores/lib/EntityStore';
import {projectStore} from '@/stores/project';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {ProjectFilter} from '@shared/filters/ProjectFilter';
import type {Board} from '@shared/models/Board';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import {useMemo} from 'react';
import type {Key} from 'react-aria-components';
import {Menu, SubmenuTrigger} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface Props {
  filter?: ProjectFilter;
  onAction?: (projectId: number, boardId: number) => void;
}

function createListItems(projects: EntityListItem[], boards: Record<number, Board[]>) {
  return projects
    .filter((p) => !!boards[p.id]?.length)
    .map((p) => {
      const boardsForProject = boards[p.id].length > 1 ? boards[p.id] : undefined;
      return {
        key: `${EntityType.Project}:${p.id}`,
        name: p.text,
        icon: p.icon,
        type: EntityType.Project,
        defaultBoardId: boards[p.id][0].id,
        projectId: p.id,
        children: boardsForProject?.map((b) => ({key: `${EntityType.Board}:${b.id}`, name: b.name, boardId: b.id})),
      };
    });
}
type BoardItemType = Exclude<ReturnType<typeof createListItems>[number]['children'], undefined>[number];
type ProjectItemType = ReturnType<typeof createListItems>[number];

function optionFromItems(items: ReturnType<typeof createListItems>, key: Key) {
  for (const item of items) {
    if (item.key === key) {
      return {projectId: item.projectId, boardId: item.defaultBoardId};
    }
    if (item.children) {
      for (const child of item.children) {
        if (child.key === key) {
          return {projectId: item.projectId, boardId: child.boardId};
        }
      }
    }
  }
}

export const ProjectBoardMenu: React.FC<Props> = ({filter, ...props}) => {
  const projects = projectStore.require((s) => s.getList(filter ?? Filters.projectFilter({})));
  const boards = boardStore.require((s) =>
    s
      .getList(Filters.boardFilter({}))
      ?.map((item) => s.getById(item.id))
      .reduce(
        (acc, board) => {
          if (!board) throw new SelectorNotReadyError();
          (acc[board.projectId] ??= []).push(board);
          return acc;
        },
        {} as Record<number, Board[]>,
      ),
  );
  const items = useMemo(() => createListItems(projects, boards), [projects, boards]);

  const onAction = (key: Key) => {
    const selection = optionFromItems(items, key);
    if (!selection) return;
    props.onAction?.(selection.projectId, selection.boardId);
  };

  return (
    <Menu items={items} className="min-w-48 max-w-96 py-2" onAction={onAction}>
      {(item) => {
        if (item.children) {
          return (
            <SubmenuTrigger delay={0}>
              <ProjectItem item={item} />
              <Popover className="min-w-36 max-w-96 rounded-md">
                <Menu items={item.children} className="py-2">
                  {(item) => <BoardItem item={item} onAction={onAction} />}
                </Menu>
              </Popover>
            </SubmenuTrigger>
          );
        } else {
          return <ProjectItem item={item} onAction={onAction} />;
        }
      }}
    </Menu>
  );
};

const itemStyles = tv({
  slots: {
    row: 'flex h-6 flex-row items-center gap-2',
    name: 'line-clamp-1 w-full flex-grow break-all text-left',
  },
});

function ProjectItem({item, onAction}: {item: ProjectItemType; onAction?: (key: Key) => void}) {
  const {row, name} = itemStyles();
  const isCurrent = item.projectId === contextStore.projectId;
  return (
    <MenuItem textValue={item.name} onAction={() => onAction?.(item.key)} isCurrent={isCurrent}>
      <div className={row()}>
        <Image id={item.icon} className="h-6 w-6 rounded-sm" />
        <div className={name()}>{item.name}</div>
      </div>
    </MenuItem>
  );
}

function BoardItem({item, onAction}: {item: BoardItemType; onAction: (key: Key) => void}) {
  const {row, name} = itemStyles();
  const isCurrent = item.boardId === contextStore.boardId;
  return (
    <MenuItem textValue={item.name} onAction={() => onAction(item.key)} isCurrent={isCurrent}>
      <div className={row()}>
        <BoardIcon className="h-6 w-6 opacity-50" />
        <div className={name()}>{item.name}</div>
      </div>
    </MenuItem>
  );
}
