import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import {MoStore} from '@/stores/lib/MoStore';
import {Tracer} from '@/stores/lib/TracerProxy';
import {TtlCache} from '@shared/TtlCache';

export enum ToastStatus {
  Success = 'success',
  Error = 'error',
  Info = 'info',
}

export interface Toast {
  id?: string;
  title: string;
  message?: string | React.ReactNode;
  type: ToastStatus;
  dismissable?: boolean; // defaults to true
  /** time to live in milliseconds */
  ttl?: number;
}

interface StoredToast extends Toast {
  id: string;
  dismissable: boolean;
  ttl: number;
}

export interface ToastState {
  id: string;
  exiting: boolean;
}

interface ToastStoreState {
  toasts: ToastState[];
}

const DEFAULT_TTL = 10_000;
const EXIT_DURATION = 300;
const TOAST_DEFAULTS = {dismissable: true};
let nextId = 0;

class ToastStore extends MoStore<ToastStoreState> {
  private ttlCache = new TtlCache<string, string>(DEFAULT_TTL);
  private toastMap = new Map<string, StoredToast>();
  private timers = new Map<string, number>();

  public getToasts(): Readonly<ToastState[]> {
    return Tracer.render(this.state.toasts);
  }

  public getToast(id: string): Readonly<StoredToast> | undefined {
    return this.toastMap.get(id);
  }

  public toast(presentedToast: Toast) {
    if (presentedToast.id) {
      clearTimeout(this.timers.get(presentedToast.id));
      this.timers.delete(presentedToast.id);
    }

    const toast: StoredToast = {
      id: presentedToast.id ?? 't:' + (nextId++).toString(36),
      ...TOAST_DEFAULTS,
      ttl: presentedToast.ttl ?? ttlFromMessage(presentedToast.message),
      ...presentedToast,
    };

    this.mutate((state) => {
      const toastState: ToastState = {id: toast.id, exiting: false};
      this.toastMap.set(toastState.id, toast);
      state.toasts.push(toastState);
      if (toast.ttl > 0) {
        this.ttlCache.put(toastState.id, toastState.id, toast.ttl, this.remove);
      }
    });
  }

  public error(toast: Omit<Toast, 'type'>) {
    this.toast({...toast, type: ToastStatus.Error});
  }

  public success(toast: Omit<Toast, 'type'>) {
    this.toast({...toast, type: ToastStatus.Success});
  }

  public info(toast: Omit<Toast, 'type'>) {
    this.toast({...toast, type: ToastStatus.Info});
  }

  public remove = (id: string) => {
    this.mutate((state) => {
      const index = state.toasts.findIndex((t) => t.id === id);
      if (index !== -1) {
        state.toasts[index].exiting = true;
        this.timers.set(id, setTimeout(() => this.delete(id), EXIT_DURATION) as never);
      }
    });
  };

  public delete = (id: string) => {
    this.timers.delete(id);
    this.mutate((state) => {
      state.toasts = state.toasts.filter((t) => t.id !== id);
      this.toastMap.delete(id);
      this.ttlCache.remove(id);
    });
  };
}

function ttlFromMessage(message: string | React.ReactNode): number {
  if (typeof message === 'string') {
    return DEFAULT_TTL + message.length * 25;
  }
  return DEFAULT_TTL;
}

export const toastStore = persistThroughHotReload('toastStore', new ToastStore({toasts: []}));

exposeInDev({toastStore});

import.meta.hot?.accept(reloadOnHotUpdate);
