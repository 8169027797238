import {EntityType} from '@shared/EntityType';
import type {TaskHistoryListItem} from '@shared/models/FilteredEntityList';
import type {DB} from 'src-sw/db/db';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from './EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from './EntityFilter';

export class TaskHistoryFilter extends EntityFilter<ITaskHistoryFilter> {
  readonly type = EntityType.TaskHistory;
  readonly taskId!: number;
  readonly minTs?: string;
  readonly maxTs?: string;
  readonly limit?: number;

  constructor(filter: Omit<ITaskHistoryFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, entity: TaskHistoryListItem): Promise<[boolean, string, RankComparator]> {
    if (this.taskId !== entity.taskId) {
      return [false, entity.rank, RANK_COMPARATOR_ASC];
    }

    if (this.minTs !== undefined && this.minTs > entity.createdAt) {
      return [false, entity.rank, RANK_COMPARATOR_ASC];
    }

    if (this.maxTs !== undefined && this.maxTs < entity.createdAt) {
      return [false, entity.rank, RANK_COMPARATOR_ASC];
    }

    return [true, entity.rank, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.taskId}|${this.minTs ?? ''}|${this.maxTs ?? ''}|${this.limit ?? ''}`;
  }
}
export type ITaskHistoryFilter = PublicPropertiesOf<TaskHistoryFilter>;
export function isTaskHistoryFilter(filter: IEntityFilter): filter is TaskHistoryFilter {
  return filter.type === EntityType.TaskHistory;
}
