import {excludesUndefined} from '@/lib/containsUndefined';
import {mutation} from '@/stores/lib/MoStore';
import type {UrlState} from '@/stores/lib/MoUrlStateStore';
import {MoUrlStateStore} from '@/stores/lib/MoUrlStateStore';
import {taskStore} from '@/stores/task';
import {Filters} from '@shared/filters/Filters';
import {withinLimitOrElse} from '@shared/lib/withinLimitOrElse';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import type {Task} from '@shared/models/Task';
import autobind from 'autobind-decorator';
import {createContext} from 'react';

interface BoardUiState {
  sprintId?: number;
  assigneeIds?: (number | null)[];
  tasks?: (Task | null)[];
  taskList?: EntityListItem[];
  selection: Set<number>;
  selected?: number;
  isTaskOpen: boolean;
  isDragging: boolean;
}

interface BoardUrlState extends UrlState {
  sprint: string | undefined;
  assignees: string | undefined;
}

const DEFAULT_STATE: BoardUiState = {
  sprintId: undefined,
  assigneeIds: [],
  tasks: undefined,
  taskList: undefined,
  selection: new Set(),
  selected: undefined,
  isTaskOpen: false,
  isDragging: false,
};

export class BoardUiStore extends MoUrlStateStore<BoardUiState, BoardUrlState> {
  static create(sprintId?: number, assigneeIds?: (number | null)[]) {
    return new BoardUiStore({...DEFAULT_STATE, sprintId, assigneeIds});
  }

  private unsubscribeFromTaskList = () => {};

  public constructor(state: BoardUiState) {
    super(state);
    this.selectAndSubscribe((s) => s.sprintId, this.subscribeToTaskList);
    this.selectAndSubscribe((s) => [s.sprintId, s.assigneeIds], this.onFilterChange);
    this.selectAndSubscribe((s) => s.taskList, this.onTaskListChange);
  }

  @autobind
  public destroy() {
    this.unsubscribeFromTaskList();
  }

  public get sprintId() {
    return this.state.sprintId;
  }

  public get assigneeIds() {
    return this.state.assigneeIds;
  }

  public get tasks() {
    return this.state.tasks ?? [];
  }

  public get taskList() {
    return this.state.taskList ?? [];
  }

  public setIsDragging(isDragging: boolean) {
    if (isDragging === false && this.state.selection.size > 1) {
      this.update({isDragging, isTaskOpen: false});
    } else {
      this.update({isDragging, isTaskOpen: false, selected: undefined, selection: new Set()});
    }
  }

  public openTask(id: number) {
    if (this.state.isDragging) return;
    this.update({isTaskOpen: true, selected: id});
  }

  public closeTask() {
    if (this.state.selection.size > 1) {
      this.update({isTaskOpen: false});
    } else {
      this.update({isTaskOpen: false, selected: undefined, selection: new Set()});
    }
  }

  public reset() {
    this.update(DEFAULT_STATE);
  }

  updateSelection(ids: Set<number>) {
    this.mutate((s) => {
      if (ids.size === 0) {
        s.selected = undefined;
        s.selection = ids;
        // s.currentTaskKey = undefined;
        // s.selectedTaskKeys = [];
      } else {
        if (s.selected === undefined || !ids.has(s.selected)) {
          s.selected = ids.values().next().value;
        }
        if (![...ids].every((id) => s.selection.has(id))) {
          s.selection = ids;
        }
      }
    });
  }

  @autobind
  @mutation
  private onFilterChange() {
    // clear the selection any time the filter changes
    this._state.selection = new Set();
    this._state.selected = undefined;
  }

  @autobind
  private onTaskListChange() {
    // ensure the selection still exists, remove any that are no longer in the list
    const ids = [this.state.selected, ...this.state.selection].filter(Boolean) as number[];
    if (ids.some((id) => this.state.taskList?.find((t) => t.id === id) === undefined)) {
      this.mutate((s) => {
        ids.forEach((id) => {
          if (this.state.taskList?.find((t) => t.id === id) === undefined) {
            s.selection.delete(id);
          }
        });
        if (!this.state.selection.has(this.state.selected ?? -1)) {
          s.selected = [...this.state.selection][0] ?? undefined;
        }
      });
    }
  }

  protected override getUrlState(): BoardUrlState {
    const assignees = this.assigneeIds?.join(',');
    return {
      sprint: this.sprintId?.toString(),
      assignees: assignees === '' ? undefined : assignees,
    };
  }

  protected override updateFromUrl(urlState: BoardUrlState): void {
    const assigneeIds = (urlState.assignees?.split(',').map((id) => (id !== '' ? +id : null)) ?? []).filter(Boolean);
    this.update({
      sprintId: urlState.sprint ? +urlState.sprint : undefined,
      assigneeIds: assigneeIds.length > 0 ? assigneeIds : undefined,
    });
  }

  protected override matchesPage(url: URL): boolean {
    return url.pathname.match(/^(?:\/(?::[\w-_]+){1,3})?\/board(?:\/|$)/) !== null;
  }

  @autobind
  private subscribeToTaskList() {
    if (this.sprintId === undefined) return;

    withinLimitOrElse((trigger, itHappened, orElse) => {
      this.unsubscribeFromTaskList = taskStore.selectAndSubscribe(
        (s) => {
          const taskList = s.getList(Filters.taskFilter({sprintId: this.sprintId}));
          const tasks = taskList?.map((t) => s.getById(t.id));
          return {tasks, taskList};
        },
        ({tasks, taskList}) => {
          trigger();
          if (excludesUndefined(tasks)) {
            this.update({tasks, taskList});
            itHappened();
          }
          orElse(() => this.update({tasks: tasks?.filter((t) => t !== undefined), taskList: taskList}));
        },
      );
    });
  }
}

export const BoardUiContext = createContext<{store: BoardUiStore}>({
  store: null!,
});
