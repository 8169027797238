import expandDownIcon from '@/assets/icon-expand-down-chevron.svg';
import {CreateCommentView} from '@/components/comment/CreateCommentView';
import {TaskDetailsView} from '@/components/task/TaskDetailsView';
import {TaskHeader} from '@/components/task/TaskHeader';
import {TaskHistoryView} from '@/components/task/TaskHistoryView';
import {taskStore} from '@/stores/task';
import React, {useCallback} from 'react';
import {Button} from 'react-aria-components';
import type {positionValues} from 'react-custom-scrollbars-2';
import Scrollbars from 'react-custom-scrollbars-2';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  id: number;
  className?: string;
}

const styles = tv({
  slots: {
    container: 'z-30 flex min-h-40 w-full flex-shrink-0 flex-col bg-white shadow-md transition-all duration-500',
    underlay: 'absolute z-20 h-full w-full bg-gray-700/60 duration-500',
    contents: 'flex-shrink flex-grow overflow-hidden transition-all',
    button: 'w-6 transition-all delay-300 duration-700',
    header: 'border-b border-transparent',
  },
  variants: {
    expanded: {
      true: {
        container: 'h-[95%]',
        underlay: 'animate-in fade-in',
        contents: 'h-full',
        button: '-scale-y-100',
      },
      false: {
        container: 'h-36',
        underlay: 'pointer-events-none animate-out fade-out fill-mode-forwards',
        contents: 'h-0',
        button: 'scale-y-100',
      },
    },
    isScrolling: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isScrolling: true,
      expanded: true,
      className: {header: 'border-gray-800/20 shadow'},
    },
  ],
});

export const TaskView: React.FC<Props> = ({id, className}) => {
  taskStore.require((s) => s.getById(id));
  const [expanded, setExpanded] = React.useState(false);
  const [wasExpanded, setWasExpanded] = React.useState(false);
  const onExpandPress = useCallback(() => {
    setExpanded(!expanded);
    setWasExpanded(true);
  }, [expanded]);

  const [isScrolling, setScrolling] = React.useState(false);
  const onScrollChange = useCallback(
    (scroll: positionValues) => {
      setScrolling(scroll.top !== 0);
    },
    [setScrolling],
  );

  const {container, underlay, contents, button, header} = styles({expanded, isScrolling});

  return (
    <div className={twMerge('flex h-full flex-col', className)}>
      <div className={container()}>
        <TaskHeader id={id} expanded={expanded} className={header()} />
        <div className={contents()}>
          <Scrollbars autoHide onScrollFrame={onScrollChange}>
            <TaskDetailsView id={id} />
          </Scrollbars>
        </div>
        <Button className="flex h-4 w-full shrink-0 items-center justify-around" onPress={onExpandPress}>
          <img className={button()} src={expandDownIcon} alt="Expand down" />
        </Button>
      </div>
      {wasExpanded && <div className={underlay()} onClick={onExpandPress} />}
      <div className="flex flex-grow flex-col justify-end overflow-hidden">
        <TaskHistoryView id={id} />
        <CreateCommentView taskId={id} />
      </div>
    </div>
  );
};
