import {StatusLabel} from '@/components/task/StatusLabel';
import {FilterableList, SelectableItem} from '@/design-system';
import {EMPTY_LIST} from '@/lib/emptyList';
import {contextStore} from '@/stores/context';
import {statusStore} from '@/stores/status';
import {Filters} from '@shared/filters/Filters';
import type {StatusFilter} from '@shared/filters/StatusFilter';
import React from 'react';

interface Props {
  onAction?: (statusId: number) => void;
  filter?: StatusFilter;
}

export const StatusLabelList: React.FC<Props> = ({onAction, filter}) => {
  const items = statusStore.use(
    (s) => s.getList(filter ?? Filters.statusFilter({projectId: contextStore.projectId})) ?? EMPTY_LIST,
    [filter],
  );

  return (
    <FilterableList items={items} aria-label="Status list" onAction={onAction}>
      {(status) => (
        <SelectableItem textValue={status.text} key={status.id} className="flex items-center gap-2">
          <StatusLabel id={status.id} style="indicator" />
          <div className="line-clamp-1 text-sm">{status?.text}</div>{' '}
        </SelectableItem>
      )}
    </FilterableList>
  );
};
