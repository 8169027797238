import {Worker} from '@/app-service-worker/Worker';
import {EntityType} from '@shared/EntityType';
import type {Mutation} from '@shared/models/Mutation';

export function mutateTask(taskId: number, operations: Mutation['operations']) {
  Worker.mutateEntity({
    entity: EntityType.Task,
    id: taskId.toString(),
    operations,
  });
}
