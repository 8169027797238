import type {EventedEvents} from '@/lib/Evented';
import {Evented} from '@/lib/Evented';
import {TimeUnit} from '@/lib/time';

interface TimeMonitorEvents extends EventedEvents {
  [TimeUnit.Seconds]: (now: Date) => void;
  [TimeUnit.Minutes]: (now: Date) => void;
  [TimeUnit.Hours]: (now: Date) => void;
  [TimeUnit.Days]: (now: Date) => void;
  [TimeUnit.Weeks]: (now: Date) => void;
  [TimeUnit.Months]: (now: Date) => void;
  [TimeUnit.Years]: (now: Date) => void;
  [TimeUnit.None]: (now: Date) => void; // unused, but ensures interface is identical for any time unit
}

class TimeMonitor extends Evented<TimeMonitorEvents> {
  private times = new Map<TimeUnit, number>();
  private interval: number | NodeJS.Timer;

  constructor() {
    super();
    this.interval = setInterval(this.tick.bind(this), 1000);
  }

  destroy() {
    clearInterval(this.interval as number);
  }

  private tick() {
    const now = new Date();

    if (now.getSeconds() === this.times.get(TimeUnit.Seconds)) return;
    this.times.set(TimeUnit.Seconds, now.getSeconds());
    this.emit(TimeUnit.Seconds, now);

    if (now.getMinutes() === this.times.get(TimeUnit.Minutes)) return;
    this.times.set(TimeUnit.Minutes, now.getMinutes());
    this.emit(TimeUnit.Minutes, now);

    if (now.getHours() === this.times.get(TimeUnit.Hours)) return;
    this.times.set(TimeUnit.Hours, now.getHours());
    this.emit(TimeUnit.Hours, now);

    if (now.getDate() === this.times.get(TimeUnit.Days)) return;
    this.times.set(TimeUnit.Days, now.getDate());
    this.emit(TimeUnit.Days, now);

    if (now.getDay() === this.times.get(TimeUnit.Weeks)) return;
    this.times.set(TimeUnit.Weeks, now.getDay());
    this.emit(TimeUnit.Weeks, now);

    if (now.getMonth() === this.times.get(TimeUnit.Months)) return;
    this.times.set(TimeUnit.Months, now.getMonth());
    this.emit(TimeUnit.Months, now);

    if (now.getFullYear() === this.times.get(TimeUnit.Years)) return;
    this.times.set(TimeUnit.Years, now.getFullYear());
    this.emit(TimeUnit.Years, now);
  }

  public hotReload(old: TimeMonitor) {
    old.destroy();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any)._listeners = (old as any)._listeners;
    this.times = old.times;
    this.interval = old.interval;
    return this;
  }
}

export let timeMonitor = new TimeMonitor();

if (import.meta.hot) {
  if (import.meta.hot.data?.timeMonitor) {
    timeMonitor = import.meta.hot.data.timeMonitor;
  }
  import.meta.hot.accept((newModule) => {
    if (newModule?.timeMonitor) {
      timeMonitor = new newModule.TimeMonitor().otReload(timeMonitor);
    }
  });
}
