import {useEffect, useRef, useState} from 'react';

export function useIsFocusWithin(ref: React.RefObject<HTMLElement>) {
  const [isFocusWithin, setIsFocusWithin] = useState(false);

  useEffect(() => {
    const onFocus = () => setIsFocusWithin(true);
    const onBlur = () => setIsFocusWithin(false);

    if (ref.current) {
      ref.current.addEventListener('focusin', onFocus);
      ref.current.addEventListener('focusout', onBlur);

      return () => {
        if (ref.current) {
          ref.current.removeEventListener('focusin', onFocus);
          ref.current.removeEventListener('focusout', onBlur);
        }
      };
    }
  }, [ref.current]);

  return isFocusWithin;
}

export function useOnFocusInOut(
  ref: React.RefObject<HTMLElement>,
  onFocusIn: () => void,
  onFocusOut: () => void,
  deps: React.DependencyList = [onFocusIn, onFocusOut],
) {
  const state = useRef({isFocusWithin: false, timer: 0});
  useEffect(() => {
    if (ref.current) {
      const handleFocusIn = () => {
        clearTimeout(state.current.timer);
        state.current.timer = window.setTimeout(() => {
          if (!state.current.isFocusWithin) {
            state.current.isFocusWithin = true;
            onFocusIn();
          }
        }, 0);
      };

      const handleFocusOut = () => {
        clearTimeout(state.current.timer);
        state.current.timer = window.setTimeout(() => {
          if (state.current.isFocusWithin) {
            state.current.isFocusWithin = false;
            onFocusOut();
          }
        }, 0);
      };

      ref.current.addEventListener('focusin', handleFocusIn);
      ref.current.addEventListener('focusout', handleFocusOut);

      return () => {
        if (ref.current) {
          ref.current.removeEventListener('focusin', handleFocusIn);
          ref.current.removeEventListener('focusout', handleFocusOut);
        }
      };
    }
  }, [ref.current, ...deps]);
}
