import {taskStore} from '@/stores/task';
import {EntityType} from '@shared/EntityType';
import type {Editor} from '@tiptap/core';
import type {
  MentionNodeAttrs as TiptapMentionNodeAttrs,
  MentionOptions as TiptapMentionOptions,
} from '@tiptap/extension-mention';
import TiptapMention from '@tiptap/extension-mention';
import type {Node} from '@tiptap/pm/model';
import {Plugin, PluginKey} from '@tiptap/pm/state';
import {NodeViewWrapper, ReactNodeViewRenderer} from '@tiptap/react';
import React from 'react';

export interface MentionNodeAttrs extends TiptapMentionNodeAttrs {
  entity: string;
}

export interface MentionOptions extends TiptapMentionOptions<MentionNodeAttrs> {
  component: (props: {node: Node; HTMLAttributes: MentionNodeAttrs}) => React.ReactNode;
}

export const Mention = TiptapMention.extend<MentionOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      component: ({node, HTMLAttributes}: {node: Node; HTMLAttributes: MentionNodeAttrs}) => {
        return (
          <NodeViewWrapper
            {...HTMLAttributes}
            data-type={this.name}
            data-id={node.attrs.id}
            data-entity={node.attrs.entity}
            className="inline-block"
          >
            @{node.attrs.label}
          </NodeViewWrapper>
        );
      },
    };
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      entity: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-entity'),
        renderHTML: (attributes) => (attributes.entity ? {'data-entity': attributes.entity} : {}),
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(this.options.component as any); // TODO: fix this any
  },
  addProseMirrorPlugins() {
    const plugins = this.parent?.();
    return [...(plugins || []), pasteHandler({editor: this.editor})];
  },
});

type PasteHandlerOptions = {
  editor: Editor;
};

const taskLinkRegex =
  /^https:\/\/(?:[\w-_]+\.)?mmntm\.ai\/:[\w+-_]+:(\w+):\d+(?:\/|\/[^?]*)(?<taskKey>\1-\d+)(?:\/|\?|$)/;
export function pasteHandler(options: PasteHandlerOptions): Plugin {
  return new Plugin({
    key: new PluginKey('handlePasteTaskLink'),
    props: {
      handlePaste: (view, _event, slice) => {
        const {state} = view;
        const {selection} = state;
        const {empty} = selection;

        if (!empty) {
          return false;
        }

        let textContent = '';

        slice.content.forEach((node) => {
          textContent += node.textContent;
        });

        const taskKey = taskLinkRegex.exec(textContent)?.groups?.taskKey;

        if (!taskKey) return false;

        // asynchronously fetch the task id
        taskStore
          .await((s) => s.getByKey(taskKey), 500)
          .then((task) => {
            if (task) {
              const mentionNode = {
                type: Mention.name,
                attrs: {
                  id: task.id.toString(),
                  label: `${taskKey}: ${task.title}`,
                  entity: EntityType.Task,
                },
              };

              options.editor.commands.insertContent(mentionNode);
            } else {
              options.editor.commands.insertContent(textContent);
            }
          });

        return true;
      },
    },
  });
}
