import type {ListBoxItemProps} from 'react-aria-components';
import {ListBoxItem} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

export interface SelectableItemProps extends ListBoxItemProps {
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
}

const itemStyles = tv({
  base: 'mx-2 flex cursor-pointer items-center gap-1 rounded-sm px-2 py-1',
  variants: {
    isFocused: {
      true: 'bg-pink-500/20 text-pink-900',
    },
    isHovered: {
      true: 'bg-pink-500/20 text-pink-900',
    },
    isSelected: {
      true: 'bg-pink-500/20 text-pink-900',
    },
  },
});

export function SelectableItem({children, className, ...props}: SelectableItemProps) {
  return (
    <ListBoxItem {...props} className={(...args) => twMerge(itemStyles(...args), className)}>
      {children}
    </ListBoxItem>
  );
}
