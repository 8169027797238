import {Popover} from '@/design-system/Popover';
import {SelectableItem} from '@/design-system/SelectableItem';
import {useCallback, useRef, useState} from 'react';
import {Button, ListBox, MenuTrigger} from 'react-aria-components';

export interface SelectItem {
  id: number | string;
  textValue: string;
}

export interface SelectProps<T extends SelectItem> {
  items: T[];
  selected?: T;
  onChange: (item: T) => void;
  ariaLabel?: string;
  children: React.ReactNode | ((selected: T | undefined) => React.ReactNode);
  itemRenderer?: (item: T) => React.ReactNode;
  className?: string;
}

export function Select<T extends SelectItem>({
  items,
  selected,
  onChange,
  ariaLabel,
  children,
  itemRenderer,
  className,
}: SelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  const onAction = useCallback(
    (item: T) => {
      onChange(item);
      setIsOpen(false);
    },
    [onChange],
  );

  return (
    <MenuTrigger aria-label={ariaLabel ?? 'Select'} isOpen={isOpen} onOpenChange={setIsOpen}>
      <Button className={className}>{typeof children === 'function' ? children(selected) : children}</Button>
      <Popover showArrow placement="bottom" className="p-2" noScroll={false}>
        {() => (
          <ListBox
            ref={listRef}
            items={items}
            className="py-2 text-sm"
            autoFocus
            aria-label={`${ariaLabel ?? 'Select'} options`}
            shouldFocusWrap
          >
            {items.map((item) => (
              <SelectableItem key={item.id} textValue={item.textValue} onAction={() => onAction(item)}>
                {itemRenderer?.(item) ?? item.textValue}
              </SelectableItem>
            ))}
          </ListBox>
        )}
      </Popover>
    </MenuTrigger>
  );
}
