import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {Task} from '@shared/models/Task';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

class TaskStore extends EntityStore<EntityStoreState<Task>, Task> {
  listByKeys(keys: string[]) {
    return this.getList(Filters.taskFilter({keys}));
  }

  getByKey(key: string) {
    const id = this.getIdByKey(key);
    return id ? this.getById(id) : undefined;
  }

  getIdByKey(key: string) {
    return this.getList(Filters.taskFilter({key}))?.[0]?.id;
  }
}

export const taskStore = persistThroughHotReload(
  'taskStore',
  new TaskStore(EntityType.Task, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({taskStore});
import.meta.hot?.accept(reloadOnHotUpdate);
