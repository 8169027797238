import {Select, Spacer} from '@/design-system';
import {contextStore} from '@/stores/context';
import {sprintStore} from '@/stores/sprint';
import {BoardUiContext} from '@/stores/ui/boardUiStore';
import {Filters} from '@shared/filters/Filters';
import {SprintState} from '@shared/models/Sprint';
import {ChevronDownIcon} from 'lucide-react';
import {useContext, useEffect} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

const styles = tv({
  base: 'font-semibold text-black/70',
});

export function SprintSelector() {
  const boardUiStore = useContext(BoardUiContext).store;
  const board = contextStore.use((s) => s.state.board, []);
  const selectedSprintId = boardUiStore.use((s) => s.state.sprintId, []);
  const {activeSprints, selectedSprint} = sprintStore.use(
    (s) => ({
      activeSprints:
        board && s.getList(Filters.sprintFilter({projectId: board.projectId, state: [SprintState.Active]})),
      selectedSprint: s.getById(selectedSprintId),
    }),
    [board, selectedSprintId],
  );

  // default to the first active sprint
  useEffect(() => {
    if (selectedSprintId === undefined && activeSprints?.[0]) {
      boardUiStore.set('sprintId', activeSprints[0].id);
    }
  }, [activeSprints, selectedSprintId]);

  const sprintList =
    activeSprints?.map((s) => ({id: s.id, textValue: s.text ?? s.id.toString()})).filter(Boolean) ?? [];

  if (!activeSprints || activeSprints.length <= 1) {
    return <div className={styles()}>{selectedSprint?.name ?? 'Select Sprint'}</div>;
  }

  return (
    <Select
      items={sprintList}
      onChange={(item) => boardUiStore.set('sprintId', item.id as number)}
      selected={sprintList.find((i) => i.id === selectedSprintId)}
      className={twMerge(styles(), 'flex flex-row items-center')}
    >
      {selectedSprint?.name ?? ''}
      <Spacer />
      <ChevronDownIcon className="h-4 w-4" />
    </Select>
  );
}
