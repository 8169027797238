import type {Entity} from '@shared/EntityType';

export enum SprintState {
  Future = 'Future',
  Active = 'Active',
  Closed = 'Closed',
}
export interface Sprint extends Entity {
  id: number;
  createdAt?: string;
  updatedAt?: string;
  accountId?: number;
  name: string;
  projectId: number;
  boardId: number;
  startsAt?: string;
  endsAt?: string;
  completedAt?: string;
  state: SprintState;
  goal?: string;
}
