import {mergeAttributes, Node} from '@tiptap/core';

export interface MediaNodeAttrs {
  id: number;
  aspect: number;
  externalId?: string;
  passthrough: string;
}

export interface MediaOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Commands<ReturnType> {}
}

/**
 * This extension allows you to insert and manage Media files.
 */
export const Media = Node.create<MediaOptions, MediaNodeAttrs>({
  name: 'media',

  inline: true,
  group: 'inline',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-id'),
        renderHTML: (attributes: MediaNodeAttrs) => (attributes.id ? {'data-id': attributes.id} : {}),
      },
      aspect: {
        default: 1,
        parseHTML: (element: HTMLElement) => parseInt(element.getAttribute('data-aspect') ?? '1'),
        renderHTML: (attributes: MediaNodeAttrs) =>
          attributes.aspect ? {'data-aspect': attributes.aspect.toString()} : {},
      },
      externalId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-external-id'),
        renderHTML: (attributes: MediaNodeAttrs) =>
          attributes.externalId ? {'data-external-id': attributes.externalId} : {},
      },
      passthrough: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-passthrough'),
        renderHTML: (attributes: MediaNodeAttrs) =>
          attributes.passthrough ? {'data-passthrough': JSON.stringify(attributes.passthrough)} : {},
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `img[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({HTMLAttributes, node}) {
    const src = `${VITE_API_BASE_URL}/attachment/${node.attrs.id}/thumbnail`;
    return [
      'img',
      mergeAttributes({'data-type': this.name}, this.options.HTMLAttributes, HTMLAttributes, {
        src,
        style: 'width: 100%',
      }),
    ];
  },

  renderText() {
    return '[Attachment]';
  },
});
