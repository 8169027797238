import {Worker} from '@/app-service-worker/Worker';
import {BoardColumn} from '@/components/board/BoardColumn';
import {TaskLabel} from '@/components/task/TaskLabel';
import {ScrollView} from '@/design-system/ScrollView';
import {isSet} from '@/lib/isSet';
import {keyboardMonitor} from '@/lib/KeyboardMonitor';
import {dragContextStore} from '@/stores/dragContext';
import type {BacklogTaskListItem} from '@/stores/ui/backlogStore';
import {BoardUiContext} from '@/stores/ui/boardUiStore';
import type {Board} from '@shared/models/Board';
import {useContext} from 'react';
import type {DragItem, DropItem, DropTarget, Key} from 'react-aria';
import {isTextDropItem} from 'react-aria';
import {DropIndicator, useDragAndDrop} from 'react-aria-components';

export const renderDragPreview = (items: DragItem[]) => {
  return (
    <div className="flex min-h-7 min-w-48 max-w-96 !cursor-grabbing flex-row items-center justify-between gap-2 rounded-sm bg-pink-500 p-1 text-sm text-pink-50 opacity-100">
      <span className="line-clamp-1 flex-grow break-all bg-pink-100">
        {items.slice(0, 3).map((i) => (
          <TaskLabel key={i['momentum/task']} id={JSON.parse(i['momentum/task']).id} />
        ))}
      </span>
      <div className="flex-shrink-0 rounded-sm bg-pink-400 px-1.5 text-center font-bold">{items.length}</div>
    </div>
  );
};

export const renderDropIndicator = (target: DropTarget) => {
  return (
    <DropIndicator
      target={target}
      className={({isDropTarget}) =>
        `-my-1 outline-dashed outline-1 outline-pink-500 transition-opacity ${isDropTarget ? 'opacity-100' : 'opacity-0'}`
      }
    />
  );
};

interface Props {
  board: Board;
}

export function ScrumBoard({board}: Props) {
  const boardUiStore = useContext(BoardUiContext).store;
  const [selected, selection] = boardUiStore.use((s) => [s.state.selected, s.state.selection], []);

  const onSelectionChange = (iterable: Iterable<Key>) => {
    const keys = isSet<Key>(iterable) ? iterable : new Set(iterable);
    boardUiStore.updateSelection(keys as Set<number>);
    if (boardUiStore.state.selection.size === 1 && !keyboardMonitor.isAnyModifierPressed()) {
      boardUiStore.openTask(boardUiStore.state.selected!);
    }
  };

  function getItems(keys: Set<Key>) {
    // using selection instead of keys because we have multiple grid lists
    let newSelection = new Set([...selection]);
    let selectionNeedsUpdate = false;
    let anySelected = false;
    for (const key of keys as Set<number>) {
      if (newSelection.has(key)) {
        anySelected = true;
        continue;
      }
      selectionNeedsUpdate = true;
      newSelection.add(key);
    }
    if (selectionNeedsUpdate) {
      if (!anySelected) {
        // if none of the keys we are dragging are selected, replace the selection with the keys
        onSelectionChange(keys);
        newSelection = new Set([...keys]) as Set<number>;
      } else {
        // otherwise, update the selection with the selection + keys we are dragging
        onSelectionChange(newSelection);
      }
    }
    return [...newSelection]
      .map((key) => boardUiStore.taskList.find((t) => t?.id === key))
      .filter((t) => !!t)
      .map(
        (t) =>
          ({
            'text/plain': t?.text ?? '',
            'momentum/task': JSON.stringify(t), // TODO: add context (account/project)
          }) as DragItem,
      );
  }

  const {dragAndDropHooks} = useDragAndDrop({
    onDragStart: (e) => {
      const selection = boardUiStore.state.selection.size === 0 ? e.keys : boardUiStore.state.selection;
      dragContextStore.beginDrag('momentum/task', getItems(selection));
      boardUiStore.setIsDragging(true);
    },
    onDragEnd: () => {
      dragContextStore.endDrag();
      boardUiStore.setIsDragging(false);
    },
    getItems,
    onInsert: async (operation) => {
      const beforeTaskId = operation.target.dropPosition === 'before' ? (operation.target.key as number) : undefined;
      const afterTaskId = operation.target.dropPosition === 'after' ? (operation.target.key as number) : undefined;
      const itemPromises = operation.items
        .map((i: DropItem) => (isTextDropItem(i) ? i.getText('momentum/task') : undefined))
        .filter((i) => !!i);
      const taskIds = await Promise.all(itemPromises).then((items) => {
        // TODO: only accept drops for the same account
        return (items.map((i) => JSON.parse(i!)) as BacklogTaskListItem[]).map((i) => i.id);
      });
      Worker.moveTasks({taskIds, sprintId: boardUiStore.sprintId, beforeTaskId, afterTaskId});
    },
    onReorder: (operation) => {
      const beforeTaskId = operation.target.dropPosition === 'before' ? (operation.target.key as number) : undefined;
      const afterTaskId = operation.target.dropPosition === 'after' ? (operation.target.key as number) : undefined;
      const taskIds = [...operation.keys] as number[];
      Worker.moveTasks({taskIds, sprintId: boardUiStore.sprintId, beforeTaskId, afterTaskId});
    },
    acceptedDragTypes: ['momentum/task'],
    renderDragPreview,
    renderDropIndicator,
    getDropOperation(target, types, allowedOperations) {
      // don't allow dropping on selected tasks (assuming they are the ones being dragged)
      if (
        target.type === 'root' ||
        (types.has('momentum/task') &&
          (!selected ||
            (selection instanceof Set
              ? selection.has(target.key as number)
              : !Array.from(selection).includes(target.key))))
      ) {
        return 'cancel';
      }
      if (typeof target.key === 'string') {
        return 'cancel';
      }
      return allowedOperations.includes('move') ? 'move' : 'cancel';
    },
  });

  return (
    <ScrollView className="mb-5 flex-grow overflow-y-hidden" onMouseDown={() => onSelectionChange([])}>
      {(scrollProps) => (
        <div className="flex min-h-full flex-row gap-2">
          {board.configuration.columns.map((column, i) => (
            <BoardColumn
              key={i}
              column={column}
              dragAndDropHooks={dragAndDropHooks}
              onSelectionChange={onSelectionChange}
              selection={selection}
              scrollProps={scrollProps}
            />
          ))}
        </div>
      )}
    </ScrollView>
  );
}
