import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import type {EntityStoreState} from '@/stores/lib/EntityStore';
import {EntityStore} from '@/stores/lib/EntityStore';
import {EntityType} from '@shared/EntityType';
import type {TaskLink} from '@shared/models/TaskLink';

export const taskLinkStore = persistThroughHotReload(
  'taskLinkStore',
  new EntityStore<EntityStoreState<TaskLink>, TaskLink>(EntityType.TaskLink, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({taskLinkStore});
import.meta.hot?.accept(reloadOnHotUpdate);
