import type {ActorAvatarProps} from '@/components/actor/ActorAvatar';
import {ActorAvatar} from '@/components/actor/ActorAvatar';
import {ActorList} from '@/components/actor/ActorList';
import {Button, Popover} from '@/design-system';
import {defaultFieldId, FieldType} from '@shared/models/FieldSchema';
import type {Mutation} from '@shared/models/Mutation';
import {UserMinusIcon} from 'lucide-react';
import React, {useCallback, useState} from 'react';
import {Button as AriaButton, MenuTrigger} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';

interface Props extends ActorAvatarProps {
  type: FieldType.Assignee | FieldType.Reporter;
  onCommit: (value: number | undefined, operation: Mutation['operations']) => void;
  className?: string;
}

export const EditableActor: React.FC<Props> = ({actorId, className, type = FieldType.Assignee, onCommit, ...props}) => {
  const [isOpen, onOpenChange] = useState(false);

  const onAction = useCallback(
    (actorId: number) => {
      onOpenChange(false);
      if (actorId === -1) {
        onCommit(actorId, {
          delete: [defaultFieldId[type].toString()],
        });
      } else {
        onCommit(actorId, {
          replace: {
            [defaultFieldId[type].toString()]: {value: actorId.toString()},
          },
        });
      }
    },
    [onCommit, onOpenChange],
  );

  return (
    <MenuTrigger aria-label={`Change ${type}`} isOpen={isOpen} onOpenChange={onOpenChange}>
      <AriaButton
        className={twMerge(
          'group/button -m-0.5 flex h-fit w-fit shrink-0 items-center justify-center overflow-hidden rounded-full p-0',
          className,
        )}
      >
        <ActorAvatar {...props} actorId={actorId} />
      </AriaButton>
      <Popover showArrow placement="bottom" className="p-2">
        <ActorList onAction={onAction} emptyName={type === FieldType.Assignee ? 'Unassigned' : 'No Reporter'} />
        {actorId && (
          <div className="sticky bottom-0 rounded-b-[1.1rem] border-t border-gray-500/20 bg-white px-2 py-1">
            <Button seamless onPress={() => onAction(-1)} className="px-2 py-1 text-sm">
              <UserMinusIcon className="h-4 w-4" /> Remove {type === FieldType.Assignee ? 'Assignee' : 'Reporter'}
            </Button>
          </div>
        )}
      </Popover>
    </MenuTrigger>
  );
};
