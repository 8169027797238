import {EntityType} from '@shared/EntityType';
import type {AnyEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {TaskType} from '@shared/models/TaskType';
import type {DB} from 'src-sw/db/db';

export class TaskTypeFilter extends EntityFilter<ITaskTypeFilter> {
  public readonly type = EntityType.TaskType;
  public readonly projectId?: number;
  public readonly hierarchyLevel?: number;
  // public readonly categoryId

  constructor(filter: Omit<ITaskTypeFilter, 'type'> = {}) {
    super();
    Object.assign(this, filter);
  }

  // TODO: filter by task category type (e.g., Epic, Task, etc.)

  public async matchWithRank(_db: DB, taskType: TaskType): Promise<[boolean, string, RankComparator]> {
    if (this.projectId !== undefined && taskType.projectId !== this.projectId) {
      return [false, taskType.name, RANK_COMPARATOR_ASC];
    }

    if (this.hierarchyLevel !== undefined && taskType.hierarchyLevel !== this.hierarchyLevel) {
      return [false, taskType.name, RANK_COMPARATOR_ASC];
    }

    return [true, this.toString(), RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.projectId ?? ''}|${this.hierarchyLevel ?? ''}`;
  }
}

export type ITaskTypeFilter = PublicPropertiesOf<TaskTypeFilter>;
export function isTaskTypeFilter(filter: AnyEntityFilter): filter is TaskTypeFilter {
  return filter.type === EntityType.TaskType;
}
