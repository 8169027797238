import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {Project} from '@shared/models/Project';
import type {DB} from 'src-sw/db/db';

export class ProjectFilter extends EntityFilter<IProjectFilter> {
  readonly type = EntityType.Project;
  readonly query?: string;
  readonly key?: string;

  constructor(filter: Omit<IProjectFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, project: Project): Promise<[boolean, string, RankComparator]> {
    if (this.query) {
      const query = this.query.toLowerCase();
      if (!project.name.toLowerCase().includes(query)) {
        return [false, project.name, RANK_COMPARATOR_ASC];
      }
    }

    if (this.key !== undefined && project.key.toUpperCase() !== this.key.toUpperCase()) {
      return [false, project.name, RANK_COMPARATOR_ASC];
    }

    return [true, project.name, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.query ?? ''}|${this.key ?? ''}`;
  }
}

export type IProjectFilter = PublicPropertiesOf<ProjectFilter>;
export function isProjectFilter(filter: IEntityFilter): filter is ProjectFilter {
  return filter.type === EntityType.Project;
}
