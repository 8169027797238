import {Worker} from '@/app-service-worker/Worker';
import {authStore} from '@/stores/auth';
import {toastStore} from '@/stores/toast';
import {EntityType} from '@shared/EntityType';
import type {Sprint} from '@shared/models/Sprint';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

class SprintStore extends EntityStore<EntityStoreState<Sprint>, Sprint> {
  constructor(state: EntityStoreState<Sprint>) {
    super(EntityType.Sprint, state);
  }

  async createFromTemplate(template: Sprint) {
    const newSprint = this.deriveSprintFromTemplate(template);
    const result = await Worker.postEntity(EntityType.Sprint, newSprint);

    if (result.success === false) {
      const {error, status} = result;
      toastStore.error({title: 'Failed to create sprint', message: error});
      throw new Error(`Failed to create sprint (${status}): ${error}`);
    }

    return result.entities?.find((e) => e.type === EntityType.Sprint)?.entities?.[0]?.id;
  }

  deriveSprintFromTemplate(template: Sprint): Partial<Sprint> {
    let duration =
      template.endsAt && template.startsAt
        ? new Date(template.endsAt).getTime() - new Date(template.startsAt).getTime()
        : undefined;
    if (duration) {
      // round to nearest number of day
      duration = Math.round(duration / 86400_000) * 86400_000;
    }

    // TODO: use an LLM to handle more complex cases
    let name = template.name;
    let matches = template.name.match(/\d+(?!.*\d)/);
    if (matches) {
      const num = parseInt(matches[0]);
      if (num >= 0) {
        name =
          template.name.slice(0, matches.index) + (num + 1) + template.name.slice(matches.index! + matches[0].length);
      } else {
        name = `${template.name} 2`;
      }
    } else {
      name = `${template.name} 2`;
    }

    return {
      name,
      accountId: authStore.accountId,
      projectId: template.projectId,
      boardId: template.boardId,
      startsAt: template.endsAt,
      endsAt: duration ? new Date(new Date(template.endsAt!).getTime() + duration).toISOString() : undefined,
    };
  }
}

export const sprintStore = persistThroughHotReload(
  'sprintStore',
  new SprintStore({
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({sprintStore});
import.meta.hot?.accept(reloadOnHotUpdate);
