import type {SprintLabelProps} from '@/components/task/SprintLabel';
import {SprintLabel} from '@/components/task/SprintLabel';
import {SprintLabelList} from '@/components/task/SprintLabelList';
import {Popover} from '@/design-system';
import type {ISprintFilter} from '@shared/filters/SprintFilter';
import {useCallback, useState} from 'react';
import {Button, MenuTrigger} from 'react-aria-components';

interface Props extends SprintLabelProps {
  onChange: (id: number) => void;
  excludeIds?: number[];
  includeNewSprintWithName?: string;
  filter?: Omit<ISprintFilter, 'type'>;
}

export const SprintLabelSelector: React.FC<Props> = ({
  onChange,
  excludeIds,
  includeNewSprintWithName,
  filter,
  ...props
}) => {
  const [isOpen, onOpenChange] = useState(false);
  const onAction = useCallback(
    (sprintId: number) => {
      onOpenChange(false);
      onChange(+sprintId);
    },
    [onOpenChange, onChange],
  );

  return (
    <MenuTrigger aria-label="Sprint" isOpen={isOpen} onOpenChange={onOpenChange}>
      <Button className="flex max-w-full flex-grow items-center overflow-hidden rounded-sm p-0.5">
        <SprintLabel {...props} />
      </Button>
      <Popover showArrow placement="bottom" className="p-2">
        <SprintLabelList
          onAction={onAction}
          excludeIds={excludeIds}
          includeNewSprintWithName={includeNewSprintWithName}
          filter={filter}
        />
      </Popover>
    </MenuTrigger>
  );
};
