import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {TaskLink} from '@shared/models/TaskLink';
import type {DB} from 'src-sw/db/db';

export class TaskLinkFilter extends EntityFilter<ITaskLinkFilter> {
  readonly type = EntityType.TaskLink;
  readonly taskId?: number;

  constructor(filter: Omit<ITaskLinkFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, entity: TaskLink): Promise<[boolean, string, RankComparator]> {
    if (this.taskId === undefined) {
      return [true, entity.createdAt, RANK_COMPARATOR_ASC];
    }
    if (entity.srcTaskId === this.taskId || entity.dstTaskId === this.taskId) {
      return [true, entity.createdAt, RANK_COMPARATOR_ASC];
    }

    return [false, entity.createdAt, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.taskId}`;
  }
}
export type ITaskLinkFilter = PublicPropertiesOf<TaskLinkFilter>;
export function isTaskLinkFilter(filter: IEntityFilter): filter is TaskLinkFilter {
  return filter.type === EntityType.TaskLink;
}
