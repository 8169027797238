import {Worker} from '@/app-service-worker/Worker';
import {Editor, InlineEdit} from '@/design-system/InlineEdit';
import {TextField} from '@/design-system/TextField';
import {EntityType} from '@shared/EntityType';
import {FieldType, defaultFieldId} from '@shared/models/FieldSchema';
import {useCallback, useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  title?: string;
  taskId: number;
  className?: string;
}

export function EditableTaskTitle({title, taskId, className}: Props) {
  const [titleValue, setTitleValue] = useState(title ?? '');
  useEffect(() => {
    setTitleValue(title ?? '');
  }, [title]);

  const onChange = useCallback((value: string) => {
    setTitleValue(value);
  }, []);

  const onCommit = useCallback(() => {
    if (titleValue !== title) {
      Worker.mutateEntity({
        entity: EntityType.Task,
        id: taskId.toString(),
        operations: {
          replace: {
            [defaultFieldId[FieldType.Title].toString()]: {value: titleValue},
          },
        },
      });
    }
  }, [title, titleValue, taskId]);

  const onCancel = useCallback(() => {
    setTitleValue(title ?? '');
  }, [title]);

  return (
    <InlineEdit className="flex-grow rounded-sm" onCommit={onCommit} onCancel={onCancel}>
      <div className={className}>{title}</div>
      <Editor>
        <TextField
          className={twMerge('w-full rounded-sm px-1 py-1 ring-1 ring-gray-500/20', className)}
          aria-label="Title"
          defaultValue={titleValue}
          onChange={onChange}
          autoFocus
        />
      </Editor>
    </InlineEdit>
  );
}
