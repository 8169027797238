import {useRef, useState} from 'react';
import {useFocusWithin} from 'react-aria';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
}

const styles = tv({
  base: 'flex h-full cursor-text flex-col gap-2 rounded-sm border border-gray-500/15 bg-white/80 px-2 py-1 transition-colors hover:bg-white',
  variants: {
    isFocusWithin: {
      true: 'bg-white',
    },
    disabled: {
      true: 'cursor-default bg-gray-50',
    },
  },
});

export const InputWell: React.FC<Props> = ({className, disabled, children, ...props}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isFocusWithin, setFocusWithin] = useState(false);
  const {focusWithinProps} = useFocusWithin({onFocusWithinChange: setFocusWithin});

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const input = e.currentTarget.querySelector('input');
    if (input) {
      input.focus();
    } else {
      const button = e.currentTarget.querySelector('button');
      if (button) {
        button.focus();
        button.click();
      }
    }
    props.onClick?.(e);
  };

  return (
    <div
      ref={ref}
      {...focusWithinProps}
      {...props}
      className={twMerge(styles({isFocusWithin, disabled}), className)}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
