import {EntityType} from '@shared/EntityType';
import type {Account} from '@shared/models/Account';
import type {ExternalAccount} from '@shared/models/ExternalAccount';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

class ExternalAccountStore extends EntityStore<EntityStoreState<Account>, Account> {
  get() {
    // there is only one, and it should have been loaded with the app context and cached by the app
    if (!this.state.byId.size) {
      throw new Error('External account not found');
    }
    return this.state.byId.values().next().value as ExternalAccount;
  }
}

export const externalAccountStore = persistThroughHotReload(
  'externalAccountStore',
  new ExternalAccountStore(EntityType.ExternalAccount, {
    byId: new Map(),
    byList: new Map(),
  }).extend(function () {
    return {
      getList() {
        throw new Error('Not implemented');
      },
    };
  }),
);

exposeInDev({externalAccountStore});
import.meta.hot?.accept(reloadOnHotUpdate);
