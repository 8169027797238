import {exposeInDev} from '@/lib/dev';
import {Tracer} from '@/stores/lib/TracerProxy';
import type {FieldValidator} from '@/stores/ui/formStore';
import {FormDataContext, FormStore} from '@/stores/ui/formStore';
import {useEffect, useState} from 'react';
import type {FormProps as AriaFormProps} from 'react-aria-components';
import {Form as AriaForm, FormValidationContext} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';

export interface FormProps extends Omit<AriaFormProps, 'onSubmit'> {
  initialData?: (() => Record<string, any>) | Record<string, any>;
  validators?: Record<string, FieldValidator | FieldValidator[]>;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>, formStore: FormStore) => void;
}

export function Form({children, className, initialData, validators, onSubmit, ...props}: FormProps) {
  const [store] = useState(() =>
    FormStore.create((typeof initialData === 'function' ? initialData() : initialData) ?? {}, validators),
  );
  exposeInDev({[props['aria-label'] ?? 'currentFormStore']: store});

  useEffect(() => {
    validators && store.setValidators(validators);
  }, [validators]);

  useEffect(() => {
    if (initialData) {
      store.setSource(typeof initialData === 'function' ? initialData() : initialData);
    }
  }, [initialData]);

  const validationErrors = store.use((s) => s.errors, []);

  return (
    <FormDataContext.Provider value={{store}}>
      <AriaForm
        onSubmit={(e) => onSubmit?.(e, store)}
        validationBehavior="aria"
        {...props}
        className={twMerge('flex flex-col gap-5', className)}
      >
        <FormValidationContext.Provider value={Tracer.renderWithoutTracing(validationErrors)}>
          {children}
        </FormValidationContext.Provider>
      </AriaForm>
    </FormDataContext.Provider>
  );
}
