import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {Attachment} from '@shared/models/Attachment';
import type {DB} from 'src-sw/db/db';

export class AttachmentFilter extends EntityFilter<IAttachmentFilter> {
  readonly type = EntityType.Attachment;
  readonly taskId?: number;

  constructor(filter: Omit<IAttachmentFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, entity: Attachment): Promise<[boolean, string, RankComparator]> {
    if (this.taskId !== undefined && entity.taskId !== this.taskId) {
      return [false, entity.toString(), RANK_COMPARATOR_ASC];
    }
    return [true, entity.toString(), RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.taskId ?? ''}`;
  }
}
export type IAttachmentFilter = PublicPropertiesOf<AttachmentFilter>;
export function isAttachmentFilter(filter: IEntityFilter): filter is AttachmentFilter {
  return filter.type === EntityType.Attachment;
}
